import isEmpty from 'lodash/isEmpty';

export const DEFAULT_KEY = 'message';

export type NormalizedErrors = { [key: string]: string | string[] };
/**
 * Function is responsible for taking an API error of a fixed format and
 * normalizing the error fields into usable key:value objects.
 *
 * Understanding this function requires an understanding of the API error
 * format.
 *
 * @function normalizeErrors
 * @errorsess private
 * @param {object} error - An API error object
 * @param {array} keys - Array of fields to pick out from the error. Usually form field names.
 * @returns {object}
 */
export const normalizeErrors = <K extends string>(
  error: {
    statusCode?: number;
    code?: string;
    target: string;
    message: string;
    details?: {
      code: string;
      message: string;
      target: string;
    }[];
  },
  keys: Array<K> = []
): NormalizedErrors => {
  // If an empty error object has been received then return
  if (isEmpty(error)) return {};

  // This checks that keys have been provided and that the error object has
  // a details key. We can only use the keys on the error details if the
  // details exist!
  if (!error.details) {
    // If no `keys` were provided and the error has no details
    // then just show the default error message which will always be part of the API error object.
    return { [DEFAULT_KEY]: error.message };
  }

  if (!keys.length) {
    return { [DEFAULT_KEY]: error.details.map((detail) => detail.message) };
  }

  return keys.reduce((errors: NormalizedErrors, key) => {
    if (key === DEFAULT_KEY) {
      return { ...errors, [DEFAULT_KEY]: error.message };
    }

    const errorObject = error.details?.find((obj) => obj.target === key);
    return errorObject
      ? { ...errors, [errorObject.target]: errorObject.message }
      : errors;
  }, {});
};
