const neutral = {
  10: '#FAFDFF',
  50: '#F8F9FB',
  100: '#F0F1F4',
  200: '#D6D8E1',
  300: '#A1A7BA',
  400: '#878EA6',
  500: '#6D7592',
  600: '#575D75',
  700: '#414658',
  800: '#2B2F3B',
  900: '#16171D',
};

// Shifted 3 stops lighter to allow for light color variants in theme
const neutralLight = {
  50: '#FFFFFF',
  100: '#FFFFFF',
  200: '#FFFFFF',
  300: '#F8F9FB',
  400: '#F5F6F7',
  500: '#F1F3F9',
  600: '#E1E5EE',
  700: '#D1D5DE',
  800: '#BABFCE',
  900: '#8A8E99',
};

// Shifted 3 stops darker to allow for dark color variants in theme
const neutralDark = {
  50: '#E1E5EE',
  100: '#D1D5DE',
  200: '#BABFCE',
  300: '#8A8E99',
  400: '#686A73',
  500: '#42464B',
  600: '#232527',
  700: '#232527',
  800: '#232527',
  900: '#232527',
};

const neutralAlpha = {
  50: 'rgba(0, 0, 0, 0.04)',
  100: 'rgba(0, 0, 0, 0.06)',
  200: 'rgba(0, 0, 0, 0.09)',
  300: 'rgba(0, 0, 0, 0.14)',
  400: 'rgba(0, 0, 0, 0.3)',
  500: 'rgba(0, 0, 0, 0.47)',
  600: 'rgba(0, 0, 0, 0.7)',
  700: 'rgba(0, 0, 0, 0.81)',
  800: 'rgba(0, 0, 0, 0.81)',
  900: 'rgba(0, 0, 0, 0.81)',
};

const neutralLightAlpha = {
  50: 'rgba(225, 229, 238, 0.04)',
  100: 'rgba(225, 229, 238, 0.06)',
  200: 'rgba(225, 229, 238, 0.09)',
  300: 'rgba(225, 229, 238, 0.14)',
  400: 'rgba(225, 229, 238, 0.3)',
  500: 'rgba(225, 229, 238, 0.47)',
  600: 'rgba(225, 229, 238, 0.7)',
  700: 'rgba(225, 229, 238, 0.81)',
  800: 'rgba(225, 229, 238, 0.81)',
  900: 'rgba(225, 229, 238, 0.81)',
};

const blue = {
  // 50: '#E9EEFC',
  // 100: '#C0CFF6',
  // 200: '#98B0F1',
  // 300: '#7091EB',
  // 400: '#4871E5',
  // 500: '#1F52E0',
  // 600: '#1942B3',
  // 700: '#133186',
  // 800: '#0D2159',
  // 900: '#06102D',
  50: '#EEEFF7',
  100: '#CFD1E8',
  200: '#B0B3D9',
  300: '#9196C9',
  400: '#7278BA',
  500: '#545BAB',
  600: '#434989',
  700: '#323667',
  800: '#212445',
  900: '#111222',
};

const blueAlpha = {
  50: 'rgba(31, 82, 224, 0.04)',
  100: 'rgba(31, 82, 224, 0.06)',
  200: 'rgba(31, 82, 224, 0.09)',
  300: 'rgba(31, 82, 224, 0.14)',
  400: 'rgba(31, 82, 224, 0.3)',
  500: 'rgba(31, 82, 224, 0.47)',
  600: 'rgba(31, 82, 224, 0.7)',
  700: 'rgba(31, 82, 224, 0.81)',
  800: 'rgba(31, 82, 224, 0.81)',
  900: 'rgba(31, 82, 224, 0.81)',
};

const purple = {
  50: '#EFEBFA',
  100: '#D3C6F0',
  200: '#B7A2E7',
  300: '#9B7DDD',
  400: '#7F59D4',
  500: '#6334CB',
  600: '#4F2AA2',
  700: '#3B1F7A',
  800: '#281551',
  900: '#140A29',
};

const red = {
  // 50: '#ffe6e8',
  // 100: '#f8bbc0',
  // 200: '#ee9098',
  // 300: '#E76B74',
  // 400: '#CF6067',
  // 500: '#c4222d',
  // 600: '#9a1922',
  // 700: '#6f1118',
  // 800: '#44080d',
  // 900: '#1d0002',

  50: '#FDE8EC',
  100: '#F8BECA',
  200: '#F495A8',
  300: '#F06B86',
  400: '#EB4263',
  500: '#E71841',
  600: '#B91334',
  700: '#8B0E27',
  800: '#5C0A1A',
  900: '#2E050D',
};

const orange = {
  50: '#fff0df',
  100: '#F7C59F',
  200: '#f5b889',
  300: '#f09b5b',
  400: '#ec802e',
  500: '#d36716',
  600: '#a44f10',
  700: '#76380a',
  800: '#482103',
  900: '#1d0900',
};

const green = {
  // 50: '#e7f9ee',
  // 100: '#cae4d8',
  // 200: '#add1c0',
  // 300: '#85BAA1',
  // 400: '#6eac8e',
  // 500: '#73A18B',
  // 600: '#40725b',
  // 700: '#2d5141',
  // 800: '#183226',
  // 900: '#00130a',

  50: '#EBF9F4',
  100: '#C8EFE0',
  200: '#A5E4CB',
  300: '#81D9B7',
  400: '#5ECFA3',
  500: '#3BC48F',
  600: '#2F9D72',
  700: '#237656',
  800: '#174F39',
  900: '#0C271D',

  // 50: '#E9FCF4',
  // 100: '#C1F5E0',
  // 200: '#99EFCB',
  // 300: '#72E9B7',
  // 400: '#4AE3A3',
  // 500: '#22DD8F',
  // 600: '#1BB172',
  // 700: '#148556',
  // 800: '#0E5839',
  // 900: '#072C1D',
};

const pink = {
  50: '#ffe7f1',
  100: '#f4bed1',
  200: '#EDA4BD',
  300: '#e16b93',
  400: '#d74274',
  500: '#be295b',
  600: '#941f46',
  700: '#6a1532',
  800: '#420b1e',
  900: '#1b010b',
};

const brown = {
  // 50: '#ffeced',
  // 100: '#e6cfd2',
  // 200: '#cfb2b6',
  // 300: '#b89499',
  // 400: '#A07178',
  // 500: '#875F65',
  // 600: '#6c484d',
  // 700: '#4e3237',
  // 800: '#321d20',
  // 900: '#19050a',

  50: '#F4F0F1',
  100: '#E1D5D7',
  200: '#CEBABD',
  300: '#BBA0A3',
  400: '#A88589',
  500: '#956A6F',
  600: '#775559',
  700: '#594043',
  800: '#3C2A2C',
  900: '#1E1516',
};

export default {
  neutral,
  neutralLight,
  neutralDark,
  neutralAlpha,
  neutralLightAlpha,
  blue,
  blueAlpha,
  purple,
  red,
  orange,
  green,
  pink,
  brown,
};
