import React, { useEffect, useState } from 'react';
import { Flex, Button } from '@workshop/ui';
import {
  PromptForm,
  PromptFormData,
  AddItem,
  Item,
  ListDataType,
} from 'components/ListItem';

import { hooks } from 'utils';

interface PromptItemProps extends ListDataType {
  defaultFormValues?: PromptFormData;
  isDisabled?: boolean;
  onSubmit?: (values: PromptFormData) => Promise<void>;
  handleDeletePrompt?: () => void;
  onPlay?: () => void;
  onReplace?: () => void;
  showPrompt?: boolean;
  disableCancel?: boolean;
}

const PromptItem: React.FC<PromptItemProps> = (props) => {
  const {
    id,
    isDisabled = false,
    title,
    label,
    complete,
    onClick,
    helpText,
    defaultFormValues,
    onPlay,
    onReplace,
    onSubmit = async () => {},
    handleDeletePrompt = () => {},
    disableCancel,
  } = props;
  const [showPrompt, setShowPrompt] = useState(Boolean(props.showPrompt));

  useEffect(() => {
    setShowPrompt(Boolean(props.showPrompt));
  }, [props.showPrompt]);

  // TODO:
  // - Add show/hide animations

  return (
    <Flex
      flexDirection="column"
      backgroundColor="background.default"
      borderBottom="1px"
      borderColor="border.muted"
    >
      <Item
        id={id}
        title={title}
        label={label}
        isLoading={false}
        complete={complete}
        onClick={onClick}
        allowHover={false}
        clickable={false}
        padding={3}
      >
        <Button secondary mr="defaultMargin" onClick={onPlay}>
          Play
        </Button>
        {!isDisabled && (
          <Button secondary onClick={onReplace}>
            Replace
          </Button>
        )}
      </Item>
      {!showPrompt && !isDisabled && (
        <AddItem
          onSave={() => {}}
          label="Add Prompt"
          onClick={() => setShowPrompt(true)}
          helpText={helpText}
        />
      )}
      {showPrompt && (
        <PromptForm
          onSubmit={onSubmit}
          onCancel={disableCancel ? undefined : () => setShowPrompt(false)}
          handleDeletePrompt={() => {
            handleDeletePrompt();
            setShowPrompt(false);
          }}
          isLoading={false}
          helpText={helpText}
          defaultFormValues={defaultFormValues}
        />
      )}
    </Flex>
  );
};

export default PromptItem;
