import React, { useState, useEffect } from 'react';

import { CAP_CLIP_NAME } from 'constants/common';

import { Box, Button, Divider, Flex, Spinner, Text } from '@workshop/ui';
import { AddItem } from 'components/ListItem';

import { SessionStepType } from 'components/SessionPlayer';

const noop = () => null;

interface StepWrapperProps {
  header: React.ReactNode;
  footer?: React.ReactNode;
  stepContainerRef?: React.RefObject<HTMLDivElement>;
}

export const StepWrapper: React.FC<StepWrapperProps> = ({
  children,
  footer,
  header,
  stepContainerRef,
}) => {
  return (
    <Box height="100%" width="100%">
      <Flex flexDirection="column" height="100%">
        <Flex
          padding={4}
          justifyContent="center"
          alignItems="center"
          display={{ base: 'none', lg: 'block' }}
        >
          {header}
        </Flex>
        <Divider
          mt={0}
          borderColor="border.default"
          display={{ base: 'none', lg: 'block' }}
        />
        <Box ref={stepContainerRef} flex={1} overflowY="scroll" padding={2}>
          {children}
        </Box>
        {footer && <Divider borderColor="border.default" />}
        {footer && (
          <Flex
            padding={4}
            justifyContent="center"
            alignItems="center"
            flexFlow="wrap"
          >
            {footer}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export interface Step {
  children: React.ReactNode;
  footer?: React.ReactNode;
  nextDisabled?: boolean;
  nextLabel?: string;
  onClickNext?: () => Promise<any> | void;
  onClickPrev?: () => void;
  prevDisabled?: boolean;
  prevLabel?: string;
  showNextBtn?: boolean;
  showPrevBtn?: boolean;
  title: string;
}

interface StepperProps {
  currentIdx?: number;
  currentType?: SessionStepType;
  stepContainerRef?: React.RefObject<HTMLDivElement>;
  forceNextBtn?: boolean;
  loading?: boolean;
  steps: Step[];
  isEditable?: boolean;
  handleAddClip?: () => void;
  canAddClip?: boolean;
}

const Stepper: React.FC<StepperProps> = ({
  currentIdx,
  currentType,
  forceNextBtn = false,
  loading = false,
  stepContainerRef,
  steps,
  isEditable = false,
  handleAddClip = noop,
  canAddClip,
}) => {
  const [stepIdx, setStepIdx] = useState(currentIdx || 0);
  const [nextLoading, setNextLoading] = useState(false);

  const currentStepIdx = stepIdx + 1 > steps.length ? 0 : stepIdx;
  const currentStep = steps[currentStepIdx];

  // If our `currentStep` prop changes, then navigate to that step
  useEffect(() => {
    if (currentIdx !== undefined && currentIdx !== stepIdx) {
      setStepIdx(currentIdx);
    }
  }, [currentIdx]);

  if (loading) {
    return <StepWrapper header={<Text color="text.muted">Loading...</Text>} />;
  }

  const {
    children,
    footer: stepFooter,
    nextDisabled = false,
    nextLabel = 'Next',
    onClickNext = noop,
    onClickPrev = noop,
    prevDisabled = false,
    prevLabel = 'Previous',
    showNextBtn = true,
    showPrevBtn = true,
    title = '',
  } = currentStep;

  const footer: React.ReactNode[] = [];

  if (stepFooter && isEditable) {
    footer.push(stepFooter);
  }

  if (showPrevBtn) {
    footer.push(
      <Button
        key="prevBtn"
        fontSize={isEditable ? 'sm' : 'md'}
        secondary
        flex={1}
        isDisabled={prevDisabled}
        marginX={1}
        onClick={() => {
          if (prevDisabled) return;

          onClickPrev && onClickPrev();

          if (currentStepIdx - 1 < 0) return;
          setStepIdx(currentStepIdx - 1);
        }}
      >
        {prevLabel}
      </Button>
    );
  }

  if (stepFooter && !isEditable) {
    footer.push(stepFooter);
  }

  if (forceNextBtn || (showNextBtn && currentStepIdx < steps.length - 1)) {
    footer.push(
      <Button
        key="nextBtn"
        fontSize={isEditable ? 'sm' : 'md'}
        flex={1}
        isDisabled={nextDisabled || nextLoading}
        marginX={1}
        onClick={async () => {
          if (nextDisabled) return;

          setNextLoading(true);
          if (onClickNext) await onClickNext();
          setNextLoading(false);

          if (currentStepIdx + 1 >= steps.length) return;
          setStepIdx(currentStepIdx + 1);
        }}
      >
        {nextLoading ? <Spinner size="xs" speed="0.75s" /> : nextLabel}
      </Button>
    );
  }

  return (
    <StepWrapper
      header={<Text fontWeight="semibold">{title}</Text>}
      footer={footer}
      stepContainerRef={stepContainerRef}
    >
      {children}
      {isEditable && currentType === 'normal' && canAddClip && (
        <Flex my={4} mx={3}>
          <AddItem
            label={`Add a ${CAP_CLIP_NAME}`}
            onSave={() => {}}
            onClick={handleAddClip}
            // isUpdating={isClipLoading}
            variant="dotted"
            button
            hideCard
            icon="HdrStrong"
          />
        </Flex>
      )}
    </StepWrapper>
  );
};

export default Stepper;
