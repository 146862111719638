import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { MdIcon, Flex, Button, Input } from '@workshop/ui';

import AnimatedDropDown from 'components/Common/AnimatedDropDown';

const EXTENDED_WIDTH = 250;

interface Form {
  userId: number;
}

interface ImpersonateUserInputProps {
  isSubmitting?: boolean;
  onSubmit: (data: Form) => {};
  onClick?: () => void;
  onBg?: boolean;
}

const ImpersonateUserInput: React.FC<ImpersonateUserInputProps> = ({
  isSubmitting,
  onSubmit,
  onClick,
  onBg,
}) => {
  const { register, handleSubmit, formState, reset } = useForm<Form>();

  const [isOpen, setIsOpen] = useState(false);

  const handleBtnClick = () => {
    onClick && onClick();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleSubmitId = handleSubmit(async (data) => {
    onSubmit(data);
  });

  return (
    <AnimatedDropDown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Impersonate"
      extendedWidth={EXTENDED_WIDTH}
      secondary
      leftIcon={<MdIcon name="BugReport" color="inherit" />}
      fontSize="sm"
      ml="defaultMargin"
      onClick={handleBtnClick}
      dropdownStyle={{
        maxHeight: 'inherit',
      }}
      minH="100px"
      onBg={onBg}
      name="Impersonate"
    >
      <Input
        ref={register}
        name="userId"
        placeholder="User ID to impersonate"
        border="none"
        borderRadius={0}
      />
      <Flex
        alignContent="center"
        padding="defaultPadding"
        justifyContent="space-between"
      >
        <Button
          colorScheme="blue"
          isLoading={isSubmitting}
          loadingText="Sending..."
          isDisabled={!formState.dirty || isSubmitting}
          onClick={handleSubmitId}
        >
          Impersonate
        </Button>
      </Flex>
    </AnimatedDropDown>
  );
};

export default ImpersonateUserInput;
