import { Action } from 'types';
import { UserCheckListState, UserLibraryState } from 'types/learner';
import { ProfileAT, CourseAT } from 'redux/actionTypes/learner';

const initialState: UserLibraryState = {
  userLibrary: {
    id: 0,
    courses: [],
    purchased: [],
    removed: [],
    sessions: [],
    wishlist: [],
  },
  userLibraryItems: {},
};

export const userLibraryReducer = (
  state = initialState,
  action: Action
): UserLibraryState => {
  switch (action.type) {
    case ProfileAT.FETCH_USER_LIBRARY_SUCCESS:
      return {
        ...state,
        userLibrary: { ...state.userLibrary, ...action.payload.result },
        userLibraryItems: {
          ...state.userLibraryItems,
          ...action.payload.entities.libraryEntry,
        },
      };
  }
  return state;
};

export const userChecklistReducer = (
  state = {},
  action: Action
): UserCheckListState => {
  switch (action.type) {
    case CourseAT.FETCH_COURSE_PROGRESS_SUCCESS:
    case ProfileAT.UPDATE_USER_CHECKLIST_SUCCESS:
      const {
        entities: { userCheckListState },
      } = action.payload;

      return userCheckListState ? { ...state, ...userCheckListState } : state;

    default:
      return state;
  }
};
