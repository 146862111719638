import { useSelector } from 'react-redux';
import { GlobalState } from 'types';

export const getUserDetails = (state: GlobalState) => state.user.userDetails;

export const useIsUserLoading = () =>
  useSelector((state: GlobalState) => state.ui.user.loading);

// TODO: Do we need to use userDetails.isMentor for any reason?
export const useIsMentor = () => true;
// export const useIsMentor = () =>
//   useSelector((state: GlobalState) => state.user.userDetails.isMentor);

export const useIsStaff = () =>
  useSelector((state: GlobalState) => state.user.userDetails.isStaff);

export const useIsImpersonated = () =>
  useSelector((state: GlobalState) => state.user.userDetails.isImpersonated);

export const useDismissedInformationCards = () =>
  useSelector(
    (state: GlobalState) =>
      state.user.userDetails.dismissedInformationCards || []
  );

export const useDiscourseUser = () =>
  useSelector((state: GlobalState) => state.user.discourseUser.user);

export const useUser = () =>
  useSelector((state: GlobalState) => state.user.userDetails);

export const useUserProfile = () =>
  useSelector((state: GlobalState) => state.user.userProfile);
