import React from 'react';
import moment from 'moment';

import { Box, Divider, Text, chakra } from '@workshop/ui';

import { LabelWrapper } from 'components/Common';
import { Loading } from 'components/Loading';

import { License, CostRecord } from 'types/cms';

import {
  EditModal,
  Props as EditModalProps,
} from 'components/Common/EditModal';

export interface EditLicenseFormData {
  organisation: number;
  enrolmentsTotal: number;
}

interface EditLicenseModalProps extends EditModalProps {
  license: License;
  costRecord?: CostRecord;
  showLicensorInfo: boolean;
  showLicenseeInfo: boolean;
  isLoading: boolean;
}

const EditLicenseModal: React.FC<EditLicenseModalProps> = (props) => {
  const {
    isOpen,
    modalSize,
    onClose,
    title,
    license,
    costRecord,
    isLoading,
    showLicensorInfo,
    showLicenseeInfo,
  } = props;

  const handleOnClose = () => {
    onClose();
  };

  const modalProps = {
    isOpen,
    modalSize,
    onCancel: handleOnClose,
    onClose: handleOnClose,
    onSave: handleOnClose,
    title,
  };

  // TODO: Allow edits to courses & expiryDate (if licensor org)
  return (
    <EditModal saveLabel="" cancelLabel="Close" {...modalProps}>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <LabelWrapper label="Created">
            {moment(license.created).format('Do MMMM YYYY')}
          </LabelWrapper>
          <LabelWrapper label="By">{license.licensor.name}</LabelWrapper>
          <LabelWrapper label="For">{license.licensee.name}</LabelWrapper>
          <LabelWrapper label="Course(s)">
            {license.courses.map(
              (course, courseIdx) =>
                `${course.title}${
                  courseIdx + 1 === license.courses.length ? '' : ', '
                }`
            )}
          </LabelWrapper>
          <LabelWrapper label="Enrolments">
            {`${license.totalEnrolments - license.enrolments.length} of ${
              license.totalEnrolments
            } remaining`}
          </LabelWrapper>
          <LabelWrapper label="Expiry Date">
            {license.expiryDate
              ? moment(license.expiryDate).format('Do MMMM YYYY')
              : 'None'}
          </LabelWrapper>
          {showLicensorInfo && (
            <>
              <Divider my="4" />
              {costRecord ? (
                <LabelWrapper label="Cost">{`£${costRecord.cost}`}</LabelWrapper>
              ) : null}
              <LabelWrapper label="Issued By">
                {license.createdByUser.name}
              </LabelWrapper>
            </>
          )}
          {showLicenseeInfo && license.licensor.contactEmail && (
            <>
              <Divider my="4" />

              <Text
                marginX={{ base: 'defaultMargin', md: 0 }}
                color="text.muted"
                fontSize="sm"
                mb={4}
              >
                {`If you have any questions for ${license.licensor.name}, please contact `}
                <chakra.a
                  href={`mailto:${license.licensor.contactEmail}`}
                  color="text.primary"
                  fontWeight="semibold"
                >
                  {license.licensor.contactEmail}
                </chakra.a>
                {'.'}
              </Text>
            </>
          )}
        </Box>
      )}
    </EditModal>
  );
};

export default EditLicenseModal;
