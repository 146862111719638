import React, { FC } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { Button, MdIcon, MdIconProps } from '@workshop/ui';

interface AlertDialogIconProps extends MdIconProps {
  onCancel: () => void;
  onSubmit: () => void;
  alertHeader: string;
  alertBody: string;
  submitBtnLabel: string;
  submitBtnColor: string;
}

const AlertDialogIcon: FC<AlertDialogIconProps> = ({
  name,
  onCancel,
  onSubmit,
  alertHeader,
  alertBody,
  submitBtnLabel,
  submitBtnColor,
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<any>();

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <>
      <MdIcon {...rest} name={name} onClick={() => setIsOpen(true)} />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent borderRadius="md">
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            paddingX={6}
            pt={6}
            pb={2}
          >
            {alertHeader}
          </AlertDialogHeader>

          <AlertDialogBody paddingX={6} pt={0} pb={6}>
            {alertBody}
          </AlertDialogBody>

          <AlertDialogFooter paddingX={6} pt={0} pb={6}>
            {/* <Button secondary ref={cancelRef} onClick={handleCancel}> */}
            <Button secondary onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              colorScheme={submitBtnColor}
              onClick={handleSubmit}
              ml="defaultMargin"
            >
              {submitBtnLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AlertDialogIcon;
