import unionBy from 'lodash/unionBy';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'fast-deep-equal';

import { Category, Post, Topic } from 'discourse-js';

import { Action } from 'types';

import { discourseUrl } from 'constants/env';
import {
  DiscourseCategoryState,
  DiscourseMembersState,
  DiscourseTopicsState,
  FetchDiscourseSubcategorySuccess,
  DiscourseUserSummaryState,
  DiscourseNotificationState,
  DiscourseMessagesState,
} from 'types/common';
import { DiscourseAT } from 'redux/actionTypes/common';

/**
 * getPostsForTopic Function.
 * Pass in a topic_id and a state object
 * returns the posts from the post_stream.
 */
function getPostsForTopic(
  topicId: number,
  state: DiscourseTopicsState
): Post[] {
  const postStream = state[topicId] && state[topicId].postStream;
  return postStream ? postStream.posts : [];
}

const updateDiscourseCategoryState = (
  state: DiscourseCategoryState,
  action: FetchDiscourseSubcategorySuccess
): DiscourseCategoryState => {
  const {
    result,
    entities: { discourseCategories },
  } = action.payload;

  const actionCategory = discourseCategories[result];
  const stateCategory = state[result];

  // If the category we're updating doesn't already exist in the
  // state
  if (!stateCategory) {
    // Check for noDifferences on the current state and the state
    // received from the discourseCategories.
    return isEqual(state, discourseCategories)
      ? state
      : {
          ...state,
          ...discourseCategories,
        };
  }

  const updatedCategory: Category = {
    ...stateCategory,
    primaryGroups: [],
    users: stateCategory.users,
    topicList: {
      ...stateCategory.topicList,
      topics: stateCategory.topicList.topics,
    },
  };

  if (actionCategory) {
    const topics = orderBy(
      unionBy(
        actionCategory.topicList.topics,
        stateCategory.topicList.topics,
        'id'
      ),
      ['id'],
      ['desc']
    );

    updatedCategory.users = unionBy(
      actionCategory.users || [],
      stateCategory.users,
      'id'
    );

    updatedCategory.topicList = {
      ...updatedCategory.topicList,
      ...actionCategory.topicList,
      topics,
    };
  }

  return isEqual(stateCategory, updatedCategory)
    ? state
    : {
        ...state,
        [result]: updatedCategory,
      };
};

export const discourseCategoryReducer = (
  state: DiscourseCategoryState = {},
  action: Action
): DiscourseCategoryState => {
  switch (action.type) {
    case DiscourseAT.FETCH_SUBCATEGORY_SUCCESS:
      return updateDiscourseCategoryState(state, action);
    default:
      return state;
  }
};

export const discourseMembersReducer = (
  state: DiscourseMembersState = {},
  action: Action
): DiscourseMembersState => {
  switch (action.type) {
    case DiscourseAT.FETCH_GROUP_MEMBERS_SUCCESS: {
      const updatedMembers = action.payload.members.reduce(
        (acc, member) => ({
          ...acc,
          // If the member already exists in the state, update the existing
          // record, specifically overriding the `avatarTemplate` with specific
          // dimensions
          [member.id]: {
            ...state[member.id],
            ...member,
            avatarTemplate: `${discourseUrl}${member.avatarTemplate.replace(
              '{size}',
              '240'
            )}`,
          },
        }),
        {}
      );

      return {
        ...state,
        ...updatedMembers,
      };
    }

    case DiscourseAT.FETCH_DISCOURSE_MESSAGES_SUCCESS: {
      // Combine all the users from all the messages into a single array
      const usersList = action.payload
        .flatMap((message) => message.users)
        .filter((u) => u && u.id !== -1);

      const updatedMembers = usersList.reduce(
        (acc, user) => ({
          ...acc,
          // If the member already exists in the state, update the existing
          // record, specifically overriding the `avatarTemplate` with specific
          // dimensions
          [user.id]: {
            ...state[user.id],
            ...user,
            avatarTemplate: `${discourseUrl}${user.avatarTemplate.replace(
              '{size}',
              '240'
            )}`,
          },
        }),
        {}
      ) as DiscourseMembersState;

      return {
        ...state,
        ...updatedMembers,
      };
    }

    case DiscourseAT.FETCH_TOPICS_BY_USER_SUCCESS:
      const { username, result: topicsByUserResult } = action.payload;
      const user = Object.values(state).find((u) => u.username === username);
      if (user) {
        return {
          ...state,
          [user.id]: {
            ...state[user.id],
            topics: [...(state[user.id].topics || []), ...topicsByUserResult],
          },
        };
      }
      return state;

    default:
      return state;
  }
};

export const discourseTopicsReducer = (
  state: DiscourseTopicsState = {},
  action: Action
): DiscourseTopicsState => {
  let post: Post;
  let topic: Partial<Topic>;
  let posts: Post[];
  let stream = null;

  switch (action.type) {
    case DiscourseAT.FETCH_USER_SUMMARY_SUCCESS:
      const topics = action.payload.topics.reduce(
        (acc: DiscourseTopicsState, topic) => {
          return { ...acc, [topic.id]: topic };
        },
        {}
      );
      return {
        ...state,
        ...topics,
      };

    case DiscourseAT.FETCH_TOPIC_SUCCESS:
      const newTopic =
        action.payload.entities.discourseTopics[action.payload.result];
      topic = state[newTopic.id];

      return {
        ...state,
        [newTopic.id]: newTopic,
      };

    case DiscourseAT.FETCH_TOPICS_BY_USER_SUCCESS:
      const {
        entities: { discourseTopics: topicsByUser },
      } = action.payload;

      return {
        ...state,
        ...topicsByUser,
      };

    case DiscourseAT.CREATE_POST_REPLY_SUCCESS:
      ({ post } = action.payload);
      topic = state[post.topicId];

      if (!topic) return { ...state };

      posts = topic.postStream?.posts || [];
      posts.push(post);

      stream = topic.postStream?.stream || [];
      stream.push(post.id);

      return {
        ...state,
        [post.topicId]: {
          ...topic,
          postStream: {
            posts,
            stream,
          },
        },
      };

    case DiscourseAT.LIKE_POST_SUCCESS:
    case DiscourseAT.UNLIKE_POST_SUCCESS:
      post = action.payload;
      topic = state[post.topicId];

      if (!topic) return { ...state };

      posts = topic.postStream?.posts || [];
      posts = posts.map((p) => (p.id === post.id ? post : p));

      stream = topic.postStream?.stream || [];

      return {
        ...state,
        [post.topicId]: {
          ...topic,
          postStream: {
            stream,
            posts,
          },
        },
      };

    case DiscourseAT.FETCH_TOPIC_POSTS_SUCCESS:
      const {
        entities: { discourseTopics },
        result: topicId,
      } = action.payload;

      const resTopic = discourseTopics[topicId.toString()];
      topic = state[topicId];

      if (!resTopic || !topic) return { ...state };

      const resPosts = resTopic.postStream.posts;

      ({ stream, posts } = topic.postStream || { stream: [], posts: [] });

      posts = uniqBy([...resPosts, ...posts], 'id');

      return {
        ...state,
        [topicId]: { ...topic, postStream: { stream, posts } },
      };

    case DiscourseAT.SEND_PRIVATE_MESSAGE_SUCCESS:
      topic = state[action.payload.topicId];

      if (!topic) return state;

      const postId = action.payload.id;

      stream = topic.postStream?.stream
        ? [...topic.postStream.stream, postId]
        : [action.payload.id, postId];

      posts = topic.postStream?.posts
        ? [...topic.postStream.posts, action.payload]
        : [action.payload];

      return {
        ...state,
        [action.payload.topicId]: {
          ...topic,
          postStream: { stream, posts },
        },
      };

    default:
      return state;
  }
};

export const discourseUserSummaryReducer = (
  state: DiscourseUserSummaryState = {},
  action: Action
): DiscourseUserSummaryState => {
  switch (action.type) {
    case DiscourseAT.FETCH_USER_SUMMARY_SUCCESS:
      let { meta } = action;
      if (!meta || !('username' in meta)) return state;

      return {
        ...state,
        [meta.username as string]: action.payload.userSummary,
      };

    default:
      return state;
  }
};

export const discourseNotificationsReducer = (
  state: DiscourseNotificationState = [],
  action: Action
): DiscourseNotificationState => {
  switch (action.type) {
    case DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_REQUEST:
    case DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_FAILURE:
      return state;
    case DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_SUCCESS:
      const { discourseNotifications } = action.payload.entities;

      /**
       * If the notifications are being updated from the result
       * of a message bus poll response, check to see if any `recent`
       * IDs are present - if so, we use this array of IDs to check
       * if any notifications have been deleted/removed by discourse
       * before adding any new ones.
       *
       * Scenario: User has a '2 replies' notification, if this transitions
       * to '3 replies' discourse deletes the old notification and
       * creates a new one - this code is responsible for deleting
       * the old one, even though we don't have the ID of the deleted
       * notification explicitly given to us.
       *
       * Take a copy of the state which we can mutate for ease
       */
      const oldNotifications = { ...state };

      const {
        payload: { recent },
      } = action;
      if (recent) {
        // recent has format [id, isRead]
        const recentIds = recent.map((r) => r[0]);
        // For notifications with an ID lower than what discourse has
        // provided in the recent array (i.e. old notifications), we
        // don't even need to check if they have changed in our filter
        // function, so we grab the lowest ID from the recent array.
        const filterUpTo = Math.min(...recentIds);
        const notificationIds = Object.keys(state);
        // Find any notification IDs which are greater than our minimum
        // ID calculated above, and which don't appear in the recent ID
        // array discourse has provided.
        const invalidIds = notificationIds.filter(
          (id) =>
            parseInt(id) >= filterUpTo && !recentIds.includes(parseInt(id))
        );
        // Remove these notifications from our state
        invalidIds.forEach((id) => delete oldNotifications[parseInt(id)]);

        // Use the recent notification array to ensure our notification
        // state is correctly up to date.
        recent.forEach((r) => {
          if (oldNotifications[r[0]]) {
            oldNotifications[r[0]].read = r[1];
          }
        });
      }

      return {
        ...oldNotifications,
        ...discourseNotifications,
      };
    default:
      return state;
  }
};

export const discourseNotificationsCountReducer = (
  state: number = 0,
  action: Action
): number => {
  switch (action.type) {
    case DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_COUNT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const discourseMessagesReducer = (
  state: DiscourseMessagesState = {},
  action: Action
): DiscourseMessagesState => {
  switch (action.type) {
    case DiscourseAT.FETCH_DISCOURSE_MESSAGES_SUCCESS:
      // Combine all the topics from all the message lists into a single array
      // of topics
      const topicLists = action.payload.flatMap(
        (message) => message.topicList.topics
      );

      // Turn the array of topics back into an object, whether the key of each
      // item is the topic ID
      const newMessages = topicLists.reduce(
        (acc: DiscourseMessagesState, topic) => ({ ...acc, [topic.id]: topic }),
        {}
      );

      return {
        ...state,
        ...newMessages,
      };

    default:
      return state;
  }
};
