import React, { useState, useEffect } from 'react';
import moize from 'moize';

import {
  Flex,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  Button,
  Collapse,
  MdIcon,
  Spinner,
} from '@workshop/ui';

import navRoutes from 'navigation/Routes';

import { ISessionSummary } from 'types/cms';

import { CohortMember, JournalUnitSummary } from './';

interface JournalTable {
  members: CohortMember[];
  journalUnits: JournalUnitSummary[];
}

interface ModalProps extends JournalTable {
  isOpen: boolean;
  onClose: () => void;
}

const findJournalEntries = moize(
  (member: CohortMember, session: number) =>
    member.journalEntries?.filter(
      (entry) => entry.destinationObjectId === session
    ) || []
);

export const JournalEntryTable: React.FC<JournalTable> = ({
  members,
  journalUnits,
}) => {
  // Control the display of specific rows of data
  const [expandedData, setExpandedData] = useState<{
    session: number | null;
    user: number | null;
  }>({ session: null, user: null });

  const handleToggle = (user: number, session: number) => {
    setExpandedData(
      user === expandedData.user && session === expandedData.session
        ? { session: null, user: null }
        : { session, user }
    );
  };

  // Extract a flat list of sessions from the list of units
  const sessions = journalUnits.reduce((acc, curr) => {
    return [...acc, ...curr.modules];
  }, [] as ISessionSummary[]);

  return (
    <Table variant="striped" size="sm">
      <Thead>
        <Tr>
          <Th>&nbsp;</Th>
          {journalUnits.map((unit, idx) => (
            <Th
              key={`unit-heading-${unit.id}`}
              colSpan={unit.modules.length}
              textAlign={unit.modules.length > 1 ? 'center' : 'left'}
              letterSpacing="normal"
              textTransform="none"
              fontWeight="bold"
              color="text.default"
              backgroundColor={
                idx % 2 === 0 ? 'background.tint3' : 'background.tint1'
              }
              borderTopRadius="md"
            >
              {unit.title}
            </Th>
          ))}
        </Tr>
        <Tr>
          <Th>&nbsp;</Th>
          {sessions.map((session) => (
            <Th
              key={`session-heading-${session.id}`}
              letterSpacing="normal"
              textTransform="none"
              fontWeight="semibold"
              color="text.muted"
            >
              {session.title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {members.map((member, idx) => (
          <Tr key={`journal-row-${member.id}`}>
            <Td>{member.name}</Td>
            {sessions.map((session) => {
              const entries = findJournalEntries(member, session.id);

              return (
                <Td
                  key={`journal-cell-${member.id}-${session.id}`}
                  valign="top"
                >
                  {entries.length > 0 ? (
                    <Flex direction="column">
                      <Button
                        size="sm"
                        aria-label="Show More"
                        leftIcon={<MdIcon name="Check" />}
                        colorScheme="green"
                        onClick={() => handleToggle(member.id, session.id)}
                      >
                        Show Uploads
                      </Button>
                      <Collapse
                        in={
                          expandedData.user === member.id &&
                          expandedData.session === session.id
                        }
                        animateOpacity
                      >
                        <Flex
                          flexDir="column"
                          paddingX="defaultPadding"
                          mt="defaultMargin"
                          pb="defaultMargin"
                          bg={
                            idx % 2 === 0
                              ? 'background.default'
                              : 'background.tint2'
                          }
                          rounded="md"
                        >
                          {entries.map((entry, i) => (
                            <Button
                              key={`link-${entry.id}`}
                              mt="defaultMargin"
                              icon="OpenInNew"
                              size="sm"
                              onClick={() =>
                                window.open(
                                  navRoutes.cms.cmsViewPost.path(
                                    entry.discourseTopicId
                                  ),
                                  '_blank'
                                )
                              }
                            >
                              View Upload {i + 1}
                            </Button>
                          ))}
                        </Flex>
                      </Collapse>
                    </Flex>
                  ) : (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      background="background.error"
                      borderRadius="md"
                      whiteSpace="nowrap"
                      height={8}
                      px={6}
                    >
                      <MdIcon mr={1.5} name="Error" color="icon.error" />
                      <Text fontSize="sm" color="text.error">
                        No Uploads
                      </Text>
                    </Flex>
                  )}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export const JournalEntryModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  members,
  journalUnits,
}) => {
  const [renderBody, setRenderBody] = useState(false);

  // This is quite nasty, but fixes a very janky UI.
  //
  // When the modal is shown, wait 250ms before rendering the modal body.
  // This more or less removes the entire animation lag and presents quite
  // a nice artificial loading UI
  useEffect(() => {
    const timeout = setTimeout(() => setRenderBody(true), 250);
    return () => {
      clearTimeout(timeout);
      setRenderBody(false);
    };
  }, [isOpen]);

  return (
    <Modal
      onClose={onClose}
      motionPreset="none"
      scrollBehavior="outside"
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent maxW={{ base: '100vw', md: '90vw' }}>
        <ModalHeader>Student Uploads</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowX="scroll">
          {renderBody ? (
            <JournalEntryTable members={members} journalUnits={journalUnits} />
          ) : (
            <Spinner />
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
