import { AssistantState } from 'types/common';
import { AssistantAT } from 'redux/actionTypes/common';
import { Action } from 'types';

const assistantInitialState: AssistantState = {
  coursePlans: {},
  responses: {},
  digests: {},
};

export const assistantReducer = (
  state = assistantInitialState,
  action: Action
): AssistantState => {
  switch (action.type) {
    case AssistantAT.CREATE_COURSE_PLAN_REQUEST:
    case AssistantAT.GET_COURSE_PLAN_REQUEST:
    case AssistantAT.UPDATE_COURSE_PLAN_REQUEST:
    case AssistantAT.CREATE_ASSISTANT_RESPONSE_REQUEST:
    case AssistantAT.GET_ASSISTANT_RESPONSE_REQUEST:
    case AssistantAT.UPDATE_ASSISTANT_RESPONSE_REQUEST:
    case AssistantAT.GET_DIGEST_REQUEST:
    case AssistantAT.GET_DIGEST_LIST_REQUEST:
      return state;
    case AssistantAT.CREATE_COURSE_PLAN_SUCCESS:
    case AssistantAT.GET_COURSE_PLAN_SUCCESS:
    case AssistantAT.UPDATE_COURSE_PLAN_SUCCESS:
      return {
        ...state,
        coursePlans: {
          ...state.coursePlans,
          [action.payload.slug]: action.payload,
        },
      };
    case AssistantAT.CREATE_ASSISTANT_RESPONSE_SUCCESS:
    case AssistantAT.GET_ASSISTANT_RESPONSE_SUCCESS:
    case AssistantAT.UPDATE_ASSISTANT_RESPONSE_SUCCESS:
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.payload.slug]: action.payload,
        },
      };
    case AssistantAT.GET_DIGEST_SUCCESS:
      return {
        ...state,
        digests: {
          ...state.digests,
          [action.payload.slug]: action.payload,
        },
      };
    case AssistantAT.GET_DIGEST_LIST_SUCCESS:
      return {
        ...state,
        digests: {
          ...state.digests,
          ...action.payload.entities.digests,
        },
      };
    case AssistantAT.CREATE_COURSE_PLAN_FAILURE:
    case AssistantAT.GET_COURSE_PLAN_FAILURE:
    case AssistantAT.UPDATE_COURSE_PLAN_FAILURE:
    case AssistantAT.CREATE_ASSISTANT_RESPONSE_FAILURE:
    case AssistantAT.GET_ASSISTANT_RESPONSE_FAILURE:
    case AssistantAT.UPDATE_ASSISTANT_RESPONSE_FAILURE:
    case AssistantAT.GET_DIGEST_FAILURE:
    case AssistantAT.GET_DIGEST_LIST_FAILURE:
      return state;
    default:
      return state;
  }
};
