import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  GroupMember,
  NotificationType,
  NotificationList,
  TopicNotification,
  GroupNotification,
} from 'discourse-js';

import navRoutes from 'navigation/Routes';

import { MdIcon, Divider, Flex, Text, Button, useTheme } from '@workshop/ui';

import { GlobalState, Action } from 'types';

import { hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';
import { getOpenMessageActions } from 'utils/discourse';
import { useCurrentTeam } from 'redux/selectors';
import { discourseActions } from 'redux/actions/common';

import AnimatedDropDown from 'components/Common/AnimatedDropDown';

import NotificationElement from './NotificationElement';

interface Props {
  // notifications: TopicNotification[];
  groupMembers?: GroupMember[];
  notifications: NotificationList['notifications'];
  showLoadMore: boolean;
  onBg?: boolean;
}

const EXTENDED_WIDTH = 400;

const InboxNotifications: React.FC<Props> = (props) => {
  const { notifications, showLoadMore, onBg } = props;

  const dispatch = hooks.useDispatch();

  const history = useHistory();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const discourseMembers = useSelector((state: GlobalState) =>
    Object.values(state.discourse.members)
  );

  const currentTeam = useCurrentTeam();

  // Retrieve all of the user's cohorts, which should be available on load,
  // where we can find data to enrich the notifications to display correctly.
  const cohorts = useSelector((state: GlobalState) => {
    return Object.values({
      ...state.cms.cohort,
      ...state.learner.courses.cohorts,
    });
  });

  const [isOpen, setIsOpen] = useState(false);

  const unreadNotifications = notifications.filter((item) => !item.read);

  const handleBtnClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNotificationClick = (
    notification: TopicNotification | GroupNotification
  ) => {
    const { id, topicId } = notification;
    dispatch(discourseActions.markNotificationRead(id));

    // Detect whether the notification clicked is a private message
    const isPrivateMessage =
      notification.notificationType === NotificationType.privateMessage;

    // If it's not a private message --> take the user to the post
    if (!isPrivateMessage) {
      if (currentTeam) {
        history.push(navRoutes.cms.cmsViewPost.path(topicId));
      } else {
        history.push(navRoutes.learner.genericViewPost.path(topicId));
      }

      setIsOpen(false);
      return;
    }

    const actions = getOpenMessageActions({
      notification,
      cohorts,
      discourseMembers,
    });
    if (actions) {
      actions?.forEach((action) => dispatch(action as Action));
    }

    setIsOpen(false);
  };

  return (
    <AnimatedDropDown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Inbox"
      minimisedWidth={isMobile ? 40 : undefined}
      extendedWidth={EXTENDED_WIDTH}
      secondary
      leftIcon={<MdIcon name="Mail" color="inherit" mr={{ base: -2, md: 0 }} />}
      onClick={handleBtnClick}
      onBg={onBg}
      name="Inbox"
      {...(unreadNotifications.length > 0 && !isOpen
        ? {
            backgroundColor: 'common.notification',
            color: 'text.light',
            _hover: { color: 'text.default', bg: 'background.tint4' },
          }
        : {})}
    >
      {notifications.length > 0 ? (
        notifications.map((notification) => {
          return (
            <Flex
              key={notification.id}
              direction="column"
              width={{ base: '100%', md: EXTENDED_WIDTH }}
            >
              <NotificationElement
                notification={notification}
                handleNotificationClick={() =>
                  handleNotificationClick(notification)
                }
                cohorts={cohorts}
              />
              <Divider borderColor="border.default" margin={0} />
            </Flex>
          );
        })
      ) : (
        <Flex
          margin="defaultMargin"
          my={8}
          justifyContent="center"
          alignItems="center"
        >
          <Text color="common.muted">You have no new notifications</Text>
        </Flex>
      )}
      {showLoadMore ? (
        <Flex
          margin="defaultMargin"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            flex={1}
            icon="ExpandMore"
            variant="ghost"
            onClick={() =>
              dispatch(
                discourseActions.getNotifications({
                  offset: notifications.length,
                })
              )
            }
          >
            Show More
          </Button>
        </Flex>
      ) : null}
    </AnimatedDropDown>
  );
};

export default InboxNotifications;
