import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import navRoutes from 'navigation/Routes';

import { Flex, Text, LinkButton, Card, Spinner, MdIcon } from '@workshop/ui';

import { authActions } from 'redux/actions/common';
import { BrandLg } from 'components/Brand';
import { Loading } from 'components/Loading';

const ConfirmEmailScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { key } = useParams<{ key: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const confirmEmail = async () => {
    setLoading(true);
    const res = await dispatch(authActions.confirmEmail(key));
    if (res.type === 'CONFIRM_EMAIL_FAILURE') {
      setError(true);
    } else {
      setTimeout(() => history.push(navRoutes.common.home.path()), 3000);
    }
    setLoading(false);
  };

  useEffect(() => {
    confirmEmail();
  }, []);

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Link to="/">
            <BrandLg mb={10} />
          </Link>
          <Card
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            maxWidth={550}
            width="100%"
            padding={{ base: 6, sm: 10 }}
          >
            {error ? (
              <>
                <MdIcon
                  name="Error"
                  boxSize="image.sm"
                  mb={6}
                  color="icon.muted"
                />
                <Text
                  fontSize="2xl"
                  fontWeight="semibold"
                  mb={6}
                  textAlign="center"
                >
                  Oops, something went wrong
                </Text>
                <Text color="text.muted" mb={8}>
                  It looks like this link is invalid, please return to the
                  homepage.
                </Text>
                <LinkButton to="/">Back to Home</LinkButton>
              </>
            ) : (
              <>
                <MdIcon
                  name="MarkEmailRead"
                  boxSize="image.sm"
                  mb={6}
                  color="common.primary"
                />
                <Text
                  fontSize="2xl"
                  fontWeight="semibold"
                  mb={6}
                  textAlign="center"
                >
                  Your email has been confirmed 👍
                </Text>
                <Flex alignItems="center" mb={6}>
                  <Spinner color="icon.muted" />
                  <Text color="text.muted" ml={4}>
                    Redirecting to homepage
                  </Text>
                </Flex>
                <LinkButton to="/" size="sm" variant="ghost">
                  (Click here if it's taking too long)
                </LinkButton>
              </>
            )}
          </Card>
        </>
      )}
    </Flex>
  );
};

export default ConfirmEmailScreen;
