import React, { CSSProperties } from 'react';
import assign from 'lodash/assign';
import Select, {
  Props as SelectProps,
  ValueType,
  IndicatorProps,
  OptionProps,
  components as SelectComponents,
  PlaceholderProps,
  ValueContainerProps,
  IndicatorContainerProps,
  SingleValueProps,
  GroupedOptionsType,
  ContainerProps,
  OptionTypeBase,
} from 'react-select';
import { Paragraph, Color, MdIcon, Card, Flex, FlexProps } from '@workshop/ui';

interface SelectCardProps<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean = false
> extends SelectProps<IOptionType, IsMulti> {
  options: GroupedOptionsType<IOptionType>;
  label?: string;
  // placeholder?: string;
  // isDisabled?: boolean;
  width?: number;
  // customStyles?: Styles;
  // customComponents?: SelectComponentsConfig<{}>;
  // react-select incorrect typing for onChange props - hence the 'any'
  onSelect: (option: ValueType<IOptionType, false>) => void;
  cardProps?: FlexProps;
}

interface CustomValueContainerProps<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean
> extends ValueContainerProps<IOptionType, IsMulti> {
  label?: string;
}

interface CustomContainerProps<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean
> extends ContainerProps<IOptionType, IsMulti> {
  width?: number;
}

// Our default styles for react-select. Any new styles from props are merged in
// replacing the base style
function baseCustomStyles<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean
>() {
  return {
    container: (base: CSSProperties) => ({
      ...base,
      flex: 1,
    }),
    control: (base: CSSProperties) => ({
      ...base,
      border: 'none',
      margin: 0,
      cursor: 'pointer',
    }),
    valueContainer: (base: CSSProperties) => ({
      ...base,
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      overflow: 'hidden',
      marginTop: 15,
    }),
    menuList: (base: CSSProperties) => ({
      ...base,
      padding: 0,
    }),
    group: (base: CSSProperties) => ({
      ...base,
      padding: 0,
    }),
    groupHeading: (base: CSSProperties) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    option: (
      base: CSSProperties,
      state: OptionProps<IOptionType, IsMulti>
    ) => ({
      ...base,
      backgroundColor: state.isSelected ? Color.common.primary : 'white',
      color: state.isSelected ? 'white' : 'black',
      ':active, :focus, :hover, :visited': {
        backgroundColor: Color.common.primary,
        color: 'white',
      },
      cursor: 'pointer',
    }),
    indicatorsContainer: () => ({
      paddingRight: 0,
    }),
    dropdownIndicator: (
      base: CSSProperties,
      state: IndicatorProps<IOptionType, IsMulti>
    ) => ({
      ...base,
      color: Color.common.primary,
      opacity: state.isDisabled ? 0 : 1,
      paddingLeft: 0,
    }),
    indicatorSeparator: (base: CSSProperties) => ({
      width: 0, // Removes react-select's default seperator
    }),
  };
}

function SelectContainer<IOptionType, IsMulti extends boolean>(
  props: CustomContainerProps<IOptionType, IsMulti>
) {
  return (
    <Flex width={props.width || '100%'} alignItems="center" cursor="pointer">
      <SelectComponents.SelectContainer {...props} />
    </Flex>
  );
}

function Placeholder<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean
>(props: PlaceholderProps<IOptionType, IsMulti>) {
  return (
    <SelectComponents.Placeholder {...props}>
      <Paragraph fontWeight="medium" color={Color.common.primary}>
        {props.children}
      </Paragraph>
    </SelectComponents.Placeholder>
  );
}

function SingleValue<IOptionType extends OptionTypeBase>(
  props: SingleValueProps<IOptionType>
) {
  return (
    <SelectComponents.SingleValue {...props}>
      <Paragraph fontWeight="medium" color={Color.common.primary}>
        {props.children}
      </Paragraph>
    </SelectComponents.SingleValue>
  );
}

function ValueContainer<IOptionType, IsMulti extends boolean>({
  children,
  label,
  ...props
}: CustomValueContainerProps<IOptionType, IsMulti>) {
  return (
    <SelectComponents.ValueContainer {...props}>
      {label && (
        <Paragraph display="flex" flex={1} pl={2} fontWeight="semibold">
          {label}
        </Paragraph>
      )}
      {children}
    </SelectComponents.ValueContainer>
  );
}

// react-select missing DropdownIndicator type on their main exports
function DropdownIndicator<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean
>(props: IndicatorProps<IOptionType, IsMulti>) {
  return (
    <SelectComponents.DropdownIndicator {...props}>
      <MdIcon name="ArrowDropDown" />
    </SelectComponents.DropdownIndicator>
  );
}

function IndicatorsContainer<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean
>(props: IndicatorContainerProps<IOptionType, IsMulti>) {
  if (props.isDisabled) return null;
  return <SelectComponents.IndicatorsContainer {...props} />;
}

// Our default components for react-select. Any new components from props are merged in
// replacing the base component
function baseCustomComponents<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean
>(label?: string, width?: number) {
  return {
    ValueContainer: (props: ValueContainerProps<IOptionType, IsMulti>) => (
      <ValueContainer {...props} label={label} />
    ),
    SelectContainer: (props: ContainerProps<IOptionType, IsMulti>) => (
      <SelectContainer {...props} width={width} />
    ),
    Placeholder,
    IndicatorsContainer,
    SingleValue,
    DropdownIndicator,
  };
}

function SelectCard<
  IOptionType extends OptionTypeBase,
  IsMulti extends boolean = false
>({
  value,
  options,
  label,
  placeholder,
  width,
  styles,
  components,
  onSelect,
  isDisabled = false,
  cardProps = {},
}: SelectCardProps<IOptionType, IsMulti>) {
  return (
    <Card borderRadius="full" overflow="visible" padding={0} {...cardProps}>
      <Select
        value={value}
        options={options}
        // Merge our base components and components from props together
        components={assign(
          baseCustomComponents<IOptionType, IsMulti>(label, width),
          components
        )}
        // Merge our base styles and styles from props together
        styles={assign(baseCustomStyles<IOptionType, IsMulti>(), styles)}
        theme={(theme) => {
          return {
            ...theme,
            colors: {
              ...theme.colors,
              neutral5: 'white', // Overrides the isDisabled background
              primary: 'white', // Overrides pseudo-class borders
            },
            borderRadius: 20,
            padding: 0,
            margin: 0,
          };
        }}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isSearchable={false}
        // @ts-ignore - almost correct
        onChange={(option) => onSelect(option)}
      />
    </Card>
  );
}

export default SelectCard;
