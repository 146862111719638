import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button } from '@workshop/ui';
import { ButtonProps, ButtonRef } from '@workshop/ui/button';

type LinkButtonProps = ButtonProps & (LinkProps | { href: string });

const LinkButton: FC<LinkButtonProps> = React.forwardRef(
  (props: LinkButtonProps, ref: ButtonRef) => (
    <Button ref={ref} as={props.href ? 'a' : Link} {...props} />
  )
);

export default LinkButton;
