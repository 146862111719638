import React from 'react';
import { Flex, Text, FlexProps } from '@workshop/ui';

interface Props extends FlexProps {
  text: string;
}

const FixedFooter: React.FC<Props> = ({ text, ...props }) => (
  <Flex
    position="fixed"
    bottom={0}
    minHeight={12}
    width="100%"
    padding="defaultPadding"
    backgroundColor="background.default"
    borderTop="1px solid"
    borderColor="border.muted"
    // @ts-ignore
    zIndex="overlay"
    {...props}
  >
    <Text fontSize="md" fontWeight="semibold">
      {text}
    </Text>
  </Flex>
);

export default FixedFooter;
