import { decamelize } from 'humps';
import { callAPI, callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { RoomAT } from 'redux/actionTypes/common';

import { RoomSchema, RoomDeviceSchema } from 'redux/schemas';

import {
  CreateRoomAction,
  FetchRoomAction,
  UpdateRoomAction,
  CreateRoomDeviceAction,
  FetchRoomDevicesAction,
  UpdateRoomDeviceAction,
  Room,
  RoomDevice,
  RoomPost,
} from 'types/common';

export const createRoom = (data: RoomPost) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    const key = k as never;
    formData.append(decamelize(k), data[key]);
  });
  return callAPIWithTeam<CreateRoomAction>({
    types: [
      RoomAT.CREATE_ROOM_REQUEST,
      RoomAT.CREATE_ROOM_SUCCESS,
      RoomAT.CREATE_ROOM_FAILURE,
    ],
    endpoint: API.room.create,
    method: 'POST',
    body: formData,
    schema: RoomSchema,
  });
};

export const fetchRoom = (
  roomId: string,
  { queryParams }: { queryParams?: string }
) => {
  let endpoint = API.room.get(roomId);
  if (queryParams) {
    endpoint = endpoint.includes('?')
      ? `${endpoint}&${queryParams}`
      : `${endpoint}?${queryParams}`;
  }
  return callAPI<FetchRoomAction>({
    types: [
      RoomAT.FETCH_ROOM_REQUEST,
      RoomAT.FETCH_ROOM_SUCCESS,
      RoomAT.FETCH_ROOM_FAILURE,
    ],
    endpoint,
    method: 'GET',
    schema: RoomSchema,
  });
};

export const updateRoom = (roomPk: number, data: Partial<Room>) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    const key = k as never;
    formData.append(decamelize(k), data[key]);
  });
  return callAPI<UpdateRoomAction>({
    types: [
      RoomAT.UPDATE_ROOM_REQUEST,
      RoomAT.UPDATE_ROOM_SUCCESS,
      RoomAT.UPDATE_ROOM_FAILURE,
    ],
    endpoint: API.room.update(roomPk),
    method: 'PATCH',
    schema: RoomSchema,
    body: formData,
    meta: {
      toast: { success: 'Room updated' },
    },
  });
};

export const createDevice = (
  roomId: string,
  data: Partial<RoomDevice>,
  { queryParams }: { queryParams?: string }
) => {
  let endpoint = API.room.devices(roomId);
  if (queryParams) {
    endpoint = endpoint.includes('?')
      ? `${endpoint}&${queryParams}`
      : `${endpoint}?${queryParams}`;
  }
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    const key = k as never;
    formData.append(decamelize(k), data[key]);
  });
  return callAPI<CreateRoomDeviceAction>({
    types: [
      RoomAT.CREATE_ROOM_DEVICE_REQUEST,
      RoomAT.CREATE_ROOM_DEVICE_SUCCESS,
      RoomAT.CREATE_ROOM_DEVICE_FAILURE,
    ],
    endpoint,
    method: 'POST',
    body: formData,
    meta: {
      toast: { success: false, error: false },
    },
  });
};

export const listDevices = (
  roomPk: number,
  { queryParams }: { queryParams?: string }
) => {
  let endpoint = API.room.devices(roomPk);
  if (queryParams) {
    endpoint = endpoint.includes('?')
      ? `${endpoint}&${queryParams}`
      : `${endpoint}?${queryParams}`;
  }
  return callAPI<FetchRoomDevicesAction>({
    types: [
      RoomAT.FETCH_ROOM_DEVICES_REQUEST,
      RoomAT.FETCH_ROOM_DEVICES_SUCCESS,
      RoomAT.FETCH_ROOM_DEVICES_FAILURE,
    ],
    endpoint,
    method: 'GET',
    schema: [RoomDeviceSchema],
    path: 'results',
  });
};

export const updateDevice = (deviceId: string, data: Partial<RoomDevice>) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    const key = k as never;
    formData.append(decamelize(k), data[key]);
  });
  return callAPI<UpdateRoomDeviceAction>({
    types: [
      RoomAT.UPDATE_ROOM_DEVICE_REQUEST,
      RoomAT.UPDATE_ROOM_DEVICE_SUCCESS,
      RoomAT.UPDATE_ROOM_DEVICE_FAILURE,
    ],
    endpoint: API.room.updateDevice(deviceId),
    method: 'PATCH',
    schema: RoomDeviceSchema,
    body: formData,
    meta: {
      toast: { success: false, error: false },
    },
  });
};
