const ListCohortsAT = {
  CMS_LIST_COHORTS_REQUEST: 'CMS_LIST_COHORTS_REQUEST',
  CMS_LIST_COHORTS_SUCCESS: 'CMS_LIST_COHORTS_SUCCESS',
  CMS_LIST_COHORTS_FAILURE: 'CMS_LIST_COHORTS_FAILURE',
} as const;

const UpdateCohortAT = {
  CMS_UPDATE_COHORT_REQUEST: 'CMS_UPDATE_COHORT_REQUEST',
  CMS_UPDATE_COHORT_SUCCESS: 'CMS_UPDATE_COHORT_SUCCESS',
  CMS_UPDATE_COHORT_FAILURE: 'CMS_UPDATE_COHORT_FAILURE',
} as const;

const CreateCohortAT = {
  CMS_CREATE_COHORT_REQUEST: 'CMS_CREATE_COHORT_REQUEST',
  CMS_CREATE_COHORT_SUCCESS: 'CMS_CREATE_COHORT_SUCCESS',
  CMS_CREATE_COHORT_FAILURE: 'CMS_CREATE_COHORT_FAILURE',
} as const;

const DeleteCohortAT = {
  CMS_DELETE_COHORT_REQUEST: 'CMS_DELETE_COHORT_REQUEST',
  CMS_DELETE_COHORT_SUCCESS: 'CMS_DELETE_COHORT_SUCCESS',
  CMS_DELETE_COHORT_FAILURE: 'CMS_DELETE_COHORT_FAILURE',
} as const;

const ListCohortCourseProgressAT = {
  CMS_LIST_COHORT_CP_REQUEST: 'CMS_LIST_COHORT_CP_REQUEST',
  CMS_LIST_COHORT_CP_SUCCESS: 'CMS_LIST_COHORT_CP_SUCCESS',
  CMS_LIST_COHORT_CP_FAILURE: 'CMS_LIST_COHORT_CP_FAILURE',
} as const;

const ListUserCourseProgressAT = {
  CMS_LIST_USER_CP_REQUEST: 'CMS_LIST_USER_CP_REQUEST',
  CMS_LIST_USER_CP_SUCCESS: 'CMS_LIST_USER_CP_SUCCESS',
  CMS_LIST_USER_CP_FAILURE: 'CMS_LIST_USER_CP_FAILURE',
} as const;

const UpdateCourseProgressAT = {
  CMS_UPDATE_CP_REQUEST: 'CMS_UPDATE_CP_REQUEST',
  CMS_UPDATE_CP_SUCCESS: 'CMS_UPDATE_CP_SUCCESS',
  CMS_UPDATE_CP_FAILURE: 'CMS_UPDATE_CP_FAILURE',
} as const;

const CreateCertificateLogAT = {
  CMS_CREATE_CERTIFICATE_LOG_REQUEST: 'CMS_CREATE_CERTIFICATE_LOG_REQUEST',
  CMS_CREATE_CERTIFICATE_LOG_SUCCESS: 'CMS_CREATE_CERTIFICATE_LOG_SUCCESS',
  CMS_CREATE_CERTIFICATE_LOG_FAILURE: 'CMS_CREATE_CERTIFICATE_LOG_FAILURE',
} as const;

const SendCertificateLogAT = {
  CMS_SEND_CERTIFICATE_LOG_REQUEST: 'CMS_SEND_CERTIFICATE_LOG_REQUEST',
  CMS_SEND_CERTIFICATE_LOG_SUCCESS: 'CMS_SEND_CERTIFICATE_LOG_SUCCESS',
  CMS_SEND_CERTIFICATE_LOG_FAILURE: 'CMS_SEND_CERTIFICATE_LOG_FAILURE',
} as const;

const actionTypes = {
  ...CreateCohortAT,
  ...ListCohortsAT,
  ...UpdateCohortAT,
  ...DeleteCohortAT,
  ...ListCohortCourseProgressAT,
  ...ListUserCourseProgressAT,
  ...UpdateCourseProgressAT,
  ...CreateCertificateLogAT,
  ...SendCertificateLogAT,
};

export default actionTypes;
