import { Cohort } from 'types/common';
import { ICourse } from 'types/cms';
import { CourseDetails, CourseSummary } from 'types/learner';
import { SOCIAL_TYPES } from 'constants/courses';

const getCohortSubcategoryId = (
  cohort: Cohort,
  course: CourseDetails | CourseSummary | ICourse
) => {
  return cohort
    ? cohort.socialType === SOCIAL_TYPES.private
      ? cohort.discourseCategoryId
      : course
      ? course.discourseCommunityCategoryId
      : null
    : null;
};

export default getCohortSubcategoryId;
