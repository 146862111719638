import * as React from 'react';
import { Flex, Text, FlexProps } from '@workshop/ui';

interface NotificationBubbleProps extends FlexProps {
  number?: string | number;
}

const NotificationBubble: React.FC<NotificationBubbleProps> = ({
  number,
  ...rest
}) => {
  if (!number) return null;
  return (
    <Flex
      minWidth={4}
      px={1}
      height={4}
      mb={2}
      borderRadius="full"
      background="common.notification"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Text fontSize="xs" fontWeight="bold" color="white" mb={0.5}>
        {number}
      </Text>
    </Flex>
  );
};

export default NotificationBubble;
