import { AnalyticsAT } from 'redux/actionTypes/cms';
import { Action } from 'types';
import { camelize } from 'humps';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

import { dataTypes } from 'constants/analytics';
import {
  AnalyticsState,
  CourseAnalyticsSuccessData,
  CourseAnalyticsData,
} from 'types/cms';

const initialState: AnalyticsState = {
  courseNetRevenue: {},
  courseGrossRevenue: {},
  courseSales: {},
  courseRefunds: {},
  studentSatisfaction: {},
  activeLearners: {},
  completionRate: {},
  postCount: {},
  postsPerStudent: {},
  sessionsPerStudent: {},
};

/**
 * Helper function to generate a new AnalyticsState given the current state
 * and CourseAnalyticsSuccessData[] payload
 *
 * @param payloadData : CourseAnalyticsSuccessData[]
 * @param state : AnalyticsState
 * @returns AnalyticsState
 */
export const getCourseAnalyticsState = (
  payloadData: CourseAnalyticsSuccessData[],
  state: AnalyticsState,
  storeKey: string
) => {
  // /** Create a copy of the existing state */
  const newState = { ...state };
  /**
   * Populate the state by iterating over each
   * CourseAnalyticsSuccessData object in the payload
   */
  payloadData.forEach((successPayload) => {
    const {
      dataType,
      unit,
      courseId,
      unitId,
      moduleId,
      paymentType,
      data,
      average,
    } = successPayload;

    const camelizedDataType = camelize<keyof typeof dataTypes>(dataType);
    if (!Array.isArray(newState[camelizedDataType][storeKey])) {
      newState[camelizedDataType][storeKey] = [];
    }

    /**
     * Get the data out of the current state for the given data type
     * (courseGrossRevenue | courseNetRevenue | courseSales)
     */
    const stateData = newState[camelizedDataType][storeKey];

    /**
     * Find the relevant data structure in the state if possible.
     *
     * If not create a new object with the relevant filters
     */
    const existingData = stateData.find(
      (d) =>
        d.courseId === courseId &&
        d.unitId === unitId &&
        d.moduleId === moduleId &&
        d.purchaseType === paymentType &&
        d.unit === unit &&
        d.average === average
    ) || {
      courseId: courseId ? parseInt(courseId) : undefined,
      unitId: unitId ? parseInt(unitId) : undefined,
      moduleId: moduleId ? parseInt(moduleId) : undefined,
      purchaseType: paymentType?.toString(),
      unit,
      data: [],
      average,
    };

    /**
     * Update the data for the found object:
     * we don't want to override anything in the state hence we use uniqBy
     * to create a new array of values
     */
    existingData['data'] = orderBy(
      uniqBy([...existingData.data, ...data], (d) => d.startDate + d.endDate),
      ['startDate'],
      ['asc']
    );

    /**
     * Remove any data corresponding to the filters for this payload object
     * (we will replace with the latest data from the given payload below)
     */
    newState[camelizedDataType][storeKey] = stateData.filter(
      (d) =>
        !(
          d.courseId === courseId &&
          d.unitId === unitId &&
          d.moduleId === moduleId &&
          d.purchaseType === paymentType &&
          d.unit === unit &&
          d.average === average
        )
    );

    newState[camelizedDataType][storeKey].push(existingData);
  });

  return newState;
};

export const analyticsReducer = (
  state = initialState,
  action: Action
): AnalyticsState => {
  switch (action.type) {
    case AnalyticsAT.GET_COURSE_SALES_DATA_SUCCESS:
    case AnalyticsAT.GET_COURSE_GROSS_REVENUE_DATA_SUCCESS:
    case AnalyticsAT.GET_COURSE_NET_REVENUE_DATA_SUCCESS:
    case AnalyticsAT.GET_COURSE_REFUNDS_DATA_SUCCESS:
    case AnalyticsAT.GET_STUDENT_SATISFACTION_DATA_SUCCESS:
    case AnalyticsAT.GET_ACTIVE_LEARNERS_DATA_SUCCESS:
    case AnalyticsAT.GET_COMPLETION_RATE_DATA_SUCCESS:
    case AnalyticsAT.GET_POST_COUNT_DATA_SUCCESS:
    case AnalyticsAT.GET_POSTS_PER_STUDENT_DATA_SUCCESS:
    case AnalyticsAT.GET_SESSIONS_PER_STUDENT_DATA_SUCCESS: {
      const {
        payload,
        meta: { storeKey },
      } = action;
      const payloadData = Array.isArray(payload) ? payload : [payload];
      return getCourseAnalyticsState(payloadData, state, storeKey as string);
    }
    case AnalyticsAT.GET_COURSE_GROSS_REVENUE_DATA_FAILURE:
      return {
        ...state,
        courseGrossRevenue: initialState.courseGrossRevenue,
      };

    case AnalyticsAT.GET_COURSE_NET_REVENUE_DATA_FAILURE:
      return {
        ...state,
        courseNetRevenue: initialState.courseNetRevenue,
      };

    case AnalyticsAT.GET_COURSE_SALES_DATA_FAILURE:
      return {
        ...state,
        courseSales: initialState.courseSales,
      };

    default:
      return state;
  }
};
