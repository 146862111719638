import { useSelector } from 'react-redux';
import { GlobalState } from 'types';

export const useIsAuthenticated = () =>
  useSelector((state: GlobalState) => state.auth.isAuthenticated);

export const useAuthError = () => {
  const { error, errorMessage } = useSelector(
    (state: GlobalState) => state.auth
  );
  return { error, errorMessage };
};
