import React, { useState } from 'react';
import { useSpring } from 'react-spring';

import { Card, Flex, FlexProps, Collapse } from '@workshop/ui';

import { AnimatedButton } from 'components/Common';

// TODO: Move this to a util file (not sure where yet)
const noop = () => null;

interface EditCardProps extends FlexProps {
  /**
   * Whether the EditCard is disabled for editing
   */
  isDisabled?: boolean;
  /**
   * Whether the EditCard can be saved
   */
  saveDisabled?: boolean;
  saveLabel?: string;
  onSave: () => void;
  onCancel: () => void;
  isUpdating?: boolean;
  error?: boolean;
  hideCard?: boolean;
  showCancel?: boolean;
}

const EditCard: React.FC<EditCardProps> = ({
  isDisabled = false,
  saveDisabled = false,
  onSave,
  onCancel = noop,
  isUpdating = false,
  children,
  saveLabel = 'Save',
  hideCard = false,
  showCancel = false,
  ...otherProps
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const saveBtnStyle = useSpring({
    config: { duration: 200 },
    opacity: saveDisabled ? 0 : isUpdating ? 0.5 : 1,
    display: saveDisabled && showCancel ? 'none' : 'block',
  });
  const cancelBtnStyle = useSpring({
    config: { duration: 200 },
    opacity: saveDisabled && !showCancel ? 0 : isUpdating ? 0.5 : 1,
  });

  return (
    <Card
      flexDirection="column"
      onClick={() => !isEditing && setIsEditing(true)}
      {...(hideCard
        ? {
            boxShadow: 'none',
            padding: 0,
            borderRadius: 'none',
            overflow: 'visible',
          }
        : {
            padding: 'defaultPadding',
          })}
      {...otherProps}
    >
      {children}
      {!isDisabled && (
        <Collapse in={!saveDisabled}>
          <Flex
            flexDirection="row"
            alignContent="center"
            justifyContent="flex-end"
          >
            <AnimatedButton
              secondary
              isDisabled={(saveDisabled && !showCancel) || isUpdating}
              // @ts-ignore
              style={cancelBtnStyle}
              onClick={() => {
                setIsEditing(false);
                onCancel();
              }}
              icon="Close"
              w={10}
            />
            <AnimatedButton
              colorScheme="blue"
              isLoading={isUpdating}
              isDisabled={saveDisabled || isUpdating}
              // @ts-ignore
              style={saveBtnStyle}
              onClick={() => {
                setIsEditing(false);
                onSave();
              }}
            >
              {saveLabel}
            </AnimatedButton>
          </Flex>
        </Collapse>
      )}
    </Card>
  );
};

export default EditCard;
