import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import {
  Flex,
  Text,
  Box,
  Card,
  useColorModeValue,
  useDimensions,
  chakra,
} from '@workshop/ui';

import { LabelSelect, LabelTextArea, LabelInput } from 'components/Common';

export type MessageProps = {
  message: string;
  fields?: {
    name: string;
    type: 'multiline' | 'select' | 'email';
    intro: string;
    options?: { [key: string]: string };
    helpText?: string;
    placeholders?: string[];
  }[];
  isCurrent?: boolean;
  isMobile?: boolean;
  condensed?: boolean;
};

const Message = ({
  message,
  fields,
  isCurrent,
  isMobile,
  condensed,
}: MessageProps) => {
  const [typingDone, setTypingDone] = useState(false);

  const fieldPlaceholders =
    fields &&
    fields.length > 0 &&
    fields[0].placeholders &&
    fields[0].placeholders.length > 0
      ? fields[0].placeholders
      : [];

  const [currentPlaceholderIdx, setCurrentPlaceholderIdx] = useState(0);
  const [placeholders, setPlaceholders] = useState(
    fieldPlaceholders.map((p) => '')
  );
  const [showPlaceholders, setShowPlaceholders] = useState(false);

  const { register } = useFormContext();
  const introRef = useRef<HTMLParagraphElement>(null);
  const fieldRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const introDimensions = useDimensions(introRef, true);

  useEffect(() => {
    setPlaceholders(
      fieldPlaceholders
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    );
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextPlaceholderIdx =
        (currentPlaceholderIdx + 1) % fieldPlaceholders.length;
      setCurrentPlaceholderIdx(nextPlaceholderIdx);
    }, 2500);
    return () => clearTimeout(timeout);
  }, [currentPlaceholderIdx]);

  useEffect(() => {
    if (isCurrent) {
      introRef?.current?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [isCurrent]);
  useEffect(() => {
    if (typingDone && fieldRef.current) {
      fieldRef.current.focus();
    }
    if (typingDone && fieldPlaceholders) {
      setTimeout(() => setShowPlaceholders(true), 3000);
    }
  }, [typingDone]);

  const messageBg = useColorModeValue('background.tint3', 'background.default');
  const inputBg = useColorModeValue('background.default', 'background.tint3');
  return (
    <Card
      padding={{ base: 4, md: condensed ? 4 : 8 }}
      borderRadius="lg"
      overflow="visible"
      boxShadow="none"
      backgroundColor={isCurrent ? 'background.primary' : messageBg}
      mt={4}
    >
      <Flex flexDirection="column" flex={1}>
        <Text
          color={isCurrent ? 'text.primary' : 'text.muted'}
          fontSize={{ base: 'md', md: condensed ? 'md' : 'lg' }}
          flex={1}
          position="relative"
        >
          {isCurrent ? (
            <>
              <chakra.span opacity={0}>{message}</chakra.span>
              <chakra.span
                position="absolute"
                left={0}
                right={0}
                top={0}
                bottom={0}
              >
                <Typist
                  avgTypingDelay={5}
                  cursor={{
                    show: true,
                    blink: true,
                    element: '|',
                    hideWhenDone: true,
                    hideWhenDoneDelay: 300,
                  }}
                  onTypingDone={() => setTypingDone(true)}
                >
                  {message}
                </Typist>
              </chakra.span>
            </>
          ) : (
            message
          )}
        </Text>
        {fields?.map((f, idx) => {
          if (f.type === 'multiline' || f.type === 'email') {
            return (
              <Box key={`field-${f.name}-${idx}`} position="relative" mt={4}>
                {f.type === 'multiline' ? (
                  <LabelTextArea
                    id={f.name}
                    name={f.name}
                    label=""
                    autoResize
                    registerInputRef={(e) => {
                      // @ts-ignore
                      register(e);
                      if (idx === 0) {
                        fieldRef.current = e;
                      }
                    }}
                    fontSize={{ base: 'md', md: condensed ? 'md' : 'lg' }}
                    paddingTop={
                      introDimensions
                        ? `${introDimensions.contentBox.height + 12}px`
                        : 8
                    }
                    pb={3}
                    bg={inputBg}
                    zIndex={0}
                    autoFocus={isCurrent && !isMobile}
                    helpText={f.helpText || ''}
                    {...(idx === 0 && showPlaceholders
                      ? {
                          placeholder:
                            placeholders[currentPlaceholderIdx] || '',
                        }
                      : {})}
                  />
                ) : (
                  <LabelInput
                    id={f.name}
                    name={f.name}
                    label=""
                    registerInputRef={(e) => {
                      // @ts-ignore
                      register(e);
                      if (idx === 0) {
                        fieldRef.current = e;
                      }
                    }}
                    fontSize={{ base: 'md', md: condensed ? 'md' : 'lg' }}
                    paddingTop={
                      introDimensions
                        ? `${introDimensions.contentBox.height + 25}px`
                        : 12
                    }
                    pb={6}
                    bg={inputBg}
                    zIndex={0}
                    autoFocus={isCurrent && !isMobile}
                    helpText={f.helpText || ''}
                    type="email"
                  />
                )}

                <Text
                  ref={introRef}
                  color="text.muted"
                  position="absolute"
                  top={3}
                  left={4}
                  right={4}
                  fontSize="sm"
                  zIndex={1}
                  pointerEvents="none"
                >
                  {f.intro}
                </Text>
              </Box>
            );
          }
          if (f.type === 'select' && f.options) {
            return (
              <Box key={`field-${f.name}-${idx}`} mt={idx === 0 ? 4 : 2}>
                <LabelSelect
                  id={f.name}
                  name={f.name}
                  label={f.intro}
                  labelPosition="top"
                  registerInputRef={register()}
                  options={f.options}
                  backgroundColor={inputBg}
                  unsorted
                  helpText={f.helpText || ''}
                  noOfLines={1}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                />
                <Box ref={introRef} />
              </Box>
            );
          }
        })}
      </Flex>
    </Card>
  );
};

export default Message;
