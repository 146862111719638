import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Flex, Text, Card } from '@workshop/ui';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

interface Props extends RouteComponentProps {
  name: string;
}

const PageForbiddenScreen: React.FC<Props> = (props) => {
  return (
    <ScreenWrapper>
      <Card w="100%" mt={20}>
        <Flex
          py={4}
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          maxW="500px"
          mx="auto"
          mb={8}
        >
          <Flex
            boxSize="image.lg"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            borderRadius="full"
          >
            <Text fontSize="6xl">🔒</Text>
          </Flex>
          <Text
            fontWeight="extrabold"
            fontSize={{ base: '3xl', md: '4xl' }}
            lineHeight={1.2}
            mb={4}
          >
            Page Locked
          </Text>
          <Text fontSize="lg" color="text.muted">
            You do not have permission to view this page.
          </Text>
        </Flex>
      </Card>
    </ScreenWrapper>
  );
};

export default PageForbiddenScreen;
