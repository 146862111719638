import React from 'react';

import {
  Box,
  Card,
  Flex,
  Heading,
  MdIcon,
  Paragraph,
  Skeleton,
  Divider,
} from '@workshop/ui';
import { ModulesList } from 'components/ModulesList';
import { ModuleListItemProps } from 'components/ModulesList/ModuleListItem';

interface UnitOverviewCardProps {
  imageUrl: string | null;
  isLoading: boolean;
  modules: ModuleListItemProps[];
  title: string;
  prefix: string;
  sessionImages?: string[];
  // TODO : the showImage prop might not be needed
  // i.e, it looks like we never set it to `false` anywhere
  // in the code base (needs investigation before removing)
  showImage?: Boolean;
}

const UnitOverviewCard: React.FC<UnitOverviewCardProps> = ({
  imageUrl,
  isLoading,
  modules,
  title,
  prefix,
  sessionImages,
  showImage = true,
}) => {
  return (
    <Card padding={0} overflow="initial">
      <Box flex={1}>
        <Flex padding="defaultPadding" alignItems="center">
          {showImage && sessionImages && sessionImages.length > 0 && (
            <>
              <Flex
                width={{ base: 'image.lg', md: 'image.2xl', xl: 'image.3xl' }}
                height={{ base: 'image.lg', md: 'image.xl', xl: 'image.2xl' }}
                mr={[2]}
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
              >
                {sessionImages.map((image, index) => (
                  <Box
                    key={`session-image-${index}`}
                    borderRadius="md"
                    height={{
                      base: 'image.md',
                      md: 'image.lg',
                      xl: 'image.xl',
                    }}
                    minWidth={{
                      base: 'image.md',
                      md: 'image.lg',
                      xl: 'image.xl',
                    }}
                    backgroundImage={`url(${image})`}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    position="absolute"
                    transform={
                      index === sessionImages.length - 3
                        ? [`scale(0.8) translateX(25px)`]
                        : index === sessionImages.length - 2
                        ? [
                            `scale(0.9) translateX(${
                              sessionImages.length === 2 ? '10px' : '5px'
                            })`,
                          ]
                        : [
                            `translateX(${
                              sessionImages.length === 1
                                ? '0px'
                                : sessionImages.length === 2
                                ? '-10px'
                                : '-15px'
                            })`,
                          ]
                    }
                    opacity={
                      index === sessionImages.length - 3
                        ? 0.8
                        : index === sessionImages.length - 2
                        ? 0.9
                        : 1
                    }
                  />
                ))}
              </Flex>
            </>
          )}
          <Flex flexDirection="column" flex={1}>
            <Flex flexDirection="column" padding="defaultPadding" width="100%">
              <Skeleton
                isLoaded={!isLoading}
                loadingStyle={{
                  width: '50%',
                  height: '1em',
                  marginBottom: 2,
                }}
              >
                <Paragraph mb={1}>{prefix}</Paragraph>
              </Skeleton>
              <Skeleton
                isLoaded={!isLoading}
                loadingStyle={{
                  width: '80%',
                  height: '1em',
                }}
              >
                <Heading as="h3" fontWeight="bold" fontSize="xl" mb={2}>
                  {title}
                </Heading>
              </Skeleton>
            </Flex>
          </Flex>
        </Flex>
        {modules.length > 0 && (
          <Box h="100%" position="relative">
            <Divider />
            <ModulesList isLoading={isLoading} moduleItems={modules} />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default UnitOverviewCard;
