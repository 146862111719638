import uniqBy from 'lodash/uniqBy';

import { JournalAT } from 'redux/actionTypes/learner';

import { Action } from 'types';
import {
  JournalState,
  JournalEntry,
  FetchJournalsSuccess,
} from 'types/learner';

function updateJournal(
  state: JournalEntry[],
  action: FetchJournalsSuccess
): JournalState {
  const { payload } = action;
  const { entities } = payload;

  const { lessonEntry, sessionEntry } = entities;

  /** Check if sessionEntry or lessonEntry has come back from the response */
  // $FlowFixMe Object.values() returns mixed[] https://github.com/facebook/flow/issues/2221
  const lessonEntries: JournalEntry[] =
    lessonEntry && Object.values(lessonEntry);
  // $FlowFixMe Object.values() returns mixed[] https://github.com/facebook/flow/issues/2221
  const sessionEntries: JournalEntry[] =
    sessionEntry && Object.values(sessionEntry);

  const newJournalEntries = [
    ...(lessonEntries || []),
    ...(sessionEntries || []),
  ];

  /**
   * Filter the current state against the entries that
   * have been returned from the payload.
   *
   * If we have an entry in the state and the same entry in the payload,
   * We want to remove it the entry **from the state.**
   *
   * We then concat the filtered state with the new data.
   * This way we get updated data, and no duplicate entrys.
   */
  const filterState = state.filter(
    (entry: JournalEntry) =>
      !newJournalEntries.some((newEntry) => newEntry.id === entry.id)
  );
  /**
   * We concat the currently filtered state with the new data.
   */

  return { journalEntries: filterState.concat(newJournalEntries) };
}

export const journalReducer = (
  state: JournalState = { journalEntries: [] },
  action: Action
): JournalState => {
  switch (action.type) {
    case JournalAT.CREATE_JOURNAL_ENTRY_SUCCESS:
      return {
        journalEntries: uniqBy([...state.journalEntries, action.payload], 'id'),
      };
    case JournalAT.FETCH_JOURNAL_ENTRIES_SUCCESS:
      return {
        journalEntries: uniqBy(
          [...state.journalEntries, ...action.payload.results],
          'id'
        ),
      };
    case JournalAT.FETCH_JOURNAL_SUCCESS:
      return updateJournal(state.journalEntries, action);
  }
  return state;
};
