import React, { useState, useRef, useEffect } from 'react';

import {
  Card,
  Flex,
  MdIcon,
  Skeleton,
  Heading,
  Text,
  LinkButton,
  LinkOverlay,
  LinkBox,
} from '@workshop/ui';

import { useDimensionsHandleResize } from 'utils/hooks/useDimensions';

import { UserAvatar } from 'components/UserAvatar';

interface NotificationCardProps {
  title: string;
  content: string;
  showIcon?: boolean;
  btnText: string;
  showContent: boolean;
  prefix?: string;
  isLoading?: boolean;
  linkTo: string;
  avatarPicture?: string;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({
  title,
  content,
  linkTo,
  btnText,
  showContent,
  avatarPicture,
  showIcon = false,
  prefix = '',
  isLoading = false,
}) => {
  const IMG_WIDTH = 32;

  const cardRef = useRef(null);
  const cardDimensions = useDimensionsHandleResize(cardRef);

  const [showButton, setShowButton] = useState(false);

  // If the card is larger than a certain threshold, we display a button instead of an icon
  useEffect(() => {
    if (cardDimensions.width && cardDimensions.width > IMG_WIDTH * 16) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [cardDimensions.width]);

  return (
    <LinkBox>
      <Card padding={0} ref={cardRef}>
        <Flex flexDirection="row" width="100%" padding={4}>
          <LinkOverlay href={linkTo}>
            <Flex justifyContent="center" alignItems="center">
              <UserAvatar
                avatarPicture={avatarPicture}
                name={showIcon ? '' : title}
                userId={title.length}
                size="md"
                fallbackIcon={
                  showIcon ? (
                    <MdIcon name="Mail" color="inherit" boxSize="22px" />
                  ) : null
                }
              />
            </Flex>
          </LinkOverlay>
          <Flex
            flex={1}
            flexDirection="column"
            height="100%"
            justifyContent={prefix ? 'flex-top' : 'center'}
            paddingLeft="defaultPadding"
          >
            {!isLoading && prefix && (
              <Text fontSize="xs" fontWeight="medium" mb={1} color="text.muted">
                {prefix}
              </Text>
            )}
            <Skeleton
              isLoaded={!isLoading}
              loadingStyle={{ height: 4, mb: 2, width: 0.6 }}
            >
              <Heading as="h5" fontSize="lg" fontWeight="bold">
                {title}
              </Heading>
            </Skeleton>
            <Skeleton
              isLoaded={!isLoading}
              loadingStyle={{ height: 3, width: 0.4 }}
            >
              {showContent && (
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  paddingLeft={1}
                  fontSize="14px"
                  textOverflow="ellipsis"
                >
                  {content}
                </Text>
              )}
            </Skeleton>
          </Flex>
          {!isLoading && (
            <Flex alignItems="center" paddingRight={4}>
              {showButton ? (
                <LinkButton secondary to={linkTo}>
                  {btnText}
                </LinkButton>
              ) : (
                <MdIcon
                  name="KeyboardArrowRight"
                  ml="defaultMargin"
                  color="neutral.500"
                  width={6}
                  height={6}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Card>
    </LinkBox>
  );
};
