import React from 'react';
import { Text, TextProps } from '@workshop/ui';

interface Props extends TextProps {
  title: string;
  bg: string;
}

const HeaderTag = (props: Props) => {
  const { title, bg } = props;
  return (
    <Text
      display="flex"
      backgroundColor={bg}
      fontWeight="semibold"
      paddingX={2}
      paddingY={1}
      borderRadius="sm"
      fontSize="xs"
      textTransform="capitalize"
      {...props}
    >
      {title}
    </Text>
  );
};

export default HeaderTag;
