import styled from '@emotion/styled';

import { Flex } from '@chakra-ui/react';

const Card = styled(Flex)``;

Card.defaultProps = {
  padding: 2,
  backgroundColor: 'background.default',
  boxShadow: { base: 0, md: 'lg' },
  borderRadius: { base: 0, md: 'lg' },
  overflow: 'hidden',
  flexShrink: 0,
};

Card.displayName = 'Card';
export default Card;
