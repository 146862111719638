import { StepAT } from 'redux/actionTypes/cms';
import pickBy from 'lodash/pickBy';

import { Action } from 'types';
import {
  StepListState,
  StepDetailState,
  StepQuestionState,
  StepUIState,
  IStepListItem,
} from 'types/cms';

const optimisticCache: StepListState = {};
const initialState: StepListState = {};

export const stepListReducer = (
  state = initialState,
  action: Action
): StepListState => {
  switch (action.type) {
    case StepAT.CMS_CREATE_STEP_MCQ_SUCCESS:
      let stepId = action.payload.stepId;
      let step = state[stepId];

      return {
        ...state,
        [stepId]: {
          ...step,
          questions: [...step.questions, action.payload.id],
        },
      };
    case StepAT.CMS_CREATE_STEP_REQUEST:
    case StepAT.CMS_FETCH_STEP_LIST_REQUEST:
      return state;
    case StepAT.CMS_CREATE_STEP_SUCCESS:
    case StepAT.CMS_FETCH_STEP_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.steps,
      };
    case StepAT.CMS_CREATE_STEP_FAILURE:
    case StepAT.CMS_FETCH_STEP_LIST_FAILURE:
      return state;
    case StepAT.CMS_FETCH_STEP_SUCCESS:
    case StepAT.CMS_PATCH_STEP_SUCCESS: {
      // When we get or update a step, we find that step in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const step = action.payload.entities.step[idString];

      // If we don't have a list representation of the step yet
      // then do nothing
      const existingItem = state[idString];
      if (!existingItem || Object.keys(state).length === 0) return state;

      const item = pickBy<IStepListItem>(
        { ...existingItem, ...step },
        (val, k) => Object.keys(existingItem).includes(k)
      ) as IStepListItem;

      return {
        ...state,
        [idString]: item,
      };
    }
    case StepAT.CMS_DELETE_STEP_REQUEST: {
      let stepId = action.meta.stepId as string;
      let { [stepId]: _, ...rest } = state;
      // Add the optimistically deleted object to the cache
      optimisticCache[stepId] = _;
      return rest;
    }
    case StepAT.CMS_DELETE_STEP_SUCCESS: {
      let stepId = action.meta.stepId as string;
      if (typeof stepId === 'boolean') return state;

      let { [stepId]: _, ...rest } = state;
      // Success, so remove the stored optimistic cache item, after
      // taking a copy of the deleted steps's index and the deleted
      // step's parent session for use in the next operation
      const deletedIndex = optimisticCache[stepId]?.index;
      const deletedSession = optimisticCache[stepId]?.module;
      delete optimisticCache[stepId];

      // When removing a step, we need to make sure that the index of any
      // steps with a higher index than the deleted one are decremented by 1
      return Object.values(rest).reduce<StepListState>(
        (acc, step: IStepListItem) => {
          if (step.module === deletedSession && step.index > deletedIndex) {
            acc[step.id] = {
              ...step,
              index: step.index - 1,
            };
          } else {
            acc[step.id] = step;
          }
          return acc;
        },
        {}
      );
    }
    case StepAT.CMS_DELETE_STEP_FAILURE: {
      let stepId = action.meta.stepId as string;
      // Failure, so restore the optimistically deleted item
      // from the optimistic cache
      return {
        ...state,
        [stepId]: optimisticCache[stepId],
      };
    }
    default:
      return state;
  }
};

const stepInitialState: StepDetailState = {};

export const stepReducer = (
  state = stepInitialState,
  action: Action
): StepDetailState => {
  switch (action.type) {
    case StepAT.CMS_FETCH_STEP_REQUEST:
      return state;
    case StepAT.CMS_FETCH_STEP_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.step,
      };
    case StepAT.CMS_FETCH_STEP_FAILURE:
      return state;
    case StepAT.CMS_PATCH_STEP_REQUEST:
      return state;
    case StepAT.CMS_PATCH_STEP_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.step,
      };
    case StepAT.CMS_PATCH_STEP_FAILURE:
      return state;
    default:
      return state;
  }
};

export const stepUIReducer = (
  state: StepUIState = {
    step: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
    stepList: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): StepUIState => {
  switch (action.type) {
    case StepAT.CMS_CREATE_STEP_REQUEST:
    case StepAT.CMS_FETCH_STEP_LIST_REQUEST:
      return {
        ...state,
        stepList: {
          ...state.stepList,
          loading: !action.error,
        },
      };
    case StepAT.CMS_CREATE_STEP_SUCCESS:
    case StepAT.CMS_FETCH_STEP_LIST_SUCCESS:
    case StepAT.CMS_CREATE_STEP_FAILURE:
    case StepAT.CMS_FETCH_STEP_LIST_FAILURE:
      return {
        ...state,
        stepList: {
          ...state.stepList,
          loading: false,
        },
      };
    case StepAT.CMS_FETCH_STEP_REQUEST:
    case StepAT.CMS_PATCH_STEP_REQUEST:
    case StepAT.CMS_DELETE_STEP_REQUEST:
      return {
        ...state,
        step: {
          ...state.step,
          loading: !action.error,
        },
      };
    case StepAT.CMS_FETCH_STEP_SUCCESS:
    case StepAT.CMS_PATCH_STEP_SUCCESS:
    case StepAT.CMS_DELETE_STEP_SUCCESS:
    case StepAT.CMS_FETCH_STEP_FAILURE:
    case StepAT.CMS_PATCH_STEP_FAILURE:
    case StepAT.CMS_DELETE_STEP_FAILURE:
      return {
        ...state,
        step: {
          ...state.step,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export const stepQuestionReducer = (
  state: StepQuestionState = {},
  action: Action
) => {
  switch (action.type) {
    case StepAT.CMS_FETCH_STEP_MCQ_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.questions,
      };

    case StepAT.CMS_CREATE_STEP_MCQ_SUCCESS:
    case StepAT.CMS_UPDATE_STEP_MCQ_SUCCESS:
      return { ...state, [action.payload.id]: { ...action.payload } };

    default:
      return state;
  }
};
