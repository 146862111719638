import React, { useState, useRef, useEffect } from 'react';

import {
  Card,
  Flex,
  MdIcon,
  Skeleton,
  Heading,
  Text,
  LinkButton,
  LinkOverlay,
  LinkBox,
} from '@workshop/ui';

import { useDimensionsHandleResize } from 'utils/hooks/useDimensions';

interface DashboardCardProps {
  title: string;
  subtitle: string;
  linkTo: string;
  btnText: string;
  imageUrl: string | null;
  prefix?: string;
  size?: 'regular' | 'small';
  isLoading?: boolean;
}

const IMG_WIDTH = 24;

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  subtitle,
  imageUrl,
  linkTo,
  btnText,
  prefix = '',
  size = 'regular',
  isLoading = false,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const cardDimensions = useDimensionsHandleResize(cardRef);

  const [showButton, setShowButton] = useState(false);

  // If the card is larger than a certain threshold, we display a button instead of an icon
  useEffect(() => {
    if (cardDimensions.width > IMG_WIDTH * 16) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [cardDimensions.width]);

  const imgSize = size === 'regular' ? IMG_WIDTH : IMG_WIDTH / 2;

  return (
    <LinkBox w="100%">
      <Card padding={0} ref={cardRef}>
        <Flex flexDirection="row" width="100%" padding={4}>
          <LinkOverlay href={linkTo}>
            <Flex
              justifyContent="center"
              alignItems="center"
              borderRadius="lg"
              backgroundColor="background.tint3"
              backgroundImage={isLoading || !imageUrl ? '' : `url(${imageUrl})`}
              padding={isLoading ? 2 : 0}
              backgroundSize="cover"
              backgroundPosition="center"
              height={imgSize}
              width={imgSize}
            >
              {(isLoading || !imageUrl) && (
                <MdIcon
                  name="CropOriginal"
                  color="icon.default"
                  width="35px !important"
                  height="35px !important"
                />
              )}
            </Flex>
          </LinkOverlay>
          <Flex
            flex={1}
            flexDirection="column"
            height="100%"
            justifyContent="center"
            padding="defaultPadding"
            maxH={imgSize}
            overflow="hidden"
          >
            {!isLoading && prefix && (
              <Text fontSize="xs" fontWeight="medium" mb={1} color="text.muted">
                {prefix}
              </Text>
            )}
            <Skeleton
              isLoaded={!isLoading}
              loadingStyle={{ height: 4, mb: 2, width: 0.6 }}
            >
              <Heading as="h5" fontSize="lg" fontWeight="bold">
                {title}
              </Heading>
            </Skeleton>
            <Skeleton
              isLoaded={!isLoading}
              loadingStyle={{ height: 3, width: 0.4 }}
            >
              {subtitle && (
                <Text
                  fontSize="xs"
                  fontWeight="medium"
                  mb={1}
                  textTransform="uppercase"
                  sx={{ textOverflow: 'ellipsis' }}
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {subtitle}
                </Text>
              )}
            </Skeleton>
          </Flex>
          {!isLoading && (
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              paddingRight={4}
            >
              {showButton ? (
                <LinkButton secondary to={linkTo}>
                  {btnText}
                </LinkButton>
              ) : (
                <MdIcon
                  name="KeyboardArrowRight"
                  ml="defaultMargin"
                  color="neutral.500"
                  width={6}
                  height={6}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Card>
    </LinkBox>
  );
};
