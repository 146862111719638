import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { InPageNav } from 'components/Common';

interface CourseMenuProps extends RouteComponentProps {
  courseSlug: string;
  isMini?: boolean;
}

const CourseMenu: React.FC<CourseMenuProps> = ({
  location,
  courseSlug,
  isMini = false,
}) => {
  let initialTab = 'overview';
  const currentPath = location.pathname.replace(/\/$/, '');
  if (currentPath === `/course/${courseSlug}/browse`) {
    initialTab = 'browse';
  } else if (currentPath === `/course/${courseSlug}/activity`) {
    initialTab = 'activity';
  } else if (currentPath === `/course/${courseSlug}/certificate`) {
    initialTab = 'certificate';
  }

  let tabs = [
    {
      slug: 'overview',
      icon: 'School',
      label: 'Overview',
      linkTo: `/course/${courseSlug}`,
    },
    {
      slug: 'browse',
      icon: 'Search',
      label: 'Browse Course',
      linkTo: `/course/${courseSlug}/browse`,
    },
  ];

  if (!isMini) {
    tabs.push({
      slug: 'activity',
      icon: 'People',
      label: 'My Class',
      linkTo: `/course/${courseSlug}/activity`,
    });
    tabs.push({
      slug: 'certificate',
      icon: 'AssignmentTurnedIn',
      label: 'Certificate',
      linkTo: `/course/${courseSlug}/certificate`,
    });
  }

  return <InPageNav initialTab={initialTab} tabs={tabs} />;
};

export default withRouter(CourseMenu);
