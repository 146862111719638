import React from 'react';
import { Flex, Heading, Paragraph } from '@workshop/ui';

const PageNotFoundScreen: React.FC = () => {
  return (
    <Flex flex={1} alignItems="center" py={10} flexDir="column">
      <Heading as="h3">404 Page Not Found</Heading>
      <Paragraph fontSize="lg">
        The link you clicked on does not exist
      </Paragraph>
    </Flex>
  );
};

export default PageNotFoundScreen;
