const RoomAT = {
  FETCH_ROOM_REQUEST: 'FETCH_ROOM_REQUEST',
  FETCH_ROOM_SUCCESS: 'FETCH_ROOM_SUCCESS',
  FETCH_ROOM_FAILURE: 'FETCH_ROOM_FAILURE',

  UPDATE_ROOM_REQUEST: 'UPDATE_ROOM_REQUEST',
  UPDATE_ROOM_SUCCESS: 'UPDATE_ROOM_SUCCESS',
  UPDATE_ROOM_FAILURE: 'UPDATE_ROOM_FAILURE',

  CREATE_ROOM_REQUEST: 'CREATE_ROOM_REQUEST',
  CREATE_ROOM_SUCCESS: 'CREATE_ROOM_SUCCESS',
  CREATE_ROOM_FAILURE: 'CREATE_ROOM_FAILURE',

  UPDATE_ROOM_FROM_SOCKET: 'UPDATE_ROOM_FROM_SOCKET',
  RESET_ROOM_FROM_SOCKET: 'RESET_ROOM_FROM_SOCKET',
} as const;

const RoomDeviceAT = {
  FETCH_ROOM_DEVICES_REQUEST: 'FETCH_ROOM_DEVICES_REQUEST',
  FETCH_ROOM_DEVICES_SUCCESS: 'FETCH_ROOM_DEVICES_SUCCESS',
  FETCH_ROOM_DEVICES_FAILURE: 'FETCH_ROOM_DEVICES_FAILURE',

  UPDATE_ROOM_DEVICE_REQUEST: 'UPDATE_ROOM_DEVICE_REQUEST',
  UPDATE_ROOM_DEVICE_SUCCESS: 'UPDATE_ROOM_DEVICE_SUCCESS',
  UPDATE_ROOM_DEVICE_FAILURE: 'UPDATE_ROOM_DEVICE_FAILURE',

  CREATE_ROOM_DEVICE_REQUEST: 'CREATE_ROOM_DEVICE_REQUEST',
  CREATE_ROOM_DEVICE_SUCCESS: 'CREATE_ROOM_DEVICE_SUCCESS',
  CREATE_ROOM_DEVICE_FAILURE: 'CREATE_ROOM_DEVICE_FAILURE',

  UPDATE_ROOM_DEVICE_FROM_SOCKET: 'UPDATE_ROOM_DEVICE_FROM_SOCKET',
} as const;

export default {
  ...RoomAT,
  ...RoomDeviceAT,
};
