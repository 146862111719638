import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, Link, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Element } from 'react-scroll';
import ReactCountdown from 'react-countdown';

import navRoutes from 'navigation/Routes';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS } from 'constants/organisation';

import { assistantActions } from 'redux/actions/common';
import { useCurrentTeamProfile, useCurrentTeam } from 'redux/selectors';

import { hooks, colorUtils, analytics } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { GlobalState } from 'types';
import { MaterialColors } from 'types/common';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import {
  Flex,
  Text,
  Button,
  Grid,
  GridItem,
  Stack,
  useColorModeValue,
  useTheme,
} from '@workshop/ui';

import { NAV_HEIGHT } from 'containers/AppHeader';
import { WORKSHOP_ORGS } from 'constants/organisation';

import { UserAvatar } from 'components/UserAvatar';
import { Loading } from 'components/Loading';
import { ProCta } from 'components/Common';

import DigestsPopup from 'screens/cms/Assistant/src/DigestsPopup';

// Routing Props
interface MatchParams {
  digestSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const DigestFeature: React.FC<{
  colSpan: number | { base: number; md: number };
  rowSpan: number | { base: number; md: number };
  section: {
    headline: string;
    emoji: string;
    body: string;
  };
  palette?: MaterialColors['light'];
}> = ({ colSpan, rowSpan, section, palette }) => {
  const surface = palette?.surface;

  const colorCombinations = [
    {
      bgColor: palette?.primaryContainer,
      color: palette?.onPrimaryContainer,
    },
    {
      bgColor: palette?.secondaryContainer,
      color: palette?.onSecondaryContainer,
    },
    {
      bgColor: palette?.tertiaryContainer,
      color: palette?.onTertiaryContainer,
    },
    {
      bgColor: palette?.errorContainer,
      color: palette?.onErrorContainer,
    },
    {
      bgColor: palette?.surfaceVariant,
      color: palette?.onSurfaceVariant,
    },
  ];
  const bgOpacityOptions = [0.5, 0.65, 0.8];

  const colorCombo =
    colorCombinations[section.headline.length % colorCombinations.length];
  const bg = colorCombo.bgColor;
  const color = colorCombo.color;

  const bgOpacity =
    bgOpacityOptions[section.body.length % bgOpacityOptions.length];
  return (
    <GridItem
      colSpan={colSpan}
      rowSpan={rowSpan}
      width="100%"
      height="100%"
      p={6}
      pt={10}
      borderRadius="lg"
      position="relative"
    >
      <Flex
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        bg={surface}
        // bg="background.tint3"
        zIndex={0}
        borderRadius="lg"
        overflow="hidden"
      >
        <Flex flex={1} bg={bg} opacity={bgOpacity} />
      </Flex>
      <Text fontSize="5xl" position="absolute" top={-6}>
        {section.emoji}
      </Text>
      <Flex flexDirection="column" position="relative">
        <Text fontWeight="bold" fontSize="lg" mb={2} color={color}>
          {section.headline}
        </Text>
        <Text color={color}>{section.body}</Text>
      </Flex>
    </GridItem>
  );
};

const DigestScreen: React.FC<Props> = ({ digestSlug, digest }) => {
  const [digestsPopupIsOpen, setDigestsPopupIsOpen] = useState(false);

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const baseColor = currentTeamProfile?.id
    ? colorUtils.getRandomColor(
        currentTeamProfile.id,
        undefined,
        undefined,
        'hex'
      )
    : '';
  const [brandColor, setBrandColor] = useState(
    currentTeamProfile?.brandColor || baseColor
  );
  const history = useHistory();

  const { digestLoading } = hooks.useLoadingDataState(
    {
      digestLoading: {
        actions: digestSlug
          ? [() => assistantActions.retrieveDigest(digestSlug)]
          : [],
      },
    },
    [digestSlug]
  );

  useEffect(() => {
    if (currentTeamProfile?.brandColor) {
      setBrandColor(currentTeamProfile.brandColor);
    } else if (baseColor) {
      setBrandColor(baseColor);
    }
  }, [currentTeamProfile?.brandColor, baseColor]);

  useEffect(() => {
    analytics.track('Viewed Digest', { slug: digestSlug });
  }, []);

  const colors = hooks.useColors(brandColor);
  const palette = useColorModeValue(colors?.light, colors?.dark);

  const primaryColor = palette?.primary || 'text.primary';
  const bg = palette?.primaryContainer || 'background.tint1';
  const textColor = palette?.onPrimaryContainer || 'text.default';
  const titleColor = palette?.secondary || 'text.default';
  const borderColor = 'border.default';
  const surface = palette?.surface || 'background.tint3';
  const mutedTextColor = palette?.outline || 'text.muted';

  const notAllowed = digest && !digest.created;

  if (
    PLATFORM !== 'steppit' ||
    (currentTeamProfile && WORKSHOP_ORGS.includes(currentTeamProfile?.id)) ||
    (!digestLoading && !digest) ||
    notAllowed
  ) {
    history.push(navRoutes.cms.assistant.path());
  }

  return (
    <>
      <Flex mt={NAV_HEIGHT} mb={8} />
      {digestLoading || !currentTeamProfile ? (
        <Loading />
      ) : !isPro ? (
        <ScreenWrapper>
          <Flex flexDirection="column" alignItems="flex-start">
            <Text
              mb={6}
              color="text.muted"
              mx={{ base: 'defaultMargin', md: 0 }}
            >
              Upgrade to Pro to see your digests:
            </Text>
            <ProCta label="" />
          </Flex>
        </ScreenWrapper>
      ) : (
        <>
          <ScreenWrapper>
            <Flex
              position="relative"
              bg="background.default"
              flexDirection="column"
              m={{ base: 0, md: -6 }}
              mt={{ base: -8, md: -6 }}
              borderRadius={{ base: 'none', md: 'xl' }}
              boxShadow={{ base: 'none', md: 'lg' }}
              overflow="hidden"
            >
              <Flex
                p={{ base: 4, md: 6 }}
                position="relative"
                flexDirection="column"
              >
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  alignItems={{ base: 'flex-start', sm: 'center' }}
                  spacing={{ base: 5, md: 6 }}
                  mb={4}
                >
                  <UserAvatar
                    name={currentTeamProfile.name}
                    userId={currentTeamProfile.id || 1}
                    avatarPicture={currentTeamProfile.logoDark || ''}
                    size={isMobile ? 'sm' : 'lg'}
                  />
                  <Flex flexDirection="column">
                    <Text
                      fontSize={{ base: 'md', md: 'lg' }}
                      fontWeight="bold"
                    >{`Weekly Digest for ${currentTeamProfile.name}`}</Text>
                    <Text
                      fontWeight="extrabold"
                      fontSize={{ base: '3xl', md: '4xl' }}
                      lineHeight={1.2}
                      mb={2}
                    >
                      {digest.json.summary.headline}
                    </Text>
                    <Text
                      fontSize={{ base: 'sm', md: 'md' }}
                      fontWeight="semibold"
                      color="text.muted"
                    >{`${moment(digest.created).format(
                      'MMM Do [‘]YY'
                    )} • Issue #${digest.issueNumber}`}</Text>
                  </Flex>
                </Stack>
                <Text>{digest.json.summary.body}</Text>
              </Flex>
              <Flex
                p={{ base: 4, md: 6 }}
                position="relative"
                flexDirection="column"
              >
                <Flex
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  bg={surface}
                  // bg="background.tint3"
                  zIndex={0}
                >
                  {/* <Flex flex={1} bg={bg} opacity={0.07} /> */}
                </Flex>
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  templateRows="repeat(4, 1fr)"
                  gap={6}
                  width="100%"
                  height="100%"
                  justifyItems="center"
                  alignItems="stretch"
                  position="relative"
                >
                  <DigestFeature
                    section={digest.json.sections.mainFeature}
                    colSpan={{ base: 2, md: 1 }}
                    rowSpan={{ base: 1, md: 2 }}
                    palette={palette}
                  />
                  <DigestFeature
                    section={digest.json.sections.sideFeature1}
                    colSpan={{ base: 2, md: 1 }}
                    rowSpan={1}
                    palette={palette}
                  />
                  <DigestFeature
                    section={digest.json.sections.sideFeature2}
                    colSpan={{ base: 2, md: 1 }}
                    rowSpan={1}
                    palette={palette}
                  />
                  <DigestFeature
                    section={digest.json.sections.fullwidth}
                    colSpan={2}
                    rowSpan={1}
                    palette={palette}
                  />
                  <DigestFeature
                    section={digest.json.sections.column1}
                    colSpan={{ base: 2, md: 1 }}
                    rowSpan={1}
                    palette={palette}
                  />
                  <DigestFeature
                    section={digest.json.sections.column2}
                    colSpan={{ base: 2, md: 1 }}
                    rowSpan={1}
                    palette={palette}
                  />
                </Grid>
              </Flex>
            </Flex>
            <Flex alignItems="flex-end">
              <Button
                variant="ghost"
                icon="List"
                mt={{ base: 4, md: 10 }}
                ml={{ base: 'defaultMargin', md: -6 }}
                onClick={() => setDigestsPopupIsOpen(true)}
              >
                See all digests
              </Button>
            </Flex>
          </ScreenWrapper>
          <DigestsPopup
            isOpen={digestsPopupIsOpen}
            onClose={() => setDigestsPopupIsOpen(false)}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { digestSlug } = props.match.params;

  const { digests } = state.assistant;

  // Course Data
  const digest = digests[digestSlug];

  return {
    digestSlug,
    digest,
  };
};

const connector = connect(mapStateToProps);

export default connector(withRouter(DigestScreen));
