import * as React from 'react';
import { Link } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import styled, { Text, Flex, MdIcon, Skeleton, Button } from '@workshop/ui';

import { ListDataType } from 'components/ListItem';

interface ItemProps extends ListDataType {
  allowHover?: boolean;
  border?: string;
  draggable?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  expandable?: boolean;
  expanded?: boolean;
  clickable?: boolean;
  linkTo?: string | null;
  padding?: number;
  highlight?: boolean;
  input?: React.ReactElement;
  onInputSave?: () => Promise<void>;
}

const Item: React.FC<ItemProps> = ({
  allowHover = true,
  border = 'none',
  children,
  complete,
  draggable = true,
  dragHandleProps = {},
  expandable = false,
  expanded = false,
  clickable = true,
  isLoading,
  label,
  linkTo,
  onClick = () => {},
  padding = 'defaultPadding',
  secondaryLabel,
  title,
  highlight,
  input,
  onInputSave,
}) => {
  const [showInput, setShowInput] = React.useState(false);
  React.useEffect(() => {
    if (!input) {
      setShowInput(false);
    }
  }, [input]);
  const item = (
    <Flex
      flex={1}
      padding={padding}
      cursor={clickable ? 'pointer' : 'auto'}
      borderTop={border}
      borderBottom={border}
      borderColor="border.muted"
      backgroundColor={highlight ? 'background.primary' : 'background.default'}
      onClick={onClick}
      transition="background-color 0.2s"
      _hover={
        clickable
          ? {
              backgroundColor: highlight
                ? 'background.primary'
                : 'background.tint1',
            }
          : {}
      }
    >
      <Skeleton flex={1} isLoaded={!isLoading} loadingStyle={{ width: '20%' }}>
        <Flex flex={1} alignItems="center">
          <Flex {...dragHandleProps}>
            <Flex
              backgroundColor={
                isLoading ? 'background.default' : 'background.tint3'
              }
              borderRadius="md"
              minWidth="65px"
              justifyContent="center"
              px={2}
              py={1}
              mr="defaultMargin"
              transition="background-color 0.2s"
              role="group"
              _hover={
                draggable && allowHover
                  ? { backgroundColor: 'background.default', cursor: 'grab' }
                  : {}
              }
            >
              <MdIcon
                name="Reorder"
                opacity={0.5}
                boxSize="1.125rem"
                display="none"
                _groupHover={
                  draggable && allowHover ? { display: 'block' } : {}
                }
              />
              <Text
                fontSize="xs"
                fontWeight="semibold"
                _groupHover={draggable && allowHover ? { display: 'none' } : {}}
              >
                {label}
              </Text>
            </Flex>
          </Flex>
          {input && showInput ? (
            <Flex alignItems="center" flex={1} mr={6}>
              {input}
              <Button
                size="sm"
                secondary
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInput(false);
                }}
                ml={3}
                px={2}
                icon="Close"
              />
              <Button
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onInputSave && onInputSave();
                  setShowInput(false);
                }}
                ml={3}
              >
                Save
              </Button>
            </Flex>
          ) : (
            <Flex alignItems="center">
              <Text>{title}</Text>
              {Boolean(input) && (
                <Button
                  ml={2}
                  size="xs"
                  icon="Edit"
                  px={1}
                  borderRadius="full"
                  variant="outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowInput(true);
                  }}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Skeleton>
      <Flex justifyContent="flex-end" alignItems="center">
        {/* TODO: Replace with custom accordion from Chakra */}
        {expandable && (
          <>
            <Text color="text.muted" marginX={1}>
              {expanded ? 'Collapse' : 'Expand'}
            </Text>
            <Flex color="text.muted">
              {expanded ? (
                <MdIcon name="KeyboardArrowUp" />
              ) : (
                <MdIcon name="KeyboardArrowDown" />
              )}
            </Flex>
          </>
        )}
        {complete && <MdIcon name="CheckCircle" color="green.500" mr={2} />}
        {children ? (
          children
        ) : (
          <Skeleton isLoaded={!isLoading} loadingStyle={{ width: '10%' }}>
            <Text fontWeight="semibold" color="common.primary">
              {secondaryLabel}
            </Text>
          </Skeleton>
        )}
      </Flex>
    </Flex>
  );

  return linkTo ? <Link to={linkTo}>{item}</Link> : item;
};

export default Item;
