import { useState } from 'react';

import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { Divider, Flex, MdIcon, Text, Box, useTheme } from '@workshop/ui';

import { HeaderTag } from 'components/AppHeader';

import { CohortType } from 'types/common';

const ClassTypeItem = ({
  classType,
  selectedClassType,
  setSelectedClassType,
  isExpandable,
  isSelectable,
  showDivider,
}: {
  classType: CohortType;
  selectedClassType?: CohortType;
  setSelectedClassType?: (classType: CohortType) => void;
  isExpandable?: boolean;
  isSelectable?: boolean;
  showDivider?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(!isExpandable);
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);
  return (
    <Box key={`class-type-${classType.id}`}>
      {showDivider && (
        <Box pt={2} pb={5}>
          <Divider />
        </Box>
      )}
      {(isExpandable && isExpanded) ||
      (isSelectable && classType.id === selectedClassType?.id) ? (
        <Flex
          flex={1}
          borderWidth={2}
          borderColor={
            classType.id === selectedClassType?.id
              ? 'common.primary'
              : 'border.muted'
          }
          backgroundColor={
            classType.id === selectedClassType?.id
              ? 'background.default'
              : 'background.tint1'
          }
          borderRadius="md"
          padding={4}
          alignItems="center"
          cursor="pointer"
          _hover={{ backgroundColor: 'background.default' }}
          onClick={() => {
            if (isExpandable) {
              setIsExpanded(false);
            }
            if (isSelectable) {
              setSelectedClassType && setSelectedClassType(classType);
            }
          }}
        >
          <Box flex={1}>
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems={{ base: 'flex-start', md: 'center' }}
              flex={1}
              mb={3}
            >
              <Box mr={2.5}>
                <MdIcon
                  name={classType.icon}
                  boxSize={7}
                  color="common.primary"
                />
              </Box>
              <Flex flex={1} alignItems="center">
                <Text fontSize="lg" fontWeight="bold" color="common.primary">
                  {classType.title}
                </Text>
                {classType.id === selectedClassType?.id ? (
                  <>
                    <MdIcon
                      name="CheckCircleOutline"
                      color="common.primary"
                      ml={4}
                    />
                    <Text
                      fontSize="xs"
                      color="common.primary"
                      fontWeight="semibold"
                      ml={1}
                    >
                      Selected
                    </Text>
                  </>
                ) : null}
              </Flex>
              {classType.tagName && classType.tagColor ? (
                <HeaderTag
                  title={classType.tagName}
                  bg={`background.${classType.tagColor}`}
                  color={`text.${classType.tagColor}`}
                  ml={{ base: 0, md: 3 }}
                  mt={{ base: 2, md: 0 }}
                />
              ) : null}
            </Flex>
            <Text fontSize="sm" color="text.muted" mb={2}>
              {classType.description}
            </Text>
            <Flex flexWrap="wrap">
              {classType.features.map((feature) => (
                <Flex
                  key={`class-${classType.id}-feature-${feature.label}-ex`}
                  alignItems="center"
                  mr={3.5}
                  mt={2}
                >
                  <MdIcon name={feature.icon} color={`icon.${feature.color}`} />
                  <Text
                    ml={1}
                    fontSize="xs"
                    fontWeight="semibold"
                    color={`text.${feature.color}`}
                  >
                    {feature.label}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Box>
        </Flex>
      ) : (
        <Flex
          flex={1}
          borderWidth={2}
          borderColor={
            classType.id === selectedClassType?.id
              ? 'common.primary'
              : 'border.muted'
          }
          backgroundColor={
            classType.id === selectedClassType?.id
              ? 'background.default'
              : 'background.tint1'
          }
          borderRadius="md"
          pl={3}
          py={1.5}
          alignItems="center"
          cursor="pointer"
          _hover={{ backgroundColor: 'background.default' }}
          onClick={() => {
            if (isExpandable) {
              setIsExpanded(true);
            }
            if (isSelectable) {
              setSelectedClassType && setSelectedClassType(classType);
            }
          }}
        >
          <Box flex={1}>
            <Flex alignItems="center" flex={1}>
              <Flex flex={1} alignItems="center" py={1.5}>
                <Box mr={2}>
                  <MdIcon
                    name={classType.icon}
                    boxSize={4}
                    color="common.primary"
                  />
                </Box>
                <Flex flex={1} alignItems="center">
                  <Text fontSize="md" fontWeight="bold" color="common.primary">
                    {classType.title}
                  </Text>
                  {!isMobile ? (
                    <MdIcon
                      boxSize={3.5}
                      ml={2.5}
                      name="InfoOutline"
                      color="icon.muted"
                    />
                  ) : null}
                </Flex>
              </Flex>
              <Flex
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-end"
              >
                {!isMobile ? (
                  classType.features.map((feature) => (
                    <Flex
                      key={`class-${classType.id}-feature-${feature.label}`}
                      alignItems="center"
                      mr={3.5}
                      my={1}
                    >
                      <MdIcon
                        name={feature.icon}
                        color={`icon.${feature.color}`}
                      />
                      <Text
                        ml={1}
                        fontSize="xs"
                        fontWeight="semibold"
                        color={`text.${feature.color}`}
                      >
                        {feature.label}
                      </Text>
                    </Flex>
                  ))
                ) : (
                  <>
                    <MdIcon name="InfoOutline" color="icon.muted" />
                    <Text
                      color="text.muted"
                      fontSize="xs"
                      ml={1.5}
                      mr={3.5}
                      display={{ base: 'none', sm: 'inline' }}
                      fontWeight="semibold"
                    >
                      Show Details
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default ClassTypeItem;
