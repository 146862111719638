import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, Range, RangeFocus } from 'react-date-range';
import { animated, useSpring } from 'react-spring';

import {
  Box,
  Flex,
  Card,
  Paragraph,
  Color,
  MdIcon,
  Modal,
  ModalOverlay,
} from '@workshop/ui';

interface TimePeriodSelectProps {
  label?: string;
  width?: number;
  defaultStart?: Date;
  defaultEnd?: Date;
  /**
   * Callback to trigger on selecting a date
   */
  onSelect: (item: Range) => void;
}
const AnimatedBox = animated(Box);

export const buildPlaceholderString = (
  start: Date | undefined,
  end: Date | undefined
) => {
  // If the start and end date are the same render only the start date string
  const startDate = start ? moment(start).format("D MMM [']YY") : null;
  const endDate = end ? moment(end).format("D MMM [']YY") : null;

  if (startDate === endDate) {
    return startDate || '';
  }

  return `${startDate || ''}${startDate && endDate ? ' - ' : ''}${
    endDate || ''
  }`;
};

const today = new Date();
const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const TimePeriodSelect: React.FC<TimePeriodSelectProps> = ({
  defaultStart,
  defaultEnd,
  label,
  onSelect,
  width = 500,
}) => {
  // Control the data for the DateRangePicked
  const [focusedRange, setFocusedRange] = useState<RangeFocus>([0, 0]);
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: defaultStart || startOfMonth,
    endDate: defaultEnd || today,
    key: 'selection',
  });

  // Control the display & animation of the DateRangePicker
  const [isOpen, setIsOpen] = useState(false);
  const animatedContentStyle = useSpring({
    config: { duration: 50 },
    opacity: isOpen ? 1 : 0,
  });

  useEffect(() => {
    if (!isOpen) {
      // Reset focus on close
      setFocusedRange([0, 0]);
    }
  }, [isOpen]);

  const handleSelect = (ranges: any) => {
    if (!('selection' in ranges)) return;

    const { startDate, endDate } = ranges.selection;

    const newValue = {
      startDate,
      endDate,
      key: 'selection',
    };

    setSelectionRange(newValue);

    if (moment(startDate).isSame(endDate)) {
      // A new startDate has been selected but a new endDate hasn't yet
      // --> handleSelect will be called again once the endDate has been picked
      return;
    }

    // Only close the date picker when the end date is selected
    setIsOpen(false);

    // Call the onSelect callback on closing our dropdown modal
    onSelect(newValue);
  };

  return (
    <>
      <Flex flexDir="column">
        <Card
          overflow="visible"
          cursor="pointer"
          onClick={() => setIsOpen(!isOpen)}
          width={width}
          alignItems="center"
          zIndex={isOpen ? 'popover' : 'auto'}
          borderRadius={100}
        >
          {label && (
            <Paragraph display="flex" flex={1} pl={2} fontWeight="semibold">
              {label}
            </Paragraph>
          )}
          <Paragraph fontWeight="medium" color={Color.common.primary}>
            {buildPlaceholderString(
              selectionRange.startDate,
              selectionRange.endDate
            )}
          </Paragraph>
          <Flex pl={2} pr={2}>
            <MdIcon name="ArrowDropDown" color={Color.common.primary} />
          </Flex>
        </Card>
        <Flex pointerEvents={isOpen ? 'auto' : 'none'}>
          <AnimatedBox
            style={animatedContentStyle}
            backgroundColor="white"
            borderRadius={20}
            boxShadow="lg"
            marginY={2}
            position="absolute"
            zIndex="popover"
            overflow="hidden"
          >
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              focusedRange={focusedRange}
              onRangeFocusChange={setFocusedRange}
              inputRanges={[]}
              showPreview
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              maxDate={today}
            />
          </AnimatedBox>
        </Flex>
      </Flex>
      {/* This allows us to close the dropdown on clicking */}
      {/* on an area outside the dropdown */}
      {/* TODO: Determine if this is actually necessary */}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay
          css={{
            cursor: 'pointer',
          }}
          onClick={() => setIsOpen(false)}
        />
      </Modal>
    </>
  );
};

export default TimePeriodSelect;
