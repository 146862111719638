const CostRecordListAT = {
  CMS_FETCH_COST_RECORD_LIST_REQUEST: 'CMS_FETCH_COST_RECORD_LIST_REQUEST',
  CMS_FETCH_COST_RECORD_LIST_SUCCESS: 'CMS_FETCH_COST_RECORD_LIST_SUCCESS',
  CMS_FETCH_COST_RECORD_LIST_FAILURE: 'CMS_FETCH_COST_RECORD_LIST_FAILURE',

  CMS_CREATE_COST_RECORD_REQUEST: 'CMS_CREATE_COST_RECORD_REQUEST',
  CMS_CREATE_COST_RECORD_SUCCESS: 'CMS_CREATE_COST_RECORD_SUCCESS',
  CMS_CREATE_COST_RECORD_FAILURE: 'CMS_CREATE_COST_RECORD_FAILURE',
} as const;

const LicenseListAT = {
  CMS_FETCH_LICENSE_LIST_REQUEST: 'CMS_FETCH_LICENSE_LIST_REQUEST',
  CMS_FETCH_LICENSE_LIST_SUCCESS: 'CMS_FETCH_LICENSE_LIST_SUCCESS',
  CMS_FETCH_LICENSE_LIST_FAILURE: 'CMS_FETCH_LICENSE_LIST_FAILURE',

  CMS_CREATE_LICENSE_REQUEST: 'CMS_CREATE_LICENSE_REQUEST',
  CMS_CREATE_LICENSE_SUCCESS: 'CMS_CREATE_LICENSE_SUCCESS',
  CMS_CREATE_LICENSE_FAILURE: 'CMS_CREATE_LICENSE_FAILURE',
} as const;

const LicenseAT = {
  CMS_FETCH_LICENSE_REQUEST: 'CMS_FETCH_LICENSE_REQUEST',
  CMS_FETCH_LICENSE_SUCCESS: 'CMS_FETCH_LICENSE_SUCCESS',
  CMS_FETCH_LICENSE_FAILURE: 'CMS_FETCH_LICENSE_FAILURE',

  CMS_PATCH_LICENSE_REQUEST: 'CMS_PATCH_LICENSE_REQUEST',
  CMS_PATCH_LICENSE_SUCCESS: 'CMS_PATCH_LICENSE_SUCCESS',
  CMS_PATCH_LICENSE_FAILURE: 'CMS_PATCH_LICENSE_FAILURE',
} as const;

const EnrolmentListAT = {
  CMS_FETCH_ENROLMENT_LIST_REQUEST: 'CMS_FETCH_ENROLMENT_LIST_REQUEST',
  CMS_FETCH_ENROLMENT_LIST_SUCCESS: 'CMS_FETCH_ENROLMENT_LIST_SUCCESS',
  CMS_FETCH_ENROLMENT_LIST_FAILURE: 'CMS_FETCH_ENROLMENT_LIST_FAILURE',

  CMS_CREATE_ENROLMENT_REQUEST: 'CMS_CREATE_ENROLMENT_REQUEST',
  CMS_CREATE_ENROLMENT_SUCCESS: 'CMS_CREATE_ENROLMENT_SUCCESS',
  CMS_CREATE_ENROLMENT_FAILURE: 'CMS_CREATE_ENROLMENT_FAILURE',
} as const;

const EnrolmentAT = {
  CMS_FETCH_ENROLMENT_REQUEST: 'CMS_FETCH_ENROLMENT_REQUEST',
  CMS_FETCH_ENROLMENT_SUCCESS: 'CMS_FETCH_ENROLMENT_SUCCESS',
  CMS_FETCH_ENROLMENT_FAILURE: 'CMS_FETCH_ENROLMENT_FAILURE',

  CMS_PATCH_ENROLMENT_REQUEST: 'CMS_PATCH_ENROLMENT_REQUEST',
  CMS_PATCH_ENROLMENT_SUCCESS: 'CMS_PATCH_ENROLMENT_SUCCESS',
  CMS_PATCH_ENROLMENT_FAILURE: 'CMS_PATCH_ENROLMENT_FAILURE',
} as const;

export default {
  ...CostRecordListAT,
  ...LicenseListAT,
  ...LicenseAT,
  ...EnrolmentListAT,
  ...EnrolmentAT,
};
