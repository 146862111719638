import { CourseAT, DashboardAT, ProfileAT } from 'redux/actionTypes/learner';

import { Action } from 'types';
import {
  CourseState,
  CourseProgressState,
  CourseScheduleState,
} from 'types/learner';

export const coursesReducer = (
  state: CourseState = {
    cohorts: {},
    upcomingCohorts: {},
    courses: { detail: {}, summary: {} },
    modules: {},
    moduleQuestions: {},
    units: {},
  },
  action: Action
): CourseState => {
  switch (action.type) {
    case CourseAT.FETCH_COURSE_SUCCESS:
      const {
        payload: { entities },
      } = action;
      return {
        ...state,
        courses: {
          ...state.courses,
          detail: { ...state.courses.detail, ...entities.course },
        },
        units: { ...state.units, ...entities.units },
        modules: { ...state.modules, ...entities.modules },
      };

    case CourseAT.FETCH_COURSE_LIST_SUCCESS:
      return {
        ...state,
        courses: {
          ...state.courses,
          summary: { ...action.payload.entities.course },
        },
      };

    case ProfileAT.FETCH_USER_LIBRARY_SUCCESS:
      return {
        ...state,
        courses: {
          ...state.courses,
          summary: { ...action.payload.entities.courses },
        },
      };

    case CourseAT.FETCH_MODULE_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        moduleQuestions: {
          ...state.moduleQuestions,
          ...action.payload.entities.questions,
        },
      };

    case CourseAT.FETCH_COHORT_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          [action.payload.result]:
            action.payload.entities.cohorts[action.payload.result],
        },
      };

    case CourseAT.LIST_COHORTS_SUCCESS:
      const cohorts = action.payload.entities.cohorts;
      return { ...state, cohorts: { ...state.cohorts, ...cohorts } };

    case CourseAT.LIST_UPCOMING_COHORTS_SUCCESS:
      const upcomingCohorts = action.payload.entities.cohorts;
      return {
        ...state,
        upcomingCohorts: { ...state.upcomingCohorts, ...upcomingCohorts },
      };

    // The dashboard API returns summary course data, along with nested
    // unit & module data
    case DashboardAT.FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        courses: {
          ...state.courses,
          summary: { ...action.payload.entities.course },
        },
        units: {
          ...state.units,
          ...action.payload.entities.units,
        },
        modules: {
          ...state.modules,
          ...action.payload.entities.modules,
        },
      };
  }
  return state;
};

export const courseProgressReducer = (
  state: CourseProgressState = { courses: {}, modules: {}, units: {} },
  action: Action
): CourseProgressState => {
  switch (action.type) {
    case DashboardAT.FETCH_DASHBOARD_SUCCESS:
    case CourseAT.FETCH_COURSE_PROGRESS_SUCCESS:
    case CourseAT.LIST_COURSE_PROGRESS_SUCCESS:
    case CourseAT.UPDATE_USER_MODULE_PROGRESS_SUCCESS:
    case CourseAT.UPDATE_USER_UNIT_PROGRESS_SUCCESS:
      return {
        ...state,
        courses: {
          ...state.courses,
          ...action.payload.entities.courseProgress,
        },
        units: {
          ...state.units,
          ...action.payload.entities.unitProgress,
        },
        modules: {
          ...state.modules,
          ...action.payload.entities.moduleProgress,
        },
      };
  }
  return state;
};

export const courseScheduleReducer = (
  state: CourseScheduleState = { courses: {}, modules: {}, units: {} },
  action: Action
) => {
  switch (action.type) {
    case DashboardAT.FETCH_DASHBOARD_SUCCESS:
    case CourseAT.FETCH_COURSE_SCHEDULE_SUCCESS:
      return {
        ...state,
        modules: {
          ...state.modules,
          ...action.payload.entities.moduleSchedules,
        },
        units: {
          ...state.units,
          ...action.payload.entities.unitSchedules,
        },
        courses: {
          ...state.courses,
          ...action.payload.entities.courseSchedules,
        },
      };
    case CourseAT.UPDATE_COURSE_MODULE_SCHEDULE_SUCCESS:
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.payload.result]: {
            ...state.modules[action.payload.result],
            ...action.payload.entities.moduleSchedules[action.payload.result],
          },
        },
      };
    default:
      break;
  }
  return { ...state };
};
