import moize from 'moize';
import moment from 'moment';

import { WeeklyGoalProgress } from 'types/learner';

export interface StreakData {
  date: string;
  percentageComplete: number;
  targetAmount: number;
  goalProgress: number;
  goalComplete: boolean;
}

export interface FormattedStreakData {
  streakValue: number;
  currentWeekData?: StreakData;
  streakItems: StreakData[];
}

/**
 * `formatProgressData` takes in an array of a users weekly goal progress objects
 * and reformats it to ensure that the weekly data is sorted. It also calculates
 * the users weekly goal streak (if they have one) and returns additional data on
 * the users progress in the current week.
 *
 * `progressData` is the array of user weekly goal progress objects.
 */
const _formatProgressData = (
  progressData: WeeklyGoalProgress[]
): FormattedStreakData => {
  /**
   * Get the current week in YYYY-MM-DD format.
   * Moment defines the start of a week as a Sunday, so we add 1 day since the backend
   * defines the start of a week as a Monday..
   */
  const currentWeek = moment().startOf('week').add(1, 'day');

  /**
   * When we display the `GoalProgress` component, we try to show historic progress for
   * up to 5 weeks, so we we build an array of weekly dates. With this we can show previous
   * week goal progress, even if the user doesn't have previous goal progress.
   */
  const weeksToShow = [];
  for (let i = 0; i <= 4; i += 1) {
    weeksToShow.push(
      currentWeek.clone().subtract(i, 'weeks').format('YYYY-MM-DD')
    );
  }

  /**
   * Now we want to loop through our raw `progressData` and map it against our
   * `weeksToShow` so that we're only working with the progress data we need. This
   * is the data which will be passed to the `GoalProgress` component to render
   * the historic goal rings.
   */
  const streakItems: Array<StreakData> = weeksToShow.map((week) => {
    // Extract progress data for the `week` we're currently iterating over. If
    // there is no progress data (the user made no progress in that week), then
    // `selectedProgressData` will be `null`.
    const selectedProgressData = progressData.find((progressItem) => {
      if (progressItem) {
        return progressItem.weekCommencing === week;
      }
      return false;
    });

    // Return a valid `StreakData` object.
    return {
      date: week,
      percentageComplete: selectedProgressData
        ? selectedProgressData.percentageComplete
        : 0,
      targetAmount: selectedProgressData
        ? selectedProgressData.targetAmount
        : 10,
      goalProgress: selectedProgressData
        ? selectedProgressData.goalProgress
        : 0,
      goalComplete: selectedProgressData
        ? selectedProgressData.goalComplete
        : false,
    };
  });

  // Sort the progress data by date, most recent first.
  streakItems.sort((a, b) => {
    // @ts-ignore - 'X' return unix timestamp
    const aMoment: number = moment(a.date).format('X');
    // @ts-ignore - 'X' return unix timestamp
    const bMoment: number = moment(b.date).format('X');
    return aMoment - bMoment;
  });

  // We specifically extract the progress data for the current week. This is to be
  // used to render the main current week goal progress ring.
  const currentWeekData = streakItems.find(
    (object) => object.date === currentWeek.format('YYYY-MM-DD')
  );

  // Calculate how many weeks in a row the user has completed their goal.
  // We don't care about the current week data not existing or not being
  // complete when calculating a streak - we only take into account the
  // current week if it's complete and incrememt the streak by 1.
  let streakValue = 0;
  let lastDate = currentWeek;
  progressData.every((item) => {
    if (!item || !item.goalComplete) {
      return false;
    }
    // Account for weeks where there was no progress made by checking
    // the date of the current item against the last checked progress
    // item date stored in `lastDate`. If the difference is greater than
    // 7 days, then the streak was broken by the user making no progress
    // that week.
    if (moment(item.weekCommencing).diff(lastDate, 'days') < -7) {
      return false;
    }
    streakValue += 1;
    lastDate = moment(item.weekCommencing);
    return true;
  });

  return {
    streakValue,
    currentWeekData,
    streakItems,
  };
};

export const formatProgressData = moize.deep(_formatProgressData);
