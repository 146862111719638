import {
  UserWorkshopRelationshipSchema,
  WorkshopSchema,
} from 'redux/schemas/learner';
import { callAPI } from 'utils';
import API from 'constants/api';

import { WorkshopAT } from 'redux/actionTypes/learner';

import {
  FetchUserUserWorkshopRelationshipAction,
  FetchWorkshopAction,
} from 'types/learner';

export const workshopActions = {
  list: () =>
    callAPI<FetchWorkshopAction>({
      types: [
        WorkshopAT.FETCH_WORKSHOP_LIST_REQUEST,
        WorkshopAT.FETCH_WORKSHOP_LIST_SUCCESS,
        WorkshopAT.FETCH_WORKSHOP_LIST_FAILURE,
      ],
      endpoint: API.learner.workshops,
      method: 'GET',
      schema: [WorkshopSchema],
      path: 'results',
    }),
};

export const workshopRelationshipActions = {
  list: () =>
    callAPI<FetchUserUserWorkshopRelationshipAction>({
      types: [
        WorkshopAT.FETCH_USER_WORKSHOP_RELATIONSHIP_REQUEST,
        WorkshopAT.FETCH_USER_WORKSHOP_RELATIONSHIP_SUCCESS,
        WorkshopAT.FETCH_USER_WORKSHOP_RELATIONSHIP_FAILURE,
      ],
      endpoint: API.account.userWorkshopRelationships,
      method: 'GET',
      schema: [UserWorkshopRelationshipSchema],
      path: 'results',
    }),
};
