const AssessmentAT = {
  CMS_COHORT_ASSESSMENTS_LIST_REQUEST: 'CMS_COHORT_ASSESSMENTS_LIST_REQUEST',
  CMS_COHORT_ASSESSMENTS_LIST_SUCCESS: 'CMS_COHORT_ASSESSMENTS_LIST_SUCCESS',
  CMS_COHORT_ASSESSMENTS_LIST_FAILURE: 'CMS_COHORT_ASSESSMENTS_LIST_FAILURE',

  CMS_ASSESSMENT_UPDATE_GRADE_REQUEST: 'CMS_ASSESSMENT_UPDATE_GRADE_REQUEST',
  CMS_ASSESSMENT_UPDATE_GRADE_SUCCESS: 'CMS_ASSESSMENT_UPDATE_GRADE_SUCCESS',
  CMS_ASSESSMENT_UPDATE_GRADE_FAILURE: 'CMS_ASSESSMENT_UPDATE_GRADE_FAILURE',
} as const;

export default {
  ...AssessmentAT,
};
