import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { GlobalState } from 'types';
import { UserLibrary, WorkshopsState } from 'types/learner';
import { getLearnerCourses } from './course';
import { getWorkshopList } from './workshops';

export const getUserLibrary = (state: GlobalState): UserLibrary =>
  state.learner.library.userLibrary;

export const getUserLibraryWorkshops = createSelector(
  [getUserLibrary, getWorkshopList],
  (userLibrary: UserLibrary, workshops: WorkshopsState) =>
    userLibrary.purchased.map((slug) => workshops[slug])
);

export const getUserLibraryWishlist = createSelector(
  [getUserLibrary, getWorkshopList],
  (userLibrary: UserLibrary, workshops: WorkshopsState) =>
    userLibrary.wishlist.map((slug) => workshops[slug])
);

export const getUserLibraryCourses = createSelector(
  [getUserLibrary, getLearnerCourses],
  (userLibrary: UserLibrary, courses) =>
    userLibrary.courses
      .filter((slug) => courses.summary[slug])
      .map((slug) => courses.summary[slug])
);

export const useUserLibraryCourses = () => useSelector(getUserLibraryCourses);
