import React from 'react';

import {
  Box,
  Card,
  Flex,
  Link,
  Image,
  MdIcon,
  Skeleton,
  BoxProps,
} from '@workshop/ui';

interface ImageListItem {
  src: string | undefined;
  url?: string;
  onClick?: () => void;
}

type ImageSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface ImageListProps {
  images: ImageListItem[];
  isLoading?: boolean;
  size?: ImageSize;
  containerType?: 'card' | 'plain';
  containerStyle?: BoxProps;
}

const noop = () => null;

const getContainerComponent = (containerType: 'card' | 'plain') => {
  switch (containerType) {
    case 'card':
      return Card;
    case 'plain':
      return Flex;
    default:
      return Flex;
  }
};
const ImageList: React.FC<ImageListProps> = ({
  images,
  isLoading = false,
  size = 'md',
  containerType = 'plain',
  containerStyle = {},
}) => {
  const Container = getContainerComponent(containerType);

  return (
    <Container
      marginLeft={{ base: 2, md: -1 }}
      flexWrap="wrap"
      {...containerStyle}
    >
      {isLoading
        ? Array(4)
            .fill(1)
            .map((k, i) => (
              <Skeleton key={i} margin={1}>
                <Box boxSize={`image.${size}`} />
              </Skeleton>
            ))
        : images.map((img, idx) => {
            const image = img.src ? (
              <Image
                key={img.src + idx}
                src={img.src}
                onClick={img.onClick || noop}
                objectFit="cover"
                cursor={img.url || img.onClick ? 'pointer' : 'inherit'}
                margin={1}
                boxSize={`image.${size}`}
                border="none"
                boxShadow={{ base: 'none', md: 'md' }}
                borderRadius="sm"
              />
            ) : (
              <Flex
                backgroundColor="background.tint3"
                alignItems="center"
                justifyContent="center"
                margin={1}
                boxSize={`image.${size}`}
                borderRadius="md"
              >
                <MdIcon
                  name="CropOriginal"
                  boxSize={size === 'xs' ? 'icon' : 'image.2xs'}
                  color="icon.default"
                />
              </Flex>
            );

            return img.url ? (
              <Link href={img.url} target="_blank">
                {image}
              </Link>
            ) : (
              image
            );
          })}
    </Container>
  );
};

export default ImageList;
