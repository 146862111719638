import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import isEqual from 'fast-deep-equal';
import {
  Button,
  Card,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Text,
  useTheme,
} from '@workshop/ui';
import { UserAvatar } from 'components/UserAvatar';

import { Grade } from 'types/cms';

interface AssessmentCardProps {
  id: number;
  submissions: {
    imageUrl: string;
    body: string | React.ReactElement;
  }[];
  disabled?: boolean;
  defaultValue?: Grade;
  title: string;

  grade?: Grade;
  onSetGrade?: (id: number, grade: Grade) => void;
}

const options = [
  { label: 'Pass', value: 'pass' },
  { label: 'Fail', value: 'fail' },
];
const AssessmentCard: React.FC<AssessmentCardProps> = ({
  id,
  disabled,
  defaultValue,
  title,
  submissions,
  onSetGrade,
}) => {
  const theme = useTheme();
  return (
    <Card
      flexGrow={1}
      flexShrink={1}
      flexDirection="column"
      m="5px"
      overflow="visible"
      padding={2}
    >
      <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        {submissions.map((submission, idx) => (
          <Flex
            key={`assessment-submission-${id}-${idx}`}
            flexDirection="column"
            w="calc(50% - 10px)"
          >
            <Image
              border="none"
              borderRadius={10}
              boxShadow="md"
              my={2}
              // objectFit="cover"
              // boxSize="image.xl"
              src={submission.imageUrl}
            />

            <Flex flexDir="column" flex={1} width="100%">
              <Text fontWeight="bold">{title}</Text>
              <Text fontSize="sm" mb={2} flex={1} width="100%">
                {submission.body}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Text fontWeight="bold" mb={2}>
        Grade
      </Text>
      <Select
        autoFocus={false}
        isDisabled={disabled}
        defaultValue={options.find((o) => o.value === defaultValue)}
        isMulti={false}
        options={options}
        onChange={(opt) => {
          // @ts-ignore
          opt && onSetGrade && onSetGrade(id, opt.value);
        }}
        styles={{
          option: (provided, state) => ({
            ...provided,
            color: theme.colors?.text.dark || '#000',
          }),
        }}
      />
    </Card>
  );
};

/**
 * Object used to keep track of grades for each assignment
 * (where the index is the assignmentId) */
interface AssessmentGrades {
  [assignmentId: number]: Grade;
}

interface UserAssessmentsModalProps {
  assessments: AssessmentCardProps[];
  isOpen: boolean;
  userAvatar: string;
  userId: number;
  userName: string;
  onCancel: () => void;
  onSave: (grades: AssessmentGrades) => Promise<any>;
}

const UserAssessmentsModal: React.FC<UserAssessmentsModalProps> = ({
  assessments,
  isOpen,
  userAvatar,
  userId,
  userName,
  onCancel,
  onSave,
}) => {
  const defaultState = assessments.reduce((acc: AssessmentGrades, a) => {
    return { ...acc, [a.id]: a.grade || null };
  }, {});

  const [assessmentGrades, setAssessmentGrades] = useState(defaultState);
  const [touched, setTouched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onSetGrade = (id: number, grade: Grade) =>
    setAssessmentGrades((prev) => ({ ...prev, [id]: grade }));

  useEffect(() => {
    if (!touched && !isEqual(assessmentGrades, defaultState)) {
      setTouched(true);
    }
    if (touched && isEqual(assessmentGrades, defaultState)) {
      setTouched(false);
    }
  }, [assessmentGrades]);

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onCancel}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader
          backgroundColor="background.tint2"
          borderTopLeftRadius="lg"
          borderTopRightRadius="lg"
          fontSize="md"
          fontWeight="bold"
          letterSpacing="title"
        >
          <Flex alignItems="center">
            <Text mr={4} fontSize="sm">
              Assessments
            </Text>
            <UserAvatar
              avatarPicture={userAvatar}
              name={userName}
              size="2xs"
              userId={userId}
            />
            <Text ml={2} fontWeight="normal" fontSize="sm" flex={1}>
              {userName}
            </Text>
            <Button
              fontSize="sm"
              secondary
              onClick={onCancel}
              isDisabled={!touched || isSaving}
            >
              Cancel
            </Button>
            <Button
              isLoading={isSaving}
              fontSize="sm"
              onClick={async () => {
                setIsSaving(true);
                await onSave(assessmentGrades);
                onCancel();
              }}
              ml={2}
              isDisabled={!touched || isSaving}
            >
              Save
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody
          borderBottomLeftRadius="lg"
          borderBottomRightRadius="lg"
          display="flex"
          flexWrap="wrap"
          maxH="70vh"
          overflow="scroll"
          pb={1}
          px="2.5px"
        >
          {assessments.map((assessment) => (
            <AssessmentCard
              key={`assessment-card-${assessment.id}`}
              {...assessment}
              defaultValue={assessment.grade}
              onSetGrade={onSetGrade}
              disabled={isSaving}
            />
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserAssessmentsModal;
