import { UI_AT } from 'redux/actionTypes/common';
import {
  Clear404Pathname,
  ClearToastMessage,
  ErrorToastMessage,
  SuccessToastMessage,
  MessagingUIData,
  TogglePrivateMessage,
} from 'types/common';

export const clearToastMessage: ClearToastMessage = {
  type: UI_AT.CLEAR_TOAST_MESSAGE,
};

export const errorToastMessage = (message: string): ErrorToastMessage => ({
  type: UI_AT.ERROR_TOAST_MESSAGE,
  message,
});

export const successToastMessage = (message: string): SuccessToastMessage => ({
  type: UI_AT.SUCCESS_TOAST_MESSAGE,
  message,
});

export const clear404Pathname = (): Clear404Pathname => ({
  type: UI_AT.CLEAR_404_PATHNAME,
});

/**
 * UI helper - allows us to show/hide the ChatWidget
 * in the AppRouter for chatting to a specific user or group
 */
export const toggleUserMessaging = (
  data: MessagingUIData,
  show: boolean = true
): TogglePrivateMessage => ({
  type: UI_AT.TOGGLE_PRIVATE_MESSAGING,
  data,
  show,
});
