import { discourseUrl } from 'constants/env';

import { DiscourseNotification } from 'types/common';

type Options = {
  enableLongPolling: boolean;
  callbackInterval: number;
  minPollInterval: number;
  maxPollInterval: number;
  baseUrl: string;
  headers: { [key: string]: string };
  enableChunkedEncoding: boolean;
  enableApiAuth: boolean;
  userApiKey: string;
};

/**
 * MessageData types ==> these are the type definitions for
 * the data returned in each message
 *
 * TODO : update these types as needed
 */

export interface NotificationData {
  unreadNotifications: number;
  unreadPrivateMessages: number;
  readFirstNotification: boolean;
  lastNotification: {
    notification: DiscourseNotification;
  };
  recent: Array<[number, boolean]>;
  seenNotificationId?: number;
}

export interface LatestTopicMessageData {
  messageType: 'latest';
  payload: {
    bumpedAt: string;
    categoryId: number;
    topicTagIds: [];
  };
  topicId: number;
}

export interface NewTopicMessageData {
  message_type: 'new_topic';
  payload: {
    createdAt: string;
    categoryId: number;
  };
  topicId: number;
}

export interface StatusMessageData {
  [key: string]: number;
}

export type MessageData =
  | StatusMessageData
  | NewTopicMessageData
  | LatestTopicMessageData
  | NotificationData;

/**
 * Message types ==> these are the definitions for each
 * message, based on each channel - NB : these will need updating
 * on the go, as each channel may return different data types
 */

interface BaseMessage {
  global_id: number;
  message_id: number;
}

type StatusMessage = BaseMessage & {
  channel: '/__status';
  data: StatusMessageData;
};

type NewMessage = BaseMessage & { channel: '/new'; data: NewTopicMessageData };

type LatestMessage = BaseMessage & {
  channel: '/latest';
  data: LatestTopicMessageData;
};

type NotificationMessage = BaseMessage & {
  channel: `/notification/{not_sure..}`;
  data: NotificationData;
};

type Message = StatusMessage | NewMessage | LatestMessage | NotificationMessage;

/** This is the definition for the callback passed when subscribing to a channel */
type CallbackFunction = (
  data: MessageData,
  messageGlobalId: number,
  messageId: number
) => any;

type Callback = {
  channel: string;
  func: CallbackFunction;
  last_id: number;
};

type RequestData = {
  [key: string]: number;
};

const buildQueryString = (uri: string, params: { [key: string]: any }) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  const separator = !!queryString ? (uri.indexOf('?') !== -1 ? '&' : '?') : '';
  return `${uri}${separator}${queryString}`;
};

class MessageBus {
  defaultOptions: Options = {
    enableLongPolling: true,
    callbackInterval: 15000,
    minPollInterval: 100,
    maxPollInterval: 180000,
    baseUrl: `${discourseUrl}/`,
    headers: { 'Content-Type': 'application/json' },
    enableChunkedEncoding: true,
    enableApiAuth: true,
    userApiKey: '',
  };

  initChannels = {
    // new: '/new',
    // latest: '/latest',
    unread: '/unread/{userId}',
    notification: '/notification/{userId}',
    // categories: '/categories',
    notificationAlert: '/notification-alert/{userId}',
  };

  options: Options;
  clientId: string;
  callbacks: Array<Callback>;
  later: Array<Message>;
  chunkedBackoff: number;
  started: boolean;
  stopped: boolean;
  paused: boolean;
  delayPollTimeout?: number | null;
  longPoll?: null | Promise<void | Response>;
  requestInProgress: boolean;
  lastRequest?: Date | null;
  requestCount: number;
  totalFailureCount: number;
  recentFailureCount: number;

  constructor(options?: Options) {
    this.options = {
      ...this.defaultOptions,
      ...options,
    };

    // Initialise class data
    this.clientId = this.uniqueId();
    this.callbacks = [];
    this.later = [];
    this.chunkedBackoff = 0;

    // Initialise polling status indicators
    this.paused = false;
    this.started = false;
    this.stopped = true;

    // Initialise other variables
    this.requestInProgress = false;

    // Diagnostics
    this.lastRequest = null;
    this.requestCount = 0;
    this.totalFailureCount = 0;
    this.recentFailureCount = 0;
  }

  configure = (options: Partial<Options>) => {
    this.options = {
      ...this.options,
      ...options,
    };
  };

  uniqueId = () =>
    'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

  diagnostics = () => {
    console.log(`Stopped: ${this.stopped} Started: ${this.started}`);
    console.log('Current callbacks');
    console.log(this.callbacks);
    console.log(
      `Total ajax calls: ${this.requestCount} Recent failure count: ${this.recentFailureCount} Total failures: ${this.totalFailureCount}`
    );
    if (this.lastRequest) {
      const timeElapsed = new Date().getTime() - this.lastRequest.getTime();
      console.log(`Last ajax call: ${timeElapsed / 1000} seconds ago`);
    }
  };

  processMessages = (messages: Array<Message>) => {
    let gotData = false;

    if (!messages) return false; // server unexpectedly closed connection

    messages.forEach((message) => {
      gotData = true;

      this.callbacks.forEach((callback) => {
        if (callback.channel === message.channel) {
          callback.last_id = message.message_id;
          try {
            callback.func(message.data, message.global_id, message.message_id);
          } catch (e) {
            if (console.log) {
              console.log(
                `MESSAGE BUS FAIL: callback ${callback.channel} caused exception ${e.stack}`
              );
            }
          }
        }
        if (message.channel === '/__status') {
          if (message.data[callback.channel] !== undefined) {
            callback.last_id = message.data[callback.channel];
          }
        }
      });
    });

    return gotData;
  };

  // processPayload = (payload, position) => {
  //   const separator = '\r\n|\r\n';
  //   const endChunk = payload.indexOf(separator, position);

  //   if (endChunk === -1) {
  //     return position;
  //   }

  //   let chunk = payload.substring(position, endChunk);
  //   chunk = chunk.replace(/\r\n\|\|\r\n/g, separator);

  //   console.log('chunk', chunk);

  //   return this.processPayload(payload, endChunk + separator.length);
  // };

  onSuccess = (messages: Array<Message>) => {
    this.recentFailureCount = 0;

    if (this.paused) {
      if (messages) {
        messages.forEach((message) => this.later.push(message));
      }
    } else {
      return this.processMessages(messages);
    }
    return false;
  };

  longPoller = async (poll: Function, data: RequestData) => {
    if (this.requestInProgress) {
      // never allow concurrent requests
      return;
    }
    let retrievedData = false;
    let aborted = false;
    let rateLimited = false;
    let rateLimitedSeconds = 1;

    this.lastRequest = new Date();
    this.requestCount += 1;
    // Set an incrementing integer sequence number for each request
    // (used to detect out of order requests and close those with the
    // same client ID and lower sequence numbers).
    data.__seq = this.requestCount;

    let chunked =
      this.options.enableLongPolling && this.options.enableChunkedEncoding;
    if (this.chunkedBackoff > 0) {
      this.chunkedBackoff--;
      chunked = false;
    }

    const headers: { [key: string]: string } = {
      'X-SILENCE-LOGGER': 'true',
      ...(this.options.enableApiAuth
        ? { 'User-Api-Key': this.options.userApiKey }
        : {}),
      ...this.options.headers,
    };

    if (!chunked) {
      headers['Dont-Chunk'] = 'true';
    }

    const dataType = chunked ? 'text' : 'json';

    const queryParams = {
      ...(!this.options.enableLongPolling ? { dlp: 't' } : {}),
    };

    const url = buildQueryString(
      `${this.options.baseUrl}message-bus/${this.clientId}/poll`,
      queryParams
    );

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers,
    });

    // TODO: Support chunking: https://github.com/facebook/react-native/issues/25910

    // if (xhr.getResponseHeader('Content-Type') === 'application/json; charset=utf-8') {
    //   chunked = false; // not chunked, we are sending json back
    // } else {
    //   position = handle_progress(xhr.responseText, position);
    // }

    // td = new TextDecoder('utf-8');
    // buffer = '';

    // const { value, done } = await reader.read();

    if (!response.ok) {
      // error
      if (response.status === 429) {
        // Rate limited - so listen to the retry after header
        let tryAfter = parseInt(response.headers.get('Retry-After') || '0');
        if (tryAfter < 15) {
          tryAfter = 15;
        }
        rateLimitedSeconds = tryAfter;
        rateLimited = true;
      } else if (response.statusText === 'abort') {
        aborted = true;
      } else {
        this.recentFailureCount += 1;
        this.totalFailureCount += 1;
      }
    } else {
      // success
      // eslint-disable-next-line
      if (!chunked) {
        const json = await response.json();
        retrievedData = this.onSuccess(json);
      } else {
        // this.processPayload(text, 0);
        console.warn('Chunking not implemented');
      }
    }

    // complete
    this.requestInProgress = false;

    // Calculate the interval until the next poll should happen
    let interval;
    try {
      if (rateLimited) {
        interval = Math.max(
          this.options.minPollInterval,
          rateLimitedSeconds * 1000
        );
      } else if (retrievedData || aborted) {
        interval = this.options.minPollInterval;
      } else {
        interval = this.options.callbackInterval;
        if (this.recentFailureCount > 2) {
          interval *= this.recentFailureCount;
        }
        if (interval > this.options.maxPollInterval) {
          interval = this.options.maxPollInterval;
        }
        const d = new Date();
        interval -= d.getTime() - this.lastRequest.getTime();

        if (interval < 100) {
          interval = 100;
        }
      }
    } catch (e) {
      if (console.log && e.message) {
        console.log(`MESSAGE BUS FAIL: ${e.message}`);
      }
    }

    if (this.delayPollTimeout) {
      clearTimeout(this.delayPollTimeout);
      this.delayPollTimeout = null;
    }

    if (this.started) {
      this.delayPollTimeout = setTimeout(() => {
        this.delayPollTimeout = null;
        poll();
      }, interval);
    }

    this.longPoll = null;

    return response;
  };

  status = () => {
    if (this.paused) {
      return 'paused';
    }
    if (this.started) {
      return 'started';
    }
    if (this.stopped) {
      return 'stopped';
    }
    return 'Cannot determine current status';
  };

  pause = () => {
    this.paused = true;
  };

  resume = () => {
    this.paused = false;
    this.processMessages(this.later);
    this.later = [];
  };

  stop = () => {
    this.stopped = true;
    this.started = false;

    if (this.delayPollTimeout) {
      clearTimeout(this.delayPollTimeout);
      this.delayPollTimeout = null;
    }

    // if (this.longPoll) {
    //   this.longPoll.abort();
    // }
  };

  start = () => {
    if (this.started) return;

    this.started = true;
    this.stopped = false;

    const poll = () => {
      const data: RequestData = {};

      if (this.stopped) return;

      // If no callbacks have been defined, then delay
      // starting
      if (this.callbacks.length === 0) {
        if (!this.delayPollTimeout) {
          this.delayPollTimeout = window.setTimeout(() => {
            this.delayPollTimeout = null;
            poll();
          }, Math.floor(500 + Math.random() * 500));
        }
        return;
      }

      // Map over the callbacks to build the POST data
      // which we'll use to poll the message bus with
      this.callbacks.forEach((callback) => {
        data[callback.channel] = callback.last_id;
      });

      if (!this.longPoll) {
        // TODO: This currently causes a crash – needs fixing
        // this.longPoll = this.longPoller(poll, data);
      }
    };

    // Start polling
    poll();
  };

  /**
   * Subscribe to a channel
   * if lastId is 0 or larger, it will recieve messages AFTER that id
   * if lastId is negative it will perform lookbehind
   * -1 will subscribe to all new messages
   * -2 will recieve last message + all new messages
   * -3 will recieve last 2 messages + all new messages
   */
  subscribe = (channel: string, func: CallbackFunction, lastId: number) => {
    // Automatically call start if it hasn't already been called.
    if (!this.started && !this.stopped) {
      this.start();
    }

    if (typeof lastId !== 'number') {
      lastId = -1;
    }

    if (typeof channel !== 'string') {
      return console.error('Channel name must be a string!');
    }

    this.callbacks.push({
      channel,
      func,
      last_id: lastId,
    });

    return func;
  };

  /**
   * Unsubscribe from a channel
   */
  unsubscribe = (channel: string, func?: CallbackFunction) => {
    // TODO allow for globbing in the middle of a channel name
    // like /something/*/something
    // at the moment we only support globbing /something/*
    let glob = false;
    if (channel.indexOf('*', channel.length - 1) !== -1) {
      channel = channel.substr(0, channel.length - 1);
      glob = true;
    }

    let removed = false;

    for (let i = this.callbacks.length - 1; i >= 0; i--) {
      const callback = this.callbacks[i];
      let keep = false;

      if (glob) {
        keep = callback.channel.substr(0, channel.length) !== channel;
      } else {
        keep = callback.channel !== channel;
      }

      if (!keep && func && callback.func !== func) {
        keep = true;
      }

      if (!keep) {
        this.callbacks.splice(i, 1);
        removed = true;
      }
    }

    return removed;
  };
}

export default new MessageBus();
