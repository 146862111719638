import DiscourseJs from 'discourse-js';

/**
 * Expose a global, configurable instance of `discourse-js`.
 * This should be initialised by calling `.config()` with the
 * required authentication data (API key & Username).
 *
 * The initialised instance can then be used to make authenticated
 * API calls for the current app user.
 *
 * See SetCurrentUserAsync.js
 */

// @ts-ignore
const Discourse = new DiscourseJs();

export default Discourse;
