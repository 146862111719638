import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { camelizeKeys } from 'humps';
import * as Sentry from '@sentry/react';

import { hooks, analytics } from 'utils';

import { LocationState } from 'types/common';

import { BackgroundAT } from 'redux/actionTypes/common';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';

const InitPage: React.FC<any> = ({ children }) => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const currentRoute = hooks.useCurrentRoute();

  // Get rough location on load (from storage or api)
  useEffect(() => {
    const location = localStorage.getItem('location');
    const locationJson = location && JSON.parse(location);
    if (locationJson) {
      dispatch({
        type: BackgroundAT.SET_LOCATION,
        payload: locationJson,
      });
    } else {
      try {
        fetch('https://ipapi.co/json/')
          .then((response) => {
            response.json().then((jsonData) => {
              const payload: LocationState = camelizeKeys(jsonData);
              dispatch({
                type: BackgroundAT.SET_LOCATION,
                payload: payload,
              });
              if (payload?.countryCode) {
                localStorage.setItem('location', JSON.stringify(payload));
              }
            });
          })
          .catch((err) => {
            try {
              Sentry.captureException(err);
            } catch (e) {
              console.warn('Sentry not installed');
            }
          });
      } catch (err) {
        try {
          Sentry.captureException(err);
        } catch (e) {
          console.warn('Sentry not installed');
        }
      }
    }
  }, []);

  // Set document title, log analytics & scroll to top on route change
  useEffect(() => {
    let currentRouteName = currentRoute?.name;
    document.title = currentRouteName
      ? currentRouteName !== PLATFORM_DISPLAY_NAME
        ? `${currentRouteName} · ${PLATFORM_DISPLAY_NAME}`
        : `${PLATFORM_DISPLAY_NAME} · Create and Sell Online Courses for Free`
      : PLATFORM_DISPLAY_NAME;

    // TODO: Remove if "Enhanced event measurement" in GA works well
    // analytics.pageView(currentRouteName || 'Other', {
    //   page: pathname,
    //   location: window.location.href,
    //   page_referrer: document.referrer,
    // });
    analytics.track(
      `Viewed ${
        currentRouteName === PLATFORM_DISPLAY_NAME
          ? `${currentRouteName} Homepage`
          : currentRouteName || 'Page'
      }`,
      {
        path: pathname,
        referrer: document.referrer,
        search,
        url: window.location.href,
      }
    );
    if (currentRoute?.scrollTopDisabled) return;

    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default InitPage;
