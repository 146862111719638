import * as CourseCT from 'constants/courses';

import { Enrolment } from 'types/cms';

import { ProgressStatus } from '../../learner';

export type Category = {
  id: number;
  title: string;
  slug: string;
  hasParents: boolean;
  status: 'inactive' | 'coming_soon' | 'active';
};

export type CertificateLogPost = {
  id: number;
  student?: number;
  course?: string;
  enrolment?: number;
};

export type CertificateLog = {
  id: number;
  student: string | null;
  certificateUid: string;
  generatedCertificate: string;
  course: string;
  enrolment: Enrolment | null;
};

export type ClassType = CourseCT.ClassType;

export type AccessType = CourseCT.AccessType;

export type SocialType = CourseCT.SocialType;

export type CohortType = CourseCT.CohortType;

export interface CohortMentor {
  id: number;
  assessor: boolean;
  cohort: number;
  expiryDate: string | null;
  isSubstitute: boolean;
  name: string;
  subStartDate: string;
  username: string;
}

export enum CohortStatus {
  'draft' = 'draft',
  'live' = 'live',
}

export interface Cohort {
  id: number;
  accessType: AccessType;
  classType: ClassType;
  course: string;
  courseDetails: {
    id: number;
    accessDuration: number;
    description: string;
    duration: number;
    imageLandscape: string;
    imagePortrait: string;
    slug: string;
    title: string;
    organisation: {
      id: number;
      name: string;
      contactEmail: string | null;
    };
  };
  discourseCategoryId: number;
  discourseGroupChatTopicId: number;
  discourseGroupName: string;
  duration: number;
  accessDuration: number | null;
  isPrivate: boolean;
  isTest: boolean;
  isAnonymous: boolean;
  endDate: string;
  label: string;
  mentors: CohortMentor[];
  numEnrolments: number;
  numParticipants: number;
  onSale: boolean;
  participants: string[];
  privateChatDisabled: boolean;
  slug: string;
  socialType: SocialType;
  spacesAvailable: number;
  spacesTotal: number;
  startDate: string;
  status: CohortStatus;
  organisation: number;
  // DEPRECATED
  mentor: CohortMentor;
  certificateLogs: CertificateLog[];
}

export interface IModuleProgress {
  id: number;
  currentStep: number;
  module: string;
  status: ProgressStatus;
  stepsRemaining: number;
  stepsTotal: number;
}

export interface IUnitProgress {
  id: number;
  assessmentComplete: boolean;
  locked: boolean;
  moduleCount: number;
  moduleProgress: IModuleProgress[];
  modulesRemaining: number;
  status: ProgressStatus;
  unit: string;
}

export interface ICourseProgress {
  id: number;
  acceptedTerms: boolean;
  course: string;
  expiryDate: string;
  participantUsername: string;
  participantName: string;
  status: ProgressStatus;
  unitProgress: IUnitProgress[];
  user: number;
}
