import React from 'react';

import { Flex, Button } from '@workshop/ui';

// TODO: Consolidate this with EditCard/AddItem buttons - why do we have
// different ways of doing the same buttons?
const ItemButtons = (props: {
  onSave?: () => void;
  onCancel?: () => void;
  saveType?: 'button' | 'submit' | 'reset' | undefined;
  isLoading?: boolean;
  cancelLabel?: string;
  saveLabel?: string;
  isDisabled?: boolean;
}) => {
  const {
    onSave,
    onCancel,
    isLoading,
    saveType = 'submit',
    cancelLabel = 'Cancel',
    saveLabel = 'Save',
    isDisabled = false,
  } = props;
  return (
    <Flex flex={1} justifyContent="flex-end">
      {onCancel && (
        <Button secondary variant="solid" onClick={onCancel} marginRight={2}>
          {cancelLabel}
        </Button>
      )}
      <Button
        type={saveType}
        onClick={onSave}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        {saveLabel}
      </Button>
    </Flex>
  );
};

export default ItemButtons;
