const SessionListAT = {
  CMS_FETCH_SESSION_LIST_REQUEST: 'CMS_FETCH_SESSION_LIST_REQUEST',
  CMS_FETCH_SESSION_LIST_SUCCESS: 'CMS_FETCH_SESSION_LIST_SUCCESS',
  CMS_FETCH_SESSION_LIST_FAILURE: 'CMS_FETCH_SESSION_LIST_FAILURE',
  CMS_CREATE_SESSION_REQUEST: 'CMS_CREATE_SESSION_REQUEST',
  CMS_CREATE_SESSION_SUCCESS: 'CMS_CREATE_SESSION_SUCCESS',
  CMS_CREATE_SESSION_FAILURE: 'CMS_CREATE_SESSION_FAILURE',
} as const;

const SessionAT = {
  CMS_FETCH_SESSION_REQUEST: 'CMS_FETCH_SESSION_REQUEST',
  CMS_FETCH_SESSION_SUCCESS: 'CMS_FETCH_SESSION_SUCCESS',
  CMS_FETCH_SESSION_FAILURE: 'CMS_FETCH_SESSION_FAILURE',

  CMS_PATCH_SESSION_REQUEST: 'CMS_PATCH_SESSION_REQUEST',
  CMS_PATCH_SESSION_SUCCESS: 'CMS_PATCH_SESSION_SUCCESS',
  CMS_PATCH_SESSION_FAILURE: 'CMS_PATCH_SESSION_FAILURE',

  CMS_DELETE_SESSION_REQUEST: 'CMS_DELETE_SESSION_REQUEST',
  CMS_DELETE_SESSION_SUCCESS: 'CMS_DELETE_SESSION_SUCCESS',
  CMS_DELETE_SESSION_FAILURE: 'CMS_DELETE_SESSION_FAILURE',
} as const;

export default {
  ...SessionListAT,
  ...SessionAT,
};
