export const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name +
    '=' +
    (value || '') +
    expires +
    '; path=/; domain=' +
    window.location.hostname.substring(window.location.hostname.indexOf('.'));
};

const getCookie = (cname: string) => {
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  var cookieValue = '';
  ca.forEach((cookie: string) => {
    if (cookie.split('=')[0].trim() === cname) {
      cookieValue = cookie.split('=')[1];
    }
  });
  return cookieValue;
};

export default getCookie;
