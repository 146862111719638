import moment from 'moment-timezone';

import {
  unitThresholds,
  timePeriodOptions,
  TimePeriod,
  AnalyticsUnit,
} from 'constants/analytics';

const calcUnixDiff = (
  startUnix: number,
  endUnix: number
): {
  [key in AnalyticsUnit]: number;
} => {
  const start = moment.unix(startUnix);
  const end = moment.unix(endUnix);

  return {
    day: end.diff(start, 'days'),
    week: end.diff(start, 'weeks'),
    month: end.diff(start, 'months'),
    year: end.diff(start, 'years'),
  };
};

/**
 * Handler for determining which time period units should be displayed on the
 * performance dashboard.
 */
export const calcTimePeriodUnitOptions = (
  timePeriod: { start: number; end: number },
  selectedOption: TimePeriod | null
): {
  unitOptions: TimePeriod[];
  defaultOption: TimePeriod;
} => {
  // Calculate the difference between the provided start and end dates. This
  // can then be used to determine which time period units are valid for this
  // selection, along with used to adjust the default selected unit if applicable.
  const diff = calcUnixDiff(timePeriod.start, timePeriod.end);

  // Filter through our defined thresholds, only keeping units which fit within
  // the bounds of our thresholds. Map valid units against the time period options
  // which can be passed straight to any `Select` component.
  const unitOptions = Object.keys(unitThresholds)
    .filter(
      // @ts-ignore
      (unit: AnalyticsUnit) => {
        const { lower, upper } = unitThresholds[unit];
        let valid = true;

        // If an upper limit has been defined, check that the diff is less than
        // the upper boundary defined
        if (upper) {
          valid = valid && diff[unit] <= upper;
        }

        // If a lower limit has been defined, check that the diff is greater than
        // the lower boundary defined
        if (lower) {
          valid = valid && diff[unit] > lower;
        }

        // Return to filter out units which aren't valid
        return valid;
      }
    )
    // @ts-ignore
    .map((unit: AnalyticsUnit) => {
      // Return the time period unit to build our `unitOptions`
      return timePeriodOptions[unit];
    });

  // Determine the default unit which should be selected, taking into account
  // the user's current selection:
  //
  // - Less than 3 weeks: default day
  // - Less than 3 months: default week
  // - Less than 1 year: default month
  // - Greater than 1 year: default month
  let defaultOption = null;

  // If the currently selected unit option exists in our generated array
  // of unit options, then we should keep this as the selected option
  // rather than overriding it with a default value.
  const selectedOptionIsValid = selectedOption
    ? unitOptions
        .map((option: TimePeriod) => option.value)
        .includes(selectedOption.value)
    : false;

  // if (diff.week <= 3) {
  if (diff.month <= 1) {
    defaultOption = timePeriodOptions.day;
  } else if (diff.month <= 3) {
    // defaultOption = timePeriodOptions.week;
    defaultOption = timePeriodOptions.day;
  } else if (diff.year <= 1) {
    defaultOption = timePeriodOptions.month;
  } else {
    defaultOption = timePeriodOptions.year;
  }

  // `selectedOption` included in the 'if' to keep TS happy. All we
  // are really doing is setting the returned default option to the
  // already set selected option as we've determined above that the
  // selected option is valid.
  if (selectedOption && selectedOptionIsValid) {
    defaultOption = selectedOption;
  }

  return { unitOptions, defaultOption };
};
