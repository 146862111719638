import { callAPI } from 'utils';
import { decamelize } from 'humps';
import API from 'constants/api';

import { OrganisationAT } from 'redux/actionTypes/common';

import { OrganisationsSchema } from 'redux/schemas';

import { ListOrganisationsAction } from 'types/admin';

export const list = () => {
  return callAPI<ListOrganisationsAction>({
    types: [
      OrganisationAT.LIST_ORGANISATIONS_REQUEST,
      OrganisationAT.LIST_ORGANISATIONS_SUCCESS,
      OrganisationAT.LIST_ORGANISATIONS_FAILURE,
    ],
    endpoint: API.organisation.list,
    method: 'GET',
    schema: [OrganisationsSchema],
    path: 'results',
  });
};
