// CMS schemas
import { schema } from 'normalizr';

export const CMSVideoClipListSchema = new schema.Entity('videoClips');
export const CMSVideoClipSchema = new schema.Entity('videoClip');
export const CMSStepListSchema = new schema.Entity('steps');
export const CMSStepSchema = new schema.Entity('step');
export const CMSSessionListSchema = new schema.Entity('sessions');
export const CMSSessionDetailSchema = new schema.Entity('session', {
  introOutroClips: [CMSVideoClipListSchema],
});
export const CMSUnitListSchema = new schema.Entity('units', {
  modules: [CMSSessionListSchema],
});
export const CMSUnitSchema = new schema.Entity('unit', {
  modules: [CMSSessionListSchema],
});
export const CMSCourseListSchema = new schema.Entity('courses');
export const CMSCourseSchema = new schema.Entity('course');

export const CMSMCQSchema = new schema.Entity('questions', {});
export const CMSCohortSchema = new schema.Entity('cohorts');
export const CMSCostRecordListSchema = new schema.Entity('costRecords');
export const CMSLicenseListSchema = new schema.Entity('licenses');
export const CMSLicenseSchema = new schema.Entity('license');
export const CMSEnrolmentListSchema = new schema.Entity('enrolments');
export const CMSEnrolmentSchema = new schema.Entity('enrolment');

export const JournalEntryEntity = new schema.Entity(
  'journalEntries',
  {},
  {
    processStrategy: (val, { courseId: course, cohort }) => ({
      ...val,
      course,
      cohort,
    }),
  }
);
export const AssessmentsEntity = new schema.Entity(
  'assessments',
  {},
  {
    processStrategy: (val, { courseId: course, cohort }) => ({
      ...val,
      course,
      cohort,
    }),
  }
);

export const UnitSummaryEntity = new schema.Entity('unitSummary');

export const SessionSummaryEntity = new schema.Entity('sessionSummary');

export const CMSAssessmentsSchema = new schema.Object({
  journalEntries: [JournalEntryEntity],
  assessments: [AssessmentsEntity],
  units: [UnitSummaryEntity],
  modules: [SessionSummaryEntity],
});

export const DigestSchema = new schema.Entity(
  'digests',
  {},
  { idAttribute: 'slug' }
);
