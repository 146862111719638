import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Select from 'react-select';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import { UNIT_TYPE, SESSION_TYPE } from 'constants/courses';

import { hooks, getParamFromUrl } from 'utils';
import { getManagedCohorts } from 'redux/selectors/course';

import { courseActions as learnerCourseActions } from 'redux/actions/learner';
import { courseActions } from 'redux/actions/cms';
import { roomActions, profileActions } from 'redux/actions/common';

import { Card, Button, Stack, Divider, useTheme } from '@workshop/ui';
import { LabelWrapper } from 'components/Common';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { GlobalState } from 'types';
import { CohortStatus } from 'types/common';

// Routing Props
interface MatchParams {
  courseId: string;
  unitId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

type SelectOption =
  | {
      value: string;
      label: string;
    }
  | null
  | undefined;

const NewRoom: React.FC<Props> = ({
  cohorts,
  courses,
  unitState,
  moduleState,
  history,
  location,
}) => {
  const moduleParam = getParamFromUrl(location, 'session');

  const dispatch = useDispatch();
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [roomLoading, setRoomLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<SelectOption>(null);
  const [selectedModule, setSelectedModule] = useState<SelectOption>(null);
  const [selectedCohort, setSelectedCohort] = useState<SelectOption>(null);

  const theme = useTheme();

  const { cohorts: cohortsLoading } = hooks.useLoadingDataState(
    {
      cohorts: {
        actions: [() => courseActions.fetchMyCohorts({ fetchNextPage: true })],
      },
    },
    []
  );

  useEffect(() => {
    if (moduleParam && !coursesLoading) {
      const module = Object.values(moduleState).find(
        (m) => m.id === parseInt(moduleParam)
      );
      const unit = module && unitState[module.unit];
      const course =
        unit && courses.find((course) => course.slug === unit.course);
      if (course && module) {
        setSelectedCourse({ value: course.slug, label: course.title });
        setSelectedModule({ value: `${module.id}`, label: module.title });
      }
    }
  }, [moduleParam, coursesLoading]);

  useEffect(() => {
    if (!cohortsLoading && cohorts.length > 0) {
      const loadCourses = async (coursesArr: Promise<unknown>[]) => {
        setCoursesLoading(true);
        await Promise.all(coursesArr);
        setCoursesLoading(false);
      };
      let coursesToFetch: string[] = [];
      cohorts.forEach((c) => {
        coursesToFetch = [...new Set([...coursesToFetch, c.course])];
      });
      loadCourses(
        coursesToFetch.map((courseSlug) =>
          dispatch(learnerCourseActions.retrieve(courseSlug))
        )
      );
    }
  }, [cohortsLoading, cohorts.length]);

  const moduleOptions = selectedCourse
    ? courses
        .find((course) => course.slug === selectedCourse.value)
        ?.units.filter((unitSlug) => {
          const unit = unitState[unitSlug];
          return (
            unit.unitType !== UNIT_TYPE.intro &&
            unit.unitType !== UNIT_TYPE.outro
          );
        })
        .map((unitSlug) => {
          const unit = unitState[unitSlug];
          return {
            value: `${unit.id}`,
            label: unit.title,
            options: unit.modules
              .filter((moduleSlug) => {
                const module = moduleState[moduleSlug];
                return module.moduleType === SESSION_TYPE.normal;
              })
              .map((moduleSlug) => {
                const module = moduleState[moduleSlug];
                return {
                  value: `${module.id}`,
                  label: module.title,
                };
              }),
          };
        })
    : undefined;

  const cohortOptions = cohorts
    .filter((c) => c.course === selectedCourse?.value)
    .map((c) => ({
      value: `${c.id}`,
      label: `${moment(c.startDate).format('Do MMMM YYYY')}${
        c.label ? ` - ${c.label}` : ''
      }`,
    }));

  const selectStyles = {
    control: (base: React.CSSProperties) => ({
      ...base,
      cursor: 'pointer',
      backgroundColor: theme.colors.background.default,
      borderColor: theme.colors.border.muted,
      borderRadius: theme.radii.md,
    }),
    placeholder: (base: React.CSSProperties) => ({
      color: theme.colors.text.primary,
    }),
    menu: (base: React.CSSProperties) => ({
      ...base,
      backgroundColor: theme.colors.background.default,
    }),
    option: (base: React.CSSProperties) => ({
      ...base,
      cursor: 'pointer',
    }),
  };

  return (
    <ScreenWrapper>
      {/* <Text marginX={{ base: 'defaultMargin', md: 0 }} mb={4}></Text> */}
      <Card padding={4} overflow="none">
        <Stack flex={1} spacing={4} position="relative">
          <LabelWrapper label="Course">
            <Select
              value={selectedCourse}
              onChange={(course) => setSelectedCourse(course)}
              placeholder="Select course..."
              options={courses.map((course) => ({
                value: course.slug,
                label: course.title,
              }))}
              styles={selectStyles}
            />
          </LabelWrapper>
          <LabelWrapper label="Session">
            <Select
              value={selectedModule}
              onChange={(module) => setSelectedModule(module)}
              isDisabled={!selectedCourse}
              placeholder="Select session..."
              options={moduleOptions}
              styles={selectStyles}
            />
          </LabelWrapper>
          <LabelWrapper label="Class">
            <Select
              value={selectedCohort}
              onChange={(cohort) => setSelectedCohort(cohort)}
              isDisabled={!selectedModule}
              placeholder="Select class..."
              options={cohortOptions}
              styles={selectStyles}
            />
          </LabelWrapper>
          <Divider />
          <Button
            isDisabled={!selectedModule || !selectedCohort}
            icon="TapAndPlay"
            isLoading={roomLoading || coursesLoading || cohortsLoading}
            onClick={async () => {
              setRoomLoading(true);
              if (selectedModule && selectedCohort) {
                const res = await dispatch(
                  roomActions.createRoom({
                    session: parseInt(selectedModule.value),
                    cohort: parseInt(selectedCohort.value),
                  })
                );
                if (!res?.error) {
                  dispatch(profileActions.fetchUserProfile());
                  // @ts-ignore
                  const roomId = res?.payload?.result;
                  history.push(navRoutes.global.room.path(roomId));
                }
              }
              setRoomLoading(false);
            }}
          >
            Launch Room
          </Button>
        </Stack>
      </Card>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => {
  const myCohorts = getManagedCohorts(state);
  // Cohorts where 'isAnonymous === true' are available for live rooms.
  // The cohort's number of enrolments determines how many people are
  // allowed in a room, so this number must be over 0 for room to be launched.
  // The cohort must also be live and not expired.
  const liveRoomCohorts = myCohorts.filter(
    (c) =>
      c.isAnonymous &&
      c.numEnrolments > 0 &&
      c.status === CohortStatus.live &&
      moment(c.endDate).isSameOrAfter()
  );
  const cohortCourses = liveRoomCohorts.map((c) => c.course);

  const allCourses = state.learner.courses.courses.detail;
  const filteredCourses = Object.values(allCourses).filter((course) =>
    cohortCourses.includes(course.slug)
  );
  return {
    cohorts: liveRoomCohorts,
    courses: filteredCourses,
    unitState: state.learner.courses.units,
    moduleState: state.learner.courses.modules,
  };
};

const connector = connect(mapStateToProps);

export default connector(NewRoom);
