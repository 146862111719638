import { decamelizeKeys } from 'humps';

import API from 'constants/api';
import { ZendeskAT } from 'redux/actionTypes/common';
import { callAPI } from 'utils';

import { ZendeskRequestBody, ZendeskAction } from 'types/common';

export const createRequest = (data: ZendeskRequestBody) =>
  callAPI<ZendeskAction>({
    types: [
      ZendeskAT.ZENDESK_REQUEST_CREATE_REQUEST,
      ZendeskAT.ZENDESK_REQUEST_CREATE_SUCCESS,
      ZendeskAT.ZENDESK_REQUEST_CREATE_FAILURE,
    ],
    endpoint: API.common.zendeskRequest,
    method: 'POST',
    body: decamelizeKeys({
      ...data,
      customFields: data.customFields || [],
    }),
    meta: {
      toast: false,
    },
  });
