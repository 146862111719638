import { callAPI } from 'utils';
import API from 'constants/api';
import { decamelizeKeys } from 'humps';

import { ProfileAT } from 'redux/actionTypes/learner';
import { UserLibrarySchema, UserCheckListStateSchema } from 'redux/schemas';

import { FetchUserLibraryAction } from 'types/learner';

export const userLibraryActions = {
  retrieve: () =>
    callAPI<FetchUserLibraryAction>({
      types: [
        ProfileAT.FETCH_USER_LIBRARY_REQUEST,
        ProfileAT.FETCH_USER_LIBRARY_SUCCESS,
        ProfileAT.FETCH_USER_LIBRARY_FAILURE,
      ],
      endpoint: API.account.userLibrary,
      method: 'GET',
      schema: UserLibrarySchema,
    }),
};

interface UserCheckListData {
  id: string;
  checkList: string;
  state: string;
}

export const userCheckListActions = {
  update: (data: UserCheckListData) =>
    callAPI<FetchUserLibraryAction>({
      types: [
        ProfileAT.UPDATE_USER_CHECKLIST_REQUEST,
        ProfileAT.UPDATE_USER_CHECKLIST_SUCCESS,
        ProfileAT.UPDATE_USER_CHECKLIST_FAILURE,
      ],
      body: decamelizeKeys(data),
      endpoint: API.learner.userCheckList,
      method: 'POST',
      schema: UserCheckListStateSchema,
    }),
};
