import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import CountUp from 'react-countup';
import { saveAs } from 'file-saver';

import { GlobalState } from 'types';
import { Unit, UnitProgress } from 'types/learner';

import navRoutes from 'navigation/Routes';

import {
  courseActions,
  courseProgressActions,
  courseScheduleActions,
  journalActions,
} from 'redux/actions/learner';
import {
  getSlugs,
  getModules,
  getUnits,
  getUnitProgress,
  getModulesProgress,
} from 'redux/selectors/course';

import { hooks } from 'utils';
import { getCourseCompletionProgress } from 'utils/learner';

import {
  Box,
  Card,
  Flex,
  Button,
  Text,
  CircularProgress,
  Progress,
  MdIcon,
} from '@workshop/ui';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { CourseMenu } from 'components/SideMenu';
import { Loading } from 'components/Loading';

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const CriteriaCard = ({
  progress,
  title,
  description,
  completeDescription,
  uploadSymbol,
}: {
  progress: number;
  title: string;
  description: string;
  completeDescription: string;
  uploadSymbol?: boolean;
}) => {
  const isComplete = progress === 1;
  // TODO: More info:
  // Expand card to see list of available sessions / units + number of locked, e.g.
  // -- Unit 3, Session 2: XXX
  // -- Unit 4, Session 4: XXX
  // [Lock symbol] 3 sessions locked

  // For uploads:
  // [Lock symbol] 3 sessions incomplete (i.e. don't link to incomplete sessions)

  return (
    <Card
      mb="defaultMargin"
      backgroundColor={isComplete ? 'background.success' : 'background.default'}
    >
      <Flex
        boxSize={16}
        alignItems="center"
        justifyContent="center"
        paddingRight={2}
      >
        {isComplete ? (
          <MdIcon boxSize="icon" name="CheckCircle" color="common.progress" />
        ) : (
          <CircularProgress
            value={progress * 100}
            size={8}
            thickness="16px"
            color="common.progress"
            trackColor="background.tint2"
            capIsRound
            sx={{
              '& > div:first-child': {
                transitionProperty: 'width',
              },
            }}
          />
        )}
      </Flex>

      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'initial', lg: 'center' }}
        justifyContent={{ base: 'center', lg: 'initial' }}
        style={{ flex: 1 }}
      >
        <Flex flex={{ base: 'initial', lg: 1 }} alignItems="center">
          <Text
            fontWeight="semibold"
            color={isComplete ? 'text.success' : 'text.default'}
          >
            {title}
          </Text>
          {uploadSymbol && (
            <MdIcon
              name="PostAdd"
              boxSize="icon"
              color={isComplete ? 'icon.success' : 'icon.warning'}
              ml={2}
            />
          )}
        </Flex>
        <Text color={isComplete ? 'text.success' : 'text.muted'} pr={4}>
          {isComplete ? completeDescription : description}
        </Text>
      </Flex>
    </Card>
  );
};

const CourseCertificate: React.FC<Props> = ({
  courseSlug,
  course,
  courseProgress,
  location,
  modules,
  moduleProgress,
  units,
  unitProgress,
  journalEntries,
  userName,
  history,
}) => {
  const { courseLoading, journalLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: [
          () => courseActions.retrieve(courseSlug, location.pathname, false),
        ],
      },
      journalLoading: { actions: [journalActions.retrieveJournalEntries] },
    },
    [courseSlug]
  );

  const { dataLoading } = hooks.useLoadingDataState(
    {
      dataLoading: {
        startLoading: !Boolean(courseLoading),
        actions: [
          () => courseProgressActions.retrieve(courseSlug),
          () => courseScheduleActions.retrieve(courseSlug),
        ],
      },
    },
    [courseSlug, courseLoading]
  );

  if (!courseProgress && !dataLoading && !courseLoading) {
    history.push(navRoutes.common.home.path());
  }

  if (
    courseLoading ||
    journalLoading ||
    dataLoading ||
    !modules ||
    !units ||
    !moduleProgress ||
    !unitProgress
  )
    return (
      <ScreenWrapper>
        <Loading />
      </ScreenWrapper>
    );

  const {
    sessionsProgress,
    uploadsProgress,
    assessmentsProgress,
    hasSessions,
    hasSessionsRequiringUploads,
    hasAssessments,
    numIncompleteSessions,
    numSessionsMissingUploads,
    numIncompleteAssessmentUnits,
    totalProgress,
  } = getCourseCompletionProgress({
    courseModules: modules,
    courseUnits: units,
    moduleProgress,
    unitProgress,
    journalEntries,
  });

  // (Mostly copied from native app)

  const certificate = courseProgress?.certificateLog;

  const certificateUnlocked = !!certificate;

  // Something must've gone wrong in the certificate generation logic for this case to occur:
  const certificatePending = totalProgress === 100 && !certificateUnlocked;

  const borderStyle = {
    borderColor: certificateUnlocked ? 'common.primary' : 'border.default',
  };
  const fillStyle = {
    backgroundColor: certificateUnlocked ? 'common.primary' : 'border.default',
  };
  const dotStyle = {
    height: 1,
    width: 1,
    borderRadius: 2,
  };
  const lineStyle = {
    flex: 1,
    height: 0.5,
    marginY: 0.25,
    marginX: 4,
  };

  if (course?.courseType === 'mini') {
    history.push(navRoutes.learner.course.path(courseSlug));
    return null;
  }

  return (
    <ScreenWrapper>
      <CourseMenu courseSlug={courseSlug} />
      <Box position="relative" maxWidth="750px">
        <Card position="relative" height={0} padding={0} paddingBottom="56.25%">
          <Flex
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            alignItems="center"
            justifyContent="center"
            padding={4}
          >
            <Flex
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              padding={4}
              backgroundColor={
                certificateUnlocked
                  ? 'background.primary'
                  : 'background.default'
              }
              flexDirection="column"
            >
              <Flex flex={1} {...borderStyle}>
                <Box
                  flex={1}
                  borderTopWidth={2}
                  borderLeftWidth={2}
                  borderTopLeftRadius="md"
                  margin={0.25}
                  marginRight={4}
                  {...borderStyle}
                />
                <Flex flex={3} {...borderStyle}>
                  <Box {...dotStyle} {...fillStyle} />
                  <Box {...lineStyle} {...fillStyle} />
                  <Box {...dotStyle} {...fillStyle} />
                </Flex>
                <Box
                  flex={1}
                  borderTopWidth={2}
                  borderRightWidth={2}
                  borderTopRightRadius="md"
                  margin={0.25}
                  marginLeft={4}
                  {...borderStyle}
                />
              </Flex>
              <Flex my={4} {...borderStyle}>
                <Box {...dotStyle} {...fillStyle} />
                <Box style={{ flex: 1 }} />
                <Box {...dotStyle} {...fillStyle} />
              </Flex>
              <Flex flex={1} {...borderStyle}>
                <Box
                  flex={1}
                  borderBottomWidth={2}
                  borderLeftWidth={2}
                  borderBottomLeftRadius="md"
                  margin={0.25}
                  marginRight={4}
                  {...borderStyle}
                />
                <Flex flex={3} alignItems="flex-end" {...borderStyle}>
                  <Box {...dotStyle} {...fillStyle} />
                  <Box {...lineStyle} {...fillStyle} />
                  <Box {...dotStyle} {...fillStyle} />
                </Flex>
                <Box
                  flex={1}
                  borderBottomWidth={2}
                  borderRightWidth={2}
                  borderBottomRightRadius="md"
                  margin={0.25}
                  marginLeft={4}
                  {...borderStyle}
                />
              </Flex>
            </Flex>
            <Flex
              flex={1}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              {certificate && certificateUnlocked ? (
                <>
                  <Text
                    fontWeight="bold"
                    color="text.primary"
                    paddingBottom={2}
                  >
                    {`Congratulations, ${userName.split(' ')[0]}!  🎉`}
                  </Text>
                  <Text color="text.primary" paddingBottom={4}>
                    Your certificate is now available:
                  </Text>
                  <Button
                    onClick={() => {
                      const filename = `${userName} - Certificate for ${course.title}`;
                      saveAs(certificate, filename);
                    }}
                  >
                    Download Certificate
                  </Button>
                </>
              ) : (
                <>
                  <>
                    {certificatePending ? (
                      <>
                        <Text fontWeight="bold" paddingBottom={2}>
                          {'Certificate Pending  📃'}
                        </Text>
                        <Text paddingBottom={4} textAlign="center" px={8}>
                          Please contact your mentor if your certificate is not
                          available after 24 hours
                        </Text>
                      </>
                    ) : (
                      <Box width="100%" px={6} maxWidth="400px">
                        <Text
                          textAlign="center"
                          color="text.muted"
                          fontWeight="semibold"
                        >
                          <CountUp
                            start={0}
                            end={totalProgress}
                            prefix="Course "
                            suffix="% complete"
                            duration={totalProgress / 100}
                          />
                        </Text>
                        <Progress
                          value={totalProgress}
                          hasStripe
                          isAnimated
                          borderRadius="full"
                          colorScheme="green"
                          mt={2}
                          sx={{
                            '& > div:first-child': {
                              transitionProperty: 'width',
                            },
                          }}
                        />
                      </Box>
                    )}
                  </>
                </>
              )}
            </Flex>
          </Flex>
        </Card>
      </Box>
      {certificateUnlocked ? null : (
        <>
          <Text
            fontWeight="semibold"
            pt={10}
            pb={4}
            marginX={{ base: 'defaultMargin', md: 0 }}
          >
            How to earn your certificate of completion:
          </Text>

          {hasSessions ? (
            <CriteriaCard
              progress={sessionsProgress}
              title="Complete every session"
              description={`${numIncompleteSessions} session${
                numIncompleteSessions === 1 ? '' : 's'
              } remaining`}
              completeDescription="All sessions complete"
            />
          ) : null}

          {hasSessionsRequiringUploads ? (
            <CriteriaCard
              progress={uploadsProgress}
              title="Upload a post on every session marked "
              description={`${numSessionsMissingUploads} upload${
                numSessionsMissingUploads === 1 ? '' : 's'
              } remaining`}
              completeDescription="All posts uploaded"
              uploadSymbol
            />
          ) : null}

          {hasAssessments ? (
            <CriteriaCard
              progress={assessmentsProgress}
              title="Pass all assessments"
              description={`${numIncompleteAssessmentUnits} assessment${
                numIncompleteAssessmentUnits === 1 ? '' : 's'
              } remaining`}
              completeDescription="All assessments passed"
            />
          ) : null}
        </>
      )}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    courses: { courses: courseState, units: unitState, modules: moduleState },
    courseProgress: {
      courses: courseProgressState,
      units: unitProgressState,
      modules: moduleProgressState,
    },
    journal: { journalEntries },
  } = state.learner;

  const {
    user: {
      userDetails: { name: userName },
    },
  } = state;

  // Course Data
  const course = courseState.detail[courseSlug];
  const units = course ? getUnits(unitState, course.units) : null;

  type U = Unit;
  type M = 'modules';
  const moduleSlugs = getSlugs<Pick<U, M>, M>(units, 'modules');

  const modules = moduleSlugs ? getModules(moduleState, moduleSlugs) : null;

  // Course Progress Data
  const courseProgress = courseProgressState[courseSlug];
  const unitProgress = courseProgress
    ? getUnitProgress(unitProgressState, courseProgress.unitProgress)
    : null;

  type UP = UnitProgress;
  type MP = 'moduleProgress';
  const moduleProgressSlugs = getSlugs<Pick<UP, MP>, MP>(
    unitProgress,
    'moduleProgress'
  );

  const moduleProgress = moduleProgressSlugs
    ? getModulesProgress(moduleProgressState, moduleProgressSlugs)
    : null;

  return {
    courseSlug,
    course,
    courseProgress,
    units,
    unitProgress,
    modules,
    moduleProgress,
    journalEntries,
    userName,
  };
};

const connector = connect(mapStateToProps);

export default connector(CourseCertificate);
