import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { GlobalState } from 'types';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import { PLATFORM } from 'constants/env';
import { WORKSHOP_ORGS } from 'constants/organisation';

import { RoleSlug, PermissionSlug } from 'types/common';

const selectRoles = (state: GlobalState) => state.organisation.roles;

const selectPermissions = (state: GlobalState) =>
  state.organisation.permissions;

export const selectMyTeams = (state: GlobalState) =>
  state.organisation.myTeams.filter((t) =>
    PLATFORM === 'steppit' ? !WORKSHOP_ORGS.includes(t.team) : true
  );

const selectTeamProfiles = (state: GlobalState) =>
  state.organisation.teamProfiles;

export const selectCurrentTeam = (state: GlobalState) =>
  state.organisation.currentTeam;

export const selectCurrentTeamProfile = (state: GlobalState) =>
  state.organisation.currentTeam
    ? state.organisation.teamProfiles[state.organisation.currentTeam]
    : null;

const selectUserRoles = createSelector(
  [selectMyTeams, selectCurrentTeam],
  (myTeams, currentTeam) => {
    return currentTeam
      ? myTeams.find(({ team }) => team === currentTeam)?.roles || []
      : uniq(flatten(myTeams.map((item) => item.roles)));
  }
);

const selectUserPermissions = createSelector(
  [selectMyTeams, selectCurrentTeam],
  (myTeams, currentTeam) => {
    return currentTeam
      ? myTeams.find(({ team }) => team === currentTeam)?.permissions || []
      : uniq(flatten(myTeams.map((item) => item.permissions)));
  }
);

// TODO: Add caching
export const useCurrentRole = () => {
  /** Retrieve all roles from the organisation */
  const roles = useRoles();

  /* Retrieve the user roles ids */
  const userRoles = useSelector(selectUserRoles);

  // Return the role names the user possess for a team or the default team
  return userRoles
    .filter((id) => roles[id.toString()])
    .map((id) => roles[id.toString()]);
};

export const useCurrentTeamProfile = () => {
  const currentTeam = useCurrentTeam();
  const teamProfiles = useTeamProfiles();
  return currentTeam ? teamProfiles[currentTeam] : null;
};

// TODO: Add caching
export const usePermissions = () => {
  /** Retrieve all permissions from the organisation */
  const permissions = useSelector(selectPermissions);

  /* Retrieve the user permission ids */
  const userPermissions = useSelector(selectUserPermissions);

  return uniq(userPermissions)
    .filter((id) => permissions[id.toString()])
    .map((id) => permissions[id.toString()].slug);
};

// TODO: Add caching
export const useHasRole = (roleSlug: string) => {
  const role = useSelector((state: GlobalState) =>
    Object.values(state.organisation.roles).find(
      (role) => role.slug === roleSlug
    )
  );
  const userRoles = useSelector(selectUserRoles);

  return role ? userRoles.includes(role.id) : false;
};

// TODO: Add caching
export const useHasPermission = (permName: PermissionSlug) => {
  const permissions = usePermissions();

  return permissions.includes(permName);
};

// TODO: Add caching
export const useHasAnyPermission = (permNames: PermissionSlug[]) => {
  const permissions = usePermissions();

  /**
   * - return true if the given list of permNames is empty
   * - return true if any of the given permNames matches any of the users permissions
   */
  return (
    !permNames.length || Boolean(permNames.find((p) => permissions.includes(p)))
  );
};

export const useRoles = () => useSelector(selectRoles);

export const useTeams = () => useSelector(selectMyTeams);

export const useTeamProfiles = () => useSelector(selectTeamProfiles);

export const useCurrentTeam = () => useSelector(selectCurrentTeam);
