import { createStore, applyMiddleware, compose } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import { GlobalState, Action } from 'types';

import sagas from 'redux/sagas';
import { timerMiddleware } from 'redux/middleware/timer';
import rootReducer from 'redux/reducers';
import { ENV } from 'constants/env';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  thunk as ThunkMiddleware<GlobalState, Action>,
  apiMiddleware,
  timerMiddleware,
  sagaMiddleware,
];
let enhancers = compose(applyMiddleware(...middleware), sentryReduxEnhancer);

// @ts-ignore
if (ENV !== 'prod') {
  const composeEnhancers: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancers = composeEnhancers(
    applyMiddleware(...middleware),
    sentryReduxEnhancer
  );
}

const store = createStore(rootReducer, enhancers);

sagaMiddleware.run(sagas);

export type AppDispatch = typeof store.dispatch;

// Try the below if we're having issues with redux-thunk typescript
// - https://github.com/reduxjs/redux-thunk/issues/103
//
// export default createStore<
//   GlobalState,
//   Action,
//   {
//     dispatch: unknown;
//   },
//   {}
// >(rootReducer, enhancers);

export default store;
