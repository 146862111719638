import { callAPI } from 'utils';
import {
  ChunkUploadEvent,
  StartChunkUpload,
  CreateChunkUpload,
  UpdateChunkUpload,
  CancelChunkUpload,
  ClearChunkUpload,
  CompleteUploadChunkAction,
} from 'types/common';

import { BackgroundAT } from 'redux/actionTypes/common';
import API from 'constants/api';

export const startChunkUpload = (
  payload: Partial<ChunkUploadEvent>
): StartChunkUpload => ({
  type: BackgroundAT.START_CHUNK_UPLOAD,
  payload,
});

export const createChunkUpload = (
  payload: ChunkUploadEvent
): CreateChunkUpload => ({
  type: BackgroundAT.CREATE_CHUNK_UPLOAD,
  payload,
});

export const updateChunkUpload = (
  payload: ChunkUploadEvent
): UpdateChunkUpload => ({
  type: BackgroundAT.UPDATE_CHUNK_UPLOAD,
  payload,
});

export const cancelChunkUpload = (id: string): CancelChunkUpload => ({
  type: BackgroundAT.CANCEL_CHUNK_UPLOAD,
  payload: id,
});

export const clearChunkUploads = (): ClearChunkUpload => ({
  type: BackgroundAT.CLEAR_CHUNK_UPLOADS,
});

export const completeUpload = (uploadId: string, data: { md5: string }) => {
  return callAPI<CompleteUploadChunkAction>({
    endpoint: API.cms.chunkUpload(uploadId),
    method: 'POST',
    body: data,
    types: [
      BackgroundAT.COMPLETE_UPLOAD_CHUNK_REQUEST,
      BackgroundAT.COMPLETE_UPLOAD_CHUNK_SUCCESS,
      BackgroundAT.COMPLETE_UPLOAD_CHUNK_FAILURE,
    ],
    meta: { toast: { success: false } },
  });
};
