import get from 'lodash/get';
import find from 'lodash/find';

import { GlobalState } from 'types';

const getFromPath = (
  state: GlobalState,
  path: string,
  defaultValue: string
) => {
  if (path.includes('(')) {
    // e.g. path = 'learner.courses.courses.detail.(id === 5).title'
    const pathParts = path.split('.(');
    // e.g. base = object at path learner.courses.courses.detail
    const base = get(state, pathParts[0]);
    const endOfPathParts = pathParts[1].split(').');
    // e.g. check = 'id === 5'
    const check = endOfPathParts[0];
    const keyValue = check.split(' === ');
    // e.g. obj = path at learner.courses.courses.detail.(id === 5)
    const key = keyValue[0];
    const value = !isNaN(parseInt(keyValue[1]))
      ? parseInt(keyValue[1])
      : keyValue[1];
    const obj = find(Object.values(base), [key, value]);
    let result = obj;
    if (endOfPathParts[1]) {
      // e.g. result = path at learner.courses.courses.detail.(id === 5).title
      result = get(obj, endOfPathParts[1]);
    }
    return result;
  }
  return get(state, path, defaultValue);
};

export default {
  getFromPath,
};
