import * as React from 'react';
import { Box, Flex, Heading, Skeleton, HeadingProps } from '@workshop/ui';

import { IconTooltip, Tooltip } from 'components/IconTooltip';

interface SectionTitleProps extends HeadingProps {
  title: string;
  loading?: boolean;
  tooltipInfo?: Tooltip;
  [x: string]: any;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  loading = false,
  mb = 3,
  size = 'md',
  tooltipInfo,
  ...otherProps
}: SectionTitleProps) => {
  return (
    <Flex
      flexShrink={0}
      marginX={{ base: 'defaultMargin', md: 0 }}
      mb={mb}
      {...otherProps}
    >
      <Skeleton isLoaded={!loading} display="flex" alignItems="center">
        <Heading fontSize={size} fontWeight="bold">
          {title}
        </Heading>
        {tooltipInfo && (
          <Box ml={1}>
            <IconTooltip tooltip={tooltipInfo} />
          </Box>
        )}
      </Skeleton>
    </Flex>
  );
};

export default SectionTitle;
