const UserLibraryAT = {
  FETCH_USER_LIBRARY_REQUEST: 'FETCH_USER_LIBRARY_REQUEST',
  FETCH_USER_LIBRARY_SUCCESS: 'FETCH_USER_LIBRARY_SUCCESS',
  FETCH_USER_LIBRARY_FAILURE: 'FETCH_USER_LIBRARY_FAILURE',
} as const;

const UserCheckListAT = {
  UPDATE_USER_CHECKLIST_REQUEST: 'UPDATE_USER_CHECKLIST_REQUEST',
  UPDATE_USER_CHECKLIST_SUCCESS: 'UPDATE_USER_CHECKLIST_SUCCESS',
  UPDATE_USER_CHECKLIST_FAILURE: 'UPDATE_USER_CHECKLIST_FAILURE',
} as const;

export default {
  ...UserLibraryAT,
  ...UserCheckListAT,
};
