import React, { FC } from 'react';
import {
  Skeleton as BaseSkeleton,
  SkeletonProps as BaseSkeletonProps,
  SkeletonCircle as BaseSkeletonCircle,
  SkeletonText as BaseSkeletonText,
  SkeletonTextProps as BaseSkeletonTextProps,
  BoxProps,
} from '@chakra-ui/react';
import { useTheme } from '@workshop/ui';

export interface SkeletonProps extends BaseSkeletonProps {
  loadingStyle?: BoxProps;
}

export interface SkeletonTextProps extends BaseSkeletonTextProps {
  loadingStyle?: BoxProps;
}

const Skeleton: FC<SkeletonProps> = ({
  isLoaded,
  loadingStyle,
  children,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <BaseSkeleton
      isLoaded={isLoaded}
      startColor={theme.colors.background.tint1}
      endColor={theme.colors.background.tint2}
      borderRadius="md"
      {...rest}
      {...(isLoaded ? {} : loadingStyle)}
    >
      {children}
    </BaseSkeleton>
  );
};

const SkeletonCircle: FC<SkeletonProps> = ({
  isLoaded,
  loadingStyle,
  children,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <BaseSkeletonCircle
      isLoaded={isLoaded}
      startColor={theme.colors.background.tint1}
      endColor={theme.colors.background.tint2}
      borderRadius="md"
      {...rest}
      {...(isLoaded ? {} : loadingStyle)}
    >
      {children}
    </BaseSkeletonCircle>
  );
};

const SkeletonText: FC<SkeletonTextProps> = ({
  isLoaded,
  loadingStyle,
  children,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <BaseSkeletonText
      isLoaded={isLoaded}
      startColor={theme.colors.background.tint1}
      endColor={theme.colors.background.tint2}
      borderRadius="md"
      {...rest}
      {...(isLoaded ? {} : loadingStyle)}
    >
      {children}
    </BaseSkeletonText>
  );
};

export { Skeleton, SkeletonCircle, SkeletonText };
