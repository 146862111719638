import moment from 'moment';
import { normalize } from 'normalizr';
import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';
import { decamelizeKeys } from 'humps';
import chunk from 'lodash/chunk';
import {
  ApiError,
  Category,
  GroupMemberList,
  MarkNotificationRead,
  NotificationList,
  NotificationParams,
  PrivateMessageList,
  Reply,
  Topic,
  TopicParams,
  TopicPosts,
  TopicByUserName,
  UserProfile,
  UserProfileSummary,
  Post,
  NewUpload,
} from 'discourse-js';

import { Dispatch } from 'types';
import { DiscourseNotification } from 'types/common';

import { DiscourseAT } from 'redux/actionTypes/common';
import {
  DiscourseCategorySchema,
  MessageBusNotificationSchema,
  DiscourseTopicSchema,
} from 'redux/schemas';

import { FRONT_ENV } from 'constants/env';
import discourse from 'utils/discoureService';
import { NotificationData } from 'utils/messageBus';

/**
 * When discourse returns an error it does not always have a useful message.
 *
 * We send extra debugging details to Sentry and show an error to the user if
 * it is a legitimate error (> 500).
 *
 * The `{ extra }` object can include inputs that have been passed to the action.
 * for extra debugging data.
 */
const discourseAlert = (message: string, status: number, extra?: Extras) => {
  // err.code 429: Too Many Requests
  if (FRONT_ENV === 'production') {
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Warning);
      scope.setExtra('status', status);

      if (extra) {
        scope.setExtras(extra);
      }

      Sentry.captureMessage(`DiscourseAlert: ${message}`);
    });
  } else {
    console.warn(`DiscourseAlert: ${message}`, {
      status,
      ...(extra || {}),
    });
  }

  if (status === 429) return;

  /**
   * TODO : Add discourse UI state to use Toaster
   * and handle the following errors

  if (status === 413) {
    toaster.error(
      'The photo you have tried to upload is too big. Please try again with a smaller image.',
      { duration: 5 }
    );
  }
  if (status >= 500) {
    toaster.error(
      showMessage
        ? message
        : 'Something went wrong, we are looking into this now.',
      {
        duration: 5,
      }
    );
  }

  */
};

export const getUser = (username: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DiscourseAT.FETCH_USER_REQUEST,
  });

  return discourse.users
    .getUser({
      username,
    })
    .then((res: UserProfile) => {
      // TODO: Response here can be text if the discourse API encounters
      // certain errors, and this doesn't get picked up right now. Should
      // the be handled by discourse JS better, or should we detect the
      // response type here and act accordingly?
      dispatch({
        type: DiscourseAT.FETCH_USER_SUCCESS,
        payload: res,
      });
    })
    .catch((error: ApiError) => {
      dispatch({
        type: DiscourseAT.FETCH_USER_FAILURE,
        payload: error.toString(),
      });
    });
};

export function getGroupMembers(group_name: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.FETCH_GROUP_MEMBERS_REQUEST,
    });

    return discourse.groups
      .getMembers({
        group_name,
      })
      .then((res: GroupMemberList) => {
        dispatch({
          type: DiscourseAT.FETCH_GROUP_MEMBERS_SUCCESS,
          payload: { ...res, groupName: group_name },
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: DiscourseAT.FETCH_GROUP_MEMBERS_FAILURE,
          payload: err.toString(),
        });
        discourseAlert(
          `${err.error || err.statusText || err.toString()}`,
          err.status,
          {
            action: 'getGroupMembers',
            group_name,
            apiKey: discourse._API_KEY,
            apiUsername: discourse._API_USERNAME,
          }
        );
        return null;
      });
  };
}

export const getUserSummary =
  (username: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.FETCH_USER_SUMMARY_REQUEST,
    });

    return discourse.users
      .getUserSummary({
        username,
      })
      .then((res: UserProfileSummary) => {
        // TODO: Response here can be text if the discourse API encounters
        // certain errors, and this doesn't get picked up right now. Should
        // the be handled by discourse JS better, or should we detect the
        // response type here and act accordingly?
        dispatch({
          type: DiscourseAT.FETCH_USER_SUMMARY_SUCCESS,
          payload: res,
          meta: { username },
        });
      })
      .catch((error: ApiError) => {
        dispatch({
          type: DiscourseAT.FETCH_USER_SUMMARY_FAILURE,
          payload: error.toString(),
        });
      });
  };

export const getSubcategory =
  (
    cat_id: number,
    subcat_id: number,
    latest: boolean = true,
    inputs?: Object
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.FETCH_SUBCATEGORY_REQUEST,
      payload: {
        category: cat_id,
        subcategory: subcat_id,
      },
    });

    return discourse.categories
      .getSubcategory({
        cat_id,
        subcat_id,
        latest,
        ...inputs,
      })
      .then((response: Category) => {
        /**
         * The DiscourseCategorySchema looks at `topicList.topics[0].category_id`.
         * to carry out normalization. If the topics array is empty, then we can't
         * do anything.
         *
         * Sometimes this can occur during pagination when discourse instructs us to
         * check the next page, but it's empty.
         *
         * TODO: Is there a nicer way of handling this or is this good enough?
         */
        type NormalisedDiscourseCategories = {
          discourseCategories: {
            [key: number]: Category;
          };
        };

        const payload = response.topicList.topics.length
          ? normalize<any, NormalisedDiscourseCategories, number>(
              response,
              DiscourseCategorySchema
            )
          : {
              entities: { discourseCategories: {} },
              result: cat_id,
            };

        dispatch({
          type: DiscourseAT.FETCH_SUBCATEGORY_SUCCESS,
          payload,
        });

        return payload;
      })
      .catch((err: any) => {
        dispatch({
          type: DiscourseAT.FETCH_SUBCATEGORY_FAILURE,
          payload: {
            category: cat_id,
            subcategory: subcat_id,
            error: err.toString(),
          },
        });
        // TODO: Verify this error capturing
        discourseAlert(
          `${err.error || err.statusText || err.toString()}`,
          err.status,
          {
            action: 'getSubcategory',
            cat_id,
            subcat_id,
            apiKey: discourse._API_KEY,
            apiUsername: discourse._API_USERNAME,
          }
        );
        return null;
      });
  };

export const getTopic =
  (id: number, loadPosts = false, reverse = false) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.FETCH_TOPIC_REQUEST,
    });

    return discourse.topics
      .getTopic({
        id,
        reverse,
      })
      .then(async (response: Topic) => {
        type NormalizedTopics = { discourseTopics: { [key: number]: Topic } };

        const payload = normalize<any, NormalizedTopics, number>(
          response,
          DiscourseTopicSchema
        );

        dispatch({
          type: DiscourseAT.FETCH_TOPIC_SUCCESS,
          payload,
        });

        if (!loadPosts) return response;

        const { chunkSize, postStream } = response;

        // No need to load more posts if the stream we received
        // is smaller than the chunkSize
        if (postStream.stream.length < chunkSize) return response;

        const chunks = chunk(postStream.stream, chunkSize);

        await Promise.all(chunks.map((c) => dispatch(getTopicPosts(id, c))));
        return response;
      })
      .catch((error: ApiError) => {
        dispatch({
          type: DiscourseAT.FETCH_TOPIC_FAILURE,
          payload: error.toString(),
        });
      });
  };

export const getTopicPosts =
  (id: number, posts: number[], inputs: TopicParams = { reverse: true }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.FETCH_TOPIC_POSTS_REQUEST,
      payload: {
        topic: id,
        ...inputs,
      },
    });

    return discourse.topics
      .getTopicPosts({ id, posts, ...inputs })
      .then((response: TopicPosts) => {
        type NormalizedTopics = {
          discourseTopics: {
            [key: string]: { postStream: { posts: Post[] }; id: number };
          };
        };

        const normalizedResponse = normalize<any, NormalizedTopics, number>(
          response,
          DiscourseTopicSchema
        );

        dispatch({
          type: DiscourseAT.FETCH_TOPIC_POSTS_SUCCESS,
          payload: normalizedResponse,
        });

        return normalizedResponse;
      })
      .catch((err: ApiError) => {
        dispatch({
          type: DiscourseAT.FETCH_TOPIC_POSTS_FAILURE,
          payload: {
            topic: id,
            error: err.toString(),
          },
        });
      });
  };

export const getTopicsByUser =
  (username: string, inputs: TopicParams = { reverse: true }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.FETCH_TOPICS_BY_USER_REQUEST,
      payload: {
        username,
        ...inputs,
      },
    });

    return discourse.topics
      .getTopicsByUsername({ username, ...inputs })
      .then((response: TopicByUserName) => {
        type NormalizedTopics = { discourseTopics: { [key: number]: Topic } };

        const normalizedResponse = normalize<any, NormalizedTopics, number[]>(
          response.topicList.topics,
          [DiscourseTopicSchema]
        );

        dispatch({
          type: DiscourseAT.FETCH_TOPICS_BY_USER_SUCCESS,
          payload: { username, ...normalizedResponse },
        });

        return normalizedResponse;
      })
      .catch((err: ApiError) => {
        dispatch({
          type: DiscourseAT.FETCH_TOPICS_BY_USER_FAILURE,
          payload: {
            username,
            error: err.toString(),
          },
        });
      });
  };

export const replyToPost =
  (data: { topicId: number; raw: string; replyToPostNumber: number }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.CREATE_POST_REPLY_REQUEST,
    });

    const body = decamelizeKeys(data) as {
      topic_id: number;
      raw: string;
      reply_to_post_number: number;
    };

    return discourse.posts
      .reply(body)
      .then((res: Reply) => {
        dispatch({
          type: DiscourseAT.CREATE_POST_REPLY_SUCCESS,
          payload: res,
        });
      })
      .catch((error: ApiError) => {
        dispatch({
          type: DiscourseAT.CREATE_POST_REPLY_FAILURE,
          payload: error.toString(),
        });
      });
  };

export const likePost = (postId: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: DiscourseAT.LIKE_POST_REQUEST,
  });

  return discourse.posts
    .like({ id: postId })
    .then((res: Post) => {
      dispatch({
        type: DiscourseAT.LIKE_POST_SUCCESS,
        payload: res,
      });
    })
    .catch((error: ApiError) => {
      dispatch({
        type: DiscourseAT.LIKE_POST_FAILURE,
        payload: error.toString(),
      });
    });
};

export const unlikePost = (postId: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: DiscourseAT.UNLIKE_POST_REQUEST,
  });

  return discourse.posts
    .unlike({ id: postId })
    .then((res: Post) => {
      dispatch({
        type: DiscourseAT.UNLIKE_POST_SUCCESS,
        payload: res,
      });
    })
    .catch((error: ApiError) => {
      dispatch({
        type: DiscourseAT.UNLIKE_POST_FAILURE,
        payload: error.toString(),
      });
    });
};

export const createPost = (
  inputs:
    | {
        raw: string;
        topicId: number;
        optimisticTransactionId?: string;
        hasTopicId: true;
      }
    | {
        raw: string;
        title: string;
        archetype: 'private_message';
        targetUsernames: string;
        hasTopicId?: false;
      }
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.CREATE_POST_REQUEST,
    });

    return discourse.posts
      .create(decamelizeKeys(inputs))
      .then((response: Post) => {
        dispatch({
          type: DiscourseAT.CREATE_POST_SUCCESS,
          payload: { ...response },
        });
        return response;
      })
      .catch((err) => {
        if (inputs.hasTopicId) {
          dispatch({
            type: DiscourseAT.CREATE_POST_FAILURE,
            payload: {
              topic_id: inputs.topicId,
              requestStatus: 'complete',
            },
          });
        } else {
          dispatch({
            type: DiscourseAT.CREATE_POST_FAILURE,
            payload: {
              topic_id: null,
              optimisticTransactionId: null,
            },
          });
        }
      });
  };
};

// TODO: Type inputs as `NotificationParams` from discourse-js
export const getNotifications =
  (inputs: NotificationParams = {}) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_REQUEST,
    });

    return discourse.notifications
      .get(inputs)
      .then((res: NotificationList) => {
        type NormalizedNotifications = {
          discourseNotifications: {
            [key: number]: DiscourseNotification;
          };
        };

        const payload = normalize<any, NormalizedNotifications, number[]>(
          res.notifications,
          [MessageBusNotificationSchema]
        );
        // payload.discourseNotifications
        dispatch({
          type: DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_SUCCESS,
          payload,
        });
        if (res.totalRowsNotifications) {
          dispatch({
            type: DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_COUNT_SUCCESS,
            payload: res.totalRowsNotifications,
          });
        }
      })
      .catch((error: ApiError) => {
        dispatch({
          type: DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_FAILURE,
          payload: error.toString(),
        });
      });
  };

export const markNotificationRead =
  (notificationId: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.MARK_READ_NOTIFICATION_REQUEST,
    });

    return discourse.notifications
      .markRead({ id: notificationId })
      .then((res: MarkNotificationRead) => {
        dispatch({
          type: DiscourseAT.MARK_READ_NOTIFICATION_SUCCESS,
          payload: { res, notificationId },
        });
        return res;
      })
      .catch((error: ApiError) => {
        dispatch({
          type: DiscourseAT.MARK_READ_NOTIFICATION_FAILURE,
          payload: error.toString(),
        });
      });
  };

export const getPrivateMessages = () => async (dispatch: Dispatch) => {
  dispatch({
    type: DiscourseAT.FETCH_DISCOURSE_MESSAGES_REQUEST,
  });

  return (
    discourse.messages
      .getAllMessages()
      // [recevied, sent]
      .then((res: [PrivateMessageList, PrivateMessageList]) => {
        dispatch({
          type: DiscourseAT.FETCH_DISCOURSE_MESSAGES_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch((error: ApiError) => {
        dispatch({
          type: DiscourseAT.FETCH_DISCOURSE_MESSAGES_FAILURE,
          payload: error.toString(),
        });
      })
  );
};

export const sendPrivateMessage = (topic_id: number, message: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.SEND_PRIVATE_MESSAGE_REQUEST,
    });

    return discourse.posts
      .create({
        topic_id,
        raw: message,
      })
      .then((response) => {
        dispatch({
          type: DiscourseAT.SEND_PRIVATE_MESSAGE_SUCCESS,
          payload: {
            ...response,
          },
        });
        return response;
      })
      .catch((err) => {
        dispatch({
          type: DiscourseAT.SEND_PRIVATE_MESSAGE_FAILURE,
          payload: {
            topic_id,
          },
        });
      });
  };
};

interface CreateTopicArgs {
  raw: string;
  title: string;
  category?: number;
  imageFile?: File;
  tags?: string[];
}

export const createTopic = (data: CreateTopicArgs) => (dispatch: Dispatch) => {
  dispatch({
    type: DiscourseAT.CREATE_TOPIC_REQUEST,
  });

  return discourse.topics
    .createTopic(data)
    .then((res: Post) => {
      dispatch({
        type: DiscourseAT.CREATE_TOPIC_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((err: ApiError) => {
      dispatch({
        type: DiscourseAT.CREATE_TOPIC_FAILURE,
        payload: err.toString(),
      });

      discourseAlert(
        `${err.error || err.statusText || err.toString()}`,
        err.status,
        {
          ...data,
          action: 'createTopic',
          apiKey: discourse._API_KEY,
          apiUsername: discourse._API_USERNAME,
        }
      );
    });
};

export function handlePollNotification(data: NotificationData) {
  return (dispatch: Dispatch) => {
    const {
      lastNotification: { notification },
      recent,
    } = data;

    // The date format in a message bus notification is incorrect.
    // Here we reformat before continuing
    if (notification.createdAt.includes('UTC')) {
      notification.createdAt = moment(
        notification.createdAt,
        'YYYY-MM-DD hh:mm:ss [UTC]'
      ).toISOString();
    }

    type NormalizedNotifications = {
      discourseNotifications: {
        [key: number]: DiscourseNotification;
      };
    };

    const normalizedResponse = normalize<
      any,
      NormalizedNotifications,
      number[]
    >([notification], [MessageBusNotificationSchema]);

    dispatch({
      type: DiscourseAT.FETCH_DISCOURSE_NOTIFICATIONS_SUCCESS,
      payload: {
        ...normalizedResponse,
        // Send the array of recent notifications with the
        // action for use in the reducer
        recent,
      },
    });

    return normalizedResponse;
  };
}

export const createUpload = (body: NewUpload) => async (dispatch: Dispatch) => {
  dispatch({
    type: DiscourseAT.DISCOURSE_CREATE_UPLOAD_REQUEST,
  });

  return discourse.uploads
    .create(body)
    .then((res) => {
      dispatch({
        type: DiscourseAT.DISCOURSE_CREATE_UPLOAD_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DiscourseAT.DISCOURSE_CREATE_UPLOAD_FAILURE,
        payload: error.toString(),
      });
    });
};

export const pickAvatar = (username: string, uploadId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DiscourseAT.DISCOURSE_PICK_AVATAR_REQUEST,
    });

    return discourse.preferences
      .pickAvatar({ username, upload_id: uploadId })
      .then((res) => {
        dispatch({
          type: DiscourseAT.DISCOURSE_PICK_AVATAR_SUCCESS,
          payload: res,
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: DiscourseAT.DISCOURSE_PICK_AVATAR_FAILURE,
          payload: error.toString(),
        });
        return false;
      });
  };
};
