import { AssessmentAT } from 'redux/actionTypes/cms';
import { Action } from 'types';
import { JournalEntriesState } from 'types/cms';

export const journalEntriesReducer = (
  state: JournalEntriesState = {},
  action: Action
): JournalEntriesState => {
  switch (action.type) {
    case AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_SUCCESS:
      return { ...state, ...action.payload.entities.journalEntries };

    default:
      return state;
  }
};
