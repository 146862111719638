import { AssessmentAT } from 'redux/actionTypes/cms';
import { Action } from 'types';
import { AssessmentState } from 'types/cms';

export const assessmentReducer = (
  state: AssessmentState = {},
  action: Action
): AssessmentState => {
  switch (action.type) {
    case AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.assessments,
      };

    case AssessmentAT.CMS_ASSESSMENT_UPDATE_GRADE_SUCCESS:
      const { id } = action.payload;
      const assessment = state[id];

      return assessment
        ? { ...state, [id]: { ...assessment, ...action.payload } }
        : { ...state, [id]: { ...action.payload, course: 0, cohort: 0 } };

    default:
      return state;
  }
};
