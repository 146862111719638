import React from 'react';
import {
  Box,
  Flex,
  LinkButton,
  Heading,
  MdIcon,
  Progress,
  Text,
} from '@workshop/ui';
import { HeaderTag } from 'components/AppHeader';

interface Props {
  buttonLabel?: string;
  buttonLink?: string;
  buttonSecondary?: boolean;
  image: string;
  onClick?: () => void;
  tag?: string;
  tagBg?: string;
  tagColor?: string;
  title: string;
  subtitle?: string;
  isPublic?: boolean | null;
  isVisible?: boolean | null;
  progress?: number;
  imagePlaceholderIcon?: string;
}

const ItemLarge: React.FC<Props> = ({
  title,
  subtitle,
  tag,
  tagBg = 'background.tint1',
  tagColor = 'text.muted',
  onClick,
  buttonLabel,
  buttonLink,
  buttonSecondary = true,
  image,
  isPublic = null,
  isVisible = null,
  progress,
  imagePlaceholderIcon = 'CropOriginal',
}) => (
  <Flex padding="defaultPadding" alignItems="center">
    <Flex mr={4}>
      <Box
        overflow="hidden"
        borderRadius="md"
        backgroundColor="background.tint3"
      >
        {image ? (
          <Flex
            backgroundImage={`url(${image})`}
            backgroundSize="cover"
            backgroundPosition="center"
            height={{ base: 'image.sm', md: 'image.md' }}
            width={{ base: 'image.sm', md: 'image.md' }}
          />
        ) : (
          <Flex
            height={{ base: 'image.sm', md: 'image.md' }}
            width={{ base: 'image.sm', md: 'image.md' }}
            flex="1 0"
            alignItems="center"
            justifyContent="center"
          >
            <MdIcon
              name={imagePlaceholderIcon}
              color="text.muted"
              boxSize={{ base: 7, md: 8 }}
            />
          </Flex>
        )}
      </Box>
    </Flex>
    <Flex flex={1} flexDirection="column" mr="defaultMargin">
      <Heading
        as="h3"
        fontWeight="semibold"
        fontSize={{ base: 'md', md: 'lg' }}
      >
        {title}
      </Heading>
      <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
        {tag ? (
          <Flex mr={3} mt={1.5}>
            <HeaderTag title={tag} bg={tagBg} color={tagColor} />
          </Flex>
        ) : null}
        {isPublic === true ? (
          <MdIcon mr={3} mt={1.5} name="Public" color="icon.disabled" />
        ) : null}
        {isVisible === false ? (
          <MdIcon name="VisibilityOff" color="icon.disabled" mr={3} mt={1.5} />
        ) : null}
        {subtitle ? (
          <Text color="text.muted" fontSize={{ base: 'xs', sm: 'sm' }} mt={1.5}>
            {subtitle}
          </Text>
        ) : null}
      </Flex>
      {progress !== undefined ? (
        <Box w="100%">
          <Progress
            value={progress}
            // hasStripe
            isAnimated
            borderRadius="full"
            colorScheme="green"
            height={2.5}
            w="100%"
            maxWidth="300px"
            mt={2.5}
            sx={{
              '& > div:first-child': {
                transitionProperty: 'width',
              },
            }}
          />
        </Box>
      ) : null}
    </Flex>
    <Flex alignItems="center" justifyContent="flex-end">
      <Box display={{ base: 'none', md: 'block' }}>
        {buttonLabel && buttonLink && (
          <LinkButton
            size="sm"
            secondary={buttonSecondary}
            to={buttonLink}
            icon={!buttonSecondary ? 'ArrowForward' : undefined}
            iconPosition="right"
          >
            {buttonLabel}
          </LinkButton>
        )}
      </Box>
      <Box display={{ base: 'block', md: 'none' }}>
        {buttonLabel && buttonLink && (
          <LinkButton
            size="sm"
            secondary={buttonSecondary}
            to={buttonLink}
            icon="ArrowForward"
            w={8}
          />
        )}
      </Box>
    </Flex>
  </Flex>
);

export default ItemLarge;
