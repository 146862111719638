import { callAPIWithTeam } from 'utils';
import API from 'constants/api';
import { decamelizeKeys } from 'humps';

import { CohortAT } from 'redux/actionTypes/cms';
import { CMSCohortSchema } from 'redux/schemas';

import { Dispatch } from 'types';
import {
  CohortForUpdate,
  CreateCohortAction,
  DeleteCohortAction,
  ListCohortCourseProgressAction,
  ListUserCourseProgressAction,
  CreateCertificateLogAction,
  SendCertificateLogAction,
} from 'types/cms';
import { CertificateLogPost } from 'types/common';

interface ListCohortAction {}

interface UpdateCohortAction {}

export const listCohorts =
  ({
    fetchNextPage,
    url,
    queryParams,
  }: {
    fetchNextPage?: boolean;
    url?: string | undefined;
    // queryParams format: a=1 or a=1&b=2
    queryParams?: string;
  }) =>
  async (dispatch: Dispatch) => {
    let endpoint = url || API.organisation.listCohorts;
    if (queryParams) {
      endpoint = endpoint.includes('?')
        ? `${endpoint}&${queryParams}`
        : `${endpoint}?${queryParams}`;
    }

    return dispatch(
      callAPIWithTeam<ListCohortAction>({
        types: [
          CohortAT.CMS_LIST_COHORTS_REQUEST,
          CohortAT.CMS_LIST_COHORTS_SUCCESS,
          CohortAT.CMS_LIST_COHORTS_FAILURE,
        ],
        endpoint,
        method: 'GET',
        path: 'results',
        schema: [CMSCohortSchema],
        fetchNextPage: fetchNextPage
          ? async (url) => {
              await dispatch(listCohorts({ fetchNextPage: true, url }));
            }
          : null,
        meta: {
          toast: {
            error: 'An error occurred loading your cohorts.',
          },
        },
      })
    );
  };

export const createCohort = (body: Partial<CohortForUpdate>) => {
  return callAPIWithTeam<CreateCohortAction>({
    types: [
      CohortAT.CMS_CREATE_COHORT_REQUEST,
      CohortAT.CMS_CREATE_COHORT_SUCCESS,
      CohortAT.CMS_CREATE_COHORT_FAILURE,
    ],
    endpoint: API.organisation.listCohorts,
    method: 'POST',
    body: decamelizeKeys(body),
  });
};

export const updateCohort = (
  cohortId: number,
  body: Partial<CohortForUpdate>
) => {
  return callAPIWithTeam<UpdateCohortAction>({
    types: [
      CohortAT.CMS_UPDATE_COHORT_REQUEST,
      CohortAT.CMS_UPDATE_COHORT_SUCCESS,
      CohortAT.CMS_UPDATE_COHORT_FAILURE,
    ],
    endpoint: API.organisation.updateCohort(cohortId),
    method: 'PATCH',
    schema: CMSCohortSchema,
    body: decamelizeKeys(body),
  });
};

export const deleteCohort = (cohortId: number) => {
  return callAPIWithTeam<DeleteCohortAction>({
    types: [
      CohortAT.CMS_DELETE_COHORT_REQUEST,
      CohortAT.CMS_DELETE_COHORT_SUCCESS,
      CohortAT.CMS_DELETE_COHORT_FAILURE,
    ],
    endpoint: API.organisation.updateCohort(cohortId),
    method: 'DELETE',
    meta: { cohortId, toast: { success: 'Successfully deleted class' } },
  });
};

export const listCohortCourseProgress = (cohortId: number) => {
  return callAPIWithTeam<ListCohortCourseProgressAction>({
    types: [
      CohortAT.CMS_LIST_COHORT_CP_REQUEST,
      CohortAT.CMS_LIST_COHORT_CP_SUCCESS,
      CohortAT.CMS_LIST_COHORT_CP_FAILURE,
    ],
    method: 'GET',
    endpoint: API.organisation.cohortCourseProgress(cohortId),
    meta: { cohortId },
  });
};

export const listUserCourseProgress = (userName: string) => {
  return callAPIWithTeam<ListUserCourseProgressAction>({
    types: [
      CohortAT.CMS_LIST_USER_CP_REQUEST,
      CohortAT.CMS_LIST_USER_CP_SUCCESS,
      CohortAT.CMS_LIST_USER_CP_FAILURE,
    ],
    method: 'GET',
    endpoint: API.organisation.userCourseProgress(userName),
  });
};

export const updateCourseProgress = (
  courseProgressId: number,
  body: { expiryDate: string }
) => {
  return callAPIWithTeam<ListUserCourseProgressAction>({
    types: [
      CohortAT.CMS_UPDATE_CP_REQUEST,
      CohortAT.CMS_UPDATE_CP_SUCCESS,
      CohortAT.CMS_UPDATE_CP_FAILURE,
    ],
    body: decamelizeKeys(body),
    method: 'PATCH',
    endpoint: API.organisation.courseProgress(courseProgressId),
  });
};

export const createCertificateLog = (body: Partial<CertificateLogPost>) => {
  return callAPIWithTeam<CreateCertificateLogAction>({
    types: [
      CohortAT.CMS_CREATE_CERTIFICATE_LOG_REQUEST,
      CohortAT.CMS_CREATE_CERTIFICATE_LOG_SUCCESS,
      CohortAT.CMS_CREATE_CERTIFICATE_LOG_FAILURE,
    ],
    body: decamelizeKeys(body),
    method: 'POST',
    endpoint: API.cms.createCertificateLog,
  });
};

export const sendCertificateLog = (
  certificateLogId: number,
  body: { email?: string }
) => {
  return callAPIWithTeam<SendCertificateLogAction>({
    types: [
      CohortAT.CMS_SEND_CERTIFICATE_LOG_REQUEST,
      CohortAT.CMS_SEND_CERTIFICATE_LOG_SUCCESS,
      CohortAT.CMS_SEND_CERTIFICATE_LOG_FAILURE,
    ],
    body: decamelizeKeys(body),
    method: 'POST',
    endpoint: API.cms.sendCertificateLog(certificateLogId),
  });
};
