const CourseAT = {
  FETCH_COURSE_REQUEST: 'FETCH_COURSE_REQUEST',
  FETCH_COURSE_SUCCESS: 'FETCH_COURSE_SUCCESS',
  FETCH_COURSE_FAILURE: 'FETCH_COURSE_FAILURE',

  FETCH_COURSE_LIST_REQUEST: 'FETCH_COURSE_LIST_REQUEST',
  FETCH_COURSE_LIST_SUCCESS: 'FETCH_COURSE_LIST_SUCCESS',
  FETCH_COURSE_LIST_FAILURE: 'FETCH_COURSE_LIST_FAILURE',

  PATCH_COURSE_REQUEST: 'PATCH_COURSE_REQUEST',
  PATCH_COURSE_SUCCESS: 'PATCH_COURSE_SUCCESS',
  PATCH_COURSE_FAILURE: 'PATCH_COURSE_FAILURE',
} as const;

const CourseProgressAT = {
  FETCH_COURSE_PROGRESS_REQUEST: 'FETCH_COURSE_PROGRESS_REQUEST',
  FETCH_COURSE_PROGRESS_SUCCESS: 'FETCH_COURSE_PROGRESS_SUCCESS',
  FETCH_COURSE_PROGRESS_FAILURE: 'FETCH_COURSE_PROGRESS_FAILURE',

  LIST_COURSE_PROGRESS_REQUEST: 'LIST_COURSE_PROGRESS_REQUEST',
  LIST_COURSE_PROGRESS_SUCCESS: 'LIST_COURSE_PROGRESS_SUCCESS',
  LIST_COURSE_PROGRESS_FAILURE: 'LIST_COURSE_PROGRESS_FAILURE',

  PATCH_COURSE_PROGRESS_REQUEST: 'PATCH_COURSE_PROGRESS_REQUEST',
  PATCH_COURSE_PROGRESS_SUCCESS: 'PATCH_COURSE_PROGRESS_SUCCESS',
  PATCH_COURSE_PROGRESS_FAILURE: 'PATCH_COURSE_PROGRESS_FAILURE',
} as const;

const CourseScheduleAT = {
  FETCH_COURSE_SCHEDULE_REQUEST: 'FETCH_COURSE_SCHEDULE_REQUEST',
  FETCH_COURSE_SCHEDULE_SUCCESS: 'FETCH_COURSE_SCHEDULE_SUCCESS',
  FETCH_COURSE_SCHEDULE_FAILURE: 'FETCH_COURSE_SCHEDULE_FAILURE',
} as const;

const ModuleQuestionAT = {
  FETCH_MODULE_QUESTIONS_LIST_REQUEST: 'FETCH_MODULE_QUESTIONS_LIST_REQUEST',
  FETCH_MODULE_QUESTIONS_LIST_SUCCESS: 'FETCH_MODULE_QUESTIONS_LIST_SUCCESS',
  FETCH_MODULE_QUESTIONS_LIST_FAILURE: 'FETCH_MODULE_QUESTIONS_LIST_FAILURE',
  SUBMIT_QUESTION_ANSWERS_REQUEST: 'SUBMIT_QUESTION_ANSWERS_REQUEST',
  SUBMIT_QUESTION_ANSWERS_SUCCESS: 'SUBMIT_QUESTION_ANSWERS_SUCCESS',
  SUBMIT_QUESTION_ANSWERS_FAILURE: 'SUBMIT_QUESTION_ANSWERS_FAILURE',
} as const;

const ModuleProgressAT = {
  UPDATE_USER_MODULE_PROGRESS_REQUEST: 'UPDATE_USER_MODULE_PROGRESS_REQUEST',
  UPDATE_USER_MODULE_PROGRESS_SUCCESS: 'UPDATE_USER_MODULE_PROGRESS_SUCCESS',
  UPDATE_USER_MODULE_PROGRESS_FAILURE: 'UPDATE_USER_MODULE_PROGRESS_FAILURE',
} as const;

const ModuleScheduleAT = {
  UPDATE_COURSE_MODULE_SCHEDULE_REQUEST:
    'UPDATE_COURSE_MODULE_SCHEDULE_REQUEST',
  UPDATE_COURSE_MODULE_SCHEDULE_SUCCESS:
    'UPDATE_COURSE_MODULE_SCHEDULE_SUCCESS',
  UPDATE_COURSE_MODULE_SCHEDULE_FAILURE:
    'UPDATE_COURSE_MODULE_SCHEDULE_FAILURE',
} as const;

const UnitProgressAT = {
  UPDATE_USER_UNIT_PROGRESS_REQUEST: 'UPDATE_USER_UNIT_PROGRESS_REQUEST',
  UPDATE_USER_UNIT_PROGRESS_SUCCESS: 'UPDATE_USER_UNIT_PROGRESS_SUCCESS',
  UPDATE_USER_UNIT_PROGRESS_FAILURE: 'UPDATE_USER_UNIT_PROGRESS_FAILURE',
} as const;

const CohortAt = {
  FETCH_COHORT_REQUEST: 'FETCH_COHORT_REQUEST',
  FETCH_COHORT_SUCCESS: 'FETCH_COHORT_SUCCESS',
  FETCH_COHORT_FAILURE: 'FETCH_COHORT_FAILURE',

  LIST_COHORTS_REQUEST: 'LIST_COHORTS_REQUEST',
  LIST_COHORTS_SUCCESS: 'LIST_COHORTS_SUCCESS',
  LIST_COHORTS_FAILURE: 'LIST_COHORTS_FAILURE',

  LIST_UPCOMING_COHORTS_REQUEST: 'LIST_UPCOMING_COHORTS_REQUEST',
  LIST_UPCOMING_COHORTS_SUCCESS: 'LIST_UPCOMING_COHORTS_SUCCESS',
  LIST_UPCOMING_COHORTS_FAILURE: 'LIST_UPCOMING_COHORTS_FAILURE',
} as const;

export default {
  ...CourseAT,
  ...CourseProgressAT,
  ...CourseScheduleAT,
  ...ModuleQuestionAT,
  ...ModuleProgressAT,
  ...ModuleScheduleAT,
  ...UnitProgressAT,
  ...CohortAt,
};
