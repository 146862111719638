import React, { useState, useEffect } from 'react';
import { FaFacebook } from 'react-icons/fa';

import { Button } from '@workshop/ui';

declare global {
  interface Window {
    fbAsyncInit: () => void;
  }
}

export type FacebookResponse =
  | fb.AuthResponse
  | {
      status: fb.LoginStatus;
    };

interface Props {
  isLoading?: boolean;
  isDisabled?: boolean;
  /**
   * Callback to run after receiving response from FB
   */
  callback: (result: fb.AuthResponse | { status: fb.LoginStatus }) => void;
  /**
   * Optional error handler to process errors from FB
   */
  onError?: ({ status }: { status: string }) => void;
  /**
   * The children/button which is used to initiate a login request
   */
  children?: (props: {
    onClick: () => void;
    isLoading: boolean;
    isDisabled: boolean;
    isProcessing: boolean;
    isSdkLoaded: boolean;
  }) => JSX.Element;
}

const appId: string = process.env.REACT_APP_FACEBOOK_APP_ID || '';

const FacebookButton: React.FC<Props> = ({
  isLoading = false,
  isDisabled = false,
  onError,
  callback,
  children = null,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);

  useEffect(() => {
    if (document.getElementById('facebook-jssdk')) {
      // SDK already loaded - skip
      setIsSdkLoaded(true);
      return;
    }
    // Initialise SDK
    initialiseSdk();
    loadSdkAsynchronously();
  }, []);

  // Declare init function for the FB SDK
  const initialiseSdk = () => {
    window.fbAsyncInit = () => {
      window.FB?.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0',
      });

      setIsSdkLoaded(true);
    };
  };

  // Load Facebook SDK asynchronously.
  const loadSdkAsynchronously = () => {
    const language = 'eu_US';
    const id = 'facebook-jssdk';

    // Target the first script element and use that as a reference
    const refElement = document.getElementsByTagName('script')[0];

    // SDK already loaded - skip
    if (document.getElementById(id)) {
      return;
    }

    // Create the script to load in the Facebook SDK
    let scriptElement: HTMLScriptElement = document.createElement('script');
    scriptElement.id = id;
    scriptElement.src = `https://connect.facebook.net/${language}/sdk.js`;
    refElement?.parentNode?.insertBefore(scriptElement, refElement);
  };

  const checkLoginState = (response: fb.StatusResponse) => {
    setIsProcessing(false);
    if (response.authResponse) {
      callback(response.authResponse);
    } else {
      if (onError) {
        onError({ status: response.status });
      } else {
        callback({ status: response.status });
      }
    }
  };

  const handleFacebookAuth = () => {
    if (!isSdkLoaded || isProcessing || isDisabled) {
      return;
    }

    setIsProcessing(true);

    // If FB SDK has not been initialised
    if (!window.FB) {
      if (onError) {
        onError({ status: 'facebookNotLoaded' });
      }

      return;
    }

    return window.FB.getLoginStatus((response) =>
      response.status === 'connected'
        ? checkLoginState(response)
        : window.FB.login(checkLoginState, {
            scope: 'public_profile',
            return_scopes: false,
          })
    );
  };

  // Default button
  if (!children)
    return (
      <Button
        onClick={handleFacebookAuth}
        colorScheme="facebook"
        width="100%"
        isLoading={isProcessing || isLoading}
        isDisabled={isDisabled || isProcessing}
        leftIcon={<FaFacebook />}
      >
        Sign in with Facebook
      </Button>
    );

  // Custom buttom
  return children({
    onClick: handleFacebookAuth,
    isLoading,
    isDisabled,
    isProcessing,
    isSdkLoaded,
  });
};

export default FacebookButton;
