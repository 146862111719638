import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

import {
  Flex,
  Box,
  Text,
  Grid,
  GridItem,
  GridProps,
  MdIcon,
} from '@workshop/ui';

import { AnimatedFlex, Tour } from 'components/Common';

interface ItemProps {
  slug: string;
  name: string;
  description?: string;
  iconElement?: React.ReactElement | null;
  icon?: string;
  emoji?: string;
  onClick: () => void;
  showTour?: boolean;
  tourText?: string;
  headerElement?: React.ReactElement;
  footerElement?: React.ReactElement;
  disabled?: boolean;
  isActive?: boolean;
  fullWidth?: boolean;
}
interface ButtonGridItemProps extends ItemProps {
  numItems: number;
  index: number;
  uniform?: boolean;
  condensed?: boolean;
}
interface Props extends GridProps {
  items: ItemProps[];
  uniform?: boolean;
  condensed?: boolean;
  fullWidth?: boolean;
}

const ButtonGridItem: React.FC<ButtonGridItemProps> = ({
  slug,
  name,
  description,
  iconElement = null,
  icon,
  emoji,
  onClick,
  numItems,
  index,
  showTour,
  tourText,
  headerElement,
  footerElement,
  disabled,
  isActive,
  uniform,
  condensed,
  fullWidth,
}) => {
  const [isOver, setIsOver] = useState(false);
  const [{ hover }, animateHover] = useSpring(() => ({
    hover: 0,
    config: { mass: 0.5, tension: 200, friction: 4.5 },
  }));
  const [{ jiggle }, animateJiggle] = useSpring(() => ({
    jiggle: 0,
    config: { tension: 300, friction: 8 },
  }));
  useEffect(() => {
    if (isOver) {
      animateJiggle({ jiggle: 1 });
      animateHover({ hover: 1 });
      const timeout = window.setTimeout(() => {
        animateJiggle({ jiggle: 0 });
        window.clearTimeout(timeout);
      }, 300);
      return () => {
        setIsOver(false);
        window.clearTimeout(timeout);
      };
    }
    if (!isOver) {
      animateHover({ hover: 0 });
    }
  }, [isOver]);
  return (
    <>
      <GridItem
        display="flex"
        ml={{
          base: 0,
          sm:
            uniform && numItems === 5 && index === 4 ? 'calc(50% + 0.5rem)' : 0,
        }}
        mr={{
          base: 0,
          sm:
            uniform && numItems === 5 && index === 4
              ? 'calc(-50% - 0.5rem)'
              : 0,
        }}
        colSpan={
          fullWidth
            ? 6
            : uniform
            ? {
                base: 6,
                sm: 3,
                md:
                  numItems === 2 ||
                  numItems === 4 ||
                  numItems === 5 ||
                  numItems === 7 ||
                  numItems === 8
                    ? 3
                    : 2,
              }
            : {
                base: 6,
                sm:
                  numItems === 1
                    ? 6
                    : numItems === 2
                    ? 3
                    : index === 0
                    ? 6
                    : numItems % 2 === 0 && index === 1
                    ? 6
                    : 3,
                md:
                  numItems === 1
                    ? 6
                    : numItems === 2
                    ? 3
                    : index === 0
                    ? 6
                    : numItems > 5 && numItems % 2 === 0 && index < 3
                    ? 3
                    : numItems % 2 === 0
                    ? 2
                    : 3,
              }
        }
        {...(showTour
          ? {
              'data-tour': slug,
            }
          : {})}
      >
        {/* @ts-ignore */}
        <AnimatedFlex
          pointerEvents={disabled ? 'none' : 'auto'}
          flex={1}
          borderWidth={2}
          borderColor={isActive ? 'common.primary' : 'border.muted'}
          backgroundColor={isActive ? 'background.default' : 'background.tint1'}
          borderRadius="md"
          p={condensed ? 4 : { base: 6, md: fullWidth ? 4 : 6 }}
          flexDirection={fullWidth ? { base: 'column', md: 'row' } : 'column'}
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          _hover={{
            backgroundColor: 'background.default',
            borderColor: 'common.primary',
          }}
          onClick={onClick}
          transition="border-color 0.3s, background-color 0.3s"
          onMouseEnter={() => setIsOver(true)}
          onMouseLeave={() => setIsOver(false)}
          style={{
            scale: hover.to({
              range: [0, 1],
              output: [1, 1.02],
            }),
          }}
          position="relative"
        >
          {isActive && (
            <Flex
              alignItems="center"
              position="absolute"
              fontSize="xs"
              top={condensed ? 1 : 2}
              right={condensed ? 2 : 3}
              color="common.primary"
            >
              <MdIcon name="TaskAlt" mr={1.5} />
              <Text>Selected</Text>
            </Flex>
          )}
          <animated.span
            style={{
              scale: jiggle.to({
                range: [0, 1],
                output: [1, 1.1],
              }),
              rotate: jiggle.to({
                range: [0, 0.55, 1],
                output: ['0deg', '2deg', '-2deg'],
              }),
            }}
          >
            {icon ? (
              <Box>
                <MdIcon
                  name={icon}
                  boxSize={condensed ? 5 : 6}
                  color={disabled ? 'text.muted' : 'common.primary'}
                />
              </Box>
            ) : emoji ? (
              <Flex
                boxSize={condensed ? 'image.sm' : 'image.md'}
                alignItems="center"
                justifyContent="center"
                zIndex={1}
              >
                <Text fontSize={condensed ? '5xl' : '6xl'}>{emoji}</Text>
              </Flex>
            ) : iconElement ? (
              <Flex
                boxSize={condensed ? 'image.sm' : 'image.md'}
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                fontSize={condensed ? '5xl' : '6xl'}
              >
                {iconElement}
              </Flex>
            ) : null}
          </animated.span>

          <Box
            mt={1}
            textAlign={fullWidth ? { base: 'center', md: 'left' } : 'center'}
            ml={fullWidth ? { base: 0, md: 4 } : 0}
          >
            {headerElement || null}
            <Text
              fontSize="md"
              fontWeight="bold"
              color={disabled ? 'text.muted' : 'common.primary'}
            >
              {name}
            </Text>
            {description ? (
              <Text fontSize="sm" color="text.muted" mt={1}>
                {description}
              </Text>
            ) : null}
            {footerElement || null}
          </Box>
        </AnimatedFlex>
      </GridItem>
    </>
  );
};

const ButtonGrid: React.FC<Props> = ({
  items,
  uniform,
  condensed,
  fullWidth,
  ...rest
}) => (
  <>
    <Grid gap={4} templateColumns="repeat(6, 1fr)" {...rest}>
      {items.map((i, index) => (
        <ButtonGridItem
          key={`buttonGridItem-${i.slug}`}
          numItems={items.length}
          index={index}
          uniform={uniform}
          condensed={condensed}
          fullWidth={fullWidth}
          {...i}
        />
      ))}
    </Grid>
    <Tour
      steps={items
        .filter((i) => !!i.showTour)
        .map((i) => ({
          id: i.slug,
          content: i.tourText || '',
          disableBeacon: true,
        }))}
    />
  </>
);

export default ButtonGrid;
