import React from 'react';

import { RouteComponentProps } from 'react-router';

import navRoutes from 'navigation/Routes';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import {
  Card,
  Flex,
  Text,
  Box,
  MdIcon,
  Button,
  Stack,
  Image,
} from '@workshop/ui';

interface HomeScreenProps extends RouteComponentProps {}

const LeithsAcademyScreen: React.FC<HomeScreenProps> = ({ history }) => {
  const features = [
    { icon: 'Pageview', label: 'Preview Courses' },
    { icon: 'GroupWork', label: 'Create Classes' },
    { icon: 'GroupAdd', label: 'Enrol Students' },
    { icon: 'School', label: 'Deliver Courses' },
  ];
  return (
    <ScreenWrapper>
      <Card padding={0} maxW="700px" mx="auto" mb={12}>
        <Box>
          <Box>
            <Image
              src="https://d1bqmh4sljm7sh.cloudfront.net/marketing-assets/lak-group.jpg"
              w="100%"
              objectFit="contain"
            />
          </Box>
          <Box py={8} px={{ base: 4, md: 8 }} textAlign="center">
            <Text fontSize="2xl" fontWeight="bold" mb={6}>
              Welcome to Leiths Academy Kitchen
            </Text>
            <Text mb={8}>
              Leiths Academy Kitchen (LAK) is the perfect solution for your
              whole school cookery curriculum. LAK is a brand new, enjoyable
              approach to practical, hands-on cookery for every pupil from
              10–18.
            </Text>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={4}
              mb={12}
            >
              {features.map(({ icon, label }) => (
                <Flex
                  key={`feature-${label}`}
                  flex={1}
                  flexDirection="column"
                  alignItems="center"
                >
                  <MdIcon
                    name={icon}
                    color="icon.primary"
                    boxSize={{ base: 16, md: 20 }}
                  />
                  <Text color="text.primary" fontWeight="semibold">
                    {label}
                  </Text>
                </Flex>
              ))}
            </Stack>
            <Button
              size="lg"
              onClick={() => history.push(navRoutes.common.home.path())}
              mb={4}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Card>
    </ScreenWrapper>
  );
};

export default LeithsAcademyScreen;
