import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { helpUrl } from 'constants/env';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';

import { MdIcon, Flex, Textarea, Button, Link, Text } from '@workshop/ui';

import AnimatedDropDown from 'components/Common/AnimatedDropDown';

import { ZendeskAction } from 'types/common';

const EXTENDED_WIDTH = 400;

interface Form {
  feedback: string;
}

interface FeedbackButtonProps {
  isSubmitting?: boolean;
  onSaveFeedback: (data: Form) => Promise<ZendeskAction | void>;
  onClick?: () => void;
  onBg?: boolean;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({
  isSubmitting,
  onSaveFeedback,
  onClick,
  onBg,
}) => {
  const { register, handleSubmit, formState, reset } = useForm<Form>();

  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const handleBtnClick = () => {
    onClick && onClick();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
      setSuccess(false);
      setInvalid(false);
    }
  }, [isOpen, reset]);

  const handleSaveFeedback = handleSubmit(async (data) => {
    const response = await onSaveFeedback(data);

    if (response && response.error) {
      setInvalid(true);
    } else {
      setSuccess(true);
    }
  });

  return (
    <AnimatedDropDown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Feedback"
      extendedWidth={EXTENDED_WIDTH}
      secondary
      leftIcon={<MdIcon name="Feedback" color="inherit" />}
      fontSize="sm"
      ml="defaultMargin"
      onClick={handleBtnClick}
      dropdownStyle={{
        maxHeight: 'inherit',
      }}
      minH="170px"
      onBg={onBg}
      name="Feedback"
    >
      {success ? (
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          padding="defaultPadding"
        >
          <MdIcon name="ThumbUp" color="inherit" fontSize="2xl" />
          <Text fontSize="xl" color="common.primary">
            Thanks for your time
          </Text>
          <Text>{`Your feedback helps improves ${PLATFORM_DISPLAY_NAME}.`}</Text>
        </Flex>
      ) : (
        <>
          <Textarea
            ref={register}
            name="feedback"
            placeholder="Help us improve this page"
            _placeholder={{
              color: 'text.muted',
            }}
            border="none"
            borderRadius={0}
          />
          <Flex direction="column" padding="defaultPadding">
            <Flex alignContent="center" justifyContent="space-between">
              <Button secondary size="sm" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                loadingText="Sending..."
                isDisabled={!formState.dirty || isSubmitting}
                onClick={handleSaveFeedback}
                size="sm"
              >
                Send Feedback
              </Button>
            </Flex>
            {invalid && (
              <Text
                color="intent.error"
                marginTop="defaultPadding"
                fontSize="md"
              >
                An error occurred
              </Text>
            )}
            <Text fontSize="sm" marginTop="defaultPadding" color="text.muted">
              Have a specific issue? Check out our{' '}
              <Link
                color="text.info"
                href={helpUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Center
              </Link>
              .
            </Text>
          </Flex>
        </>
      )}
    </AnimatedDropDown>
  );
};

export default FeedbackButton;
