import React from 'react';

import { CardsList, CardListItemProps } from 'components/CardsList';
import { Flex, Box, Text, MdIcon, CircularProgress } from '@workshop/ui';

interface ProgressListItemProps extends CardListItemProps {
  progress?: number;
  showProgress?: boolean;
  progressDescription?: string;
  expiryDescription?: string;
  isExpired?: boolean;
}

interface ProgressCardsListProps {
  isLoading?: boolean;
  items: ProgressListItemProps[];
  noItemsMessage?: string;
}

export const ProgressCardsList: React.FC<ProgressCardsListProps> = ({
  isLoading = false,
  items,
  noItemsMessage,
}) => (
  <CardsList
    isLoading={isLoading}
    noItemsMessage={noItemsMessage}
    items={items.map((item) =>
      item.showProgress && typeof item.progress === 'number'
        ? {
            ...item,
            children: (
              <Flex flexDirection={{ base: 'column', sm: 'row' }}>
                {item.expiryDescription ? (
                  <Box
                    justifyContent="center"
                    px={2}
                    py={1}
                    borderRadius="sm"
                    backgroundColor={
                      item.isExpired ? 'background.error' : 'background.warning'
                    }
                    mr={{ base: 0, sm: 2 }}
                    mb={{ base: 2, sm: 0 }}
                  >
                    <Text
                      fontSize="xs"
                      color={item.isExpired ? 'text.error' : 'text.warning'}
                      fontWeight="semibold"
                      textAlign="center"
                    >
                      {item.expiryDescription}
                    </Text>
                  </Box>
                ) : null}

                <Box
                  position="relative"
                  backgroundColor="background.success"
                  borderRadius="sm"
                  minWidth="65px"
                  justifyContent="center"
                  px={2}
                  py={1}
                  mr="defaultMargin"
                >
                  <Text
                    fontSize="xs"
                    color="text.success"
                    fontWeight="semibold"
                    textAlign="center"
                  >
                    {item.progressDescription}
                  </Text>
                  <Box
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    justifyContent="center"
                    px={2}
                    py={1}
                    borderRadius="sm"
                    clipPath={`inset(0 0 0 ${item.progress}%)`}
                    backgroundColor="background.tint3"
                  >
                    <Text
                      fontSize="xs"
                      color="text.muted"
                      fontWeight="semibold"
                      textAlign="center"
                    >
                      {item.progressDescription}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            ),
          }
        : item
    )}
  />
);

export interface FavouriteListItemProps extends CardListItemProps {
  favouriteEnabled?: boolean;
  onFavouriteClicked?: () => void;
}

export interface FavouritesCardsListProps {
  isLoading?: boolean;
  items: FavouriteListItemProps[];
  noItemsMessage?: React.ReactNode;
}

const noop = () => null;

export const FavouritesCardsList: React.FC<FavouritesCardsListProps> = ({
  isLoading = false,
  items,
  noItemsMessage,
}) => (
  <CardsList
    isLoading={isLoading}
    noItemsMessage={noItemsMessage}
    items={items.map((item) => {
      const onFavouriteClicked = item.onFavouriteClicked || noop;
      const icon = item.favouriteEnabled ? (
        <MdIcon name="Favorite" color="red.300" boxSize={6} />
      ) : (
        <MdIcon name="FavoriteBorder" color="red.300" boxSize={6} />
      );
      return {
        ...item,
        children: (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onFavouriteClicked();
            }}
          >
            {icon}
          </div>
        ),
      };
    })}
  />
);
