import React, { useState } from 'react';
import { Input } from '@workshop/ui';
import { useForm } from 'react-hook-form';
import { decamelize } from 'humps';
import isEmail from 'validator/lib/isEmail';

import { Flex, FormControl, FormErrorMessage, useToast } from '@workshop/ui';

import { CourseEmailPreviewAction } from 'types/cms';

import { EditModal } from 'components/Common/EditModal';
import { LabelSelect } from 'components/Common';

import { EmailConfigTypes } from './EmailConfig';

export interface ModalFormData {
  emailType: string;
  emailAddress: string;
  unit?: number;
}

interface Props {
  isOpen: boolean;
  emailType: EmailConfigTypes;
  options?: { [key: string]: string };
  isLoading?: boolean;
  handleSubmit: (
    arg0: ModalFormData
  ) => Promise<CourseEmailPreviewAction | void>;
  onClose: () => void;
}

const EmailConfigModal: React.FC<Props> = ({
  isOpen,
  onClose,
  emailType,
  options,
  handleSubmit,
}) => {
  const {
    register,
    handleSubmit: baseHandleSubmit,
    formState: { dirty },
    reset,
    getValues,
    errors,
  } = useForm<ModalFormData>();

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = baseHandleSubmit(async (formData) => {
    setIsLoading(true);
    const response = await handleSubmit(formData);
    setIsLoading(false);

    if (!response) {
      toast({
        title: 'An unknown error occurred.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (!response.error) {
      const emailAddress = getValues('emailAddress');
      toast({
        title: `Email successfully sent to ${emailAddress}`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      reset();
      onClose();
    } else {
      toast({
        title: `An error occurred. Please try again.`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  });

  return (
    <EditModal
      title="Send a Test Email"
      isOpen={isOpen}
      onSave={onSubmit}
      onClose={onClose}
      saveLabel="Send"
      closeOnOverlayClick={false}
      onCancel={onClose}
      isUpdating={isLoading}
      saveDisabled={!dirty}
    >
      <FormControl isInvalid={Boolean(errors?.emailAddress)}>
        {emailType && (
          <Input
            ref={register}
            type="hidden"
            name="emailType"
            // Decamelize value to make it readable by the backend
            value={decamelize(emailType)}
          />
        )}
        <Input
          ref={register({
            required: {
              value: true,
              message: 'You must include an email',
            },
            validate: (value) => isEmail(value),
          })}
          name="emailAddress"
          placeholder="Insert your email"
        />
        {emailType === 'automatedEmail' && options && (
          <Flex my={2}>
            <Flex>
              <LabelSelect
                registerInputRef={register}
                id="unit"
                name="unit"
                options={options}
                helpText={"Choose which Unit email you'd like to preview"}
              />
            </Flex>
            <Flex />
          </Flex>
        )}
        <FormErrorMessage>Please enter a valid email</FormErrorMessage>
      </FormControl>
    </EditModal>
  );
};

export default EmailConfigModal;
