import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Card,
  Flex,
  Text,
  Skeleton,
  Spinner,
} from '@workshop/ui';

import { hooks } from 'utils';
import { organisationActions } from 'redux/actions/admin';
import { impersonateUser } from 'redux/actions/common/auth';
import { GlobalState } from 'types';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface OrganisationsProps extends PropsFromRedux, RouteComponentProps {}

const Organisations: React.FC<OrganisationsProps> = ({
  organisations,
  history,
}) => {
  const [loading, setLoading] = useState<string | null>(null);
  const dispatch = useDispatch();

  const { organisations: organisationsLoading } = hooks.useLoadingDataState(
    {
      organisations: {
        actions: [() => organisationActions.list()],
      },
    },
    []
  );

  return (
    <Flex direction="column" flex={1} marginX="defaultScreenX">
      <Flex display="flex" flexDir="column">
        {organisationsLoading && (
          <Card>
            <Skeleton isLoaded={false} w="100%">
              <Box h="60px" w="100%" />
            </Skeleton>
          </Card>
        )}
        {Object.keys(organisations).map((k) => {
          const org = organisations[k];
          return (
            <Card padding={0} display="flex" flexDir="column" mb={4}>
              {org.teamMembers.length ? (
                <Accordion allowMultiple allowToggle flex="1">
                  <AccordionItem>
                    <AccordionButton display="flex">
                      <Text py={2} fontWeight="bold">
                        {org.name}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel display="flex" flexWrap="wrap" padding={0}>
                      <Flex flexDir="column" flex={1}>
                        {org.teamMembers.map((m, idx) => {
                          return (
                            <Flex
                              key={idx}
                              flex={1}
                              flexDir="column"
                              pt={idx === 0 ? 4 : 0}
                            >
                              {idx === 0 ? null : <Divider w="100%" />}
                              <Flex
                                alignItems="center"
                                flex={1}
                                padding={2}
                                pb={idx + 1 < org.teamMembers.length ? 2 : 4}
                              >
                                <Text flex={1} ml={2}>
                                  {m.user.name}
                                </Text>
                                <Button
                                  isDisabled={loading !== null}
                                  minW="250px"
                                  mr="2"
                                  secondary
                                  onClick={() => {
                                    setLoading(m.user.name);
                                    dispatch(impersonateUser(m.user.id)).then(
                                      () => history.go(0)
                                    );
                                  }}
                                >
                                  {loading === m.user.name ? (
                                    <Spinner size="xs" mr={2} />
                                  ) : (
                                    `Impersonate ${m.user.name}`
                                  )}
                                </Button>
                              </Flex>
                            </Flex>
                          );
                        })}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                <Flex flexDir="column">
                  <Text py={2} px={4} fontWeight="bold">
                    {org.name}
                  </Text>
                  <Text pb={2} px={4} color="text.muted">
                    There are currently no members in this organisation :(
                  </Text>
                </Flex>
              )}
            </Card>
          );
        })}
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  organisations: state.admin.organisations,
});

const connector = connect(mapStateToProps);

export default connector(withRouter(Organisations));
