import { Action } from 'types';
import { WorkshopsState, UserWorkshopsState } from 'types/learner';
import { ProfileAT, WorkshopAT } from 'redux/actionTypes/learner';

const initialState: WorkshopsState = {};

export const workshopsReducer = (
  state = initialState,
  action: Action
): WorkshopsState => {
  switch (action.type) {
    case WorkshopAT.FETCH_WORKSHOP_LIST_SUCCESS:
    case ProfileAT.FETCH_USER_LIBRARY_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.workshops,
      };
  }
  return state;
};

export const userWorkshopRelationshipsReducer = (
  state: UserWorkshopsState = {},
  action: Action
): UserWorkshopsState => {
  switch (action.type) {
    case WorkshopAT.FETCH_USER_WORKSHOP_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.userWorkshopRelationships,
      };
  }
  return state;
};
