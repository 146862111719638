import { CategoryState } from 'types/common';
import { CategoryAT } from 'redux/actionTypes/common';
import { Action } from 'types';

const categoryInitialState: CategoryState = {};

export const categoryReducer = (
  state = categoryInitialState,
  action: Action
): CategoryState => {
  switch (action.type) {
    case CategoryAT.CMS_FETCH_CATEGORIES_REQUEST:
      return state;
    case CategoryAT.CMS_FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.category,
      };
    case CategoryAT.CMS_FETCH_CATEGORIES_FAILURE:
      return state;
    default:
      return state;
  }
};
