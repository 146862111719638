import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import slugify from 'slugify';

import styled, {
  Text,
  Flex,
  Box,
  Image,
  MdIcon,
  useColorMode,
  useTheme,
} from '@workshop/ui';

import {
  useIsAuthenticated,
  useIsMentor,
  useIsStaff,
  useUploadList,
} from 'redux/selectors';
import { useDismissedInformationCards } from 'redux/selectors/user';

import { zendeskActions } from 'redux/actions/common';

import { routingUtils } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { SectionTitle, InformationCard } from 'components/Common';
import {
  InboxNotifications,
  HeaderTag,
  UploadStatusButton,
  FeedbackButton,
  UserSettingsButton,
  JoinButton,
  ImpersonateUserInput,
} from 'components/AppHeader';
import { Breadcrumbs, getCrumbs } from 'components/Breadcrumbs';

import { GlobalState } from 'types';
import { NotificationType } from 'discourse-js';
import { impersonateUser } from 'redux/actions/common/auth';

export const NAV_HEIGHT: number = 59; // The height of the navbar when it is minimised

const HeaderContainer = styled(Flex)`
  transition: all 500ms;
  transform: translate3d(0, 0, 0);
`;

interface AppHeaderProps extends RouteComponentProps<any> {
  topPosition?: number;
  onBg?: boolean;
}

interface AppHeadingProps extends RouteComponentProps<any> {
  tags?: Array<{
    title: string;
    color: string;
    bg: string;
  }> | null;
  alignCenter?: boolean;
}

const TitleText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

/**
 * On scrolling the AppHeader can be minimised to allow more screen
 * content
 */
const _AppHeader: FC<AppHeaderProps> = (props) => {
  const { topPosition = 0, onBg = false } = props;

  const [submittingFeedback, setSubmittingFeedback] = useState(false);

  const dispatch = useDispatch();

  const theme = useTheme();

  const isAuthenticated = useIsAuthenticated();
  const isStaff = useIsStaff();
  const uploadList = useUploadList();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const { email, id, name } = useSelector(
    (state: GlobalState) => state.user.userDetails
  );

  const onImpersonateUser = (data: { userId: number }) => {
    return dispatch(impersonateUser(data.userId)).then((response) => {
      localStorage.removeItem('defaultTeam');
      window.location.reload();
    });
  };

  const onSaveFeedback = async ({ feedback }: { feedback: string }) => {
    if (!email || !id || !name) return Promise.resolve();

    setSubmittingFeedback(true);

    const extraInfo: { [key: string]: string | number } = {
      page: window.location.pathname,
      time: new Date().toISOString(),
      height: window.screen.height,
      width: window.screen.width,
      browserName: navigator.appName,
      browserVersion: navigator.appVersion,
      browserAgent: navigator.userAgent,
    };

    const moreInfo = Object.keys(extraInfo).map(
      (info) => `${info}: ${extraInfo[info]}`
    );

    const body = `
      ${feedback}
      \n \n 
      userId: ${id}
      userName: ${name}
      userEmail: ${email} 
      ${moreInfo?.join('\n')}
    `;

    const response = await dispatch(
      zendeskActions.createRequest({
        requester: {
          name,
          email,
          verified: true,
        },
        subject: `Feedback - ${feedback}`,
        comment: { body, authorId: id.toString() },
        type: 'question',
        priority: 'normal',
        via: { channel: 'app' },
      })
    );

    setSubmittingFeedback(false);

    return response;
  };

  const allNotifications = useSelector((state: GlobalState) =>
    Object.values(state.discourse.notifications)
  );

  const notifications = allNotifications
    .filter((notification) => {
      // Filter system notifications
      if ('displayUsername' in notification.data) {
        return !(
          notification.data.originalUsername === 'system' ||
          notification.data.originalUsername === 'discobot'
        );
      }
      // Filter badge related notifications
      if (NotificationType.grantedBadge) return false;
      return true;
    })
    .reverse(); // New notifications at the top

  const notificationsCount = useSelector(
    (state: GlobalState) => state.discourse.notificationsCount
  );
  return (
    <>
      <HeaderContainer
        flexDirection="column"
        justifyContent="center"
        position="fixed"
        left={0}
        right={0}
        height={NAV_HEIGHT}
        zIndex={1100}
        top={`${topPosition}px !important`}
        backgroundColor={{ base: 'background.default', md: 'transparent' }}
        borderBottomWidth={{ base: 1, md: 0 }}
        borderBottomColor="border.default"
      >
        <Flex alignItems="center">
          <Flex flex={1} />
          <Box marginRight={4}>
            {isAuthenticated ? (
              <Flex>
                {isStaff && !isMobile && (
                  <ImpersonateUserInput
                    onSubmit={onImpersonateUser}
                    onBg={onBg}
                  />
                )}
                {Object.keys(uploadList).length > 0 && (
                  <Flex alignItems="stretch">
                    <UploadStatusButton />
                  </Flex>
                )}
                {!isMobile && (
                  <FeedbackButton
                    onSaveFeedback={onSaveFeedback}
                    isSubmitting={submittingFeedback}
                    onBg={onBg}
                  />
                )}
                <InboxNotifications
                  notifications={notifications}
                  showLoadMore={allNotifications.length < notificationsCount}
                  onBg={onBg}
                />
                <UserSettingsButton onBg={onBg} />
              </Flex>
            ) : (
              <Flex>
                <Flex h={10} />
                <JoinButton onBg={onBg} />
              </Flex>
            )}
          </Box>
        </Flex>
      </HeaderContainer>
    </>
  );
};

export const AppHeader = withRouter<AppHeaderProps, React.FC<AppHeaderProps>>(
  _AppHeader
);

export const _AppHeading: FC<AppHeadingProps> = (props) => {
  const { tags = null, location, alignCenter } = props;

  const [showInfo, setShowInfo] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  const isMentor = useIsMentor();
  const dismissedInformationCards = useDismissedInformationCards();

  // Based on the current path, determine what the title and label for the
  // page should be
  const crumbs = getCrumbs(location.pathname, isAuthenticated, isMentor);

  const currentCrumb = crumbs.length > 0 ? crumbs[crumbs.length - 1] : null;

  // If the crumb contains a state name path, extract the title from the
  // definition in our state, if it exists.

  const title = useSelector((state: GlobalState) =>
    currentCrumb?.stateNamePath
      ? routingUtils.getFromPath(
          state,
          currentCrumb.stateNamePath,
          currentCrumb.route.name
        )
      : currentCrumb?.route.name || ''
  );

  const banner = useSelector((state: GlobalState) =>
    currentCrumb?.stateBannerPath
      ? routingUtils.getFromPath(state, currentCrumb.stateBannerPath, '')
      : ''
  );

  const featureImage = useSelector((state: GlobalState) =>
    currentCrumb?.stateFeatureImagePath
      ? routingUtils.getFromPath(state, currentCrumb.stateFeatureImagePath, '')
      : ''
  );

  const logo = useSelector((state: GlobalState) =>
    currentCrumb?.stateLogoPath
      ? routingUtils.getFromPath(state, currentCrumb.stateLogoPath, '')
      : ''
  );

  const orgName = useSelector((state: GlobalState) =>
    currentCrumb?.stateOrgNamePath
      ? routingUtils.getFromPath(state, currentCrumb.stateOrgNamePath, '')
      : ''
  );

  const routeId = slugify(currentCrumb?.route.name || '').toLowerCase();
  const infoText = currentCrumb?.route.infoText;
  const infoDismissed = routeId
    ? dismissedInformationCards.includes(routeId)
    : true;

  let label =
    title === currentCrumb?.route.name ? '' : currentCrumb?.route.name || '';

  // Override label with route-defined label if applicable, and
  // replace 'orgName' in string with orgName from state
  if (currentCrumb?.route.label) {
    label = currentCrumb?.route.label.replace('orgName', orgName);
  }

  const showBanner = Boolean(banner && logo);

  const hideHeading = currentCrumb?.route.hideHeading;
  if (hideHeading) return null;

  return (
    <>
      {showBanner ? (
        <Box
          position="relative"
          zIndex={1}
          marginTop={{ base: NAV_HEIGHT, md: 0 }}
        >
          <Box position="absolute" left={0} top={0}>
            <Image src={logo} height="35px" margin={4} />
          </Box>
          <Image
            width="100%"
            backgroundColor="background.tint2"
            src={banner}
            objectFit="cover"
          />
        </Box>
      ) : null}

      <Flex
        minHeight={NAV_HEIGHT}
        marginTop={showBanner ? { base: 2, md: 6, lg: 10 } : NAV_HEIGHT}
        alignItems="center"
        justifyContent="center"
        transition="padding-left 0.5s"
        zIndex={4}
        flexDirection="column"
      >
        {Boolean(featureImage) && (
          <Flex
            alignSelf="normal"
            width={{ base: '100%', md: '85%', xl: '70%', '2xl': '70%' }}
            maxWidth="1200px"
            mx="auto"
            position="relative"
            overflow="hidden"
            mt={{ base: 4, md: 12 }}
            justifyContent="center"
          >
            <Flex
              position="absolute"
              top={16}
              right={0}
              bottom={16}
              left={0}
              overflow="hidden"
              borderRadius="lg"
              display={{ base: 'none', md: 'flex' }}
            >
              <Box
                flex={1}
                backgroundImage={`url(${featureImage})`}
                backgroundSize="cover"
                backgroundPosition="center"
                filter="blur(100px)"
              />
            </Flex>
            <Box
              position="relative"
              borderRadius="lg"
              overflow="hidden"
              mx={{ base: 4, md: 0 }}
            >
              <Image
                width="100%"
                src={featureImage}
                objectFit="cover"
                maxHeight={400}
              />
            </Box>
          </Flex>
        )}
        <Box
          pb={8}
          width={{ base: '100%', md: '85%', xl: '70%', '2xl': '70%' }}
          maxWidth="1200px"
          paddingX={{ base: 'defaultMargin', md: 0 }}
        >
          <Flex
            marginTop={alignCenter ? 9 : { base: 4, lg: 2, xl: 0 }}
            // @ts-ignore
            flexDirection={{
              base: 'column-reverse',
              md: 'row',
            }}
            {...(alignCenter
              ? {
                  alignItems: 'center',
                  flexDirection: 'column',
                }
              : {})}
          >
            <Box flex={1}>
              <SectionTitle
                height={5}
                color="text.muted"
                mb={0}
                title={label}
                marginX={{ base: 0 }}
                justifyContent={alignCenter ? 'center' : 'auto'}
              />
              <Flex alignItems="center">
                <TitleText
                  mb={0}
                  pr={
                    !alignCenter || (infoText && !showInfo && infoDismissed)
                      ? 4
                      : 2
                  }
                  pl={alignCenter ? 2 : 0}
                  lineHeight={1.2}
                  fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                  fontWeight="800"
                  letterSpacing="title"
                  flex={1}
                  textAlign={alignCenter ? 'center' : 'left'}
                >
                  {title}
                </TitleText>
                {infoText && !showInfo && infoDismissed && (
                  <Box
                    pr={alignCenter ? 0 : 4}
                    cursor="pointer"
                    _hover={{ opacity: 0.8 }}
                    onClick={() => setShowInfo(true)}
                  >
                    <MdIcon name="InfoOutline" color="icon.muted" />
                  </Box>
                )}
              </Flex>
            </Box>
            <Flex pb={{ base: 2, md: 1 }} alignItems="flex-end">
              {tags &&
                tags.map((tag, key) => (
                  <HeaderTag
                    key={key}
                    title={tag.title}
                    bg={tag.bg}
                    color={tag.color}
                  />
                ))}
              <Box
                pl={{ base: 0, md: alignCenter ? 0 : 'defaultPadding' }}
                pt={alignCenter ? 2 : 0}
              >
                <Breadcrumbs />
              </Box>
            </Flex>
          </Flex>
          {infoText && (
            <InformationCard
              id={routeId}
              information={{ description: infoText }}
              showInfo={showInfo}
              onDismiss={() => setShowInfo(false)}
              mt={6}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};

export const AppHeading = withRouter<
  AppHeadingProps,
  React.FC<AppHeadingProps>
>(_AppHeading);
