import * as Sentry from '@sentry/react';

import { Dispatch } from 'types';
import { Cohort } from 'types/common';
import { CourseDetails } from 'types/learner';

import { discourseActions } from 'redux/actions/common';

interface LoadDiscourseParams {
  dispatch: Dispatch;
  cohort: Cohort;
  course: CourseDetails;
  subCategoryId: number | null;
}

const loadDiscourseData = async ({
  dispatch,
  course,
  cohort,
  subCategoryId,
}: LoadDiscourseParams) => {
  // Receiving errors that subCategoryId is undefined.
  // This is a production debug log.
  if (subCategoryId === null) {
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Warning);
      scope.setExtras({
        cohort: cohort.id,
        classType: cohort.classType,
        cohortDiscourseCategoryId: cohort.discourseCategoryId,
        courseDiscourseCategoryId: course.discourseCategoryId,
        courseDiscourseCommunityCategoryId: course.discourseCommunityCategoryId,
      });
      Sentry.captureMessage('No subcategory ID provided during course load.');
    });
    return;
  }

  dispatch(
    discourseActions.getSubcategory(
      course.discourseCategoryId,
      subCategoryId,
      true
    )
  );

  /**
   * TODO : copied over from native app
   * Group members need to be loaded to show "Your Mentor" card
   
  if (cohort.discourseGroupName && !groupMembers) {
    dispatch(getGroupMembers(cohort.discourseGroupName));
  }
  */
};

export default loadDiscourseData;
