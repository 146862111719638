import React, { useEffect, useState } from 'react';
import { useLottie } from 'lottie-react';

import assistantAnimation from 'assets/lottie/assistant.json';

const AssistantAnimation: React.FC<{ boxSize: number }> = ({ boxSize }) => {
  const [assistantJson, setAssistantJson] = useState(null);

  const { View: lottieAssistant } = useLottie({
    animationData: assistantJson,
    style: {
      height: boxSize,
      width: boxSize,
    },
  });

  useEffect(() => {
    // @ts-ignore
    setAssistantJson(assistantAnimation);
  }, []);

  return lottieAssistant;
};

export default AssistantAnimation;
