import React from 'react';
import { FieldErrors } from 'react-hook-form';

import {
  LabelInput,
  LabelTextArea,
  LabelInputProps,
  LabelTextAreaProps,
} from 'components/Common';

import { OverviewFormData } from 'types/cms';

interface OverviewInputProps extends LabelInputProps {
  name: string;
  validation?: Object;
  // TODO: Type `register` and make register/errors injected props
  // https://stackoverflow.com/questions/57124810/typescript-type-for-child-component-of-a-higher-order-component-that-adds-a-prop
  register?: any;
  errors?: FieldErrors<OverviewFormData>;
}

const OverviewInput = ({
  name,
  register,
  validation,
  errors,
  ...rest
}: OverviewInputProps) => (
  <LabelInput
    name={name}
    registerInputRef={validation ? register(validation) : register}
    // @ts-ignore
    error={!!errors[name]}
    // @ts-ignore
    errorMessage={errors[name]?.message}
    bg="background.default"
    {...rest}
  />
);

interface OverviewTextAreaProps extends LabelTextAreaProps {
  name: string;
  validation?: Object;
  // TODO: Type `register` and make register/errors injected props
  // https://stackoverflow.com/questions/57124810/typescript-type-for-child-component-of-a-higher-order-component-that-adds-a-prop
  register?: any;
  errors?: FieldErrors<OverviewFormData>;
}

export const OverviewTextArea = ({
  name,
  register,
  validation,
  errors,
  ...rest
}: OverviewTextAreaProps) => (
  <LabelTextArea
    name={name}
    registerInputRef={validation ? register(validation) : register}
    // @ts-ignore
    error={!!errors[name]}
    // @ts-ignore
    errorMessage={errors[name]?.message}
    bg="background.default"
    {...rest}
  />
);

export default OverviewInput;
