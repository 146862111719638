import React, { useCallback } from 'react';

import { Box } from '@workshop/ui';

type VisualizerExtensions = {
  renderStyle: () => void;
};

type VisualizerOptions = {
  autoplay: boolean;
  shadowBlur: number;
  shadowColor: string;
  barColor: string;
  barWidth: number;
  barHeight: number;
  barSpacing: number;
};

type VisualizerProps = {
  stream: MediaStream;
  size?: 'sm' | 'md';
  options?: Partial<VisualizerOptions>;
  extensions?: Partial<VisualizerExtensions>;
};

const Visualizer: React.FC<VisualizerProps> = (props) => {
  const { size } = props;

  const OPTIONS_ANALYSER = {
    smoothingTime: 0.6,
    fftSize: 512,
  };

  const OPTIONS_DEFAULT = {
    autoplay: false,
    shadowBlur: 50,
    shadowColor: '#fff',
    barColor: '#fff',
    barWidth: size === 'sm' ? 3 : 4,
    barHeight: size === 'sm' ? 3 : 4,
    barSpacing: size === 'sm' ? 2 : 14,
  };

  let vars: {
    playing: boolean;
    requestAnimationFrame: typeof window.requestAnimationFrame | null;
    animFrameId: number | null;
    ctx: AudioContext | null;
    analyser: AnalyserNode | null;
    frequencyData: Uint8Array | [];
    sourceNode: MediaStreamAudioSourceNode | null;
    gradient: CanvasGradient | null;
    canvasCtx: CanvasRenderingContext2D | null;
    options: VisualizerOptions;
    extensions: VisualizerExtensions;
  } = {
    playing: true,
    requestAnimationFrame: null,
    animFrameId: null,
    ctx: null,
    analyser: null,
    frequencyData: [],
    sourceNode: null,
    gradient: null,
    canvasCtx: null,
    options: OPTIONS_DEFAULT,
    extensions: {
      renderStyle: () => null,
    },
  };

  let canvas: HTMLCanvasElement | null = null;
  const canvasRef = useCallback((node: HTMLCanvasElement) => {
    if (node !== null) {
      canvas = node;
      _init();
    }
  }, []);

  const _init = async () => {
    // Set audio context
    // @ts-ignore
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    const ctx = new window.AudioContext();

    // Set audio buffer analyser
    const analyser = ctx.createAnalyser();

    analyser.smoothingTimeConstant = OPTIONS_ANALYSER.smoothingTime;
    analyser.fftSize = OPTIONS_ANALYSER.fftSize;

    // Set frequency data
    const frequencyData = new Uint8Array(analyser.frequencyBinCount);

    const requestAnimationFrame = (() => {
      return (
        window.requestAnimationFrame ||
        // @ts-ignore
        window.webkitRequestAnimationFrame ||
        // @ts-ignore
        window.mozRequestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })();

    // Extend constructor options
    const options = Object.assign(OPTIONS_DEFAULT, props.options);
    const extensions = Object.assign(
      {},
      {
        renderStyle: _onRenderStyleDefault,
        ...props.extensions,
      }
    );

    // Set stream source and connect processor and analyser
    // let sourceNode = ctx.createBufferSource()
    const sourceNode = ctx.createMediaStreamSource(props.stream);

    sourceNode.connect(analyser);
    // sourceNode.connect(ctx.destination);
    // sourceNode.onended = () => {
    //   _onAudioStop();
    // };

    // Set canvas context & styles
    let canvasCtx = canvas?.getContext('2d') || null;
    const { barColor, shadowBlur, shadowColor } = vars.options;
    const gradient = canvasCtx?.createLinearGradient(0, 0, 0, 300) || null;
    gradient?.addColorStop(1, barColor);
    if (canvasCtx) {
      canvasCtx = Object.assign(canvasCtx, {
        fillStyle: gradient,
        shadowBlur: shadowBlur,
        shadowColor: shadowColor,
        textAlign: 'center',
      });
    }

    vars = {
      ...vars,
      ctx,
      analyser,
      frequencyData,
      requestAnimationFrame,
      options,
      extensions,
      sourceNode,
      canvasCtx,
      gradient,
    };

    _onRender({
      renderStyle: extensions.renderStyle,
    });

    _onRenderFrame();
  };

  //   onunmount, ctx.close();

  // const _onStop = () => {
  //   const { canvasCtx, ctx } = vars;
  //   // const { canvas } = this.refs;

  //   return new Promise((resolve, reject) => {
  //     window.cancelAnimationFrame(vars.animFrameId);
  //     clearInterval(vars.interval);
  //     vars.sourceNode.disconnect();
  //     canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  //     this._onChange(STATES[0]);

  //     this._onResetTimer()
  //       .then(() => {
  //         ctx.resume();
  //       })
  //       .then(() => {
  //         this._setBufferSourceNode();
  //       })
  //       .then(() => {
  //         setState(
  //           {
  //             playing: false,
  //             animFrameId: null,
  //           },
  //           () => {
  //             return resolve();
  //           }
  //         );
  //       });
  //   });
  // };

  const _onRenderFrame = () => {
    const { analyser, frequencyData, requestAnimationFrame } = vars;

    if (
      vars.playing &&
      requestAnimationFrame &&
      analyser &&
      frequencyData.length > 0
    ) {
      const animFrameId = requestAnimationFrame(_onRenderFrame);
      vars = { ...vars, animFrameId };
      analyser.getByteFrequencyData(frequencyData as Uint8Array);
      _onRender(vars.extensions);
    }
  };

  const _onRender = (extensions: VisualizerExtensions) => {
    const { canvasCtx } = vars;
    if (!canvas || !canvasCtx) return;

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

    Object.keys(extensions).forEach((extension) => {
      // @ts-ignore
      return extensions[extension] && extensions[extension]();
    });
  };

  const _onRenderStyleDefault = () => {
    const { frequencyData, canvasCtx } = vars;
    const { size } = props;
    const { barWidth, barHeight, barSpacing } = vars.options;

    if (!canvas || !canvasCtx) return;

    const radiusReduction = size === 'sm' ? 15 : 250;
    const amplitudeReduction = size === 'sm' ? 10 : 3;
    const slicedPercentage = size === 'sm' ? 50 : 25;
    const rotation = size === 'sm' ? 90 : 135;

    const cx = canvas.width / 2;
    const cy = canvas.height / 2;
    const radius = Math.min(cx, cy) - radiusReduction;
    const maxBarNum = Math.floor(
      (radius * 2 * Math.PI) / (barWidth + barSpacing)
    );
    const slicedPercent = Math.floor((maxBarNum * slicedPercentage) / 100);
    const barNum = maxBarNum - slicedPercent;
    const freqJump = Math.floor(frequencyData.length / maxBarNum);

    for (let i = 0; i < barNum; i++) {
      const amplitude = frequencyData[i * freqJump];
      const theta = (i * 2 * Math.PI) / maxBarNum;
      const delta = ((rotation - barWidth) * Math.PI) / 180;
      const x = 0;
      const y = radius - (amplitude / 12 - barHeight);
      const w = barWidth;
      const h = amplitude / amplitudeReduction + barHeight;

      canvasCtx.save();
      canvasCtx.translate(cx + barSpacing, cy + barSpacing);
      canvasCtx.rotate(theta - delta);
      canvasCtx.fillRect(x, y, w, h);
      canvasCtx.restore();
    }
  };

  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      transform={['scale(-1, 1)']}
    >
      <canvas
        ref={canvasRef}
        width={size === 'sm' ? 80 : 800}
        height={size === 'sm' ? 80 : 800}
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      ></canvas>
    </Box>
  );
};

export default Visualizer;
