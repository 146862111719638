import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Flex, Card } from '@workshop/ui';

import { UNIT_TYPE, SESSION_TYPE } from 'constants/courses';

import { GlobalState } from 'types';
import {
  cohortActions,
  courseActions,
  courseProgressActions,
} from 'redux/actions/learner';
import { hooks } from 'utils';
import { getCohortSubcategoryId } from 'utils/learner';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { NewPostCard } from 'components/NewPostCard';

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const NewPost: React.FC<Props> = ({
  cohort,
  courseSlug,
  course,
  unitState,
  moduleState,
  location,
  subCategoryId,
  history,
}) => {
  const { courseLoading } = hooks.useLoadingDataState({
    courseLoading: {
      actions: [
        () => courseActions.retrieve(courseSlug, location.pathname, false),
      ],
    },
  });

  const { dataLoading } = hooks.useLoadingDataState(
    {
      dataLoading: {
        startLoading: !Boolean(courseLoading),
        actions: [
          () => cohortActions.retrieve(courseSlug),
          () => courseProgressActions.retrieve(courseSlug),
        ],
      },
    },
    [courseSlug, courseLoading]
  );

  if (courseLoading) return null;

  const moduleOptions = course?.units
    .filter((unitSlug) => {
      const unit = unitState[unitSlug];
      return (
        unit.unitType !== UNIT_TYPE.intro && unit.unitType !== UNIT_TYPE.outro
      );
    })
    .map((unitSlug) => {
      const unit = unitState[unitSlug];
      return {
        value: `${unit.id}`,
        label: unit.title,
        options: unit.modules
          .filter((moduleSlug) => {
            const module = moduleState[moduleSlug];
            return module.moduleType === SESSION_TYPE.normal;
          })
          .map((moduleSlug) => {
            const module = moduleState[moduleSlug];
            return {
              value: module.slug,
              label: module.title,
            };
          }),
      };
    });

  return (
    <ScreenWrapper>
      <Flex mb={4} flexDirection="column" position="relative">
        <Card overflow="visible">
          <NewPostCard
            onSubmitSuccess={(post) => {
              if (post) {
                history.push(`/course/${courseSlug}/activity/${post.topicId}`);
              }
            }}
            moduleOptions={moduleOptions}
            discourseCategoryId={subCategoryId || undefined}
            title={cohort?.courseDetails?.title || ''}
          />
        </Card>
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    learner: {
      courses: { cohorts, courses, units: unitState, modules: moduleState },
    },
  } = state;

  // Course Data
  const course = courses.detail[courseSlug];

  // Cohort Data
  const cohort = cohorts[courseSlug];

  const subCategoryId = getCohortSubcategoryId(cohort, course);

  return {
    cohort,
    course,
    courseSlug,
    subCategoryId,
    unitState,
    moduleState,
  };
};

const connector = connect(mapStateToProps);

export default connector(NewPost);
