import React from 'react';
import { Message } from 'react-hook-form';

import {
  Box,
  Flex,
  Select,
  FormLabelProps,
  SelectProps,
  BoxProps,
} from '@workshop/ui';

import { IconTooltip, Tooltip } from 'components/IconTooltip';
import LabelWrapper from './LabelWrapper';

export interface SelectOption {
  [key: string]: string;
}

interface LabelSelectProps<IOptionType extends SelectOption>
  extends SelectProps {
  label?: string;
  registerInputRef?: RegisterFunc<HTMLSelectElement>;
  options: IOptionType;
  error?: boolean;
  errorMessage?: Message;
  helpText?: string | React.ReactNode;
  unit?: string;
  helpTextPosition?: 'underline' | 'inline';
  labelPosition?: 'top' | 'inline';
  labelStyleProps?: FormLabelProps;
  labelButtonText?: string;
  labelButtonOnClick?: () => void;
  isLoading?: boolean;
  loadingStyle?: BoxProps;
  tooltip?: Tooltip;
  unsorted?: boolean;
  reverseSort?: boolean;
}

function LabelSelect<IOptionType extends SelectOption>({
  id,
  name,
  label,
  registerInputRef,
  options,
  error = false,
  errorMessage,
  helpText,
  unit,
  defaultValue = undefined,
  helpTextPosition = 'underline',
  labelPosition = 'inline',
  labelStyleProps = {},
  labelButtonText,
  labelButtonOnClick,
  isReadOnly = false,
  isDisabled = false,
  isLoading = false,
  onChange = () => {},
  tooltip,
  loadingStyle,
  unsorted = false,
  reverseSort = false,
  ...props
}: LabelSelectProps<IOptionType>) {
  return (
    <LabelWrapper
      inputId={id}
      label={label}
      unit={unit}
      helpText={helpText}
      helpTextPosition={helpTextPosition}
      labelPosition={labelPosition}
      labelStyleProps={labelStyleProps}
      labelButtonText={labelButtonText}
      labelButtonOnClick={labelButtonOnClick}
      isInvalid={error}
      errorMessage={errorMessage}
      isLoading={isLoading}
      loadingStyle={loadingStyle}
    >
      <Flex alignItems="center">
        <Select
          flex={1}
          id={id}
          ref={registerInputRef}
          name={name}
          backgroundColor="background.default"
          defaultValue={defaultValue}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          onChange={onChange}
          {...props}
        >
          {Object.keys(options)
            .sort((a, b) =>
              unsorted
                ? 0
                : reverseSort
                ? options[b].localeCompare(options[a])
                : options[a].localeCompare(options[b])
            )
            .map((option, idx) => (
              <option key={idx} value={option}>
                {options[option]}
              </option>
            ))}
        </Select>
        {tooltip && (
          <Box ml={2}>
            <IconTooltip tooltip={tooltip} />
          </Box>
        )}
      </Flex>
    </LabelWrapper>
  );
}

export default LabelSelect;
