import { Middleware } from 'redux';

import { authActions } from 'redux/actions/common';
import { Auth } from 'redux/actionTypes/common';
import { Action } from 'types';

export const timerMiddleware: Middleware = ({ dispatch }) => {
  // TODO : find a way to link this timeout value to the access
  // cookie expiration returned by the backend
  const timeout = 4 * 60 * 1000; // 240s
  return (next: (arg0: Action) => any) => (action: Action) => {
    // On logging out we stop any token refreshing
    if (action.type === Auth.DELETE_TOKEN_SUCCESS) return next(action);

    if (
      action.type === Auth.FETCH_TOKEN_SUCCESS ||
      action.type === Auth.REFRESH_TOKEN_SUCCESS
    ) {
      setTimeout(() => {
        // @ts-ignore
        dispatch(authActions.refreshTokenRequest());
      }, timeout);
    }
    return next(action);
  };
};
