import { Action } from 'types';
import { OrganisationAT } from 'redux/actionTypes/common';
import { OrganisationsState } from 'types/admin';

export const organisationsReducer = (
  state: OrganisationsState = {},
  action: Action
): OrganisationsState => {
  switch (action.type) {
    case OrganisationAT.LIST_ORGANISATIONS_SUCCESS:
      return { ...state, ...action.payload.entities.organisations };

    default:
      return state;
  }
};
