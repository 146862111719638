import React, { useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import QRCode from 'react-qr-code';
import { camelize } from 'humps';

import navRoutes from 'navigation/Routes';

import { baseUrl } from 'constants/env';

import { hooks, getParamFromUrl } from 'utils';

import { roomActions, profileActions } from 'redux/actions/common';
import { RoomAT } from 'redux/actionTypes/common';

import {
  Flex,
  Box,
  Stack,
  Text,
  Card,
  Button,
  Divider,
  Spinner,
  Collapse,
  CircularProgress,
  Progress,
  MdIcon,
  useToast,
  useDisclosure,
} from '@workshop/ui';
import { LabelInput } from 'components/Common';
import { LoadingScreen } from 'components/Loading';
import { BrandLg } from 'components/Brand';
import { UserAvatar } from 'components/UserAvatar';
import { ModalVideo } from 'components/ModalVideo';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { SessionPreviewScreen } from 'screens/cms/SessionPreview';
import { DownloadsModalBody } from 'screens/learner/BrowseCourse/src/CourseModals';

import { GlobalState } from 'types';
import { Room, RoomDevice } from 'types/common';

// Routing Props
interface MatchParams {
  roomId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const CurrentlyOnline: React.FC<{
  roomDevices: RoomDevice[];
  room?: Room;
  isMentor?: boolean;
}> = ({ roomDevices, room, isMentor = false }) => {
  const totalNumSteps = room?.session?.steps?.length;
  return (
    <Box mt={8} {...(isMentor ? {} : { maxWidth: 'lg', marginX: 'auto' })}>
      <Divider mb={4} />
      <Box mb={4}>
        <Text textAlign="center" fontWeight="bold">
          {isMentor ? 'Class Progress' : 'Currently Online'}
        </Text>
      </Box>
      <Divider mb={2} />
      {roomDevices.length === 0 && (
        <Text mt={4} color="text.muted" px={2} textAlign="center">
          There is currently no-one online
        </Text>
      )}
      {roomDevices.map((d) => {
        const percentageComplete = totalNumSteps
          ? Math.min(((d.currentStep + 1) / totalNumSteps) * 100, 100)
          : 0;
        const isComplete = percentageComplete === 100;
        return (
          <Box mb={2} key={`device-${d.id}`}>
            <Flex
              flexDirection={{ base: 'column', sm: 'row' }}
              textAlign="left"
              alignItems={{ base: 'normal', sm: 'center' }}
              mb={2}
              px={{ base: 'defaultMargin', md: 0 }}
            >
              <Flex alignItems="center" flex={1}>
                <UserAvatar size="2xs" userId={d.id} name={d.name} />
                <Text mx={3} flex={1}>
                  {d.name}
                </Text>
              </Flex>
              <Flex
                alignItems="center"
                flex={{ base: 1, sm: 'none' }}
                justifyContent={{ base: 'flex-end', sm: 'none' }}
                mt={{ base: 1, sm: 0 }}
              >
                <Text color="text.muted">{`${d.numStudents}${
                  d.numStudents === 1 ? ' person' : ' people'
                }`}</Text>
                {totalNumSteps && d.currentStep !== null ? (
                  <Flex
                    backgroundColor={
                      isComplete ? 'background.success' : 'background.default'
                    }
                    py={1}
                    pl={3}
                    pr={3}
                    ml={4}
                    borderRadius="full"
                    alignItems="center"
                    justifyContent="center"
                    minWidth="127px"
                  >
                    <CircularProgress
                      value={percentageComplete}
                      color="common.progress"
                      trackColor={
                        isComplete ? 'background.default' : 'background.tint2'
                      }
                      thickness="20px"
                      size="icon"
                      capIsRound
                    />
                    <Text
                      color={isComplete ? 'text.success' : 'text.muted'}
                      ml={2}
                    >
                      {d.currentStep === 0
                        ? 'Started'
                        : isComplete
                        ? 'Complete'
                        : `Step ${d.currentStep}`}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
            {isMentor && totalNumSteps && d.currentStep !== null ? (
              <Progress
                value={percentageComplete}
                hasStripe={isComplete}
                isAnimated={isComplete}
                borderRadius="full"
                colorScheme="green"
                mt={2}
                mb={2}
                height={2.5}
                mx={{ base: 'defaultMargin', md: 0 }}
                sx={{
                  '& > div:first-child': {
                    transitionProperty: 'width',
                  },
                }}
              />
            ) : null}
            <Divider />
          </Box>
        );
      })}
    </Box>
  );
};

const RoomAccessCard: React.FC<{
  room: Room;
  onResetRoom?: () => Promise<void>;
}> = ({ room, onResetRoom }) => {
  const toast = useToast();
  const path = `${window.location.host}/room/${room.roomId}`;
  const fullPath = `${window.location.protocol}//${path}`;
  return (
    <Card
      padding={{ base: 4, sm: 8 }}
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Text fontWeight="bold" mb={2} fontSize="lg">
        Room Access Instructions
      </Text>
      <Text mb={6}>
        To join the room, students can visit the link below or scan the QR code
        with their device's camera app.
      </Text>
      <Box
        mb={7}
        borderRadius="md"
        backgroundColor="background.primary"
        py={3}
        px={4}
        cursor="pointer"
        _hover={{ opacity: 0.75 }}
        onClick={() => {
          navigator.clipboard?.writeText(fullPath);
          toast({
            title: `Copied link to clipboard`,
            status: 'info',
            duration: 1500,
          });
        }}
      >
        <Text color="text.primary" fontWeight="semibold" fontSize="lg">
          {path}
        </Text>
      </Box>
      <Box
        backgroundColor="white"
        borderRadius="md"
        p={2}
        borderWidth={1}
        mb={6}
      >
        {/* @ts-ignore */}
        <QRCode value={fullPath} size={128} />
      </Box>

      <Text mb={3}>Enter room PIN:</Text>
      <Box
        borderRadius="md"
        backgroundColor="background.primary"
        py={2}
        pl={6}
        pr={3}
        mb={4}
      >
        <Text
          color="text.primary"
          fontWeight="semibold"
          fontSize="4xl"
          letterSpacing={12}
        >
          {room.roomPin}
        </Text>
      </Box>
      {onResetRoom && (
        <Button variant="ghost" colorScheme="red" onClick={onResetRoom}>
          Reset PIN & Clear Room
        </Button>
      )}
    </Card>
  );
};

const JoinRoomCard: React.FC<{
  room: Room;
  onJoinRoom: (roomPin: string) => Promise<void>;
}> = ({ room, onJoinRoom }) => {
  const [name, setName] = useState('');
  const [numStudents, setNumStudents] = useState<number | undefined>(undefined);
  const [roomPin, setRoomPin] = useState('');
  const [pinError, setPinError] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  const onJoin = async () => {
    setPinError('');
    const res = await dispatch(
      roomActions.createDevice(
        room.roomId,
        {
          name,
          numStudents,
          roomPin,
        },
        { queryParams: `pin=${roomPin}` }
      )
    );
    if (res?.error) {
      const errorMessage =
        // @ts-ignore
        res.payload?.message || res.payload?.normalizedErrors?.message[0];
      if (errorMessage) {
        if (errorMessage.toLowerCase().includes('pin')) {
          setPinError('This PIN is incorrect');
          return;
        }
        toast({
          title: errorMessage,
          status: 'error',
          duration: 2000,
        });
      }
      return;
    }
    // Save deviceID & roomPin to roomId in local storage
    // so that we can retrieve details on refresh
    // @ts-ignore
    const deviceId = res.payload?.deviceId;
    localStorage.setItem(room.roomId, JSON.stringify({ roomPin, deviceId }));
    onJoinRoom(roomPin);
  };
  return (
    <Card
      padding={{ base: 4, sm: 8 }}
      flexDirection="column"
      maxWidth="lg"
      marginX="auto"
    >
      <Text fontWeight="bold" mb={5} fontSize="lg" textAlign="center">
        Join Room
      </Text>
      <LabelInput
        label="Enter your name(s)"
        onChange={(e) => setName(e.target.value)}
        // helpText="(Optional) Add a short label to name your class or to help students differentiate between classes starting on the same date."
        labelPosition="top"
      />
      <LabelInput
        label="How many people are using this device?"
        onChange={(e) => setNumStudents(parseInt(e.target.value))}
        labelPosition="top"
        type="number"
      />
      <LabelInput
        label="Enter the room PIN"
        onChange={(e) => setRoomPin(e.target.value)}
        labelPosition="top"
        type="number"
        error={!!pinError}
        errorMessage={pinError}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onJoin();
          }
        }}
      />
      <Divider mb={6} mt={3} />
      <Button
        onClick={onJoin}
        isDisabled={
          !name || !numStudents || (numStudents && numStudents < 1) || !roomPin
        }
      >
        Join
      </Button>
    </Card>
  );
};

const MonitorRoomSection: React.FC<
  Props & {
    onEndSession: () => Promise<void>;
    onResetRoom: () => Promise<void>;
    isLoading: boolean;
  }
> = (props) => {
  const {
    room,
    roomDevices,
    history,
    location,
    onEndSession,
    onResetRoom,
    isLoading,
  } = props;
  const [videoForModal, setVideoForModal] = useState<string | null>(null);
  const { isOpen: isSessionOpen, onToggle: onSessionToggle } = useDisclosure();
  const { isOpen: isAccessLinkOpen, onToggle: onAccessLinkToggle } =
    useDisclosure();
  const { isOpen: isResourcesOpen, onToggle: onResourcesToggle } =
    useDisclosure();
  const teacherDownloads = room.downloads?.filter((d) => d.teacherOnly);
  const studentDownloads = room.downloads?.filter((d) => !d.teacherOnly);
  const numPeopleOnline = roomDevices.reduce((acc, obj) => {
    const numStudents =
      typeof obj.numStudents === 'string'
        ? parseInt(obj.numStudents)
        : obj.numStudents;
    return acc + numStudents;
  }, 0);
  return (
    <Box>
      <Text textAlign="center" color="text.muted">
        Today's Session:
      </Text>
      <Text
        textAlign="center"
        fontWeight="bold"
        fontSize="2xl"
        mx={{ base: 'defaultMargin', md: 0 }}
      >
        {room.session?.title}
      </Text>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        color="text.muted"
        fontWeight="semibold"
        fontSize="sm"
        spacing={{ base: 1, md: 5 }}
        mt={2}
        mb={8}
      >
        <Flex alignItems="center">
          <MdIcon name="Devices" />
          <Text pl={2}>{`${roomDevices.length} Device${
            roomDevices.length === 1 ? '' : 's'
          } Active`}</Text>
        </Flex>
        <Flex alignItems="center">
          <MdIcon name="Groups" />
          <Text pl={2}>{`${numPeopleOnline} Student${
            numPeopleOnline === 1 ? '' : 's'
          } Online (Max Allowed: ${room.studentLimit})`}</Text>
        </Flex>
      </Stack>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
        mb={12}
        mx={{ base: 'defaultMargin', md: 0 }}
      >
        <Button
          variant="outline"
          colorScheme={isAccessLinkOpen ? 'orange' : 'blue'}
          onClick={onAccessLinkToggle}
          icon={isAccessLinkOpen ? 'RemoveCircle' : 'QrCode2'}
          isLoading={isLoading}
        >
          {isAccessLinkOpen ? 'Hide Access Link' : 'View Access Link'}
        </Button>
        {room.downloads && room.downloads.length > 0 ? (
          <Button
            variant="outline"
            colorScheme={isResourcesOpen ? 'orange' : 'blue'}
            onClick={onResourcesToggle}
            icon={isResourcesOpen ? 'RemoveCircle' : 'School'}
            isLoading={isLoading}
          >
            {isResourcesOpen ? 'Hide Resources' : 'View Resources'}
          </Button>
        ) : null}
        <Button
          variant="outline"
          colorScheme={isSessionOpen ? 'orange' : 'blue'}
          onClick={onSessionToggle}
          icon={isSessionOpen ? 'RemoveCircle' : 'PlayCircle'}
          isLoading={isLoading}
        >
          {isSessionOpen ? 'Hide Session' : 'View Session'}
        </Button>

        <Button
          onClick={onEndSession}
          icon="Cancel"
          colorScheme="red"
          isLoading={isLoading}
        >
          End Session
        </Button>
      </Stack>

      <Collapse in={isAccessLinkOpen}>
        <Box maxWidth="lg" marginX="auto" mb={8}>
          <RoomAccessCard room={room} onResetRoom={onResetRoom} />
        </Box>
      </Collapse>

      <Collapse in={isResourcesOpen}>
        <Box textAlign="center" maxWidth="lg" marginX="auto" mb={12}>
          {teacherDownloads && teacherDownloads.length > 0 ? (
            <>
              <Text fontWeight="bold" mb={4}>
                Teacher Resources
              </Text>
              <Card mb={6}>
                <Box flex={1}>
                  <DownloadsModalBody
                    downloads={teacherDownloads}
                    onClickVideo={(video: string) => {
                      setVideoForModal(video);
                    }}
                  />
                </Box>
              </Card>
            </>
          ) : null}
          {studentDownloads && studentDownloads.length > 0 ? (
            <>
              <Text fontWeight="bold" mb={4}>
                Student Resources
              </Text>
              <Card mb={6}>
                <Box flex={1}>
                  <DownloadsModalBody
                    downloads={studentDownloads}
                    onClickVideo={(video: string) => {
                      setVideoForModal(video);
                    }}
                  />
                </Box>
              </Card>
            </>
          ) : null}
        </Box>
      </Collapse>

      <Collapse in={isSessionOpen}>
        {isSessionOpen && (
          // @ts-ignore
          <SessionPreviewScreen
            session={room.session}
            location={location}
            history={history}
            asComponent
          />
        )}
      </Collapse>
      <Box maxWidth="2xl" marginX="auto">
        <CurrentlyOnline roomDevices={roomDevices} room={room} isMentor />
      </Box>
      <ModalVideo
        isOpen={Boolean(videoForModal)}
        onClose={() => setVideoForModal(null)}
        video={videoForModal || undefined}
        autoplay
      />
    </Box>
  );
};

const StudentSessionSection: React.FC<Props & { onLeaveRoom: () => void }> = (
  props
) => {
  const { room, roomDevices, history, location, onLeaveRoom } = props;
  const [videoForModal, setVideoForModal] = useState<string | null>(null);
  const { isOpen: isResourcesOpen, onToggle: onResourcesToggle } =
    useDisclosure();
  const studentDownloads = room.downloads?.filter((d) => !d.teacherOnly);
  return (
    <Box>
      <Text textAlign="center" color="text.muted">
        Today's Session:
      </Text>
      <Text
        textAlign="center"
        fontWeight="bold"
        mb={8}
        fontSize="2xl"
        mx={{ base: 'defaultMargin', md: 0 }}
      >
        {room.session?.title}
      </Text>
      {/* @ts-ignore */}
      <SessionPreviewScreen
        session={room.session}
        location={location}
        history={history}
        asComponent
      />

      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="center"
        mb={10}
        mt={8}
        mx={{ base: 'defaultMargin', md: 0 }}
      >
        {studentDownloads && studentDownloads.length > 0 ? (
          <Button
            variant="outline"
            colorScheme={isResourcesOpen ? 'orange' : 'blue'}
            onClick={onResourcesToggle}
            icon={isResourcesOpen ? 'RemoveCircle' : 'GetApp'}
          >
            {isResourcesOpen ? 'Hide Resources' : 'View Resources'}
          </Button>
        ) : null}
      </Flex>

      {studentDownloads && studentDownloads.length > 0 ? (
        <Collapse in={isResourcesOpen}>
          <Box textAlign="center" maxWidth="lg" marginX="auto">
            <Text fontWeight="bold" mb={4}>
              Resources
            </Text>
            <Card mb={6}>
              <Box flex={1}>
                <DownloadsModalBody
                  downloads={studentDownloads}
                  onClickVideo={(video: string) => {
                    setVideoForModal(video);
                  }}
                />
              </Box>
            </Card>
          </Box>
        </Collapse>
      ) : null}
      <CurrentlyOnline roomDevices={roomDevices} room={room} />
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="center"
        mt={12}
        mx={{ base: 'defaultMargin', md: 0 }}
      >
        <Button
          variant="outline"
          colorScheme="red"
          onClick={onLeaveRoom}
          icon="Logout"
        >
          Leave Room
        </Button>
      </Flex>
      <ModalVideo
        isOpen={Boolean(videoForModal)}
        onClose={() => setVideoForModal(null)}
        video={videoForModal || undefined}
        autoplay
      />
    </Box>
  );
};

const MentorWaitingSection: React.FC<
  Props & {
    onStartSession: () => Promise<void>;
    onEndSession: () => Promise<void>;
    isLoading: boolean;
  }
> = (props) => {
  const { room, roomDevices, onStartSession, onEndSession, isLoading } = props;
  const numPeopleOnline = roomDevices.reduce((acc, obj) => {
    const numStudents =
      typeof obj.numStudents === 'string'
        ? parseInt(obj.numStudents)
        : obj.numStudents;
    return acc + numStudents;
  }, 0);
  return (
    <Box maxWidth="lg" marginX="auto">
      <RoomAccessCard room={room} />
      <Box mt={5} textAlign="center">
        <Box mb={6} textAlign="center">
          {Boolean(room.studentLimit && room.studentLimit > 0) && (
            <Text color="text.muted">{`This room can be accessed by up to ${room.studentLimit} people`}</Text>
          )}
          {numPeopleOnline > 0 && (
            <Text fontWeight="semibold" color="text.muted" mt={1}>
              {`${
                numPeopleOnline === 1
                  ? '1 person is'
                  : `${numPeopleOnline} people are`
              } waiting for the session to start`}
            </Text>
          )}
        </Box>
        <Box>
          <Button
            onClick={onStartSession}
            icon="PlayCircle"
            size="lg"
            isLoading={isLoading}
          >
            Start Session
          </Button>
        </Box>
        <Box mt={3}>
          <Button
            onClick={onEndSession}
            icon="Cancel"
            colorScheme="red"
            variant="ghost"
            isLoading={isLoading}
          >
            End Session
          </Button>
        </Box>
        <CurrentlyOnline roomDevices={roomDevices} />
      </Box>
    </Box>
  );
};

const StudentWaitingSection: React.FC<Props> = (props) => {
  const { room, roomDevices } = props;
  return (
    <Box>
      <Text textAlign="center" color="text.muted">
        Today's Session:
      </Text>
      <Text
        textAlign="center"
        fontWeight="bold"
        mb={4}
        fontSize="2xl"
        mx={{ base: 'defaultMargin', md: 0 }}
      >
        {room.session?.title}
      </Text>
      <Card
        padding={{ base: 4, sm: 8 }}
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        maxWidth="lg"
        marginX="auto"
      >
        <Spinner color="icon.muted" speed="1s" mb={4} mt={1} />
        <Text color="text.muted">Waiting for session to start</Text>
      </Card>
      <CurrentlyOnline roomDevices={roomDevices} />
    </Box>
  );
};

const RoomScreen: React.FC<Props> = (props) => {
  const { currentUserId, roomId, room, history, location } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const roomSocket = useRef<WebSocket | null>(null);
  const onlineTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const disconnectedTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const reconnectInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const pingInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const storedDetails = localStorage.getItem(roomId);
  const storedDetailsJson: { roomPin: string; deviceId: string } | null =
    storedDetails ? JSON.parse(storedDetails) : null;

  const [deviceId, setDeviceId] = useState(storedDetailsJson?.deviceId || '');
  const [roomPin, setRoomPin] = useState(storedDetailsJson?.roomPin || '');

  const toast = useToast();

  const { room: roomLoading } = hooks.useLoadingDataState(
    {
      room: {
        actions: [
          () =>
            roomActions.fetchRoom(
              roomId,
              roomPin ? { queryParams: `pin=${roomPin}` } : {}
            ),
        ],
      },
    },
    [roomPin]
  );

  const { roomDevices: roomDevicesLoading } = hooks.useLoadingDataState(
    {
      room: {
        actions: !room?.id
          ? []
          : [
              () =>
                roomActions.listDevices(
                  room.id as number,
                  roomPin ? { queryParams: `pin=${roomPin}` } : {}
                ),
            ],
      },
    },
    [room, roomPin]
  );

  const isMentor = currentUserId && currentUserId === room?.mentor;
  const isLocked = !room?.session;
  const isWaiting = room?.status === 'waiting';
  const isStarted = room?.status === 'started';
  const isExpired = room?.status === 'expired';

  const stepId = getParamFromUrl(location, 'step');

  const resetRoom = () => {
    if (!isMentor) {
      dispatch({
        type: RoomAT.RESET_ROOM_FROM_SOCKET,
        payload: roomId,
      });
      if (roomSocket.current?.readyState === 1) {
        roomSocket.current?.send(
          JSON.stringify({ device_id: deviceId, is_online: false })
        );
      }
      setIsOnline(false);
      localStorage.removeItem(roomId);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  useEffect(() => {
    if (storedDetailsJson) {
      setDeviceId(storedDetailsJson.deviceId);
      setRoomPin(storedDetailsJson.roomPin);
    }
  }, [storedDetailsJson]);

  useEffect(() => {
    if (deviceId || isMentor) {
      if (isLocked) {
        if (roomSocket.current?.readyState === 1) {
          roomSocket.current.close();
        }
      } else {
        const roomUrl = `${baseUrl.replace('http', 'ws')}/ws/rooms/${roomId}/`;
        if (deviceId) {
          roomSocket.current = new WebSocket(
            `${roomUrl}?device_id=${deviceId}`
          );
        } else {
          roomSocket.current = new WebSocket(roomUrl);
        }

        const setOnlineStatus = (isOnline: boolean) => {
          if (deviceId) {
            roomSocket.current?.send(
              JSON.stringify({ device_id: deviceId, is_online: isOnline })
            );
            setIsOnline(isOnline);
          }
        };

        // "Ping Pong" method to tell when connection to server is lost
        // https://stackoverflow.com/questions/26971026/handling-connection-loss-with-websockets
        const ping = () => {
          roomSocket.current?.send(
            JSON.stringify({ device_id: deviceId, is_online: true })
          );
          onlineTimeout.current = setTimeout(() => {
            setOnlineStatus(false);
            // Attempt reconnection
            if (!reconnectInterval.current) {
              reconnectInterval.current = setInterval(ping, 5000);
              disconnectedTimeout.current = setTimeout(() => {
                // After 20 seconds of trying to reconnect, stop trying and disconnect
                setIsDisconnected(true);
                if (reconnectInterval.current) {
                  clearInterval(reconnectInterval.current);
                }
                if (pingInterval.current) {
                  clearInterval(pingInterval.current);
                }
              }, 20000);
            }
          }, 5000);
        };
        const pong = () => {
          if (onlineTimeout.current) {
            clearTimeout(onlineTimeout.current);
          }
          if (disconnectedTimeout.current) {
            clearTimeout(disconnectedTimeout.current);
          }
          // If we receive a pong while trying to reconnect,
          // stop trying and set isOnline to true
          if (reconnectInterval.current) {
            setOnlineStatus(true);
            clearInterval(reconnectInterval.current);
          }
        };

        roomSocket.current.onopen = () => {
          if (deviceId) {
            setOnlineStatus(true);
            pingInterval.current = setInterval(ping, 30000);
          }
        };
        roomSocket.current.onclose = () => setOnlineStatus(false);
        roomSocket.current.onmessage = (e) => {
          if (e.data === 'device_is_online') {
            // If the server sends a pong in response to our ping, prevent
            // the client from disconnecting
            pong();
            return;
          }
          const allData = JSON.parse(e.data);
          const { type: dataType, ...data } = allData;
          const formattedData: any = {};
          Object.keys(data).forEach((k) => {
            formattedData[camelize<string>(k)] = data[k];
          });
          if (dataType === 'room_status') {
            dispatch({
              type: RoomAT.UPDATE_ROOM_FROM_SOCKET,
              payload: {
                roomId,
                ...formattedData,
              } as Partial<Room>,
            });
          }
          if (dataType === 'room_reset') {
            resetRoom();
            if (!isMentor) {
              toast({
                title: 'Room PIN changed',
                status: 'info',
                duration: 1500,
              });
            }
          }
          if (dataType === 'room_device') {
            dispatch({
              type: RoomAT.UPDATE_ROOM_DEVICE_FROM_SOCKET,
              payload: formattedData as Partial<RoomDevice>,
            });
          }
        };
      }
    }
  }, [deviceId, isMentor, isLocked]);

  useEffect(() => {
    // When a learner session's step changes, update the device's current step
    if (deviceId && !isMentor && !isLocked && isStarted && stepId !== null) {
      dispatch(
        roomActions.updateDevice(deviceId, { currentStep: parseInt(stepId) })
      );
    }
  }, [stepId, deviceId]);

  useEffect(() => {
    // If a room pin is saved but the room is still locked after loading,
    // an incorrect pin has been saved and should be reset
    if (roomPin && !roomLoading && !isLoading && isLocked) {
      resetRoom();
    }
  }, [roomPin, roomLoading, isLoading, isLocked]);

  if (roomLoading || roomDevicesLoading) return <LoadingScreen />;

  if (!room) history.push(navRoutes.common.home.path());
  if (!room) return null;

  if (!isOnline && !isMentor && !isLocked && (isWaiting || isStarted)) {
    return (
      <Box backgroundColor="background.tint3" py={12}>
        <ScreenWrapper>
          <Flex justifyContent="center">
            <BrandLg
              onClick={() => history.push(navRoutes.common.home.path())}
              cursor="pointer"
              mb={10}
            />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            p={12}
            mt={6}
            flexDirection="column"
            maxWidth="lg"
            marginX="auto"
          >
            {isDisconnected ? (
              <>
                <MdIcon name="CloudOff" color="icon.muted" />
                <Text color="text.muted" mt={3}>
                  Lost Connection
                </Text>
              </>
            ) : (
              <>
                <Spinner color="icon.muted" />
                <Text color="text.muted" mt={3}>
                  Connecting
                </Text>
              </>
            )}
            <Divider mt={6} />
            <Flex alignItems="center" mt={4}>
              <Button
                variant="ghost"
                size="sm"
                icon="Refresh"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
              <Button
                variant="ghost"
                colorScheme="red"
                onClick={resetRoom}
                icon="Logout"
                size="sm"
                ml={1}
              >
                Leave Room
              </Button>
            </Flex>
          </Flex>
        </ScreenWrapper>
      </Box>
    );
  }

  return (
    <Box backgroundColor="background.tint3" py={12}>
      <ScreenWrapper>
        <Flex justifyContent="center">
          <BrandLg
            onClick={() => history.push(navRoutes.common.home.path())}
            cursor="pointer"
            mb={10}
          />
        </Flex>
        {isMentor ? (
          <>
            {isWaiting ? (
              // isMentor & isWaiting = screen for mentor to start session
              <MentorWaitingSection
                {...props}
                isLoading={isLoading}
                onStartSession={async () => {
                  setIsLoading(true);
                  if (room.id) {
                    await dispatch(
                      roomActions.updateRoom(room.id, { status: 'started' })
                    );
                  }
                  setIsLoading(false);
                }}
                onEndSession={async () => {
                  setIsLoading(true);
                  if (room.id) {
                    await dispatch(
                      roomActions.updateRoom(room.id, { status: 'expired' })
                    );
                    await dispatch(profileActions.fetchUserProfile());
                  }
                  setIsLoading(false);
                }}
              />
            ) : isStarted ? (
              // isMentor & isStarted = screen for mentor to monitor students
              <MonitorRoomSection
                {...props}
                isLoading={isLoading}
                onEndSession={async () => {
                  setIsLoading(true);
                  if (room.id) {
                    await dispatch(
                      roomActions.updateRoom(room.id, { status: 'expired' })
                    );
                    await dispatch(profileActions.fetchUserProfile());
                  }
                  setIsLoading(false);
                }}
                onResetRoom={async () => {
                  setIsLoading(true);
                  if (room.id) {
                    await dispatch(
                      roomActions.updateRoom(room.id, { roomPin: '' })
                    );
                  }
                  setIsLoading(false);
                }}
              />
            ) : isExpired ? (
              // isMentor & isExpired = screen for mentor to review expired room
              <Card
                padding={{ base: 4, sm: 8 }}
                flexDirection="column"
                textAlign="center"
                alignItems="center"
                maxWidth="lg"
                marginX="auto"
              >
                <Text mb={3}>Your session has ended</Text>
                <Button
                  onClick={() => history.push(navRoutes.common.home.path())}
                  icon="Logout"
                >
                  Exit Room
                </Button>
              </Card>
            ) : null}
          </>
        ) : isExpired ? (
          // !isMentor & isExpired = student is on an expired room
          <Card
            padding={{ base: 4, sm: 8 }}
            flexDirection="column"
            textAlign="center"
            alignItems="center"
            maxWidth="lg"
            marginX="auto"
          >
            <Text mb={3}>This session has ended</Text>
            <Button
              onClick={() => history.push(navRoutes.common.home.path())}
              icon="Logout"
            >
              Exit Room
            </Button>
          </Card>
        ) : isLocked ? (
          // !isMentor & !isExpired & isLocked = screen for student to input access details
          <JoinRoomCard
            room={room}
            onJoinRoom={async (pin: string) => {
              setIsLoading(true);
              await dispatch(
                roomActions.fetchRoom(roomId, {
                  queryParams: `pin=${pin}`,
                })
              );
              setIsLoading(false);
            }}
          />
        ) : isWaiting ? (
          // !isMentor & !isLocked & isWaiting = student is waiting for session to start
          <StudentWaitingSection {...props} />
        ) : isStarted ? (
          // !isMentor & !isLocked & isStarted = student is following the session
          <StudentSessionSection {...props} onLeaveRoom={resetRoom} />
        ) : null}
      </ScreenWrapper>
    </Box>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { roomId } = props.match.params;
  const { rooms, roomDevices: allRoomDevices } = state.room;
  const room = rooms[roomId];
  const onlineDevices = room
    ? Object.values(allRoomDevices).filter(
        (d) => d.room === room.id && d.isOnline
      )
    : [];
  return {
    currentUserId: state.user.userDetails.id,
    roomId,
    room,
    roomDevices: onlineDevices,
  };
};

const connector = connect(mapStateToProps);

export default connector(RoomScreen);
