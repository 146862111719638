const VideoCLipListActionTypes = {
  CMS_FETCH_VIDEOCLIP_LIST_REQUEST: 'CMS_FETCH_VIDEOCLIP_LIST_REQUEST',
  CMS_FETCH_VIDEOCLIP_LIST_SUCCESS: 'CMS_FETCH_VIDEOCLIP_LIST_SUCCESS',
  CMS_FETCH_VIDEOCLIP_LIST_FAILURE: 'CMS_FETCH_VIDEOCLIP_LIST_FAILURE',
  CMS_CREATE_VIDEOCLIP_REQUEST: 'CMS_CREATE_VIDEOCLIP_REQUEST',
  CMS_CREATE_VIDEOCLIP_SUCCESS: 'CMS_CREATE_VIDEOCLIP_SUCCESS',
  CMS_CREATE_VIDEOCLIP_FAILURE: 'CMS_CREATE_VIDEOCLIP_FAILURE',
} as const;

const VideoCLipActionTypes = {
  CMS_FETCH_VIDEOCLIP_REQUEST: 'CMS_FETCH_VIDEOCLIP_REQUEST',
  CMS_FETCH_VIDEOCLIP_SUCCESS: 'CMS_FETCH_VIDEOCLIP_SUCCESS',
  CMS_FETCH_VIDEOCLIP_FAILURE: 'CMS_FETCH_VIDEOCLIP_FAILURE',

  CMS_PATCH_VIDEOCLIP_REQUEST: 'CMS_PATCH_VIDEOCLIP_REQUEST',
  CMS_PATCH_VIDEOCLIP_SUCCESS: 'CMS_PATCH_VIDEOCLIP_SUCCESS',
  CMS_PATCH_VIDEOCLIP_FAILURE: 'CMS_PATCH_VIDEOCLIP_FAILURE',

  CMS_DELETE_VIDEOCLIP_REQUEST: 'CMS_DELETE_VIDEOCLIP_REQUEST',
  CMS_DELETE_VIDEOCLIP_SUCCESS: 'CMS_DELETE_VIDEOCLIP_SUCCESS',
  CMS_DELETE_VIDEOCLIP_FAILURE: 'CMS_DELETE_VIDEOCLIP_FAILURE',
} as const;

export default {
  ...VideoCLipListActionTypes,
  ...VideoCLipActionTypes,
};
