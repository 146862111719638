import React from 'react';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import { Card, Flex, Box, Heading, Text, MdIcon, Skeleton } from '@workshop/ui';

const noop = () => null;

export interface CardListItemProps {
  body: string;
  imageUrl?: string | null;
  isLoading?: boolean;
  linkTo?: string;
  onClick?: () => void;
  subtitle?: string | null;
  title: string;
}

const CardsListItem: React.FC<CardListItemProps> = ({
  body,
  children,
  imageUrl = null,
  isLoading = false,
  linkTo,
  onClick,
  subtitle = null,
  title,
}) => {
  const card = (
    <Card
      padding="defaultPadding"
      onClick={onClick ? () => onClick() : noop}
      cursor={onClick || linkTo ? 'pointer' : 'initial'}
      _hover={{ bg: 'background.tint1' }}
    >
      <Flex flex={1} alignItems="center">
        <Flex mr={4}>
          <Box
            overflow="hidden"
            borderRadius="md"
            backgroundColor="background.tint3"
          >
            {!isLoading && imageUrl ? (
              <Flex
                backgroundImage={`url(${imageUrl})`}
                backgroundSize="cover"
                backgroundPosition="center"
                height={{ base: 'image.md', md: 'image.md' }}
                width={{ base: 'image.md', md: 'image.lg' }}
              />
            ) : (
              <Flex
                height={{ base: 'image.md', md: 'image.md' }}
                width={{ base: 'image.md', md: 'image.lg' }}
                flex="1 0"
                alignItems="center"
                justifyContent="center"
              >
                <MdIcon name="CropOriginal" color="text.muted" boxSize={8} />
              </Flex>
            )}
          </Box>
        </Flex>
        <Flex flex={1} flexDirection={{ base: 'column', md: 'row' }}>
          <Flex
            flex={1}
            flexDirection="column"
            height="100%"
            justifyContent="center"
            pr="defaultPadding"
          >
            <Skeleton
              isLoaded={!isLoading}
              loadingStyle={{ height: 4, mb: 2, width: 0.6 }}
            >
              <Heading as="h3" fontWeight="semibold" fontSize="lg">
                {title}
              </Heading>
            </Skeleton>
            <Skeleton
              isLoaded={!isLoading}
              loadingStyle={{ height: 3, width: 0.4 }}
            >
              {subtitle && (
                <Text fontSize="sm" mt={1} color="text.muted">
                  {subtitle}
                </Text>
              )}
              {body ? (
                <Text mt={3}>
                  {/* Strip out any html */}
                  {sanitizeHtml(body, { allowedTags: [] })}
                </Text>
              ) : null}
            </Skeleton>
          </Flex>

          {!isLoading && (
            <Flex
              flexDirection="row"
              alignItems="center"
              pr="defaultPadding"
              mt={{ base: 2, md: 0 }}
            >
              {children}
            </Flex>
          )}
        </Flex>
        <MdIcon
          name="KeyboardArrowRight"
          ml="defaultMargin"
          color="icon.muted"
          width={6}
          height={6}
          display={{ base: 'none', sm: 'inline' }}
        />
      </Flex>
    </Card>
  );

  return linkTo ? (
    <Link to={linkTo} style={{ display: 'block' }}>
      {card}
    </Link>
  ) : (
    card
  );
};

export default CardsListItem;
