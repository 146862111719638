import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import uniq from 'lodash/uniq';
import isEmail from 'validator/lib/isEmail';

import navRoutes from 'navigation/Routes';

import { PLATFORM_EMAIL } from 'constants/common';

import { Box, Button, Card, Flex, Text } from '@workshop/ui';
import { AlertDialogButton } from '@workshop/ui/button/src/Button';

import { GlobalState } from 'types';
import { hooks } from 'utils';
import { organisationActions } from 'redux/actions/common';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import {
  SectionTitle,
  LabelInput,
  LabelWrapper,
  LabelTextArea,
} from 'components/Common';

import RoleEditor from './RoleEditor';

interface MemberFormData {
  email: string;
  name: string;
  message: string;
  roles: number[];
}

// Routing Props
interface MatchParams {
  memberId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface EditMemberScreenProps extends OwnProps, PropsFromRedux {}

const EditMemberScreen: React.FC<EditMemberScreenProps> = ({
  currentTeam,
  currentUserId,
  history,
  location: { pathname },
  match: {
    params: { memberId },
  },
  member,
  permissions,
  roles,
}) => {
  const isInviteScreen = pathname.includes('invite');
  const [isSubmitting, setIsSubitting] = useState(false);

  const dispatch = hooks.useDispatch();

  const { teamMember: teamMemberLoading } = hooks.useLoadingDataState(
    {
      teamMember: {
        actions:
          currentTeam && !isInviteScreen
            ? [() => organisationActions.fetchTeamMember(parseInt(memberId))]
            : [],
      },
    },
    [currentTeam]
  );

  const { register, errors, getValues, reset, setValue, triggerValidation } =
    useForm<MemberFormData>({
      defaultValues: { name: '', email: '', message: '', roles: [] },
    });

  const {
    roles: userRoles,
    user: { name, email },
  } = member;

  useEffect(() => {
    /** Reset all values after props change (e.g if the user
     * navigates to another page to edit a different member) */
    reset({
      email,
      name,
      message: '',
      roles: userRoles,
    });
    register('roles');
  }, [email, name, userRoles]);

  useEffect(() => {
    register('roles');
  }, []);

  const isCurrentUser = member.user?.id === currentUserId;

  const memberName = getValues('name');
  const memberEmail = getValues('email');
  const memberRoles = getValues('roles');
  const inviteMessage = getValues('message');

  const isLoading = teamMemberLoading;

  const status = 'active';

  return (
    <ScreenWrapper>
      <SectionTitle title="Member Details" />
      {isInviteScreen ? (
        <Card flexDir="column" padding={4} maxWidth="600px">
          <LabelInput
            id="name"
            name="name"
            label="Name"
            defaultValue={name}
            error={Boolean(errors.name)}
            errorMessage="This information is required"
            isLoading={isLoading}
            registerInputRef={register({ required: true })}
            onChange={() => (Boolean(errors.name) ? triggerValidation() : null)}
          />
          <LabelInput
            id="email"
            name="email"
            label="Email"
            defaultValue={email}
            error={Boolean(errors.email)}
            errorMessage="Please enter a valid email address"
            isLoading={isLoading}
            registerInputRef={register({
              required: true,
              validate: (value) => isEmail(value),
            })}
            onChange={() =>
              Boolean(errors.email) ? triggerValidation() : null
            }
          />
          <LabelTextArea
            id="message"
            name="message"
            label="Message"
            placeholder="Send a message with your invite..."
            registerInputRef={register()}
            autoResize
            fontStyle="italic"
            isLoading={isLoading}
          />
        </Card>
      ) : (
        <Card padding={4}>
          <Flex flex={1} flexDir="column">
            <LabelWrapper label="Name">
              <Text fontWeight="semibold">{memberName}</Text>
            </LabelWrapper>
            <LabelWrapper label="Email">
              <Text>{memberEmail}</Text>
            </LabelWrapper>
          </Flex>
          <Flex flex={1} flexDir="column">
            <LabelWrapper label="Status">
              <Text
                mr={2}
                textTransform="capitalize"
                color={status === 'active' ? 'text.success' : 'text.warning'}
              >
                {status}
              </Text>
            </LabelWrapper>
            {!isCurrentUser && (
              <Box>
                <AlertDialogButton
                  alertBody={
                    member.isCohortMentor
                      ? `${memberName} may not be removed yet, as they are still a mentor for at least one cohort. Please contact support${PLATFORM_EMAIL}.`
                      : `Are you sure you wish to remove ${memberName} from your team?`
                  }
                  alertHeader="Delete Member"
                  allowSubmit={!member.isCohortMentor}
                  mr={2}
                  submitBtnColor="red"
                  submitBtnLabel="Remove Member"
                  onSubmit={async () => {
                    if (currentTeam) {
                      await dispatch(
                        organisationActions.deleteTeamMember(
                          currentTeam,
                          member.id
                        )
                      );
                    }

                    history.push(navRoutes.cms.myOrganisation.path());
                  }}
                  onCancel={() => null}
                  size="sm"
                  colorScheme="red"
                  variant="outline"
                >
                  Remove Member
                </AlertDialogButton>
              </Box>
            )}
          </Flex>
        </Card>
      )}
      <SectionTitle title="Roles" mt={8} />
      <Card display="flex" flexDir="column" padding={4} mb={8}>
        <RoleEditor
          /** TODO: Disable the editor if the member's
           *  invite status is still pending */
          isCurrentUser={isCurrentUser}
          isLoading={isLoading}
          onToggleRole={async (roles) => {
            if (isInviteScreen) {
              await setValue('roles', roles);
              triggerValidation();
              return;
            }

            dispatch(
              organisationActions.updateTeamMember(member.id, {
                roles,
              })
            );
          }}
          permissions={permissions}
          roles={roles}
          userRoles={memberRoles}
        />
      </Card>

      {isInviteScreen && (
        <Box mb={4}>
          <Button
            isDisabled={Boolean(
              !memberEmail || !memberName || errors.email || errors.name
            )}
            isLoading={isSubmitting}
            onClick={async () => {
              // Convert the list of roles into a uniq list of permissions
              // for the team member
              const memberPermissions = uniq(
                memberRoles.reduce((perms: number[], r) => {
                  return [...perms, ...roles[r].permissions];
                }, [])
              );
              setIsSubitting(true);
              await dispatch(
                organisationActions.sendMemberInvitation({
                  email: memberEmail,
                  name: memberName,
                  permissions: memberPermissions,
                  roles: memberRoles,
                  ...(inviteMessage ? { message: inviteMessage } : {}),
                })
              );
              setIsSubitting(false);
              history.push(navRoutes.cms.myOrganisation.path());
            }}
          >
            Send Invite
          </Button>
        </Box>
      )}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, ownProps: OwnProps) => {
  const {
    user: {
      userDetails: { id: currentUserId },
    },
    organisation: { currentTeam, permissions, roles },
  } = state;
  const { memberId } = ownProps.match.params;

  const member = state.organisation.currentTeamMemberList[memberId] || {
    permissions: [],
    roles: [],
    user: {},
  };

  return {
    currentTeam: currentTeam as number,
    currentUserId,
    member,
    permissions,
    roles,
  };
};

const connector = connect(mapStateToProps);

export default connector(EditMemberScreen);
