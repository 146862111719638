import * as course from './course';
import * as goalActions from './goal';
import * as journalActions from './journal';
import * as profileActions from './profile';
import * as ratingActions from './rating';
import * as workshop from './workshop';

const {
  courseProgressActions,
  courseScheduleActions,
  courseActions,
  moduleActions,
  unitActions,
  stepQuestionActions,
  cohortActions,
} = course;

const { workshopRelationshipActions, workshopActions } = workshop;

const { userCheckListActions, userLibraryActions } = profileActions;

export {
  cohortActions,
  courseActions,
  courseProgressActions,
  courseScheduleActions,
  goalActions,
  journalActions,
  moduleActions,
  unitActions,
  stepQuestionActions,
  userCheckListActions,
  userLibraryActions,
  workshopActions,
  workshopRelationshipActions,
  ratingActions,
};
