import React from 'react';
import { Text } from '@workshop/ui';

const Paragraph: typeof Text = (props) => (
  <Text marginBottom={1} {...props}>
    {props.children}
  </Text>
);

Paragraph.displayName = 'Paragraph';
export default Paragraph;
