const FetchJournalAT = {
  FETCH_JOURNAL_REQUEST: 'FETCH_JOURNAL_REQUEST',
  FETCH_JOURNAL_SUCCESS: 'FETCH_JOURNAL_SUCCESS',
  FETCH_JOURNAL_FAILURE: 'FETCH_JOURNAL_FAILURE',

  FETCH_JOURNAL_ENTRIES_REQUEST: 'FETCH_JOURNAL_ENTRIES_REQUEST',
  FETCH_JOURNAL_ENTRIES_SUCCESS: 'FETCH_JOURNAL_ENTRIES_SUCCESS',
  FETCH_JOURNAL_ENTRIES_FAILURE: 'FETCH_JOURNAL_ENTRIES_FAILURE',
} as const;

const CreateJournalEntryAT = {
  CREATE_JOURNAL_ENTRY_REQUEST: 'CREATE_JOURNAL_ENTRY_REQUEST',
  CREATE_JOURNAL_ENTRY_SUCCESS: 'CREATE_JOURNAL_ENTRY_SUCCESS',
  CREATE_JOURNAL_ENTRY_FAILURE: 'CREATE_JOURNAL_ENTRY_FAILURE',
} as const;

export default { ...FetchJournalAT, ...CreateJournalEntryAT };
