import { CohortAT } from 'redux/actionTypes/cms';
import { Action } from 'types';
import { CourseProgressState } from 'types/cms';

const initialState: CourseProgressState = {};

export const courseProgressReducer = (
  state = initialState,
  action: Action
): CourseProgressState => {
  switch (action.type) {
    case CohortAT.CMS_LIST_USER_CP_SUCCESS:
    case CohortAT.CMS_LIST_COHORT_CP_SUCCESS:
      return {
        ...state,
        ...action.payload.results.reduce(
          (acc, cp) => ({ ...acc, [cp.id.toString()]: cp }),
          {} as CourseProgressState
        ),
      };

    case CohortAT.CMS_UPDATE_CP_SUCCESS:
      const newState = Object.keys(state).reduce(
        (acc, k) => ({
          ...acc,
          [k]: k === action.payload.id.toString() ? action.payload : state[k],
        }),
        {} as CourseProgressState
      );

      return newState;

    default:
      return state;
  }
};
