import { decamelizeKeys } from 'humps';
import { CMSVideoClipListSchema, CMSVideoClipSchema } from 'redux/schemas/cms';

import { callAPIWithTeam } from 'utils';
import API from 'constants/api';
import { CLIP_NAME } from 'constants/common';

import { VideoClipAT } from 'redux/actionTypes/cms';

import {
  FetchVideoClipAction,
  PatchVideoClipAction,
  VideoClipCreateAction,
  VideoClipListAction,
  IVideoClipListItem,
  IVideoClip,
  DeleteVideoClipAction,
} from 'types/cms';

export const list = (sessionId: number, stepId?: number) => {
  return callAPIWithTeam<VideoClipListAction>({
    types: [
      VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_REQUEST,
      VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_SUCCESS,
      VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_FAILURE,
    ],
    endpoint: stepId
      ? API.cms.videoClips(sessionId, stepId)
      : API.cms.sessionVideoClips(sessionId),
    method: 'GET',
    schema: [CMSVideoClipListSchema],
    path: 'results',
  });
};

interface CreateVideoClipArgs {
  sessionId: number;
  stepId?: number;
}

export const create = (
  { sessionId, stepId }: CreateVideoClipArgs,
  data: Partial<IVideoClipListItem>,
  noToast?: boolean
) => {
  return callAPIWithTeam<VideoClipCreateAction>({
    types: [
      VideoClipAT.CMS_CREATE_VIDEOCLIP_REQUEST,
      VideoClipAT.CMS_CREATE_VIDEOCLIP_SUCCESS,
      VideoClipAT.CMS_CREATE_VIDEOCLIP_FAILURE,
    ],
    endpoint: stepId
      ? API.cms.videoClips(sessionId, stepId)
      : API.cms.sessionVideoClips(sessionId),
    method: 'POST',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSVideoClipListSchema,
    meta: {
      toast: {
        success: noToast ? false : `Successfully created ${CLIP_NAME}.`,
      },
    },
  });
};

export const retrieve = (id: number) => {
  return callAPIWithTeam<FetchVideoClipAction>({
    types: [
      VideoClipAT.CMS_FETCH_VIDEOCLIP_REQUEST,
      VideoClipAT.CMS_FETCH_VIDEOCLIP_SUCCESS,
      VideoClipAT.CMS_FETCH_VIDEOCLIP_FAILURE,
    ],
    endpoint: API.cms.videoClip(id),
    method: 'GET',
    schema: CMSVideoClipSchema,
  });
};

export const update = (id: number, data: Partial<IVideoClip> | FormData) => {
  return callAPIWithTeam<PatchVideoClipAction>({
    types: [
      VideoClipAT.CMS_PATCH_VIDEOCLIP_REQUEST,
      VideoClipAT.CMS_PATCH_VIDEOCLIP_SUCCESS,
      VideoClipAT.CMS_PATCH_VIDEOCLIP_FAILURE,
    ],
    endpoint: API.cms.videoClip(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSVideoClipSchema,
  });
};

export const remove = (id: number) => {
  return callAPIWithTeam<DeleteVideoClipAction>({
    types: [
      VideoClipAT.CMS_DELETE_VIDEOCLIP_REQUEST,
      VideoClipAT.CMS_DELETE_VIDEOCLIP_SUCCESS,
      VideoClipAT.CMS_DELETE_VIDEOCLIP_FAILURE,
    ],
    endpoint: API.cms.videoClip(id),
    method: 'DELETE',
    schema: CMSVideoClipSchema,
    meta: {
      videoClipId: id,
      toast: { success: 'Successfully deleted clip.' },
    },
  });
};
