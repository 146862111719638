import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CountUp from 'react-countup';
import { useHistory } from 'react-router';

import { ratingActions } from 'redux/actions/learner';

import {
  Textarea,
  CircularProgress,
  CircularProgressLabel,
  Progress,
  Collapse,
  Stack,
  Box,
  Text,
  MdIcon,
  Flex,
  Button,
  useTheme,
} from '@workshop/ui';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { NewPostCard } from 'components/NewPostCard';
import { StepsModal } from 'components/Common';

interface CompleteSessionPopupProps {
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  unitTitle: string;
  unitPercentageComplete: number;
  coursePercentageComplete: number;
  discourseCategoryId?: number;
  courseSlug: string;
  moduleId: number;
  moduleProgressId: number;
  tags: string[] | null;
  requiresUpload: boolean;
  isAssessment?: boolean;
}

const CompleteSessionPopup: React.FC<CompleteSessionPopupProps> = ({
  isOpen,
  onClose,
  unitTitle,
  unitPercentageComplete,
  coursePercentageComplete,
  discourseCategoryId,
  courseSlug,
  moduleId,
  moduleProgressId,
  tags,
  requiresUpload,
  isAssessment = false,
}) => {
  const [selectedFeedback, setSelectedFeedback] = useState('');
  const [feedbackDisabled, setFeedbackDisabled] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [postSuccess, setPostSuccess] = useState(false);
  const [unitPercentageValue, setUnitPercentageValue] = useState(0);
  const [coursePercentageValue, setCoursePercentageValue] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        setUnitPercentageValue(unitPercentageComplete);
        setCoursePercentageValue(coursePercentageComplete);
      });
    }, 400);
  }, [unitPercentageComplete, coursePercentageComplete]);

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const completePopupSteps = [
    {
      label: 'Progress',
      icon: <MdIcon name="TrendingUp" />,
      nextButtonText: isAssessment ? 'Finish' : 'Next',
      content: (
        <Box py={4}>
          <Flex
            borderRadius="md"
            background="background.tint3"
            padding="defaultPadding"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb={4}
          >
            <CircularProgress
              value={unitPercentageValue}
              size={14}
              mb={2}
              color="common.progress"
              trackColor="background.tint2"
              capIsRound
              sx={{
                '& > div:first-child': {
                  transitionProperty: 'width',
                },
              }}
            >
              <CircularProgressLabel
                fontSize="sm"
                color={
                  unitPercentageValue === 0 ? 'text.muted' : 'text.success'
                }
              >
                <CountUp
                  start={0}
                  end={unitPercentageValue}
                  suffix="%"
                  duration={unitPercentageValue / 100}
                />
              </CircularProgressLabel>
            </CircularProgress>
            <Text fontWeight="semibold" textAlign="center">
              {unitTitle}
            </Text>
          </Flex>
          <Progress
            value={coursePercentageValue}
            hasStripe
            isAnimated
            borderRadius="full"
            colorScheme="green"
            mb={2}
            sx={{
              '& > div:first-child': {
                transitionProperty: 'width',
              },
            }}
          />
          <Text textAlign="center" color="text.muted">
            <CountUp
              start={0}
              end={coursePercentageValue}
              prefix="Course "
              suffix="% complete"
              duration={coursePercentageValue / 100}
            />
          </Text>
        </Box>
      ),
    },
    {
      label: 'Feedback',
      icon: <MdIcon name="ThumbUp" />,
      nextButtonText: selectedFeedback ? 'Submit' : 'Skip',
      content: (
        <Box py={4}>
          <Flex
            borderRadius="md"
            background="background.tint3"
            padding={6}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb={4}
          >
            <Text textAlign="center" mb={3}>
              Did you find this session useful?
            </Text>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing="defaultPadding"
            >
              <Button
                icon="ThumbDown"
                variant="outline"
                boxShadow="none"
                colorScheme={selectedFeedback === 'yes' ? 'gray' : 'red'}
                opacity={selectedFeedback === 'yes' ? 0.5 : 1}
                onClick={() => setSelectedFeedback('no')}
                disabled={feedbackDisabled}
              >
                No
              </Button>
              <Button
                icon="ThumbUp"
                colorScheme={selectedFeedback === 'no' ? 'gray' : 'green'}
                opacity={selectedFeedback === 'no' ? 0.5 : 1}
                onClick={() => setSelectedFeedback('yes')}
                disabled={feedbackDisabled}
              >
                Yes
              </Button>
            </Stack>
          </Flex>
          <Collapse in={!!selectedFeedback} animateOpacity>
            <Textarea
              name="sessionFeedback"
              placeholder={
                selectedFeedback === 'no'
                  ? '(Optional) How do you think this session be improved?'
                  : '(Optional) What did you enjoy about this session?'
              }
              borderColor="border.muted"
              backgroundColor="background.default"
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              maxLength={1000}
              disabled={feedbackDisabled}
            />
          </Collapse>
        </Box>
      ),
    },
    {
      label: 'New Post',
      icon: <MdIcon name="PostAdd" />,
      nextButtonText: postSuccess ? 'Finish' : 'Skip',
      content: (
        <Box py={4}>
          <Flex
            borderRadius="md"
            background="background.tint3"
            padding={2}
            paddingTop={3}
            mb={4}
            flexDirection="column"
          >
            {postSuccess ? (
              <>
                <Text textAlign="center" fontWeight="semibold" mt={3} mb={1}>
                  Post Submitted 🙌
                </Text>
                <Text textAlign="center" mb={3}>
                  You can head to your profile to review your posts
                </Text>
              </>
            ) : (
              <>
                <Flex
                  flexDirection={{ base: 'column', sm: 'row' }}
                  alignItems="flex-start"
                  mb={3}
                >
                  <Box
                    justifyContent="center"
                    px={2}
                    py={1}
                    borderRadius="sm"
                    backgroundColor={
                      requiresUpload
                        ? 'background.warning'
                        : 'background.primary'
                    }
                    mr={{ base: 0, sm: 2 }}
                    mb={{ base: 2, sm: 0 }}
                  >
                    <Text
                      fontSize="xs"
                      color={requiresUpload ? 'text.warning' : 'text.primary'}
                      fontWeight="semibold"
                      textAlign="center"
                    >
                      {requiresUpload ? 'Required' : 'Optional'}
                    </Text>
                  </Box>
                  <Text color="text.muted">
                    Upload a post for this session:
                  </Text>
                </Flex>
                <NewPostCard
                  onSubmitSuccess={() => setPostSuccess(true)}
                  discourseCategoryId={discourseCategoryId}
                  fullWidth
                  buttonsPosition="bottom"
                  moduleProgressId={moduleProgressId}
                  tags={tags}
                  isTitleRequired={false}
                />
              </>
            )}
          </Flex>
        </Box>
      ),
    },
  ];
  return (
    <StepsModal
      heading="Session Complete"
      isOpen={isOpen}
      onClose={onClose}
      onCompleteStep={async (stepIndex) => {
        if (isAssessment) {
          setTimeout(() => history.push(`/assessment/${courseSlug}`), 1000);
          return;
        }
        if (stepIndex === 1 && selectedFeedback) {
          setFeedbackDisabled(true);
          await dispatch(
            ratingActions.addRating({
              model: 'module',
              id: moduleId,
              vote: selectedFeedback === 'yes' ? 'true' : 'false',
              ...(feedbackText ? { feedback: feedbackText } : {}),
            })
          );
        } else if (stepIndex === 2) {
          setTimeout(() => history.push(`/course/${courseSlug}`), 1000);
        }
      }}
      steps={isAssessment ? [completePopupSteps[0]] : completePopupSteps}
      forceHorizontalSteps
      hideStepLabels={isMobile}
      disableClose
    />
  );
};

export default CompleteSessionPopup;
