import moment from 'moment';
import React, { useState } from 'react';
import { DateRange, DateRangeProps, Range } from 'react-date-range';
import onClickOutside from 'react-onclickoutside';

import { Flex, MdIcon, Text } from '@workshop/ui';

import { buildPlaceholderString } from '../SelectCard/TimePeriodSelect';

interface DateRangeInputProps extends DateRangeProps {
  dateRange?: Range;
  size?: 'xs' | 'sm' | 'md';
}

interface DateRangeComponentProps extends DateRangeInputProps {
  onClickOutside: () => any;
}

class DateRangeComponent extends React.Component<DateRangeComponentProps, {}> {
  constructor(props: DateRangeComponentProps) {
    super(props);
    this.state = {};
  }

  handleClickOutside = () => {
    // This timeout is a hacky solution to prevent the DateRangeInput
    // from re-opening immediately after clicking on the main container
    setTimeout(this.props.onClickOutside, 100);
  };

  render() {
    const { onChange, dateRange } = this.props;
    return (
      <DateRange
        // @ts-ignore
        onChange={(r: { range1: Range }) => {
          if (!onChange) return;

          if (!moment(r.range1.startDate).isSame(r.range1.endDate)) {
            this.props.onClickOutside();
          }

          // @ts-ignore
          onChange(r);
        }}
        ranges={[dateRange || { startDate: new Date(), endDate: new Date() }]}
      />
    );
  }
}

// @ts-ignore
const WrappedDateRange = onClickOutside(DateRangeComponent);

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  dateRange,
  onChange,
  size = 'md',
}) => {
  const [showDateRange, setShowDateRange] = useState(false);

  return (
    <Flex position="relative">
      <Flex
        cursor="pointer"
        onClick={() => {
          if (!showDateRange) {
            setShowDateRange(true);
          }
        }}
        tabIndex={0}
        alignItems="center"
      >
        <Text fontSize={size}>
          {dateRange
            ? buildPlaceholderString(dateRange.startDate, dateRange.endDate)
            : 'Select date range'}
        </Text>
        <Flex pl={2} pr={2}>
          <MdIcon
            name={showDateRange ? 'ArrowDropUp' : 'ArrowDropDown'}
            color="common.primary"
          />
        </Flex>
      </Flex>
      {showDateRange && (
        <Flex position="absolute" top="10" zIndex={1} boxShadow="2xl">
          <WrappedDateRange
            // @ts-ignore
            dateRange={dateRange}
            // @ts-ignore
            onChange={onChange}
            // @ts-ignore
            onClickOutside={() => setShowDateRange(false)}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default DateRangeInput;
