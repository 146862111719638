import React from 'react';
import { animated } from 'react-spring';

import { Button, ButtonProps } from '@workshop/ui';

const AnimatedBtn = animated(Button);

interface Props extends ButtonProps {
  secondary?: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  onClick: (...args: any) => void;
}
const AnimatedButton: React.FC<Props> = ({
  secondary = false,
  isDisabled = false,
  isLoading = false,
  style = {},
  onClick,
  children,
  ...props
}) => {
  const btnProps = {
    ml: 3,
    isDisabled,
    isLoading,
    cursor: isDisabled ? 'initial' : 'pointer',
    style,
    onClick,
    ...props,
  };
  return (
    <AnimatedBtn secondary={secondary} {...btnProps}>
      {children}
    </AnimatedBtn>
  );
};

export default AnimatedButton;
