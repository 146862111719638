import moment from 'moment';
import { Category as DiscourseCategory, GroupMember } from 'discourse-js';

import { discourseUrl } from 'constants/env';
import { urlUtils } from 'utils';

import { Cohort } from 'types/common';
import { ClassActivity } from 'types/learner';

export interface DetailedGroupMember extends GroupMember {
  isMentor?: boolean;
}

interface CohortModelConstructor {
  cohort: Cohort | null;
  category: DiscourseCategory | null;
  members?: GroupMember[] | null;
}

class CohortModel {
  _cohort: Cohort | null;
  _category: DiscourseCategory | null;
  _members?: GroupMember[] | null;

  constructor({ cohort, category, members }: CohortModelConstructor) {
    this._cohort = cohort;
    this._category = category;
    this._members = members;
  }

  getCohort = () => this._cohort;

  getCategory = () => this._category;

  getCategoryUsers = () => {
    const category = this.getCategory();
    return category ? category.users : [];
  };

  getTopics = () => {
    const category = this.getCategory();

    return category
      ? category.topicList.topics.filter(
          (topic) => topic.posters[0].userId !== -1
        )
      : [];
  };

  getParticipants = () => {
    return this.getCohort()?.participants || [];
  };

  getMentors = () => {
    return this.getCohort()?.mentors || [];
  };

  getMembers = (): DetailedGroupMember[] => {
    if (!this._members?.length) return [];

    const participants = this.getParticipants();
    const mentors = this.getMentors();

    return this._members
      .filter(
        (member) =>
          participants.includes(member.username) ||
          mentors.find((mentor) => mentor.username === member.username)
      )
      .map((member) => {
        const isMentor = Boolean(
          mentors.find(({ username }) => member.username === username)
        );

        return { ...member, isMentor };
      });
  };

  getTopicOriginalPoster = (id: number) => {
    const topics = this.getTopics();
    const topic = topics.find((topic) => topic.id === id);

    if (!topic || !topic.posters?.length || topic.posters[0].userId === -1) {
      return undefined;
    }

    const users = this.getCategoryUsers();
    return users.find((user) => user.id === topic.posters[0].userId);
  };

  /**
   * Convert list of topics to ClassActivity
   * fitlering only keeping topics with images
   */
  getClassActivity = (filterImages: boolean = true): ClassActivity[] => {
    const category = this.getCategory();
    let topics = this.getTopics();

    if (!category || !topics.length) return [];

    const { users } = category;

    if (filterImages) {
      topics = topics.filter((topic) => Boolean(topic.imageUrl));
    }

    // Filter out any system messages and only return the data we need
    // to display the recent class activity. Only return the first 30
    // items.
    return topics
      .slice(0, Math.min(topics.length, 29))
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((topic) => {
        return {
          id: topic.id,
          image: urlUtils.discourseImageResizeURL(topic?.imageUrl, 'medium'),
          liked: topic.liked,
          // The 'posters' array within a topic summary details who has
          // posted on the topic. The first item in the array will always
          // be the original author.
          user: users
            .filter((user) => user.id === topic.posters[0].userId)
            .map((user) => ({
              id: user.id,
              username: user.username,
              name: user.name,
              avatar: discourseUrl.concat(
                user.avatarTemplate.replace('{size}', '48')
              ),
            }))[0],
          onPress: () => null,
          // onPress: () =>
          //   navigation.push('FeedbackTopic', {
          //     discourseTopicId: topic.id,
          //     topicTitle: 'Post Feedback',
          //     iconName: 'photo',
          //   }),
        };
      });
  };
}

export default CohortModel;
