import React, { useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import uniq from 'lodash/uniq';

import {
  Box,
  Divider,
  ListItem,
  ListIcon,
  List,
  Flex,
  Switch,
  Text,
  Skeleton,
} from '@workshop/ui';
import { RoleState, PermissionState } from 'types/common';
import { ROLES } from 'constants/organisation';

interface RoleEditorProps {
  isCurrentUser: boolean;
  isLoading?: boolean;
  onToggleRole: (roles: number[]) => void;
  permissions: PermissionState;
  roles: RoleState;
  userRoles: number[];
}

const RoleEditor: React.FC<RoleEditorProps> = ({
  isCurrentUser,
  isLoading = false,
  onToggleRole,
  permissions,
  roles,
  userRoles = [],
}) => {
  const [activeRoles, setActiveRoles] = useState<number[]>(userRoles);

  // If our array of available `userRoles` changes, update `activeRoles`
  // to reflect this change. This will happen after API updates and allows
  // us to show the 'true' value for the user roles following a response
  // from the API.
  useEffect(() => {
    setActiveRoles(userRoles);
  }, [userRoles]);

  if (isLoading) {
    return (
      <Skeleton isLoaded={!isLoading}>
        <Box p={4} />
      </Skeleton>
    );
  }

  const toggleRole = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    // When toggling a role, update the display of active/inactive roles.
    let updatedRoles = [...activeRoles];
    if (e.target.checked) {
      updatedRoles = [...activeRoles, id];
    } else {
      updatedRoles = activeRoles.filter((role) => role !== id);
    }
    setActiveRoles(updatedRoles);
    // Bubble the toggle event up through the `onToggleRole` callback.
    onToggleRole(updatedRoles);
  };

  // Set a list of unique active permissions to display based on the member's
  // active roles
  const activePermissions = uniq(
    activeRoles.reduce((perms: number[], r) => {
      return [...perms, ...roles[r].permissions];
    }, [])
  );

  // Find the role IDs for admin & owner 'special' roles
  const adminRoleId = Object.values(roles).find(
    (role) => role?.name.toLowerCase() === ROLES.admin
  )?.id;
  const ownerRoleId = Object.values(roles).find(
    (role) => role?.name.toLowerCase() === ROLES.owner
  )?.id;

  // TODO: Show in loading state if `roles` or `permissions` are empty
  return (
    <>
      {Object.values(roles).map((role) => {
        // Admin & owner roles should not be assigned through this interface.
        // If the member has an owner or admin role assigned to them, the we
        // still want to display this.
        if (
          (role.id === adminRoleId && !activeRoles.includes(adminRoleId)) ||
          (role.id === ownerRoleId && !activeRoles.includes(ownerRoleId))
        )
          return null;

        // Owner roles can only be assigned by administrators
        if (
          role.id === ownerRoleId &&
          adminRoleId &&
          !activeRoles.includes(adminRoleId) &&
          !activeRoles.includes(ownerRoleId)
        )
          return null;

        return (
          <Box key={role.id}>
            <Flex justifyContent="space-between">
              <Flex>
                <Text fontWeight="semibold">{role.name}</Text>
              </Flex>

              <Switch
                name={role?.name.toLowerCase()}
                isChecked={activeRoles.includes(role.id)}
                isDisabled={role.id === adminRoleId || role.id === ownerRoleId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  toggleRole(e, role.id)
                }
              />
            </Flex>
            <Divider my={2} />
          </Box>
        );
      })}
      <List spacing={3} css={{ columns: 2 }}>
        {activePermissions.map((permissionId) => (
          <ListItem
            key={permissionId}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {permissions[permissionId]?.name}
            <ListIcon as={MdCheck} color="green.500" />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default RoleEditor;
