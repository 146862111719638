import moment from 'moment';

import { Topic } from 'discourse-js';

import { callAPI } from 'utils';
import API from 'constants/api';
import { CONTENT_TYPES, ENTRY_TYPES } from 'constants/common';

import { JournalEntrySchema } from 'redux/schemas/learner';
import { JournalAT } from 'redux/actionTypes/learner';

import {
  CreateJournalEntryAction,
  FetchJournalAction,
  FetchJournalEntriesAction,
} from 'types/learner';

export const retrieve = () => {
  return callAPI<FetchJournalAction>({
    types: [
      JournalAT.FETCH_JOURNAL_REQUEST,
      JournalAT.FETCH_JOURNAL_SUCCESS,
      JournalAT.FETCH_JOURNAL_FAILURE,
    ],
    endpoint: `${API.learner.userJournal}?unfiltered=true`,
    method: 'GET',
    schema: JournalEntrySchema,
    path: 'entries',
  });
};

export const retrieveJournalEntries = () => {
  return callAPI<FetchJournalEntriesAction>({
    types: [
      JournalAT.FETCH_JOURNAL_ENTRIES_REQUEST,
      JournalAT.FETCH_JOURNAL_ENTRIES_SUCCESS,
      JournalAT.FETCH_JOURNAL_ENTRIES_FAILURE,
    ],
    endpoint: API.learner.userJournalEntries,
    method: 'GET',
  });
};

interface ModuleProgressJournalEntryParams {
  description: string;
  discourseTopicId?: string | number;
  imageUri?: string;
  imageFile?: File;
  ratingStrength: 1 | 2 | 3;
  title?: string;
  moduleProgress: number;
  isAssessment?: boolean;
  migratedImageUrl?: string;
  topic?: Topic;
}

export const createModuleProgressJournalEntry = ({
  description,
  discourseTopicId,
  imageUri,
  imageFile,
  ratingStrength,
  title,
  moduleProgress,
  isAssessment,
  migratedImageUrl,
  topic,
}: ModuleProgressJournalEntryParams) => {
  const data = new FormData();
  data.append('content_type', CONTENT_TYPES.MODULE_PROGRESS);
  data.append('description', description);
  data.append(
    'entry_type',
    isAssessment ? ENTRY_TYPES.COURSE_ASSESSMENT : ENTRY_TYPES.COURSE
  );
  data.append('object_id', moduleProgress.toString());
  data.append('rating', ratingStrength.toString());
  if (title) {
    data.append('title', title);
  }
  if (discourseTopicId) {
    data.append('discourse_topic_id', discourseTopicId.toString());
  }
  if (migratedImageUrl) {
    data.append('migrated_image_url', migratedImageUrl);
  }
  if (topic) {
    if (topic.categoryId) {
      data.append('discourse_category_id', topic.categoryId.toString());
    }
    data.append('slug', topic.slug);
    data.append('participant_count', topic.participantCount.toString());
    data.append('post_count', topic.postsCount.toString());
    data.append('last_posted_at', topic.lastPostedAt);
  }

  const imageData = imageFile
    ? imageFile
    : imageUri && {
        uri: imageUri,
        name: `journal_entry_${moment().toISOString()}.jpg`,
        type: 'image/jpeg',
      };

  // @ts-ignore
  imageData && data.append('image_raw', imageData);

  return callAPI<CreateJournalEntryAction>({
    types: [
      JournalAT.CREATE_JOURNAL_ENTRY_REQUEST,
      JournalAT.CREATE_JOURNAL_ENTRY_SUCCESS,
      JournalAT.CREATE_JOURNAL_ENTRY_FAILURE,
    ],
    body: data,
    endpoint: API.learner.userJournalEntry(),
    method: 'POST',
  });
};
