const StepListActionTypes = {
  CMS_FETCH_STEP_LIST_REQUEST: 'CMS_FETCH_STEP_LIST_REQUEST',
  CMS_FETCH_STEP_LIST_SUCCESS: 'CMS_FETCH_STEP_LIST_SUCCESS',
  CMS_FETCH_STEP_LIST_FAILURE: 'CMS_FETCH_STEP_LIST_FAILURE',
  CMS_CREATE_STEP_REQUEST: 'CMS_CREATE_STEP_REQUEST',
  CMS_CREATE_STEP_SUCCESS: 'CMS_CREATE_STEP_SUCCESS',
  CMS_CREATE_STEP_FAILURE: 'CMS_CREATE_STEP_FAILURE',
} as const;

const StepActionTypes = {
  CMS_FETCH_STEP_REQUEST: 'CMS_FETCH_STEP_REQUEST',
  CMS_FETCH_STEP_SUCCESS: 'CMS_FETCH_STEP_SUCCESS',
  CMS_FETCH_STEP_FAILURE: 'CMS_FETCH_STEP_FAILURE',

  CMS_PATCH_STEP_REQUEST: 'CMS_PATCH_STEP_REQUEST',
  CMS_PATCH_STEP_SUCCESS: 'CMS_PATCH_STEP_SUCCESS',
  CMS_PATCH_STEP_FAILURE: 'CMS_PATCH_STEP_FAILURE',

  CMS_DELETE_STEP_REQUEST: 'CMS_DELETE_STEP_REQUEST',
  CMS_DELETE_STEP_SUCCESS: 'CMS_DELETE_STEP_SUCCESS',
  CMS_DELETE_STEP_FAILURE: 'CMS_DELETE_STEP_FAILURE',
} as const;

const StepQuestionAT = {
  CMS_FETCH_STEP_MCQ_LIST_REQUEST: 'CMS_FETCH_STEP_MCQ_LIST_REQUEST',
  CMS_FETCH_STEP_MCQ_LIST_SUCCESS: 'CMS_FETCH_STEP_MCQ_LIST_SUCCESS',
  CMS_FETCH_STEP_MCQ_LIST_FAILURE: 'CMS_FETCH_STEP_MCQ_LIST_FAILURE',

  CMS_CREATE_STEP_MCQ_REQUEST: 'CMS_CREATE_STEP_MCQ_REQUEST',
  CMS_CREATE_STEP_MCQ_SUCCESS: 'CMS_CREATE_STEP_MCQ_SUCCESS',
  CMS_CREATE_STEP_MCQ_FAILURE: 'CMS_CREATE_STEP_MCQ_FAILURE',

  CMS_UPDATE_STEP_MCQ_REQUEST: 'CMS_UPDATE_STEP_MCQ_REQUEST',
  CMS_UPDATE_STEP_MCQ_SUCCESS: 'CMS_UPDATE_STEP_MCQ_SUCCESS',
  CMS_UPDATE_STEP_MCQ_FAILURE: 'CMS_UPDATE_STEP_MCQ_FAILURE',
} as const;

const StepPromptAT = {
  CMS_CREATE_PROMPT_REQUEST: 'CMS_CREATE_PROMPT_REQUEST',
  CMS_CREATE_PROMPT_SUCCESS: 'CMS_CREATE_PROMPT_SUCCESS',
  CMS_CREATE_PROMPT_FAILURE: 'CMS_CREATE_PROMPT_FAILURE',
} as const;

export default {
  ...StepListActionTypes,
  ...StepActionTypes,
  ...StepQuestionAT,
  ...StepPromptAT,
};
