import React from 'react';
import AppleLogin from 'react-apple-login';
import { FaApple } from 'react-icons/fa';

import { Button } from '@workshop/ui';

export type AppleResponse =
  | {
      authorization: {
        /**
         * This is our auth code which we can treat as the access token
         */
        code: string;
        /**
         *  JWT token to verify the user
         */
        id_token: string;
      };
      /**
       * User details only shown the first time the user signs in
       */
      user?: {
        email: string;
        name: {
          firstName: string;
          lastName: string;
        };
      };
    }
  | { code: string }
  | { error: { error: string } };

interface Props {
  isLoading?: boolean;
  isDisabled?: boolean;
  /**
   * Callback to run after receiving response from Apple
   */
  callback: (response: AppleResponse) => void;
  /**
   * Optional error handler to process errors from Apple
   */
  onError?: (error: { error: { error: string } }) => void;
  /**
   * The children/button which is used to initiate a login request
   */
  children?: (props: {
    onClick: () => void;
    isLoading: boolean;
    isDisabled: boolean;
  }) => JSX.Element;
}
const clientId: string = process.env.REACT_APP_APPLE_CLIENT_ID || '';

const redirectUri: string = process.env.REACT_APP_APPLE_REDIRECT_URI || '';

const AppleButton: React.FC<Props> = ({
  isLoading = false,
  isDisabled = false,
  onError,
  callback,
  children,
}) => (
  <AppleLogin
    clientId={clientId}
    redirectURI={redirectUri}
    responseMode="form_post"
    scope="name email"
    callback={(d: AppleResponse) =>
      // If we've received an error and `onError` is defined
      'error' in d && onError ? onError(d) : callback(d)
    }
    responseType="code id_token"
    // Use the Apple login SDK rather than redirect auth flow and does not rely
    // on us intercepting a redirect to fulfill the request
    usePopup={true}
    render={(appleProps) =>
      children ? (
        // Custom button
        children({
          onClick: appleProps.onClick,
          isLoading,
          isDisabled: appleProps.disabled || isDisabled,
        })
      ) : (
        // Default button
        <Button
          onClick={appleProps.onClick}
          color="white"
          bgColor="black"
          _hover={{
            bgColor: 'black',
          }}
          width="100%"
          isLoading={isLoading}
          isDisabled={appleProps.disabled || isDisabled}
          leftIcon={<FaApple />}
        >
          Sign in with Apple
        </Button>
      )
    }
  />
);

export default AppleButton;
