import styled, { CreateStyled } from '@emotion/styled';
import Theme from './theme';

export { default as Palette } from './palette';
export { default as Color } from './color';
export { default as ColorDark } from './colorDark';

export { default as SteppitPalette } from './steppit/palette';
export { default as SteppitColor } from './steppit/color';
export { default as SteppitColorDark } from './steppit/colorDark';

export { default as Size } from './size';
export { default as Spacing } from './spacing';
export { default as Theme, DarkTheme } from './theme';

// Our non-chakra components
export { Paragraph } from './typography';
export { AlertDialogIcon, MdIcon } from './icon';
export { AnimatedView, Card } from './layout';

export type { MdIconProps } from './icon';

// Our chakra components
export * from '@chakra-ui/react';
export { Step, Steps, useSteps } from 'chakra-ui-steps';

// Our chakra overrides
export { Skeleton } from './skeleton';
export { Button, AlertDialogButton, SwitchButton } from './button';
export { LinkButton } from './linkButton';

export type { ButtonProps } from './button';

type ThemeType = typeof Theme;

// Our emotion overrides
// export default styled as CreateStyled<ThemeType>;
export default styled;

// Our TS overrides
declare module '@workshop/ui' {
  // Add override to `useTheme` from chakra to include our custom theme
  function useTheme(): ThemeType;
}
