import React, { useState, useEffect } from 'react';
import { Message } from 'react-hook-form';
import ResizeTextarea from 'react-textarea-autosize';

import { Box, Textarea, FormLabelProps, TextareaProps } from '@workshop/ui';
import { IconTooltip, Tooltip } from 'components/IconTooltip';

import LabelWrapper, { AiButtonProps } from './LabelWrapper';

export interface LabelTextAreaProps extends TextareaProps {
  id: string;
  isDisabled?: boolean;
  defaultValue?: string;
  error?: boolean;
  errorMessage?: Message;
  helpText?: string;
  isLoading?: boolean;
  label: string;
  name: string;
  registerInputRef?: RegisterFunc<HTMLTextAreaElement>;
  unit?: string;
  labelPosition?: 'top' | 'inline';
  labelStyleProps?: FormLabelProps;
  tooltip?: Tooltip;
  maxLength?: number;
  autoResize?: boolean;
  noMargin?: boolean;
  focusedMinH?: 'xs' | 'sm' | 'md';
  aiButton?: AiButtonProps;
}

const LabelTextArea: React.FC<LabelTextAreaProps> = ({
  id,
  isDisabled = false,
  defaultValue = '',
  error = false,
  errorMessage,
  helpText,
  isLoading = false,
  label,
  name,
  registerInputRef,
  unit,
  labelPosition = 'inline',
  labelStyleProps = {},
  tooltip,
  maxLength = 1000,
  autoResize = false,
  noMargin = false,
  placeholder,
  focusedMinH,
  aiButton,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);
  const [placeholderOpacity, setPlaceholderOpacity] = useState(1);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (placeholder !== currentPlaceholder) {
      setPlaceholderOpacity(0);
      const timeout = setTimeout(() => {
        setCurrentPlaceholder(placeholder);
        setPlaceholderOpacity(1);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [placeholder]);

  return (
    <LabelWrapper
      labelPosition={labelPosition}
      labelStyleProps={labelStyleProps}
      errorMessage={errorMessage}
      helpText={helpText}
      inputId={id}
      isInvalid={error}
      isLoading={isLoading}
      label={label}
      labelAlign="flex-start"
      unit={unit}
      noMargin={noMargin}
      aiButton={aiButton}
      setIsFocused={setIsFocused}
      isFocused={isFocused}
    >
      <Box position="relative">
        {/* @ts-ignore */}
        <Textarea
          id={id}
          ref={registerInputRef}
          isDisabled={isDisabled}
          name={name}
          maxLength={maxLength}
          placeholder={currentPlaceholder}
          _placeholder={{
            opacity: placeholderOpacity,
            transition: 'opacity 0.5s',
          }}
          onFocus={(e) => {
            onFocus && onFocus(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            onBlur && onBlur(e);
            setIsFocused(false);
          }}
          {...(defaultValue ? { defaultValue } : {})}
          {...(autoResize
            ? {
                as: ResizeTextarea,
                ...(focusedMinH
                  ? {
                      minH: isFocused ? `image.${focusedMinH}` : 0,
                      transition: 'min-height 0.3s',
                    }
                  : {
                      minH: 'unset',
                    }),
                overflow: 'hidden',
                resize: 'none',
                minRows: 1,
              }
            : {})}
          {...rest}
        />
        {tooltip && (
          <Box position="absolute" bottom={2} right={2} zIndex={1}>
            <IconTooltip tooltip={tooltip} />
          </Box>
        )}
      </Box>
    </LabelWrapper>
  );
};

export default LabelTextArea;
