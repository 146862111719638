import moment from 'moment';
import { ProfileAT } from 'redux/actionTypes/common';
import { Action } from 'types';
import { UserProfileState, UserUIState, DeviceInfo } from 'types/common';
import { DiscourseAT } from 'redux/actionTypes/common';

const initialState: UserProfileState = {
  userDetails: {
    id: 0,
    dismissedInformationCards: [],
    isImpersonated: false,
    discourseApiKey: '',
    discourseUserApiKey: '',
    email: '',
    hasUsablePassword: false,
    hasUnusedCredit: false,
    isMentor: false,
    isStaff: false,
    mentorProfile: null,
    name: '',
    numCredits: 0,
    profile: 0,
    referralCode: '',
    unverifiedEmail: null,
    username: '',
    userType: 0,
  },
  userProfile: {
    completedChallengeCount: 0,
    country: '',
    dateOfBirth: '',
    deviceInfo: 0,
    journalPostCount: 0,
    lastOpenedApp: '',
    preferences: [],
    activeAssessment: null,
    activeRooms: [],
  },
  userCoupons: [],
  userSubscriptions: [],
  userPaymentSettings: [],
  deviceInfo: {
    id: 0,
    created: '',
    modified: '',
    uniqueId: '',
    deviceBrand: '',
    deviceManufacturer: '',
    deviceId: '',
    systemVersion: '',
    appVersion: '',
    appBuildNumber: '',
    deviceCountry: '',
    deviceLocale: '',
    deviceTimezone: '',
    deviceIsEmulator: false,
    deviceIsTablet: false,
    deviceHasNotch: false,
  },
  discourseUser: {
    userBadges: [],
    badges: [],
    badgeTypes: [],
    users: [],
    // @ts-ignore
    user: null,
    // @ts-ignore
    userOption: null,
  },
};

export const userProfileReducer = (
  state = initialState,
  action: Action
): UserProfileState => {
  switch (action.type) {
    case ProfileAT.UPDATE_USER_PROFILE_SUCCESS:
    case ProfileAT.FETCH_USER_PROFILE_SUCCESS:
    case ProfileAT.CANCEL_EMAIL_CHANGE_SUCCESS:
      const { entities, result } = action.payload;

      const userDetails = entities.userDetails[result];
      const userProfile = entities.userProfile[userDetails.profile];
      const deviceInfo =
        entities?.deviceInfo?.[userProfile.deviceInfo] || ({} as DeviceInfo);

      return {
        ...state,
        deviceInfo,
        userDetails,
        userProfile,
      };
    case DiscourseAT.FETCH_USER_SUCCESS:
      return { ...state, discourseUser: action.payload };
    case ProfileAT.FETCH_USER_COUPONS_SUCCESS: {
      const {
        payload: { results },
      } = action;
      // We replace the key "type" with "couponType" to
      // avoid keyword confusion using TypeScript
      return {
        ...state,
        userCoupons: results.map((result) => ({
          ...result,
          coupon: {
            code: result.coupon.code,
            // @ts-ignore - TODO: Fix backend object keys on Coupon to avoid keyword clashing
            couponType: result.coupon.type,
          },
        })),
      };
    }
    case ProfileAT.REDEEM_COUPON_SUCCESS: {
      const {
        payload: { redeemedCoupons },
      } = action;
      const [redeemedCoupon] = redeemedCoupons;

      // Add the newly redeemed coupon to the user coupon state
      const updatedUserCoupons = [
        {
          coupon: {
            code: redeemedCoupon.code,
            couponType: redeemedCoupon.type,
          },
          redeemedAt: moment().format(),
          isRedeemed: true,
        },
        ...state.userCoupons,
      ];

      return {
        ...state,
        userCoupons: updatedUserCoupons,
      };
    }
    case ProfileAT.FETCH_USER_SUBSCRIPTIONS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        userSubscriptions: payload,
      };
    }
    case ProfileAT.FETCH_PAYMENT_SETTINGS_SUCCESS:
    case ProfileAT.UPDATE_PAYMENT_SETTINGS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        userPaymentSettings: payload,
      };
    }
  }
  return { ...state };
};

export const userUIReducer = (
  state: UserUIState = {
    loading: true,
    error: false,
    errorPayload: null,
    errorMessage: null,
  },
  action: Action
): UserUIState => {
  switch (action.type) {
    case ProfileAT.FETCH_USER_PROFILE_REQUEST:
      return { ...state, loading: true, error: false };
    case ProfileAT.FETCH_USER_PROFILE_SUCCESS:
      return { ...state, loading: false, error: false };
  }
  return { ...state };
};
