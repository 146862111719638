import moment from 'moment';

import { Cohort } from 'types/common';
import {
  Module,
  JournalEntry,
  ModuleProgress,
  ModuleState,
  UnitState,
  ModuleProgressState,
  UnitProgressState,
  UnitProgress,
  UnitSchedule,
} from 'types/learner';

import {
  ACCESS_TYPES,
  PROGRESS_STATUS,
  SESSION_TYPE,
  UNIT_TYPE,
} from 'constants/courses';

export const getCourseCompletionProgress = ({
  courseModules,
  courseUnits,
  moduleProgress,
  unitProgress,
  journalEntries,
}: {
  courseModules: ModuleState;
  courseUnits: UnitState;
  moduleProgress: ModuleProgressState;
  unitProgress: UnitProgressState;
  journalEntries: JournalEntry[];
}) => {
  // Session completion section
  const allSessions = Object.values(courseModules).filter(
    (module) =>
      module.moduleType === SESSION_TYPE.normal &&
      courseUnits[module.unit]?.unitType !== UNIT_TYPE.assessment
  );

  const incompleteSessions = allSessions.filter(
    (module) => moduleProgress[module.slug]?.status !== PROGRESS_STATUS.complete
  );
  const hasSessions = allSessions.length > 0 ? 1 : 0;
  const sessionsProgress = hasSessions
    ? (allSessions.length - incompleteSessions.length) / allSessions.length
    : 0;

  // Uploads section
  const sessionsRequiringUploads = allSessions.filter(
    (module) => module.requiresUpload
  );
  const sessionsWithoutUploads = sessionsRequiringUploads.filter(
    (module) =>
      !journalEntries.find(
        (je) =>
          je.destinationContentType === 'module' &&
          module.id === je.destinationObjectId
      )
  );
  const hasSessionsRequiringUploads =
    sessionsRequiringUploads.length > 0 ? 1 : 0;
  const uploadsProgress = hasSessionsRequiringUploads
    ? (sessionsRequiringUploads.length - sessionsWithoutUploads.length) /
      sessionsRequiringUploads.length
    : 0;

  // Assessments section
  const assessmentUnits = Object.values(courseUnits).filter(
    (unit) => unit.unitType === UNIT_TYPE.assessment
  );
  const incompleteAssessmentUnits = assessmentUnits.filter(
    (unit) => !unitProgress[unit.slug]?.assessmentComplete
  );
  const hasAssessments = assessmentUnits.length > 0 ? 1 : 0;
  const assessmentsProgress = hasAssessments
    ? (assessmentUnits.length - incompleteAssessmentUnits.length) /
      assessmentUnits.length
    : 0;

  const progressPercentage =
    100 *
    ((sessionsProgress + uploadsProgress + assessmentsProgress) /
      (hasSessions + hasSessionsRequiringUploads + hasAssessments));

  // Round down to 1 decimal place if progress is under 10%, otherwise round down to 0dp
  const totalProgress =
    progressPercentage < 10
      ? Math.floor(progressPercentage * 10) / 10
      : Math.floor(progressPercentage);

  return {
    sessionsProgress,
    uploadsProgress,
    assessmentsProgress,
    hasSessions,
    hasSessionsRequiringUploads,
    hasAssessments,
    numIncompleteSessions: incompleteSessions.length,
    numSessionsMissingUploads: sessionsWithoutUploads.length,
    numIncompleteAssessmentUnits: incompleteAssessmentUnits.length,
    totalProgress,
  };
};

export const getModuleCompletionStatus = ({
  module,
  uploads,
  progress,
}: {
  module: Module;
  uploads: JournalEntry[];
  progress: ModuleProgress;
}): 'uploadMissing' | 'complete' | 'incomplete' => {
  const uploadMissing =
    progress?.status === PROGRESS_STATUS.complete &&
    module.requiresUpload &&
    uploads.length === 0;

  if (uploadMissing) return 'uploadMissing';

  const isCompleteWithUpload =
    progress?.status === PROGRESS_STATUS.complete &&
    module.requiresUpload &&
    uploads.length > 0;

  const isComplete =
    isCompleteWithUpload ||
    (progress?.status === PROGRESS_STATUS.complete && !module.requiresUpload);
  if (isComplete) return 'complete';
  return 'incomplete';
};

export const getUnitLockedStatus = (
  cohort?: Cohort | null,
  progress?: UnitProgress | null,
  schedule?: UnitSchedule | null
) => {
  return cohort && cohort.accessType === ACCESS_TYPES.open
    ? false
    : progress
    ? !!(
        progress.status !== PROGRESS_STATUS.complete &&
        progress.locked &&
        schedule &&
        moment(schedule.weekCommencing).isAfter(moment())
      )
    : true;
};
