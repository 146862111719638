import { schema } from 'normalizr';

export const CategorySchema = new schema.Entity('category');

export const RoleSchema = new schema.Entity('roles');
export const PermissionSchema = new schema.Entity('permissions');
export const TeamMembershipSchema = new schema.Entity('teamMember');
export const MemberInvitationSchema = new schema.Entity('memberInvitation');
export const MessageBusNotificationSchema = new schema.Entity(
  'discourseNotifications'
);

export const DiscourseTopicSchema = new schema.Entity('discourseTopics');

export const RoomSchema = new schema.Entity(
  'room',
  {},
  { idAttribute: 'roomId' }
);
export const RoomDeviceSchema = new schema.Entity(
  'roomDevice',
  {},
  { idAttribute: 'deviceId' }
);
