import React from 'react';

import { Flex, Text, Card } from '@workshop/ui';

import navRoutes from 'navigation/Routes';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';

import Brand from 'components/Brand';

export const FallbackError = () => {
  return (
    <Card
      position="fixed"
      top="50%"
      left="50%"
      direction="column"
      transform="translate(-50%, -50%)"
      alignItems="center"
      justifyContent="center"
      bg="background.tint1"
      width="300px"
      height="200px"
    >
      <Brand navbarTransparent={false} />
      <Text my="defaultMargin">
        {'Oops, something went wrong...'}
        <span role="img" aria-label="lost">
          🧐
        </span>
      </Text>
      <Flex
        onClick={() => {
          window.location.pathname === navRoutes.common.home.path()
            ? window.location.reload()
            : window.location.replace(
                window.location.origin + navRoutes.common.home.path()
              );
        }}
        cursor="pointer"
      >
        Back to &nbsp;<Text color="text.selected">{PLATFORM_DISPLAY_NAME}</Text>
      </Flex>
    </Card>
  );
};
