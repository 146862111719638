import React, { ReactNode } from 'react';
import Select, {
  Props as SelectProps,
  OptionProps,
  components,
  OptionTypeBase,
} from 'react-select';

import { Flex, Text, Checkbox, useTheme } from '@workshop/ui';

interface MultiValueGenericProps {
  children: ReactNode;
  data: OptionTypeBase;
  innerProps: { className?: string };
  selectProps: any;
}

// Removes react-select's default delete button
const MultiValueRemoveItem: React.FC<any> = (props) => null;

const MultiValueLabelItem: React.FC<MultiValueGenericProps> = (props) => {
  const { selectProps, data } = props;
  const name = selectProps.value[0].label;
  const total = selectProps.value.length;

  if (data !== selectProps.value[0]) return <></>;

  return (
    <Flex flexWrap="wrap">
      <Text fontWeight="semibold">{`${name}${'\u00A0'}`}</Text>
      <Text>{total > 1 && `and ${total - 1} more`}</Text>
    </Flex>
  );
};

interface MultiSelectOptionItem<
  IOptions extends OptionTypeBase,
  IsMulti extends boolean = true
> extends OptionProps<IOptions, IsMulti> {}

function OptionItem<IOptions extends OptionTypeBase>(
  props: MultiSelectOptionItem<IOptions, true>
) {
  const { selectProps, data } = props;
  const { value } = selectProps;

  const itemIsChecked = value
    ? !!value.find((v: any) => v.value === data.value)
    : false;

  return (
    <Flex
      cursor="pointer"
      padding={2}
      onClick={() => props.selectOption(props.data)}
    >
      <Flex flex={2}>
        <components.Option {...props} />
      </Flex>
      <Flex flex={1} justifyContent="flex-end">
        <Checkbox isChecked={itemIsChecked} />
      </Flex>
    </Flex>
  );
}

interface MultiSelectProps<
  IOptions extends OptionTypeBase,
  IsMulti extends boolean = true
> extends SelectProps<IOptions, IsMulti> {
  condensedValues?: boolean;
}

function MultiSelect<IOptions extends OptionTypeBase>(
  props: MultiSelectProps<IOptions, true>
) {
  const theme = useTheme();
  return (
    <Select
      closeMenuOnSelect={false}
      components={{
        Option: OptionItem,
        ...(props.condensedValues
          ? {
              MultiValueLabel: MultiValueLabelItem,
              MultiValueRemove: MultiValueRemoveItem,
            }
          : {}),
      }}
      styles={{
        multiValue: (base) =>
          props.condensedValues
            ? {}
            : {
                ...base,
                backgroundColor: theme.colors.background.primary,
                color: theme.colors.text.primary,
              },
        multiValueLabel: (base) =>
          props.condensedValues
            ? {}
            : {
                ...base,
                color: theme.colors.text.primary,
              },
        container: (base) => ({
          ...base,
          width: '100%',
        }),
        placeholder: (base) => ({
          color: theme.colors.text.primary,
        }),
        option: (base) => ({}), // Enables us to override styling
        control: (base) => ({
          ...base,
          cursor: 'pointer',
          backgroundColor: theme.colors.background.default,
          borderColor: theme.colors.border.muted,
          borderRadius: theme.radii.md,
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: theme.colors.background.default,
        }),
      }}
      isMulti={true}
      multiValueRemove={false}
      hideSelectedOptions={false}
      {...props}
    />
  );
}

export default MultiSelect;
