/**
 * This is a placeholder file
 */

import React from 'react';
import styled, { Flex, Text } from '@workshop/ui';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnderConstruction = () => {
  return (
    <ScreenWrapper>
      <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
        <Text fontWeight="bold" fontSize="md">
          This screen is under construction
        </Text>
      </Flex>
    </ScreenWrapper>
  );
};

export default UnderConstruction;
