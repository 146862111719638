import React from 'react';
import { animated } from 'react-spring';
import { MdIcon, MdIconProps } from '@workshop/ui';

const AnimatedMdIcon = animated(MdIcon);

interface Props extends MdIconProps {
  isDisabled?: boolean;
  style?: any;
  onClick?: (...args: any) => void;
}
const AnimatedButton: React.FC<Props> = ({
  isDisabled = false,
  onClick,
  style = {},
  ...props
}) => {
  const btnProps = {
    isDisabled,
    cursor: onClick && !isDisabled ? 'pointer' : 'initial',
    style,
    onClick,
    ...props,
  };
  return <AnimatedMdIcon {...btnProps} />;
};

export default AnimatedButton;
