import { camelizeKeys } from 'humps';

import { Dispatch } from 'types';

import { discourseUrl } from 'constants/env';
import MessageBus, {
  NotificationData,
  MessageData,
  LatestTopicMessageData,
} from './messageBus';

import { discourseActions } from 'redux/actions/common';

export type {
  NotificationData,
  LatestTopicMessageData,
  MessageData,
} from './messageBus';

const courseChannels = {
  latest: '/latest',
};

const defaultHandleMessageBusPoll = ({
  dispatch,
  key,
  data,
  params,
}: {
  dispatch: Dispatch;
  key: string;
  data: MessageData;
  params?: { [key: string]: string | number };
}) => {
  if (key === 'notification') {
    // If the message bus receives a notification, process it and add
    // it to our notification state directly.
    dispatch(
      discourseActions.handlePollNotification(
        camelizeKeys<NotificationData>(data)
      )
    );
  } else if (key === 'latest') {
    // When the message bus indicates a change to the 'latest' subscription
    // for the current user, we check to see if this change is related to
    // the currently open course, and if so we retrieve the category/subcategory
    // data from discourse again
    const processedParams = params as {
      categoryId: number;
      subCategoryId: number;
    };
    // Cameliza data
    const processedData = camelizeKeys<LatestTopicMessageData>(data);

    const {
      payload: { categoryId },
    } = processedData;

    if (
      categoryId === processedParams.categoryId ||
      categoryId === processedParams.subCategoryId
    ) {
      dispatch(
        discourseActions.getSubcategory(
          processedParams.categoryId,
          processedParams.subCategoryId,
          true
        )
      );
    }
  }
};

const messageBus = {
  __bus: MessageBus,

  init: (userApiKey: string, discourseUserId: number, dispatch: Dispatch) => {
    MessageBus.configure({
      baseUrl: `${discourseUrl}/`,
      userApiKey: userApiKey,
      enableChunkedEncoding: false,
    });

    MessageBus.start();
    // Subscribe to all default channels
    (
      Object.keys(MessageBus.initChannels) as Array<
        keyof typeof MessageBus.initChannels
      >
    ).forEach((key) => {
      MessageBus.subscribe(
        MessageBus.initChannels[key].replace(
          '{userId}',
          discourseUserId.toString()
        ),
        (data: MessageData) =>
          defaultHandleMessageBusPoll({ key, data, dispatch }),
        -1
      );
    });
  },

  subscribeToCourseChanges: (
    categoryId: number,
    subCategoryId: number,
    dispatch: Dispatch
  ) => {
    // Only continue if the message bus is already started - here we only support
    // subscribing to the messagebus
    if (!MessageBus.started) return;

    // Unsubscribe from any active subscriptions first to ensure we're only loading
    // data for the current course
    messageBus.unsubscribeFromCourseChanges();

    // Loop through all relevant course related channels and update the subscription
    // receivers to correctly process data for the given course/cohort as indicated
    // through the category & subcategory IDs
    (Object.keys(courseChannels) as Array<keyof typeof courseChannels>).forEach(
      (key) =>
        MessageBus.subscribe(
          courseChannels[key],
          (data: MessageData) =>
            defaultHandleMessageBusPoll({
              key,
              data,
              params: { categoryId, subCategoryId },
              dispatch,
            }),
          -1
        )
    );
  },

  unsubscribeFromCourseChanges: () => {
    // Only continue if the message bus is already started
    if (!MessageBus.started) return;

    // Unsubscribe from each of the course related channels
    (Object.keys(courseChannels) as Array<keyof typeof courseChannels>).forEach(
      (key) => MessageBus.unsubscribe(courseChannels[key])
    );
  },

  subscribeToTopicChanges: (
    topicId: number,
    callback: (data?: { type: string; id: number }) => void
  ) => {
    // @ts-ignore
    MessageBus.subscribe(`/topic/${topicId}`, callback, -1);
  },

  unsubscribeFromTopicChanges: (topicId: number) => {
    MessageBus.unsubscribe(`/topic/${topicId}`);
  },
};

export default messageBus;
