import { Action } from 'types';
import { UserGoal, WeeklyGoalProgressState } from 'types/learner';
import { GoalAT } from 'redux/actionTypes/learner';

const GoalProgressInitialState: WeeklyGoalProgressState = {
  currentGoal: {} as UserGoal,
  progress: [],
};

export const goalsReducer = (
  state: WeeklyGoalProgressState = GoalProgressInitialState,
  action: Action
): WeeklyGoalProgressState => {
  switch (action.type) {
    case GoalAT.FETCH_GOAL_PROGRESS_REQUEST:
      return state;
    case GoalAT.FETCH_GOAL_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: action.payload.results,
      };
    case GoalAT.FETCH_GOAL_PROGRESS_FAILURE:
      return state;
    default:
      return state;
  }
};
