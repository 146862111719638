import { AssessmentAT, UnitAT, SessionAT } from 'redux/actionTypes/cms';

import { Action } from 'types';
import {
  UnitListState,
  UnitDetailState,
  UnitUIState,
  IUnitListItem,
  IUnit,
  UnitSummaryState,
} from 'types/cms';

const initialState: UnitListState = {};

export const unitListReducer = (
  state = initialState,
  action: Action
): UnitListState => {
  switch (action.type) {
    case UnitAT.CMS_CREATE_UNIT_REQUEST:
    case UnitAT.CMS_FETCH_UNIT_LIST_REQUEST:
      return state;
    case UnitAT.CMS_CREATE_UNIT_SUCCESS:
    case UnitAT.CMS_FETCH_UNIT_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.units,
      };
    case UnitAT.CMS_CREATE_UNIT_FAILURE:
    case UnitAT.CMS_FETCH_UNIT_LIST_FAILURE:
      return state;
    case UnitAT.CMS_FETCH_UNIT_SUCCESS:
    case UnitAT.CMS_PATCH_UNIT_SUCCESS: {
      // When we get or update a unit, we find that unit in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const unit = action.payload.entities.unit[idString];

      // If we don't have a list representation of the unit yet
      // then do nothing
      if (!state[idString] || Object.keys(state).length === 0) return state;

      const item = Object.keys(state[idString]).reduce((acc, k: string) => {
        const key = k as keyof IUnitListItem;
        const value = unit[key] || state[idString][key];

        // @ts-ignore
        acc[key] = value;

        return acc;
      }, {} as IUnitListItem);

      return {
        ...state,
        [idString]: item,
      };
    }
    case UnitAT.CMS_DELETE_UNIT_SUCCESS: {
      let unitId = action.meta.unitId as string;
      if (typeof unitId === 'boolean') return state;

      let { [unitId]: deletedUnit, ...rest } = state;

      // When removing a unit, we need to make sure that the index of any
      // units with a higher index than the deleted one are decremented by 1
      return Object.values(rest).reduce(
        (acc: UnitListState, unit: IUnit | IUnitListItem) => {
          if (
            unit.course === deletedUnit.course &&
            unit.index > deletedUnit.index
          ) {
            acc[unit.id] = {
              ...unit,
              index: unit.index - 1,
            };
          } else {
            acc[unit.id] = unit;
          }
          return acc;
        },
        {}
      );
    }
    case SessionAT.CMS_CREATE_SESSION_SUCCESS:
      const createdSession =
        action.payload.entities.sessions[action.payload.result];
      const unitId = createdSession?.unit;
      const createdModuleId = createdSession?.id;
      const unit = state[unitId];
      return {
        ...state,
        [unit.id]: {
          ...unit,
          modules: [...unit.modules, createdModuleId],
        },
      };
    case SessionAT.CMS_PATCH_SESSION_SUCCESS:
      const updatedSession =
        action.payload.entities.session[action.payload.result];
      const updatedUnitId = updatedSession?.unit;
      const updatedModuleId = updatedSession?.id;
      const updatedUnit = state[updatedUnitId];

      if (
        !updatedUnit ||
        (updatedUnit && updatedUnit.modules.includes(updatedModuleId))
      ) {
        return state;
      }
      const newUnit = {
        ...updatedUnit,
        modules: [...updatedUnit.modules, updatedModuleId],
      };
      const prevUnit = Object.values(state).find((u) =>
        u.modules.includes(updatedModuleId)
      );
      if (prevUnit) {
        const prevUnitModules = [...prevUnit.modules];
        const moduleIndex = prevUnitModules.indexOf(updatedModuleId);
        prevUnitModules.splice(moduleIndex, 1);
        const newPrevUnit = { ...prevUnit, modules: prevUnitModules };
        return {
          ...state,
          [newUnit.id]: newUnit,
          [prevUnit.id]: newPrevUnit,
        };
      }
      return {
        ...state,
        [newUnit.id]: newUnit,
      };
    case SessionAT.CMS_DELETE_SESSION_SUCCESS: {
      const deletedSessionId = action.meta.sessionId as string;
      const deletedFromUnit = Object.values(state).find((u) =>
        u.modules.includes(parseInt(deletedSessionId))
      );
      if (deletedFromUnit) {
        const deletedFromUnitModules = [...deletedFromUnit.modules];
        const deletedModuleIndex = deletedFromUnitModules.indexOf(
          parseInt(deletedSessionId)
        );
        deletedFromUnitModules.splice(deletedModuleIndex, 1);
        const newDeletedFromUnit = {
          ...deletedFromUnit,
          modules: deletedFromUnitModules,
        };
        return {
          ...state,
          [newDeletedFromUnit.id]: newDeletedFromUnit,
        };
      }
      return state;
    }
    // If not, add sessionId to list and look for unit which contains ID – remove from that list
    default:
      return state;
  }
};

const unitInitialState: UnitDetailState = {};

export const unitReducer = (
  state = unitInitialState,
  action: Action
): UnitDetailState => {
  switch (action.type) {
    case UnitAT.CMS_FETCH_UNIT_REQUEST:
      return state;
    case UnitAT.CMS_FETCH_UNIT_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.unit,
      };
    case UnitAT.CMS_FETCH_UNIT_FAILURE:
      return state;
    case UnitAT.CMS_PATCH_UNIT_REQUEST:
      return state;
    case UnitAT.CMS_PATCH_UNIT_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.unit,
      };
    case UnitAT.CMS_PATCH_UNIT_FAILURE:
      return state;
    default:
      return state;
  }
};

const unitSummaryInitialState: UnitSummaryState = {};

export const unitSummaryReducer = (
  state = unitSummaryInitialState,
  action: Action
): UnitSummaryState => {
  switch (action.type) {
    // We don't worry about updating unit summary data upon
    // `CMS_PATCH_UNIT_SUCCESS` like we do with unit list
    // data, as unit summary data is always re-retrieved before
    // use
    case AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.unitSummary,
      };
    default:
      return state;
  }
};

export const unitUIReducer = (
  state: UnitUIState = {
    unit: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
    unitList: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): UnitUIState => {
  switch (action.type) {
    case UnitAT.CMS_CREATE_UNIT_REQUEST:
    case UnitAT.CMS_FETCH_UNIT_LIST_REQUEST:
      return {
        ...state,
        unitList: {
          ...state.unitList,
          loading: !action.error,
        },
      };
    case UnitAT.CMS_CREATE_UNIT_SUCCESS:
    case UnitAT.CMS_FETCH_UNIT_LIST_SUCCESS:
    case UnitAT.CMS_CREATE_UNIT_FAILURE:
    case UnitAT.CMS_FETCH_UNIT_LIST_FAILURE:
      return {
        ...state,
        unitList: {
          ...state.unitList,
          loading: false,
        },
      };
    case UnitAT.CMS_FETCH_UNIT_REQUEST:
    case UnitAT.CMS_PATCH_UNIT_REQUEST:
    case UnitAT.CMS_DELETE_UNIT_REQUEST:
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: !action.error,
        },
      };
    case UnitAT.CMS_FETCH_UNIT_SUCCESS:
    case UnitAT.CMS_PATCH_UNIT_SUCCESS:
    case UnitAT.CMS_DELETE_UNIT_SUCCESS:
    case UnitAT.CMS_FETCH_UNIT_FAILURE:
    case UnitAT.CMS_PATCH_UNIT_FAILURE:
    case UnitAT.CMS_DELETE_UNIT_FAILURE:
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: false,
        },
      };
    default:
      return state;
  }
};
