import React, { useEffect, useState, Children } from 'react';
import { useFormContext } from 'react-hook-form';

import { Flex, FlexProps, Text, Skeleton, Spinner } from '@workshop/ui';

import { EditCard, ImageUpload, LabelWrapper } from 'components/Common';
import { OverviewFormData, OverviewUnitFormData } from 'types/cms';

type OverviewCardData = OverviewFormData | OverviewUnitFormData;

interface OverviewCardProps extends FlexProps {
  onSave: (formData: OverviewCardData) => void;
  /**
   * Optional help text to display at the bottom of the overview card
   */
  helpText?: string | null;
  onCancel?: () => void;
  title?: string;
  subtitle?: string;
  landscape?: string;
  portrait?: string;
  isUpdating?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  error?: boolean;
  onlyTitle?: boolean;
  trailer?: boolean;
  updateImage?: boolean;
  showImage?: boolean;
  imageSize?: 'sm' | 'lg';
}

interface PreviewState {
  [key: string]: string | null;
}

const initialImagePreviewState = {
  landscape: null,
  portrait: null,
};

const OverviewCard: React.FC<OverviewCardProps> = ({
  onSave,
  onCancel = () => {},
  helpText,
  title = '',
  subtitle = '',
  landscape,
  portrait,
  isUpdating = false,
  isLoading = false,
  isDisabled = false,
  onlyTitle = false,
  trailer = true,
  updateImage = true,
  children,
  showImage = true,
  imageSize = 'lg',
  ...rest
}) => {
  // TODO: Why does this use form context whereas components
  // such as `FurtherDetailsSessionCard` and `FormCard` are
  // their own forms?
  //
  // Do we want the fields of `OverviewCard` to be accessed/
  // submitted by the parent, or should it be its own form?
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState,
    reset,
    setValue,
  } = useFormContext<OverviewCardData>();

  const onSubmit = handleSubmit(async (formData) => {
    await onSave(formData);
    reset(formData);
  });

  // As we render the children components, we build an object
  // containing a copy of any default values which can be used
  // to reset any input values if the form is reset
  let defaultValues: { [key: string]: string } = {};

  const [imagePreview, setImagePreview] = useState<PreviewState>(
    initialImagePreviewState
  );
  const [loadingImage, setLoadingImage] = useState(false);

  const imagePreviewArray = Object.values(imagePreview);

  useEffect(() => {
    // Avoiding memory leaks
    return () => {
      imagePreviewArray.forEach(
        (preview) => preview && URL.revokeObjectURL(preview)
      );
    };
  }, [imagePreviewArray]);

  const onImageDrop = async (name: string, acceptedFiles: File[]) => {
    setValue(name, acceptedFiles[0]);
    // setImagePreview({
    //   ...imagePreview,
    //   [name]: URL.createObjectURL(acceptedFiles[0]),
    // });
    setLoadingImage(true);
    await onSubmit();
    setLoadingImage(false);
  };

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        setImagePreview(initialImagePreviewState);
        onCancel();
        clearError();
        reset(defaultValues);
      }}
      saveDisabled={
        isDisabled || !formState.dirty || Object.keys(errors).length > 0
      }
      isDisabled={isDisabled}
      isUpdating={isUpdating}
      flex={1}
      {...rest}
    >
      <Flex
        {...(imageSize === 'sm'
          ? {
              flexDirection: { base: 'column', md: 'row' },
              alignItems: { base: 'normal', md: 'center' },
            }
          : {
              flexDirection: 'column',
            })}
      >
        {showImage && (
          <>
            {imageSize === 'lg' ? (
              <Flex
                flexShrink={0}
                flexDirection="row"
                marginBottom="defaultMargin"
                mb={4}
              >
                <Skeleton isLoaded={!isLoading} flex={3} position="relative">
                  <ImageUpload
                    isDisabled={isDisabled}
                    id="landscape"
                    name="landscape"
                    label="Thumbnail Image"
                    textColor="text.muted"
                    textBgColor={
                      imagePreview.landscape || landscape
                        ? 'background.defaultTransparent'
                        : 'transparent'
                    }
                    backgroundColor="background.tint3"
                    height={{
                      base: 'image.xl',
                      sm: 'image.2xl',
                      md: 'image.3xl',
                    }}
                    width="100%"
                    image={imagePreview.landscape || landscape}
                    onDrop={onImageDrop}
                    backgroundSize="contain"
                    showBlur
                  />
                  {loadingImage && (
                    <Flex
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Spinner color="white" />
                    </Flex>
                  )}
                </Skeleton>
                {/* <Skeleton isLoaded={!isLoading} flex={1}>
        <ImageUpload
          isDisabled={isDisabled}
          id="portrait"
          name="portrait"
          label="Portrait Image"
          textColor="text.muted"
          textBgColor={
            imagePreview.portrait || portrait
              ? 'background.defaultTransparent'
              : 'transparent'
          }
          backgroundColor="background.tint3"
          height={{
            base: 'image.xl',
            sm: 'image.2xl',
            md: 'image.3xl',
            '2xl': 'image.2xl',
          }}
          width="100%"
          image={imagePreview.portrait || portrait}
          onDrop={onImageDrop}
          hideText={isMobile}
          backgroundSize="contain"
          showBlur
        />
      </Skeleton> */}
              </Flex>
            ) : (
              <Flex
                flexShrink={0}
                flexDirection="row"
                marginBottom="defaultMargin"
                mb={{ base: 4, md: 2 }}
                mr={{ base: 0, md: 4 }}
              >
                <Skeleton
                  isLoaded={!isLoading}
                  height={{
                    base: 'image.lg',
                    sm: 'image.xl',
                    md: 'image.xl',
                  }}
                  width={{
                    base: 'image.xl',
                    sm: 'image.3xl',
                    md: 'image.2xl',
                  }}
                  position="relative"
                >
                  <ImageUpload
                    isDisabled={isDisabled}
                    id="landscape"
                    name="landscape"
                    label="Thumbnail Image"
                    textColor="text.muted"
                    textBgColor={
                      imagePreview.landscape || landscape
                        ? 'background.defaultTransparent'
                        : 'transparent'
                    }
                    backgroundColor="background.tint3"
                    height={{
                      base: 'image.lg',
                      sm: 'image.xl',
                      md: 'image.xl',
                    }}
                    width={{
                      base: 'image.xl',
                      sm: 'image.3xl',
                      md: 'image.2xl',
                    }}
                    image={imagePreview.landscape || landscape}
                    onDrop={onImageDrop}
                    backgroundSize="contain"
                    showBlur
                  />
                  {loadingImage && (
                    <Flex
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Spinner color="white" />
                    </Flex>
                  )}
                </Skeleton>
              </Flex>
            )}
          </>
        )}
        <Flex flexDirection="column" flex={1}>
          {Children.map(children, (child) => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
              if (!defaultValues[child.props.name]) {
                defaultValues = {
                  ...defaultValues,
                  [child.props.name]: child.props.defaultValue || '',
                };
              }
              return React.cloneElement(child, { register, errors });
            }
            return child;
          })}

          {!isLoading && helpText && (
            <LabelWrapper>
              <Text maxWidth="25rem" fontSize="xs" color="text.muted">
                {helpText}
              </Text>
            </LabelWrapper>
          )}
        </Flex>
      </Flex>
    </EditCard>
  );
};

export default OverviewCard;
