import { Action } from 'types';
import { RoomAT } from 'redux/actionTypes/common';
import { RoomState, RoomDeviceState } from 'types/common';

const roomInitialState: RoomState = {};

export const roomReducer = (
  state = roomInitialState,
  action: Action
): RoomState => {
  switch (action.type) {
    case RoomAT.FETCH_ROOM_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.room,
      };
    case RoomAT.CREATE_ROOM_SUCCESS:
    case RoomAT.UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.room,
      };
    case RoomAT.UPDATE_ROOM_FROM_SOCKET:
      if (action.payload.roomId) {
        return {
          ...state,
          [action.payload.roomId]: {
            ...state[action.payload.roomId],
            ...action.payload,
          },
        };
      }
      return state;
    case RoomAT.RESET_ROOM_FROM_SOCKET:
      if (state[action.payload]) {
        return {
          ...state,
          [action.payload]: {
            roomId: state[action.payload].roomId,
            status: state[action.payload].status,
            mentor: state[action.payload].mentor,
          },
        };
      }
      return state;
    default:
      return state;
  }
};

const roomDeviceInitialState: RoomDeviceState = {};

export const roomDeviceReducer = (
  state = roomDeviceInitialState,
  action: Action
): RoomDeviceState => {
  switch (action.type) {
    case RoomAT.CREATE_ROOM_DEVICE_SUCCESS:
    case RoomAT.UPDATE_ROOM_DEVICE_SUCCESS:
      return {
        ...state,
        [action.payload.deviceId]: action.payload,
      };
    case RoomAT.FETCH_ROOM_DEVICES_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.roomDevice,
      };
    case RoomAT.UPDATE_ROOM_DEVICE_FROM_SOCKET:
      if (action.payload.deviceId) {
        return {
          ...state,
          [action.payload.deviceId]: {
            ...state[action.payload.deviceId],
            ...action.payload,
          },
        };
      }
      return state;
    default:
      return state;
  }
};
