import * as React from 'react';

import styled, { Box, Flex, Text, MdIcon } from '@workshop/ui';

interface Props {
  data: Array<RoleButtonProps>;
}

interface RoleButtonProps {
  hidden?: boolean;
  label: string;
  selected: boolean;
  onClick: () => void;
}

export const ButtonContainer = styled(Flex)`
  &:hover {
    background-color: ${(props) =>
      // FIXME
      // @ts-ignore
      props.theme.colors.neutralAlpha[100]};
  }
`;

export const RoleButton = (props: RoleButtonProps) => (
  <ButtonContainer
    backgroundColor={props.selected ? 'background.default' : ''}
    onClick={props.onClick}
    p="defaultPadding"
    cursor="pointer"
    boxShadow={props.selected ? 'lg' : ''}
    alignItems="center"
  >
    <Text
      color={props.selected ? 'common.primary' : ''}
      fontWeight={props.selected ? 'semibold' : ''}
      flex={1}
    >
      {props.label}
    </Text>
    {props.selected ? (
      <Flex alignItems="center">
        <Text
          color="common.primary"
          fontWeight="semibold"
          fontSize="2xs"
          marginRight={1}
        >
          Current View
        </Text>
        <MdIcon name="CheckCircle" color="common.primary" boxSize="icon" />
      </Flex>
    ) : (
      <MdIcon name="RadioButtonUnchecked" color="common.muted" boxSize="icon" />
    )}
  </ButtonContainer>
);

const RoleSwitcher: React.FC<Props> = (props) => {
  return (
    <Box
      border="light"
      borderRadius="md"
      overflow="hidden"
      backgroundColor="background.tint3"
      boxShadow="lg"
    >
      {props.data.map((role, key) => (
        <RoleButton
          key={`role-button-${key}`}
          label={role.label}
          selected={role.selected}
          onClick={role.onClick}
        />
      ))}
    </Box>
  );
};

export default RoleSwitcher;
