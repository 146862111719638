const CourseListAT = {
  CMS_FETCH_COURSE_LIST_REQUEST: 'CMS_FETCH_COURSE_LIST_REQUEST',
  CMS_FETCH_COURSE_LIST_SUCCESS: 'CMS_FETCH_COURSE_LIST_SUCCESS',
  CMS_FETCH_COURSE_LIST_FAILURE: 'CMS_FETCH_COURSE_LIST_FAILURE',

  CMS_CREATE_COURSE_REQUEST: 'CMS_CREATE_COURSE_REQUEST',
  CMS_CREATE_COURSE_SUCCESS: 'CMS_CREATE_COURSE_SUCCESS',
  CMS_CREATE_COURSE_FAILURE: 'CMS_CREATE_COURSE_FAILURE',
} as const;

const CourseAT = {
  CMS_FETCH_COURSE_REQUEST: 'CMS_FETCH_COURSE_REQUEST',
  CMS_FETCH_COURSE_SUCCESS: 'CMS_FETCH_COURSE_SUCCESS',
  CMS_FETCH_COURSE_FAILURE: 'CMS_FETCH_COURSE_FAILURE',

  CMS_PATCH_COURSE_REQUEST: 'CMS_PATCH_COURSE_REQUEST',
  CMS_PATCH_COURSE_SUCCESS: 'CMS_PATCH_COURSE_SUCCESS',
  CMS_PATCH_COURSE_FAILURE: 'CMS_PATCH_COURSE_FAILURE',

  CMS_DELETE_COURSE_REQUEST: 'CMS_DELETE_COURSE_REQUEST',
  CMS_DELETE_COURSE_SUCCESS: 'CMS_DELETE_COURSE_SUCCESS',
  CMS_DELETE_COURSE_FAILURE: 'CMS_DELETE_COURSE_FAILURE',
} as const;

const CourseProgressAT = {
  CMS_LIST_COURSE_PROGRESS_REQUEST: 'CMS_LIST_COURSE_PROGRESS_REQUEST',
  CMS_LIST_COURSE_PROGRESS_SUCCESS: 'CMS_LIST_COURSE_PROGRESS_SUCCESS',
  CMS_LIST_COURSE_PROGRESS_FAILURE: 'CMS_LIST_COURSE_PROGRESS_FAILURE',

  CMS_PATCH_COURSE_PROGRESS_REQUEST: 'CMS_PATCH_COURSE_PROGRESS_REQUEST',
  CMS_PATCH_COURSE_PROGRESS_SUCCESS: 'CMS_PATCH_COURSE_PROGRESS_SUCCESS',
  CMS_PATCH_COURSE_PROGRESS_FAILURE: 'CMS_PATCH_COURSE_PROGRESS_FAILURE',
} as const;

const EmailConfigAT = {
  CMS_SEND_PREVIEW_EMAIL_REQUEST: 'CMS_SEND_PREVIEW_EMAIL_REQUEST',
  CMS_SEND_PREVIEW_EMAIL_SUCCESS: 'CMS_SEND_PREVIEW_EMAIL_SUCCESS',
  CMS_SEND_PREVIEW_EMAIL_FAILURE: 'CMS_SEND_PREVIEW_EMAIL_FAILURE',
} as const;

const CohortAT = {
  CMS_COHORT_LIST_REQUEST: 'CMS_COHORT_LIST_REQUEST',
  CMS_COHORT_LIST_SUCCESS: 'CMS_COHORT_LIST_SUCCESS',
  CMS_COHORT_LIST_FAILURE: 'CMS_COHORT_LIST_FAILURE',
} as const;

export default {
  ...CourseListAT,
  ...CourseAT,
  ...CourseProgressAT,
  ...EmailConfigAT,
  ...CohortAT,
};
