import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useWindowResize, useDebouncedFn } from 'beautiful-react-hooks';

export const useDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (ref.current !== null) {
      setDimensions({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      });
    }
  }, []);

  return dimensions;
};

export const useDimensionsHandleResize = (
  ref: React.RefObject<HTMLDivElement>,
  delay: number = 500
) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // there's no need to use `useCallback` since the returned function
  // is already memoized
  const onWindowResizeHandler = useDebouncedFn(() => {
    if (ref.current) {
      setDimensions({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      });
    }
  }, delay);

  useWindowResize(onWindowResizeHandler);

  useEffect(() => {
    // Run once on mount
    onWindowResizeHandler();
    // Cancel debounced
    return () => onWindowResizeHandler.cancel();
  }, []);

  return dimensions;
};

export const useWindowDimensions = (delay: number = 250) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const updateDimensions = useDebouncedFn(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, delay);

  useWindowResize(updateDimensions);

  useEffect(() => {
    // Run once on mount
    updateDimensions();
    // Cancel debounced
    return () => updateDimensions.cancel();
  }, []);

  return dimensions;
};
