/**
 * All the possible Analytics Time Period Types in string format
 */
type AnalyticsUnit = 'day' | 'week' | 'month' | 'year';

interface TimePeriod {
  /**
   * Label the dropdown will show
   */
  label: string;
  /**
   * Value the dropdown will pass
   */
  value: AnalyticsUnit;
  /**
   * Optional boolean whether the option is disabled
   */
  isDisabled?: boolean;
}

const dataUnits: AnalyticsUnit[] = ['day', 'week', 'month', 'year'];

const timePeriodOptions: { [key in AnalyticsUnit]: TimePeriod } = {
  day: {
    label: 'Day',
    value: 'day',
  },

  week: {
    label: 'Week',
    value: 'week',
  },
  month: {
    label: 'Month',
    value: 'month',
  },
  year: {
    label: 'Year',
    value: 'year',
  },
};

const unitFormatMapping: {
  [key in AnalyticsUnit]: {
    format: string;
    next: AnalyticsUnit | null;
  };
} = {
  day: {
    format: 'Do',
    next: 'month',
  },
  week: {
    format: 'Do',
    next: 'month',
  },
  month: {
    format: 'MMM',
    next: 'year',
  },
  year: {
    format: 'YYYY',
    next: null,
  },
};

/**
 * Lower: The threshold, in days, above which the unit is displayed
 * Upper: The threshold, in days, below which the unit is displayed
 *
 * i.e. The only time we show a unit is if it's between the lower/upper
 * range or if the range is `null`
 */
const unitThresholds: {
  [key in AnalyticsUnit]: {
    upper: number | null;
    lower: number | null;
  };
} = {
  day: {
    // upper: 90,
    upper: 180,
    lower: null, // Always show `day` unit below 90 days
  },
  week: {
    upper: 12,
    lower: 2.5,
  },
  month: {
    upper: null, // Always show `month` unit above 3 months
    lower: 3,
  },
  year: {
    upper: null,
    lower: 1, // Always show `year` unit above 1 year
  },
};

const paymentOptions = {
  all: 'All',
  standard: 'Standard',
  instalment: 'Instalment',
} as const;

/**
 * All the possible Analytics Payment Types in string format
 */
type AnalyticsPaymentChoice = keyof typeof paymentOptions;

const dataTypes = {
  courseGrossRevenue: 'courseGrossRevenue',
  courseNetRevenue: 'courseNetRevenue',
  courseSales: 'courseSales',
  courseRefunds: 'courseRefunds',
  studentSatisfaction: 'studentSatisfaction',
  activeLearners: 'activeLearners',
  completionRate: 'completionRate',
  postCount: 'postCount',
  postsPerStudent: 'postsPerStudent',
  sessionsPerStudent: 'sessionsPerStudent',
} as const;

/**
 * All the possible Analytics Data Types in string format
 */
type AnalyticsDataType =
  | 'course_gross_revenue'
  | 'course_net_revenue'
  | 'course_sales'
  | 'course_refunds'
  | 'student_satisfaction'
  | 'active_learners'
  | 'completion_rate'
  | 'post_count'
  | 'posts_per_student'
  | 'sessions_per_student';

export {
  timePeriodOptions,
  unitFormatMapping,
  paymentOptions,
  unitThresholds,
  dataTypes,
  dataUnits,
};

export type {
  TimePeriod,
  AnalyticsUnit,
  AnalyticsPaymentChoice,
  AnalyticsDataType,
};
