import React from 'react';

import { ORG_IDS, ORG_LANDING_PAGES } from 'constants/organisation';

import { FlexProps } from '@workshop/ui';

import SchoolsLicensor from './SchoolsLicensor';

// We have bespoke variants for specific organisations (e.g. Leiths Academy Kitchen)

// When a variant for the provided organisation ID is available,
// this organisation's variant appears within the homepage card item
export interface HomeCardItemVariantProps extends FlexProps {
  variantId?: number;
  isCurrentTeam?: boolean;
  inviteMessage?: string;
  inviteLandingPage?: string;
  invitePageButtonLabel?: string;
}

// List of manually entered variants, where [key: organisationId] on prod
// TODO: Use backend "variant" or "orgType" field to select this – currently only applies to Leiths Academy Kitchen
const homeCardItemVariants: {
  [key: number]: React.FC<HomeCardItemVariantProps>;
} = {
  [ORG_IDS.lak]: (props) => (
    <SchoolsLicensor
      inviteMessage="Hi! Your Leiths Academy Kitchen courses are ready to go on Workshop. We've already set up your school account, and so when you're ready you can accept this invite to start creating your classes and enrolling your students."
      inviteLandingPage={ORG_LANDING_PAGES.lak}
      invitePageButtonLabel="Preview LAK Invite Landing Page"
      {...props}
    />
  ),
};

export default homeCardItemVariants;
