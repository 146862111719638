import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import navRoutes from 'navigation/Routes';

import { GlobalState } from 'types';

import { ACTIVE_ROOMS_BANNER_HEIGHT } from 'constants/ui';

import { Flex, Text, Button } from '@workshop/ui';

const ActiveRoomsBanner: React.FC<{}> = () => {
  const history = useHistory();
  const activeRooms = useSelector(
    (state: GlobalState) => state.user.userProfile.activeRooms
  );
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h={`${ACTIVE_ROOMS_BANNER_HEIGHT}px`}
      position="fixed"
      top="0"
      w="100%"
      zIndex={1200}
    >
      <Flex
        backgroundColor="background.error"
        h="100%"
        alignItems="center"
        w="100%"
        px={3}
        overflowX="scroll"
      >
        <Flex flex={1} alignItems="center" justifyContent="center">
          <Text
            fontSize="sm"
            color="text.error"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            {`You currently have ${
              activeRooms.length === 1
                ? 'a live session'
                : `${activeRooms.length} live sessions`
            } running`}
          </Text>
          <Flex ml={2} alignItems="center">
            {activeRooms.map((roomId, idx) => (
              <Button
                key={`room-button-${roomId}`}
                size="sm"
                colorScheme="red"
                ml={2}
                onClick={() => history.push(navRoutes.global.room.path(roomId))}
              >
                {`Open Room${activeRooms.length > 1 ? ` ${idx + 1}` : ''}`}
              </Button>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ActiveRoomsBanner;
