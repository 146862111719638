import * as Sentry from '@sentry/react';
import { put, takeEvery } from 'redux-saga/effects';

import { FetchUserProfileSuccess } from 'types/common';
import { discourseUrl as baseUrl, FRONT_ENV } from 'constants/env';

import { ProfileAT } from 'redux/actionTypes/common';
import { discourseActions } from 'redux/actions/common';

import Discourse from 'utils/discoureService';

function* setCurrentUserAsync(action: FetchUserProfileSuccess) {
  const {
    payload: { entities, result },
  } = action;
  const user = entities.userDetails[result];

  if (!user) return;

  const { id, email, username } = user;

  /**
   * Init Sentry identity
   */
  if (FRONT_ENV === 'production') {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: `${id}`,
        email,
      });
    });
  }

  /**
   * Init Discourse config here so we get the users apiKey and the base url.
   */
  const { discourseUserApiKey: userApiKey, username: apiUsername } = user;

  if (!userApiKey || !apiUsername) {
    if (FRONT_ENV === 'production') {
      Sentry.captureMessage(
        `Unable to initialise discourse client - ${user.id}.`
      );
    } else {
      console.error(`Unable to initialise discourse client - ${user.id}.`);
    }
  }

  // @ts-ignore
  Discourse.config({ userApiKey, apiUsername, baseUrl });

  // TOOD: Fix the below
  // @ts-ignore - `put` not working nicely with our `Action` definition yet
  yield put(discourseActions.getUser(username));

  // TODO: If we have a user, load user specific data
  // COPIED FROM NATIVE APP
  // dispatch(getUserPreferences());
  // dispatch(getUserGoal());
  // dispatch(getUserJournal());
  // dispatch(getUserGoalProgress());
}

export function* watchFetchUserProfile() {
  yield takeEvery(ProfileAT.FETCH_USER_PROFILE_SUCCESS, setCurrentUserAsync);
}
