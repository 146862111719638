import { EnrolmentAT, CohortAT } from 'redux/actionTypes/cms';
import { Action } from 'types';
import {
  LicenseListState,
  LicenseUIState,
  License,
  EnrolmentListState,
  EnrolmentUIState,
  Enrolment,
  CostRecordListState,
  CostRecordUIState,
} from 'types/cms';

const initialLicenseState: LicenseListState = {};

export const licenseListReducer = (
  state = initialLicenseState,
  action: Action
): LicenseListState => {
  switch (action.type) {
    case EnrolmentAT.CMS_CREATE_LICENSE_REQUEST:
    case EnrolmentAT.CMS_FETCH_LICENSE_LIST_REQUEST:
      return state;
    case EnrolmentAT.CMS_CREATE_LICENSE_SUCCESS:
    case EnrolmentAT.CMS_FETCH_LICENSE_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.licenses,
      };
    case EnrolmentAT.CMS_CREATE_LICENSE_FAILURE:
    case EnrolmentAT.CMS_FETCH_LICENSE_LIST_FAILURE:
      return state;

    case EnrolmentAT.CMS_FETCH_LICENSE_SUCCESS:
    case EnrolmentAT.CMS_PATCH_LICENSE_SUCCESS: {
      // When we get or update a license, we find that license in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const license = action.payload.entities.license[idString];

      // If we don't have a list representation of the license yet
      // then do nothing
      if (!state[idString] || Object.keys(state).length === 0) return state;
      // @ts-ignore
      return {
        ...state,
        [idString]: Object.keys(state[idString]).reduce(
          // @ts-ignore
          (intersection: License, key: keyof License) => {
            // @ts-ignore
            intersection[key] = license[key] || state[idString][key];
            return intersection;
          },
          {}
        ),
      };
    }
    default:
      return state;
  }
};

export const licenseUIReducer = (
  state: LicenseUIState = {
    license: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): LicenseUIState => {
  switch (action.type) {
    case EnrolmentAT.CMS_CREATE_LICENSE_REQUEST:
    case EnrolmentAT.CMS_FETCH_LICENSE_LIST_REQUEST:
      return {
        ...state,
        license: {
          ...state.license,
          loading: !action.error,
        },
      };
    case EnrolmentAT.CMS_CREATE_LICENSE_SUCCESS:
    case EnrolmentAT.CMS_FETCH_LICENSE_LIST_SUCCESS:
    case EnrolmentAT.CMS_FETCH_LICENSE_SUCCESS:
    case EnrolmentAT.CMS_PATCH_LICENSE_SUCCESS:
    case EnrolmentAT.CMS_CREATE_LICENSE_FAILURE:
    case EnrolmentAT.CMS_FETCH_LICENSE_LIST_FAILURE:
    case EnrolmentAT.CMS_FETCH_LICENSE_FAILURE:
    case EnrolmentAT.CMS_PATCH_LICENSE_FAILURE:
      return {
        ...state,
        license: {
          ...state.license,
          loading: false,
        },
      };
    case EnrolmentAT.CMS_FETCH_LICENSE_REQUEST:
    case EnrolmentAT.CMS_PATCH_LICENSE_REQUEST:
      return {
        ...state,
        license: {
          ...state.license,
          loading: !action.error,
        },
      };
    default:
      return state;
  }
};

const initialEnrolmentState: EnrolmentListState = {};

export const enrolmentListReducer = (
  state = initialEnrolmentState,
  action: Action
): EnrolmentListState => {
  switch (action.type) {
    case EnrolmentAT.CMS_CREATE_ENROLMENT_REQUEST:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_REQUEST:
      return state;
    case EnrolmentAT.CMS_CREATE_ENROLMENT_SUCCESS:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.enrolments,
      };
    case EnrolmentAT.CMS_CREATE_ENROLMENT_FAILURE:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_FAILURE:
      return state;

    case EnrolmentAT.CMS_FETCH_ENROLMENT_SUCCESS:
    case EnrolmentAT.CMS_PATCH_ENROLMENT_SUCCESS: {
      // When we get or update a enrolment, we find that enrolment in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const enrolment = action.payload.entities.enrolment[idString];

      // If we don't have a list representation of the enrolment yet
      // then do nothing
      if (!state[idString] || Object.keys(state).length === 0) return state;
      // @ts-ignore
      return {
        ...state,
        [idString]: Object.keys(state[idString]).reduce(
          // @ts-ignore
          (intersection: Enrolment, key: keyof Enrolment) => {
            // @ts-ignore
            intersection[key] = enrolment[key] || state[idString][key];
            return intersection;
          },
          {}
        ),
      };
    }
    case CohortAT.CMS_CREATE_CERTIFICATE_LOG_SUCCESS:
      if (action.payload.enrolment) {
        return {
          ...state,
          [action.payload.enrolment.id]: {
            ...state[action.payload.enrolment.id],
            ...action.payload.enrolment,
          },
        };
      }
      return state;
    default:
      return state;
  }
};

export const enrolmentUIReducer = (
  state: EnrolmentUIState = {
    enrolment: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): EnrolmentUIState => {
  switch (action.type) {
    case EnrolmentAT.CMS_CREATE_ENROLMENT_REQUEST:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_REQUEST:
      return {
        ...state,
        enrolment: {
          ...state.enrolment,
          loading: !action.error,
        },
      };
    case EnrolmentAT.CMS_CREATE_ENROLMENT_SUCCESS:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_SUCCESS:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_SUCCESS:
    case EnrolmentAT.CMS_PATCH_ENROLMENT_SUCCESS:
    case EnrolmentAT.CMS_CREATE_ENROLMENT_FAILURE:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_FAILURE:
    case EnrolmentAT.CMS_FETCH_ENROLMENT_FAILURE:
    case EnrolmentAT.CMS_PATCH_ENROLMENT_FAILURE:
      return {
        ...state,
        enrolment: {
          ...state.enrolment,
          loading: false,
        },
      };
    case EnrolmentAT.CMS_FETCH_ENROLMENT_REQUEST:
    case EnrolmentAT.CMS_PATCH_ENROLMENT_REQUEST:
      return {
        ...state,
        enrolment: {
          ...state.enrolment,
          loading: !action.error,
        },
      };
    default:
      return state;
  }
};

const initialCostRecordState: CostRecordListState = {};

export const costRecordListReducer = (
  state = initialCostRecordState,
  action: Action
): CostRecordListState => {
  switch (action.type) {
    case EnrolmentAT.CMS_CREATE_COST_RECORD_REQUEST:
    case EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_REQUEST:
      return state;
    case EnrolmentAT.CMS_CREATE_COST_RECORD_SUCCESS:
    case EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.costRecords,
      };
    case EnrolmentAT.CMS_CREATE_COST_RECORD_FAILURE:
    case EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_FAILURE:
      return state;
    default:
      return state;
  }
};

export const costRecordUIReducer = (
  state: CostRecordUIState = {
    costRecord: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): CostRecordUIState => {
  switch (action.type) {
    case EnrolmentAT.CMS_CREATE_COST_RECORD_REQUEST:
    case EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_REQUEST:
      return {
        ...state,
        costRecord: {
          ...state.costRecord,
          loading: !action.error,
        },
      };
    case EnrolmentAT.CMS_CREATE_COST_RECORD_SUCCESS:
    case EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_SUCCESS:
    case EnrolmentAT.CMS_CREATE_COST_RECORD_FAILURE:
    case EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_FAILURE:
      return {
        ...state,
        costRecord: {
          ...state.costRecord,
          loading: false,
        },
      };
    default:
      return state;
  }
};
