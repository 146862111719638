import React, { ReactNode } from 'react';

import { Flex, Box, Paragraph } from '@workshop/ui';
import CardListItem, { CardListItemProps } from './CardListItem';

export interface ItemProps extends CardListItemProps {
  id: string | number;
}

export interface CardsListProps {
  isLoading?: boolean;
  items: ItemProps[];
  noItemsMessage?: ReactNode;
}

const CardsList: React.FC<CardsListProps> = ({
  isLoading = false,
  items,
  noItemsMessage = 'No items to display',
}) => {
  if (isLoading) {
    return <CardListItem isLoading={isLoading} title="" body="" />;
  }

  return (
    <Flex flexDirection="column">
      {items?.length ? (
        items.map((item) => (
          <Box marginBottom={2} key={item.id}>
            <CardListItem {...item} />
          </Box>
        ))
      ) : (
        <Paragraph pt={2}>{noItemsMessage}</Paragraph>
      )}
    </Flex>
  );
};

export default CardsList;
