import { PLATFORM } from 'constants/env';

export const CONTENT_TYPES = {
  MODULE_PROGRESS: 'moduleprogress',
} as const;

export const ENTRY_TYPES = {
  COURSE: 'course',
  COURSE_ASSESSMENT: 'course_assessment',
};

export const DATETIME_FORMAT = {
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  nextWeek: 'dddd [at] LT',
  lastDay: '[Yesterday at] LT',
  lastWeek: '[Last] dddd [at] LT',
  sameElse: 'MMM Do [at] LT',
};

export const DATE_FORMAT = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'MMM Do YYYY',
};

const vocab = {
  email: {
    steppit: '@steppit.com',
    workshop: '@workshop.ws',
  },
  website: {
    steppit: 'steppit.com',
    workshop: 'web.workshop.ws',
  },
  platformDisplayName: {
    steppit: 'Steppit',
    workshop: 'Workshop',
  },
  clipName: {
    steppit: 'bit',
    workshop: 'clip',
  },
  capClipName: {
    steppit: 'Bit',
    workshop: 'Clip',
  },
};

export const PLATFORM_DISPLAY_NAME = vocab.platformDisplayName[PLATFORM];
export const PLATFORM_WEBSITE = vocab.website[PLATFORM];
export const PLATFORM_EMAIL = vocab.email[PLATFORM];

export const CLIP_NAME = vocab.clipName[PLATFORM];
export const CAP_CLIP_NAME = vocab.capClipName[PLATFORM];
