import { takeEvery, select } from 'redux-saga/effects';

import { GlobalState } from 'types';
import { UI_AT } from 'redux/actionTypes/common';

const uiMessagingSelector = (state: GlobalState) => state.ui.messaging;

/**
 * Save a copy of the ui messaging state in the local storage
 * This copy is used to initiate the reducer (see reducers/common/ui)
 * in order to keep the messaging widget open when refreshing the page
 */
function* setMessagingLocalStorage() {
  // @ts-ignore
  const messagingState = yield select(uiMessagingSelector);
  localStorage.setItem('messagingState', JSON.stringify(messagingState));
}

export function* wathcMessagingUI() {
  yield takeEvery(UI_AT.TOGGLE_PRIVATE_MESSAGING, setMessagingLocalStorage);
}
