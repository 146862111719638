const AssistantAT = {
  CREATE_COURSE_PLAN_REQUEST: 'CREATE_COURSE_PLAN_REQUEST',
  CREATE_COURSE_PLAN_SUCCESS: 'CREATE_COURSE_PLAN_SUCCESS',
  CREATE_COURSE_PLAN_FAILURE: 'CREATE_COURSE_PLAN_FAILURE',

  GET_COURSE_PLAN_REQUEST: 'GET_COURSE_PLAN_REQUEST',
  GET_COURSE_PLAN_SUCCESS: 'GET_COURSE_PLAN_SUCCESS',
  GET_COURSE_PLAN_FAILURE: 'GET_COURSE_PLAN_FAILURE',

  UPDATE_COURSE_PLAN_REQUEST: 'UPDATE_COURSE_PLAN_REQUEST',
  UPDATE_COURSE_PLAN_SUCCESS: 'UPDATE_COURSE_PLAN_SUCCESS',
  UPDATE_COURSE_PLAN_FAILURE: 'UPDATE_COURSE_PLAN_FAILURE',

  BUILD_COURSE_FROM_PLAN_REQUEST: 'BUILD_COURSE_FROM_PLAN_REQUEST',
  BUILD_COURSE_FROM_PLAN_SUCCESS: 'BUILD_COURSE_FROM_PLAN_SUCCESS',
  BUILD_COURSE_FROM_PLAN_FAILURE: 'BUILD_COURSE_FROM_PLAN_FAILURE',

  GENERATE_CLIP_SUMMARY_REQUEST: 'GENERATE_CLIP_SUMMARY_REQUEST',
  GENERATE_CLIP_SUMMARY_SUCCESS: 'GENERATE_CLIP_SUMMARY_SUCCESS',
  GENERATE_CLIP_SUMMARY_FAILURE: 'GENERATE_CLIP_SUMMARY_FAILURE',

  CREATE_ASSISTANT_RESPONSE_REQUEST: 'CREATE_ASSISTANT_RESPONSE_REQUEST',
  CREATE_ASSISTANT_RESPONSE_SUCCESS: 'CREATE_ASSISTANT_RESPONSE_SUCCESS',
  CREATE_ASSISTANT_RESPONSE_FAILURE: 'CREATE_ASSISTANT_RESPONSE_FAILURE',

  GET_ASSISTANT_RESPONSE_REQUEST: 'GET_ASSISTANT_RESPONSE_REQUEST',
  GET_ASSISTANT_RESPONSE_SUCCESS: 'GET_ASSISTANT_RESPONSE_SUCCESS',
  GET_ASSISTANT_RESPONSE_FAILURE: 'GET_ASSISTANT_RESPONSE_FAILURE',

  UPDATE_ASSISTANT_RESPONSE_REQUEST: 'UPDATE_ASSISTANT_RESPONSE_REQUEST',
  UPDATE_ASSISTANT_RESPONSE_SUCCESS: 'UPDATE_ASSISTANT_RESPONSE_SUCCESS',
  UPDATE_ASSISTANT_RESPONSE_FAILURE: 'UPDATE_ASSISTANT_RESPONSE_FAILURE',

  GET_DIGEST_REQUEST: 'GET_DIGEST_REQUEST',
  GET_DIGEST_SUCCESS: 'GET_DIGEST_SUCCESS',
  GET_DIGEST_FAILURE: 'GET_DIGEST_FAILURE',

  GET_DIGEST_LIST_REQUEST: 'GET_DIGEST_LIST_REQUEST',
  GET_DIGEST_LIST_SUCCESS: 'GET_DIGEST_LIST_SUCCESS',
  GET_DIGEST_LIST_FAILURE: 'GET_DIGEST_LIST_FAILURE',
} as const;

export default {
  ...AssistantAT,
};
