import { callAPI } from 'utils';
import API from 'constants/api';
import { RatingAT } from 'redux/actionTypes/learner';

export const addRating = ({
  model,
  id,
  vote,
  feedback,
}: {
  model: string;
  id: number;
  vote: 'true' | 'false';
  feedback?: string;
}) => {
  const data = new FormData();
  data.append('model', model);
  data.append('id', id.toString());
  data.append('vote', vote);
  if (feedback) {
    data.append('feedback', feedback);
  }

  return callAPI({
    types: [
      RatingAT.ADD_RATING_REQUEST,
      RatingAT.ADD_RATING_SUCCESS,
      RatingAT.ADD_RATING_FAILURE,
    ],
    body: data,
    endpoint: API.learner.addRating,
    method: 'POST',
  });
};
