/**
 * Takes a list of functions that each return a promise
 * --> resolve each promise after the other (recursively)
 **/
export const resolveQueue = async (
  queue: (() => Promise<any>)[]
): Promise<any> => {
  if (!queue.length) return;

  const newQueue = [...queue];
  const first = newQueue.shift();
  first && (await first());

  return Promise.resolve(resolveQueue(newQueue));
};

export default {
  resolveQueue,
};
