import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { categoryActions } from 'redux/actions/common';
/**
 * Use this hook to fetch permissions/roles/organisations
 * Returns true once all the above have been fetched
 * */
const useLoadBaseData = () => {
  const dispatch = useDispatch();
  const [dataFetched, setDataFetched] = useState(false);

  // Load base data required to determine the users security access
  useEffect(() => {
    Promise.all([dispatch(categoryActions.list())]).then(() =>
      setDataFetched(true)
    );
  }, []);

  return dataFetched;
};

export default useLoadBaseData;
