import moment from 'moment';
import moize from 'moize';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';

import { PROGRESS_STATUS, UNIT_TYPE } from 'constants/courses';
import { getPrivateMessageNotifications } from 'utils/discourse';
import { getCohortSubcategoryId } from 'utils/learner';

import {
  LearnerState,
  ProgressData,
  CourseSummaryState,
  DashboardTopic,
} from 'types/learner';
import { DiscourseState, UserProfileState } from 'types/common';

import { SECTION, ISectionData } from './Sections';
import { Acts, getAct } from './Acts';
import CohortModel from './cohort';
import { getDiscourseCategory } from 'redux/selectors';
import { discourseImageResizeURL } from 'utils/urls';

function shuffleArray(array: any[]) {
  let currentIndex: number = array.length;
  let randomIndex: number;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

/*
 * The DashboardModel is the orchestrator of the dashboard.
 * Its in charge of retrieving the right sections and their data.
 * To add a section to our Dashboard, add your session in Session.ts then add its name to a certain act in Act.ts
 */
class DashboardModel {
  _learner: LearnerState;
  _discourse: DiscourseState;
  _user: UserProfileState;
  _act: ValueOf<Acts>;
  _progressData: ProgressData;
  _discourseData: any;

  constructor(
    learnerState: LearnerState,
    discourseState: DiscourseState,
    userState: UserProfileState
  ) {
    this._learner = learnerState;
    this._discourse = discourseState;
    this._user = userState;
    this._act = this.getAct();
    this._progressData = this.getProgressData();
    this._discourseData = this.getDiscourseData();
  }

  getLearner = () => this._learner;

  getDiscourse = () => this._discourse;

  getUser = () => this._user;

  // Get the representation of the user journey as an "ACT"
  getAct = moize((): ValueOf<Acts> => {
    const {
      courseProgress: { courses },
      courseSchedule,
    } = this.getLearner();
    const { userProfile } = this.getUser();

    return getAct(courses, courseSchedule, userProfile);
  });

  // Retrieve the valid Sections and their priority/order to display on the Dashboard
  getSections = (): ISectionData[] => {
    // Get valid sections
    const sections = this._act.sections;

    const data = { ...this.getProgressData(), ...this.getDiscourseData() };

    // Compute valid sections
    const sectionData = sections.map((section) => {
      const validData = SECTION[section].getSectionData(data);
      return validData;
    });

    // Order & filter out empty sections
    // @ts-ignore
    return orderBy(sectionData, 'priority').filter((section) => section);
  };

  /**
   * Function for building valid sections & items, ordered by priority, to be displayed
   * on the student dashboard.
   *
   * This function is responsible for passing all of our calculated progress & discourse
   * data to our `getSectionData` functions, for each valid section in the current user's
   * 'act', and returning a set of components which can be directly rendered to the
   * dashboard. i.e. data in -> components out
   */
  getSectionItems = (): ISectionData => {
    const sections = this._act.sections;

    const user = this.getUser();

    const data = {
      ...this.getProgressData(),
      ...this.getDiscourseData(),
      user,
    };

    // Compute valid items & filter out empty items
    const sectionData: ISectionData = sections
      .flatMap((section) => SECTION[section].getSectionData(data))
      .filter((item) => item);

    // Order by priority
    const prioritisedItems = orderBy(sectionData, 'priority');

    /**
     * Group items based on their component type. This will result in data that looks
     * something like:
     *
     * {
     *    featureCard: ISectionItem[],
     *    dashboardCard: ISectionItem[],
     *    notificationCard: ISectionItem[],
     *    etc
     * }
     */
    type GroupedItems = { [key: string]: ISectionData };
    const groupedItems: GroupedItems = groupBy(
      prioritisedItems,
      (item) => item.componentType
    );

    const { dashboardCard, featureCard, itemsListCard, notificationCard } =
      groupedItems;

    /** Start constructing the dashboard items */

    /** Start dashboard with the notifications banner (if applicable) & header carousel */
    const dashboardItems: ISectionData = [];

    if (notificationCard) {
      dashboardItems.push(...notificationCard);
    }

    /** Split card-based items into single & multi item cards */
    const allItems = orderBy(
      [
        ...(dashboardCard || []),
        ...(featureCard || []),
        ...(itemsListCard || []),
      ],
      'priority'
    );

    const [multiItemCards, singleItemCards] = partition(
      allItems,
      (item) => item.componentType === 'itemsListCard'
    );

    let multiItemCount = 0;
    for (
      let singleItemCount = 0;
      singleItemCount < Math.max(singleItemCards.length, multiItemCards.length);
      singleItemCount += 2
    ) {
      if (
        singleItemCards[singleItemCount] &&
        singleItemCards[singleItemCount + 1]
      ) {
        dashboardItems.push(singleItemCards[singleItemCount]);
        dashboardItems.push(singleItemCards[singleItemCount + 1]);
      }

      if (multiItemCards[multiItemCount]) {
        dashboardItems.push(multiItemCards[multiItemCount]);
        multiItemCount++;
      }
    }

    // Limit dashboard data to 12 items
    return dashboardItems.slice(0, 12);
  };

  // Group all needed data related to user progresses
  getProgressData = moize((): ProgressData => {
    const learner = this.getLearner();
    const {
      courses: { cohorts },
    } = learner;

    const currentCourses = this.getCurrentCourses();

    const currentSessions = this.getCurrentModuleProgress();

    const currentUnitSchedules = this.getCurrentSchedules();

    const scheduledSessions = this.getScheduledSessions();

    const catchUps = this.getCatchUpSlugs();

    return {
      courseState: learner.courses,
      currentCourses,
      currentSessions,
      currentUnitSchedules,
      scheduledSessions,
      catchUp: {
        courses: catchUps.courses,
        sessions: catchUps.sessions,
      },
      cohorts: Object.values(cohorts),
    };
  });

  // Group all needed data related to discourse (messages, notifications, etc)
  getDiscourseData = moize(() => {
    const discourse = this.getDiscourse();

    const privateMessageNotifications = getPrivateMessageNotifications(
      discourse.notifications
    );

    const topics = this.getTopics();

    return {
      privateMessageNotifications,
      topics,
      discourseMembers: Object.values(discourse.members),
    };
  });

  /**
   * Return the summaries for all current/in progress courses
   */
  getCurrentCourses = (): CourseSummaryState => {
    const {
      courses: {
        courses: { summary },
      },
      courseProgress: { courses },
    } = this.getLearner();

    return Object.values(courses)
      .filter(({ status }) => status === PROGRESS_STATUS.inProgress)
      .reduce((acc, { course }) => {
        acc[course] = summary[course];
        return acc;
      }, {} as CourseSummaryState);
  };

  // /**
  //  * Return the units for all current/in progress courses
  //  */
  // getCurrentUnits = () => {
  //   const {
  //     courses: { units },
  //   } = this.getLearner();

  //   const courses = this.getCurrentCourses();

  //   return Object.values(courses)
  //     .filter(({ status }) => status === PROGRESS_STATUS.inProgress)
  //     .map(({ course }) => summary[course]);
  // };

  /**
   * Return the session progress objects for all in progress sessions
   */
  getCurrentModuleProgress = () => {
    const {
      courseProgress: { modules },
    } = this.getLearner();

    return Object.values(modules)
      .filter(({ status }) => status === PROGRESS_STATUS.inProgress)
      .sort((a, b) => b.id - a.id);
  };

  /**
   * Return course & session slugs for all courses and sessions which
   * are incomplete and in the past. This will not include sessions which
   * are incomplete for the current week.
   */
  getCatchUpSlugs = (): { courses: string[]; sessions: string[] } => {
    const { courses, courseProgress } = this.getLearner();

    // Last week's units
    const pastUnitSchedules = this.getPastSchedules();

    // Last week's sessions
    const sessionSlugs = pastUnitSchedules.flatMap((s) => s.moduleSchedules);

    // Incomplete sessions
    const incompleteSessions = sessionSlugs.filter(
      (slug) =>
        courseProgress.modules[slug]?.status !== PROGRESS_STATUS.complete
    );

    // A catch up session is a valid incomplete session (not an assessment)
    //
    // Initialise an empty array for us to capture the course slugs of the
    // catch up sessions.
    let catchUpCoursesSlugs: string[] = [];
    const catchUpSessionsSlugs = incompleteSessions.filter((slug) => {
      const session = courses.modules[slug];
      const unit = courses.units[session.unit];

      // Don't show courses which have expired
      const courseExpiryDate = courseProgress.courses[unit.course]?.expiryDate;
      if (
        Boolean(
          courseExpiryDate &&
            moment(courseExpiryDate).diff(moment(), 'days') <= 0
        )
      ) {
        return false;
      }

      catchUpCoursesSlugs.push(unit.course);

      return unit.unitType !== UNIT_TYPE.assessment;
    });

    // Remove duplicate slugs
    catchUpCoursesSlugs = uniq(catchUpCoursesSlugs);

    return {
      courses: catchUpCoursesSlugs,
      sessions: catchUpSessionsSlugs,
    };
  };

  /**
   * Retrieve and sort all unit schedules
   */
  getAllSchedules = () => {
    const {
      courseSchedule: { units },
    } = this.getLearner();

    return Object.values(units).sort((a, b) =>
      moment(a.weekCommencing).diff(moment(b.weekCommencing))
    );
  };

  /**
   * Retrieve all unit schedules in the past
   */
  getPastSchedules = () => {
    return this.getAllSchedules().filter(({ weekCommencing }) =>
      moment().isAfter(moment(weekCommencing).add(7, 'days'))
    );
  };

  /**
   * Retrieve the unit schedules for the current week.
   */
  getCurrentSchedules = () => {
    // Current week is found by defining a 7 day window after the week commencing
    // date of a unit and detecing whether the current date/time falls within
    // that window
    return this.getAllSchedules().filter(
      ({ weekCommencing }) =>
        moment().isAfter(moment(weekCommencing)) &&
        moment().isBefore(moment(weekCommencing).add(7, 'days'))
    );
  };

  /**
   * Return the session schedules for all currently scheduled sessions, only if
   * they are in the future
   */
  getScheduledSessions = () => {
    const {
      courseSchedule: { modules },
    } = this.getLearner();

    // TODO: Filter out completed sessions
    return Object.values(modules)
      .filter(
        ({ scheduled, scheduledTime }) =>
          scheduled && moment().diff(scheduledTime, 'hours') <= 0
      )
      .sort((a, b) => moment(a.scheduledTime).diff(moment(b.scheduledTime)));
  };

  /**
   * Determine whether there are any outstanding assessments. This is
   * predominantly used to determine whether the course can be shown in
   * its' completed/ended status
   */
  // getIncompleteAssessments = () => {
  //   const { units, unitSchedules, unitProgress } = this.props;

  //   if (!unitSchedules || !unitProgress) return [];

  //   const schedules = Object.values(unitSchedules);

  //   return (
  //     schedules.filter((schedule) => {
  //       const unit = units[schedule.unit];
  //       const progress = unitProgress[schedule.unit];
  //       const isDue = moment().isAfter(moment(schedule.weekCommencing));

  //       return (
  //         unit &&
  //         unit.unitType === UNIT.assessment &&
  //         !progress.assessmentComplete &&
  //         isDue
  //       );
  //     }) || []
  //   );
  // };

  getTopics = (): DashboardTopic[] => {
    const learner = this.getLearner();
    const discourse = this.getDiscourse();

    const {
      cohort,
      courses: {
        courses: { summary },
      },
    } = learner;
    const { categories } = discourse;

    return Object.values(cohort)
      .map((cohort) => {
        // Extract the discourse category ID for this cohort/course
        const categoryId = getCohortSubcategoryId(
          cohort,
          summary[cohort.course]
        );
        // Retrieve the category from discourse, which will include
        // a list of topics
        const category = categoryId
          ? getDiscourseCategory(categoryId, categories)
          : null;

        // Return null and filter out afterwards
        if (!category) return null;

        // Init our cohort model with the relevant discourse data
        const cohortModel = new CohortModel({
          cohort,
          category,
        });

        return {
          course: cohort.course,
          topics: cohortModel
            .getTopics()
            // Grab the 3 most recent topics from the category
            .sort((a, b) =>
              moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1
            )
            .slice(0, 3)
            .map((topic) => {
              // Enhance the topic from discourse with details on the
              // original poster and with a resized image URL for improved
              // rendering performance of the image
              const originalPoster = cohortModel.getTopicOriginalPoster(
                topic.id
              );

              const imageUrl = topic.imageUrl
                ? discourseImageResizeURL(topic.imageUrl, 'medium')
                : null;

              return {
                ...topic,
                originalPoster,
                imageUrl,
              };
            }),
        };
      })
      .filter((a) => a) as DashboardTopic[];
  };
}

export default DashboardModel;
