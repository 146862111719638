import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { hooks } from 'utils';

import { organisationActions } from 'redux/actions/common';
import { useIsAuthenticated } from 'redux/selectors/auth';

import { GlobalState } from 'types';

const defaultTeamString = localStorage.getItem('defaultTeam');

/**
 * Use this hook to fetch permissions/roles/organisations
 * Returns true once all the above have been fetched
 * */
const useUserAccess = () => {
  const dispatch = useDispatch();
  const [dataFetched, setDataFetched] = useState(false);

  const isAuthenticated = useIsAuthenticated();

  const myTeams = useSelector(
    (state: GlobalState) => state.organisation.myTeams
  );

  const currentTeam = useSelector(
    (state: GlobalState) => state.organisation.currentTeam
  );

  // Load base data required to determine the users security access
  useEffect(() => {
    if (isAuthenticated) {
      Promise.all([
        dispatch(organisationActions.listRoles()),
        dispatch(organisationActions.listPermissions()),
        dispatch(organisationActions.fetchMyTeams()),
      ]).then(() => setDataFetched(true));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    /**
     * If we have a default team string on load, set the users current
     * team to the default value.
     */
    if (defaultTeamString) {
      const defaultTeam: number = parseInt(defaultTeamString);
      dispatch(organisationActions.setCurrentTeam(defaultTeam));
    }
  }, []);

  /**
   * Upon receiving the list of teams our user is part of,
   * determine which team is their 'active' team.
   * If the user has a 'defaultTeam' then there's nothing to do,
   * otherwise default to the first team in their list of teams.
   */
  hooks.useDeepEqualEffect(() => {
    myTeams.forEach(({ team }) => {
      // TODO: Pull in organisation names + (dark) logos in initial myTeams fetch to get rid of this:
      dispatch(organisationActions.fetchOrganisationProfile(team));
    });

    const defaultTeam = myTeams[0]?.team;

    // Only set `defaultTeam` if we have a valid value and it's
    // changed from the current one
    if (defaultTeam && defaultTeam !== currentTeam && !defaultTeamString) {
      dispatch(organisationActions.setCurrentTeam(defaultTeam));
      // Persist to local storage
      localStorage.setItem('defaultTeam', defaultTeam.toString());
    }
  }, [myTeams]);

  return dataFetched;
};

export default useUserAccess;
