import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import sanitizeHtml from 'sanitize-html';

import { simplifyName, emojifyText } from 'utils';

import {
  Box,
  Button,
  Textarea,
  Flex,
  MdIcon,
  Skeleton,
  Text,
} from '@workshop/ui';

import { discourseUrl } from 'constants/env';
import { PostsWithReplies } from 'models/learner';

import { UserAvatar } from 'components/UserAvatar';

const postsToComments = (posts: PostsWithReplies[]): Comment[] =>
  posts.map((p) => ({
    id: p.id,
    postNumber: p.postNumber,
    createdAt: p.createdAt,
    liked: p.liked,
    likeDisabled: !p.canLike,
    replies: postsToComments(p.replies),
    text: p.cooked,
    totalLikes: p.totalLikes,
    user: {
      id: p.userId,
      avatar: `${discourseUrl}${p.avatarTemplate.replace('{size}', '240')}`,
      name: p.name,
    },
  }));

interface ReplyBoxProps {
  id: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ReplyBox: React.FC<ReplyBoxProps> = ({
  id,
  isDisabled,
  isLoading,
  value,
  onChange,
  onSubmit,
}) => {
  const {
    register,
    errors,
    formState: { dirty },
  } = useForm<{ reply: string }>({});

  return (
    <Flex flexDir="column" w="100%">
      <Textarea
        name={`reply_box_${id}`}
        boxSizing="border-box"
        placeholder="Write your comment..."
        defaultValue={value}
        onChange={onChange}
        ref={register({ required: true })}
      />
      {!isDisabled && (
        <Flex justifyContent="flex-end">
          <Button
            isLoading={isLoading}
            mt={2}
            disabled={!dirty || Boolean(errors.reply)}
            onClick={onSubmit}
          >
            Post Comment
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const TopicCommentContext = React.createContext<{
  showReplyToPostNumber: number | null;
  setShowReplyToPostNumber: any;
}>({
  showReplyToPostNumber: null,
  setShowReplyToPostNumber: () => null,
});

export interface Comment {
  id: number;
  createdAt: string;
  likeDisabled?: boolean;
  liked?: boolean;
  postNumber: number;
  replies?: Comment[];
  text: string;
  totalLikes?: number;
  user: {
    id: number;
    avatar?: string;
    name: string;
  };
}

type BaseProps = {
  isLoading?: false;
  topicId?: number;
  onSubmitComment: (id: number, text: string) => Promise<void>;
  onLikeComment: (id: number) => Promise<void>;
  onUnlikeComment: (id: number) => Promise<void>;
};

type BaseTopicCommentsProps =
  | (BaseProps & {
      comments: Comment[];
    })
  | { isLoading: true };

const defaultProps = {
  comments: [],
  topicId: undefined,
  onSubmitComment: async () => {},
  onLikeComment: async () => {},
  onUnlikeComment: async () => {},
};

const BaseTopicComments: React.FC<BaseTopicCommentsProps> = (props) => {
  const { comments, topicId, onSubmitComment, onLikeComment, onUnlikeComment } =
    props.isLoading ? defaultProps : props;

  /**
   * Since the TopicComment component renders other TopicComment recursively,
   * we need to use a global context to keep track of which reply box is currently
   * opened - as we only want to show one reply box open at a time.
   *
   * This context tells us which post number we are currently replying to.
   */
  const { showReplyToPostNumber, setShowReplyToPostNumber } =
    useContext(TopicCommentContext);

  /**
   * This state is using for keeping track of replies currently being
   * written for a given post number (i.e, reply string indexed by post number)
   */
  const [postNumberReplies, setPostNumberReplies] = useState<{
    [key: number]: string | undefined;
  }>({});

  /**
   * This state is used to keep track of which posts number(s) we are
   * currently in the process of submitting a reply for
   * (i.e after hitting the submit button)
   */
  const [submittingForPostNumbers, setSubmittingForPostNumbers] = useState<
    number[]
  >([]);

  if (props.isLoading) {
    return (
      <Skeleton isLoaded={false} p={6}>
        <Flex flexDir="column" w="100%" p={6}>
          Loading...
        </Flex>
      </Skeleton>
    );
  }

  return (
    <Flex flexDir="column" w="100%">
      {comments
        .sort((c1, c2) =>
          moment(c1.createdAt).isBefore(c2.createdAt) ? 1 : -1
        )
        .map((c) => {
          const showReplyTo = showReplyToPostNumber === c.postNumber;
          return (
            <Flex flexDir="column" w="100%" key={c.id}>
              <Flex alignItems="center" w="100%" mb={1}>
                <UserAvatar
                  size="2xs"
                  userId={c.user.id}
                  name={c.user.name}
                  avatarPicture={c.user.avatar}
                />
                <Text fontSize="xs" fontWeight="bold" ml={2}>
                  {simplifyName(c.user.name)}
                </Text>
                <Text fontSize="xs" color="text.muted" ml={4}>{`${moment(
                  c.createdAt
                ).format('D MMM YYYY')} at ${moment(c.createdAt).format(
                  'h:mm A'
                )}`}</Text>
              </Flex>
              <Box
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(emojifyText(c.text)),
                }}
              />
              {showReplyTo ? (
                <Box my="defaultMargin">
                  <ReplyBox
                    id={c.postNumber}
                    isDisabled={!Boolean(postNumberReplies[c.postNumber])}
                    isLoading={Boolean(
                      submittingForPostNumbers.includes(c.postNumber)
                    )}
                    value={postNumberReplies[c.postNumber]}
                    onChange={(e) =>
                      setPostNumberReplies({
                        ...postNumberReplies,
                        [c.postNumber]: e.target.value,
                      })
                    }
                    onSubmit={async () => {
                      const reply = postNumberReplies[c.postNumber];
                      if (!reply) return;

                      setSubmittingForPostNumbers((prev) => [
                        ...prev,
                        c.postNumber,
                      ]);
                      await onSubmitComment(c.postNumber, reply);

                      setSubmittingForPostNumbers((prev) =>
                        prev.filter((postNumber) => postNumber !== c.postNumber)
                      );
                      setShowReplyToPostNumber(null);
                      setPostNumberReplies((prev) => ({
                        ...prev,
                        [c.postNumber]: undefined,
                      }));
                    }}
                  />
                </Box>
              ) : (
                <Flex justifyContent="flex-end" alignItems="center">
                  <Flex
                    alignItems="center"
                    cursor="pointer"
                    mr={4}
                    onClick={() => {
                      setShowReplyToPostNumber(c.postNumber);
                    }}
                  >
                    <MdIcon name="Reply" mr={1} />
                    <Text fontSize="xs" marginY={0}>
                      Reply
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <MdIcon
                      cursor={c.likeDisabled ? 'initial' : 'pointer'}
                      name={c.liked ? 'Favorite' : 'FavoriteBorder'}
                      color={
                        c.likeDisabled
                          ? 'icon.disabled'
                          : c.liked
                          ? 'common.notification'
                          : 'icon.default'
                      }
                      onClick={
                        c.likeDisabled
                          ? () => null
                          : () =>
                              c.liked
                                ? onUnlikeComment(c.id)
                                : onLikeComment(c.id)
                      }
                    />
                    {c.totalLikes ? (
                      <Text
                        fontSize="xs"
                        color={c.liked ? 'common.notification' : 'text.default'}
                        ml={1}
                      >
                        {c.totalLikes}
                      </Text>
                    ) : null}
                  </Flex>
                </Flex>
              )}
              {c.replies?.length ? (
                <Flex
                  pl={4}
                  borderLeft="1px solid"
                  borderColor="neutral.300"
                  mb={2}
                >
                  <BaseTopicComments
                    topicId={topicId}
                    onSubmitComment={onSubmitComment}
                    comments={c.replies}
                    onLikeComment={onLikeComment}
                    onUnlikeComment={onUnlikeComment}
                  />
                </Flex>
              ) : null}
            </Flex>
          );
        })}
    </Flex>
  );
};

type TopicCommentsProps =
  | (BaseProps & {
      posts: PostsWithReplies[];
    })
  | { isLoading: true };

const TopicComments: React.FC<TopicCommentsProps> = (props) => {
  const [showReplyToPostNumber, setShowReplyToPostNumber] = useState<
    number | null
  >(null);

  if (props.isLoading) {
    return <BaseTopicComments isLoading />;
  }

  if (!props.posts.length) {
    return (
      <Text color="text.muted">
        Leave a comment above to start the conversation 💬
      </Text>
    );
  }

  const { posts, ...rest } = props;
  const comments = postsToComments(posts);

  const topicCommentProps = { ...rest, comments };

  return (
    <TopicCommentContext.Provider
      value={{ showReplyToPostNumber, setShowReplyToPostNumber }}
    >
      <BaseTopicComments {...topicCommentProps} />
    </TopicCommentContext.Provider>
  );
};

export default TopicComments;
