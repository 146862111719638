/**
 * Given a Discourse image URL, modify the URL to return a resized version of
 * the image. The resized images are pre-generated at 3 different sizes: small,
 * medium and large.
 *
 * Image URLs from Discourse will come through in 4 variations - orignal and
 * optimized at 1X and the same at 2X. Based on which URL we receive, we manipulate
 * the URL differently.
 *
 * 1X original: https://d24ulgn30r4a2h.cloudfront.net/original/1X/00102701d7fa2b4d37ae0c11d989ea073825f704.png
 * 1X optimized: https://s3.eu-west-1.amazonaws.com/workshop-discourse/prod/optimized/1X/0390508d7bf9cdd82e712ee77e99df05243583dd_${version}_1618x2880.jpeg
 * 2X original: https://d24ulgn30r4a2h.cloudfront.net/original/2X/c/cc623ccea46c74d4aeb21b34379e87cb82525cc9.jpeg
 * 2X optimized: https://d24ulgn30r4a2h.cloudfront.net/optimized/2X/c/cc623ccea46c74d4aeb21b34379e87cb82525cc9_${version}_1079x1920.jpeg
 */

const _versionIndex = (uri: string) => {
  // Current discourse version = 2
  // https://github.com/discourse/discourse/blob/c50db76f5d8f409cc5d22a440bbc40b9f25f2a4c/app/models/optimized_image.rb#L10
  const versions = [1, 2, 3, 4, 5];
  let index = -1;
  versions.forEach((version) => {
    if (uri.indexOf(`_${version}_`) !== -1) index = uri.indexOf(`_${version}_`);
  });
  return index;
};

export const discourseImageResizeURL = (
  uri: string | null,
  size: 'small' | 'medium' | 'large' = 'large'
): string => {
  if (!uri) return '';

  let maxDimensions = '80x80';
  if (size === 'medium') maxDimensions = '640x640';
  if (size === 'large') maxDimensions = '1280x1280';

  return uri.indexOf('original') !== -1
    ? uri
        .replace('original/1X', 'optimized/resized')
        .replace(/(original\/\wX)(\/)(\w+\/)+/g, 'optimized/resized/')
        .replace(/(\.[\w\d_-]+)$/i, `_${maxDimensions}$1`)
    : `${uri.substring(0, _versionIndex(uri))}_${maxDimensions}${uri.substring(
        uri.lastIndexOf('.')
      )}`
        .replace('1X', 'resized')
        .replace(/(\wX)(\/)(\w+\/)+/g, 'resized/');
};

export default {
  discourseImageResizeURL,
};
