import React, { useRef } from 'react';

import { Button, Flex, Input } from '@workshop/ui';

import { LabelWrapper } from 'components/Common';

const OverviewVideoInput = ({
  isDisabled = false,
  isLoading,
  isUpdating,
  isPlayDisabled = false,
  onPlay,
  onUpload,
  videoLabel = 'Trailer',
}: {
  isDisabled?: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  isPlayDisabled?: boolean;
  onPlay: () => void;
  onUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  videoLabel?: string;
}) => {
  const fileInput = useRef<HTMLInputElement>();

  return (
    <LabelWrapper label={videoLabel} isLoading={isLoading}>
      <Flex flexDirection="row" flex={1}>
        <Button
          secondary
          mr="defaultMargin"
          isDisabled={isPlayDisabled}
          onClick={onPlay}
        >
          Play
        </Button>
        <Input
          ref={(e: HTMLInputElement | null) => {
            if (e) {
              fileInput.current = e;
            }
          }}
          id="trailer"
          type="file"
          style={{ display: 'none' }}
          onChange={onUpload}
          name="trailer"
          isDisabled={isUpdating}
        />
        {!isDisabled && (
          <Button
            secondary
            mr="defaultMargin"
            onClick={() => fileInput.current?.click()}
            isLoading={isUpdating}
          >
            Upload
          </Button>
        )}
      </Flex>
    </LabelWrapper>
  );
};

export default OverviewVideoInput;
