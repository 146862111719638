import { useEffect, useState } from 'react';

// TODO: Fix TS issues around this package
// @ts-ignore
import 'material-dynamic-colors';

import { MaterialColors } from 'types/common';

import { useTheme } from '@workshop/ui';

const neutralColors = {
  dark: {
    inversePrimary: '#555555',
    onPrimary: '#003547',
    onPrimaryContainer: '#dddddd',
    primary: '#aaaaaa',
    primaryContainer: '#333333',
  },
  light: {
    inversePrimary: '#aaaaaa',
    onPrimary: '#ffffff',
    onPrimaryContainer: '#000000',
    primary: '#555555',
    primaryContainer: '#dddddd',
  },
};

// Input can be image or primary color hex code
// See https://www.npmjs.com/package/material-dynamic-colors
const useColors = (input: string | null | undefined) => {
  const [colors, setColors] = useState<MaterialColors | null>(null);
  const theme = useTheme();

  const getMaterialColors = async (i: string) => {
    try {
      // @ts-ignore
      const materialColors = await materialDynamicColors(i);
      setColors(materialColors);
    } catch {
      // @ts-ignore
      const defaultMaterialColors = await materialDynamicColors(
        theme.colors.common.primary
      );
      setColors(defaultMaterialColors);
    }
  };

  useEffect(() => {
    if (input) {
      getMaterialColors(input);
    }
  }, [input]);

  // If the base color is a grey (i.e. all hex characters are the same), override primary colors
  if (
    colors &&
    input &&
    input.length > 1 &&
    input
      .replace('#', '')
      .split('')
      .every((char) => char === input[1])
  ) {
    return {
      ...colors,
      dark: {
        ...colors.dark,
        ...neutralColors.dark,
      },
      light: {
        ...colors.light,
        ...neutralColors.light,
      },
    };
  }

  return colors;
};

export default useColors;
