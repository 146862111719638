import React from 'react';
import {
  Route,
  Switch,
  RouteComponentProps,
  withRouter,
  matchPath,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LinkBox, LinkOverlay, Flex } from '@workshop/ui';
import Brand from 'components/Brand';

import { GlobalState } from 'types';

import { AppHeader, AppHeading } from 'containers/AppHeader';
import { PageNotFoundScreen } from 'screens/common/PageNotFound';

import navRoutes, { Route as RouteType } from './Routes';

interface RouteRendererProps {
  routes: RouteType[];
  pathname404: string | null;
}

const RouteRenderer = ({ routes, pathname404 }: RouteRendererProps) => (
  <Switch>
    {routes.map((route, idx) => {
      let { path, component: Component } = route;

      const show404 =
        pathname404 &&
        matchPath(pathname404, {
          path: path(),
          exact: true,
          strict: false,
        });

      if (show404)
        return (
          <Route
            key={`route-${route.path()}_${idx}`}
            exact
            path={path()}
            component={PageNotFoundScreen}
          />
        );

      return (
        <Route
          key={`route-${route.path()}_${idx}`}
          exact
          path={path()}
          render={(props) => <Component {...props} />}
        />
      );
    })}
    <Route component={PageNotFoundScreen} />
  </Switch>
);

interface TemplatedGlobalRoutesProps extends RouteComponentProps {}

const TemplatedGlobalRoutes = ({
  location,
  history,
}: TemplatedGlobalRoutesProps) => {
  const pathname404 = useSelector(
    (state: GlobalState) => state.ui.show404ForPath
  );
  const routes = [...Object.values(navRoutes.global)];

  return (
    <>
      <Flex>
        <Flex
          position="fixed"
          top={0}
          left={0}
          padding={{ base: 0, md: 0.5 }}
          zIndex={1100}
          width="100%"
          backgroundColor="background.default"
        >
          <LinkBox padding={3} zIndex={1101}>
            <LinkOverlay href="/">
              <Brand navbarTransparent={false} />
            </LinkOverlay>
          </LinkBox>
          <AppHeader topPosition={0} onBg />
        </Flex>
      </Flex>
      <Flex backgroundColor="background.tint3">
        <Flex flex={1} direction="column">
          <AppHeading alignCenter />
          <RouteRenderer routes={routes} pathname404={pathname404} />
        </Flex>
      </Flex>
    </>
  );
};

export default withRouter(TemplatedGlobalRoutes);
