import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { GlobalState } from 'types';
import { Flex, Text } from '@workshop/ui';
import { FavouritesCardsList } from 'screens/learner/Library/CardsList';
import { userLibraryActions } from 'redux/actions/learner';
import { hooks } from 'utils';
import { getUserLibraryWishlist } from 'redux/selectors/profile';

// Routing Props
interface MatchParams {}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const WishList: React.FC<Props> = ({ workshops }) => {
  const { profile: profileLoading } = hooks.useLoadingDataState({
    profile: { actions: [userLibraryActions.retrieve] },
  });

  const workshopItems = workshops
    ? workshops.map((w) => ({
        id: w.id,
        imageUrl: w.imageLandscapeThumbnail,
        body: w.creatorName,
        title: w.title,
        favouriteEnabled: true,
      }))
    : [];

  return (
    <Flex width="100%" justifyContent="center">
      <Flex
        paddingX={[3, null]}
        pt={4}
        width={['100%', '75%', '70%']}
        flexDirection="column"
      >
        <Text fontWeight="semibold" fontSize="md" mb={4} mt="defaultMargin">
          Courses
        </Text>
        <FavouritesCardsList
          isLoading={profileLoading}
          items={workshopItems || []}
        />
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    workshops: getUserLibraryWishlist(state),
  };
};

const connector = connect(mapStateToProps);

export default connector(WishList);
