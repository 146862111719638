import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { PLATFORM } from 'constants/env';

import { LabelInput } from 'components/Common';
import { EditModal } from 'components/Common/EditModal';

interface NewSessionFormData {
  title: string;
  subtitle: string;
  summaryText: string;
}

interface Props {
  title: string;
  isOpen: boolean;
  isUpdating?: boolean;
  onClose: () => void;
  onSave: (formData: NewSessionFormData) => void;
  onCancel?: () => void;
  error?: boolean;
}

const SESSION_PLACEHOLDERS = [
  'Make the Perfect Flat White',
  "Uncle Neil's Brisket Recipe",
  'Changing a Tire',
  'How to Ollie on a Skateboard',
  'Designing Wedding Invitations',
  'Coding Your First Website',
  'Advanced Yoga Poses',
  'Choosing a Camera Lens',
  'How to Build a Desk',
  'How to Write a Simple Song',
  'My Perfect Workout',
  'How to Sew a Button',
  'Choosing a Drum Set',
  'Train Your Dog to "Speak"',
  'How to Plant Tomatoes',
  'Folding an Origami Crane',
  'Drawing a Cartoon Cat',
  'Basics of Architectural Drawing',
  'Start an Online Store',
];

const noop = () => null;
const NewSessionModal = ({
  title = 'New Session',
  isOpen = false,
  isUpdating = false,
  onClose,
  onSave,
  onCancel = noop,
  error = false,
}: Props) => {
  const [currentPlaceholderIdx, setCurrentPlaceholderIdx] = useState(0);
  const [placeholders, setPlaceholders] = useState(
    SESSION_PLACEHOLDERS.map((p) => '')
  );
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
  } = useForm<NewSessionFormData>();

  useEffect(() => {
    setPlaceholders(
      SESSION_PLACEHOLDERS.map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    );
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextPlaceholderIdx =
        (currentPlaceholderIdx + 1) % SESSION_PLACEHOLDERS.length;
      setCurrentPlaceholderIdx(nextPlaceholderIdx);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [currentPlaceholderIdx]);

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    // react-hook-form calls `preventDefault` on the event internally,
    // but we have to call `stopPropagation` to prevent the modal from
    // closing.
    if (e && e.stopPropagation) {
      // prevent any outer forms from receiving the event too
      e.stopPropagation();
    }

    handleSubmit((formData) => {
      onSave(formData);
    })(e);
  };

  return (
    <EditModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        onClose();
        clearError();
        reset();
      }}
      saveLabel="Create"
      saveDisabled={Boolean(!dirty || errors.title)}
      isUpdating={isUpdating}
    >
      <LabelInput
        id="title"
        name="title"
        label="What are you calling this session?"
        placeholder={
          PLATFORM === 'steppit' ? placeholders[currentPlaceholderIdx] : ''
        }
        labelPosition="top"
        error={!!errors.title}
        errorMessage={errors.title?.message}
        registerInputRef={register({
          required: {
            value: true,
            message: 'Please give your session a title.',
          },
        })}
      />
    </EditModal>
  );
};

export default NewSessionModal;
