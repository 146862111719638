import { callAPI, callAPIWithTeam } from 'utils';
import { decamelize } from 'humps';
import mapKeys from 'lodash/mapKeys';
import {
  CreateCoursePlanAction,
  RetrieveCoursePlanAction,
  UpdateCoursePlanAction,
  BuildCourseFromPlanAction,
  GenerateClipSummaryAction,
  CoursePlanInputs,
  CoursePlan,
  AssistantResponseInputs,
  CreateAssistantResponseAction,
  RetrieveAssistantResponseAction,
  UpdateAssistantResponseAction,
  AssistantResponse,
  RetrieveDigestAction,
  RetrieveDigestListAction,
} from 'types/common';
import { Dispatch } from 'types';

import { DigestSchema } from 'redux/schemas';
import { AssistantAT } from 'redux/actionTypes/common';
import API from 'constants/api';

export const createCoursePlan = (inputs: CoursePlanInputs) => {
  return callAPI<CreateCoursePlanAction>({
    endpoint: API.assistant.createCoursePlan,
    method: 'POST',
    body: { inputs: JSON.stringify(inputs) },
    types: [
      AssistantAT.CREATE_COURSE_PLAN_REQUEST,
      AssistantAT.CREATE_COURSE_PLAN_SUCCESS,
      AssistantAT.CREATE_COURSE_PLAN_FAILURE,
    ],
    meta: { toast: { success: false, error: false } },
  });
};

export const retrieveCoursePlan = (slug: string) => {
  return callAPI<RetrieveCoursePlanAction>({
    endpoint: API.assistant.coursePlan(slug),
    method: 'GET',
    types: [
      AssistantAT.GET_COURSE_PLAN_REQUEST,
      AssistantAT.GET_COURSE_PLAN_SUCCESS,
      AssistantAT.GET_COURSE_PLAN_FAILURE,
    ],
  });
};

export const updateCoursePlan = (slug: string, data: Partial<CoursePlan>) => {
  return callAPI<UpdateCoursePlanAction>({
    endpoint: API.assistant.coursePlan(slug),
    method: 'PATCH',
    body: mapKeys(data, (v, k) => decamelize(k)),
    types: [
      AssistantAT.UPDATE_COURSE_PLAN_REQUEST,
      AssistantAT.UPDATE_COURSE_PLAN_SUCCESS,
      AssistantAT.UPDATE_COURSE_PLAN_FAILURE,
    ],
    meta: { toast: { success: 'Course plan saved' } },
  });
};

export const buildCourseFromPlan = (slug: string) => {
  return callAPIWithTeam<BuildCourseFromPlanAction>({
    endpoint: API.assistant.buildCourseFromPlan(slug),
    method: 'POST',
    types: [
      AssistantAT.BUILD_COURSE_FROM_PLAN_REQUEST,
      AssistantAT.BUILD_COURSE_FROM_PLAN_SUCCESS,
      AssistantAT.BUILD_COURSE_FROM_PLAN_FAILURE,
    ],
    meta: { toast: { success: false } },
  });
};

export const generateClipSummary = (
  contentType: 'clip' | 'unit',
  id: string
) => {
  return callAPIWithTeam<GenerateClipSummaryAction>({
    endpoint: API.assistant.generateClipSummary,
    method: 'POST',
    body: {
      type: contentType,
      id,
    },
    types: [
      AssistantAT.GENERATE_CLIP_SUMMARY_REQUEST,
      AssistantAT.GENERATE_CLIP_SUMMARY_SUCCESS,
      AssistantAT.GENERATE_CLIP_SUMMARY_FAILURE,
    ],
    meta: { toast: { success: 'Summary generated' } },
  });
};

export const createResponse = (inputs: AssistantResponseInputs) => {
  return callAPIWithTeam<CreateAssistantResponseAction>({
    endpoint: API.assistant.createResponse,
    method: 'POST',
    body: { inputs: JSON.stringify(inputs) },
    types: [
      AssistantAT.CREATE_ASSISTANT_RESPONSE_REQUEST,
      AssistantAT.CREATE_ASSISTANT_RESPONSE_SUCCESS,
      AssistantAT.CREATE_ASSISTANT_RESPONSE_FAILURE,
    ],
    meta: { toast: { success: false, error: false } },
  });
};

export const retrieveResponse = (slug: string) => {
  return callAPIWithTeam<RetrieveAssistantResponseAction>({
    endpoint: API.assistant.response(slug),
    method: 'GET',
    types: [
      AssistantAT.GET_ASSISTANT_RESPONSE_REQUEST,
      AssistantAT.GET_ASSISTANT_RESPONSE_SUCCESS,
      AssistantAT.GET_ASSISTANT_RESPONSE_FAILURE,
    ],
  });
};

export const updateResponse = (
  slug: string,
  data: Partial<AssistantResponse>
) => {
  return callAPIWithTeam<UpdateAssistantResponseAction>({
    endpoint: API.assistant.response(slug),
    method: 'PATCH',
    body: mapKeys(data, (v, k) => decamelize(k)),
    types: [
      AssistantAT.UPDATE_ASSISTANT_RESPONSE_REQUEST,
      AssistantAT.UPDATE_ASSISTANT_RESPONSE_SUCCESS,
      AssistantAT.UPDATE_ASSISTANT_RESPONSE_FAILURE,
    ],
  });
};

export const retrieveDigest = (slug: string) => {
  return callAPIWithTeam<RetrieveDigestAction>({
    endpoint: API.assistant.digest(slug),
    method: 'GET',
    types: [
      AssistantAT.GET_DIGEST_REQUEST,
      AssistantAT.GET_DIGEST_SUCCESS,
      AssistantAT.GET_DIGEST_FAILURE,
    ],
  });
};

export const listDigests =
  ({ fetchNextPage, url }: { fetchNextPage?: boolean; url?: string }) =>
  async (dispatch: Dispatch) =>
    dispatch(
      callAPIWithTeam<RetrieveDigestListAction>({
        endpoint: API.assistant.digestList,
        method: 'GET',
        types: [
          AssistantAT.GET_DIGEST_LIST_REQUEST,
          AssistantAT.GET_DIGEST_LIST_SUCCESS,
          AssistantAT.GET_DIGEST_LIST_FAILURE,
        ],
        fetchNextPage: fetchNextPage
          ? async (url: string): Promise<any> =>
              dispatch(listDigests({ fetchNextPage: true, url }))
          : null,
        schema: [DigestSchema],
        path: 'results',
      })
    );
