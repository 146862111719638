import { ToastError as ToastState } from 'redux/selectors';
import { Action } from 'types';
import { MessagingState } from 'types/common';
import { UI_AT } from 'redux/actionTypes/common';

const defaultToast = {
  loading: false,
  error: false,
  errorPayload: null,
  errorMessage: null,
  successMessage: null,
  success: false,
};

export const toastReducer = (
  state: ToastState = defaultToast,
  action: Action
): ToastState => {
  // Generic error toast
  if (action.type === UI_AT.ERROR_TOAST_MESSAGE) {
    return {
      ...state,
      error: true,
      errorMessage: action.message,
      successMessage: null,
      success: false,
    };
  }

  // Generic success toast
  if (action.type === UI_AT.SUCCESS_TOAST_MESSAGE) {
    return {
      ...state,
      error: false,
      errorMessage: null,
      successMessage: action.message,
      success: true,
    };
  }

  // Clear toast state
  if (action.type === UI_AT.CLEAR_TOAST_MESSAGE) {
    return defaultToast;
  }

  // If any other action contains toast metadata, attempt to
  // display the relevant toast. We also enforce only one toast error
  // per screen.
  if (
    'meta' in action &&
    action?.meta?.toast?.error
    // TODO: Prevent toast stacking?
    // && state === defaultToast
  ) {
    // There are three types of toast messages:
    // - Custom messages (defined in the action or dispatch method)
    // - API messages (defined in our API response)
    // - Default messages (defined in our callAPI function)
    //
    // In general, error messages are passed straight through from our
    // toast, unless the error source on the toast is specified as `api`.
    // In this case, we extract the error message from the API response.

    const {
      meta: {
        toast: { error },
      },
    } = action;

    // Our custom or default error message
    let errorMessage = action.meta.toast.error.message;

    // If the error source of the toast has been specified as API, then we'll use
    // our normalized error in the action payload as the source of the error
    // message

    if (
      error.source === 'api' &&
      'payload' in action &&
      action.payload != null &&
      typeof action.payload === 'object' &&
      'normalizedErrors' in action.payload &&
      action.payload?.normalizedErrors
    ) {
      // The error message from the backend may either be a single `errorMessage`
      // string or an array of errorMessages (in this case we take the first value
      // to avoid stacking toasts)
      const { message } = action.payload.normalizedErrors;
      errorMessage = Array.isArray(message) ? message[0] : message;
    }

    return {
      ...state,
      error: true,
      errorMessage,
      successMessage: null,
      success: false,
    };
  }

  if ('meta' in action && action.meta?.toast?.success) {
    return {
      ...state,
      error: false,
      errorMessage: null,
      successMessage: action.meta.toast.success.message,
      success: true,
    };
  }

  return state;
};

export const show404Reducer = (state: string | null = null, action: Action) => {
  if (action.type === UI_AT.CLEAR_404_PATHNAME) return null;

  if (
    'meta' in action &&
    action.meta?.pathname404 &&
    typeof action.meta.pathname404 === 'string' &&
    'payload' in action
  ) {
    const { payload } = action;
    return payload != null &&
      typeof payload === 'object' &&
      'status' in payload &&
      payload.status === 404
      ? action.meta.pathname404
      : null;
  }

  return state;
};

const getConversationIndex = (
  conversationId: number,
  state: MessagingState
) => {
  const indexes = Object.keys(state).map((k) => state[parseInt(k)].idx || 0);
  const lastIdx = indexes.length ? Math.max(...indexes) : 0;
  return state[conversationId]?.idx || lastIdx + 1;
};

/**
 * Use the local storage to keep a copy of the state
 * in order to keep any open chat tabs when closing
 * and reopening the app (or when refreshing the page)
 * (see redux/sagas/setPrivateMessagingState)
 */
const messagingState = localStorage.getItem('messagingState');
const defaultMessagingState = JSON.parse(messagingState || '{}') as {};

export const messagingReducer = (
  state: MessagingState = defaultMessagingState,
  action: Action
) => {
  let conversationId: number | undefined;

  if (action.type !== UI_AT.TOGGLE_PRIVATE_MESSAGING) return state;

  if ('userId' in action.data) {
    conversationId = action.data.userId;
  }

  if ('discourseGroupTopicId' in action.data) {
    conversationId = action.data.discourseGroupTopicId;
  }

  if (!conversationId) return state;

  let newState = action.show
    ? // Showing a new conversation --> adding it to the existing state
      {
        ...state,
        [conversationId]: {
          ...action.data,
          idx: getConversationIndex(conversationId, state),
        },
      }
    : // Hiding an existing conversation --> removing it from the existing state
      {
        ...Object.keys(state).reduce(
          (acc, key) =>
            parseInt(key) === conversationId
              ? { ...acc }
              : { ...acc, [key]: state[parseInt(key)] },
          {}
        ),
      };

  if (Object.keys(newState).length > 3) {
    // If we have more than 3 conversations in the state,
    // only keep the ones with the highest indexes

    newState = Object.keys(newState)
      // Sort conversations by indexes in descending order
      // (i.e highest index in first place)
      .sort((a, b) => {
        const convA = newState[parseInt(a)];
        const convB = newState[parseInt(b)];

        return !convA.idx || !convB.idx ? -1 : convB.idx - convA.idx;
      })
      // Only keep the first 3 in the list
      // (i.e, the 3 conversations that were most recently openned)
      .filter((k, idx) => idx < 3)
      .reduce(
        (acc, k) => ({ ...acc, [parseInt(k)]: newState[parseInt(k)] }),
        {}
      );
  }

  return newState;
};
