import * as assessmentActions from './assessments';
import * as courseActions from './course';
import * as unitActions from './unit';
import * as sessionActions from './session';
import * as stepActions from './step';
import * as videoClipActions from './videoClip';
import * as cohortActions from './cohort';
import * as analyticsActions from './analytics';
import * as enrolmentActions from './enrolment';

const { stepQuestionActions, stepPromptActions } = stepActions;

export {
  assessmentActions,
  cohortActions,
  courseActions,
  stepQuestionActions,
  stepPromptActions,
  sessionActions,
  stepActions,
  unitActions,
  videoClipActions,
  analyticsActions,
  enrolmentActions,
};
