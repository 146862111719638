import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { discourseUrl } from 'constants/env';
import navRoutes from 'navigation/Routes';

import { authActions } from 'redux/actions/common';

import {
  useUser,
  useDiscourseUser,
  useTeams,
  useCurrentTeamProfile,
  useCurrentTeam,
} from 'redux/selectors';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS } from 'constants/organisation';

import { analytics } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { Flex, Box, Text, Divider, MdIcon, useTheme } from '@workshop/ui';
import AnimatedDropDown from 'components/Common/AnimatedDropDown';
import { UserAvatar } from 'components/UserAvatar';
import { ProCta } from 'components/Common';

interface Props {
  onBg?: boolean;
}

const EXTENDED_WIDTH = 300;

const SettingsItem: React.FC<{
  label: string;
  icon: string;
  onClick?: () => void;
}> = ({ label, icon, onClick = () => null }) => (
  <Flex
    alignItems="center"
    padding="defaultPadding"
    cursor="pointer"
    _hover={{ bg: 'background.tint3' }}
    onClick={onClick}
  >
    <MdIcon name={icon} color="icon.default" mr={2} />
    <Text fontSize="sm" fontWeight="semibold">
      {label}
    </Text>
  </Flex>
);

const UserSettingsButton: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const user = useUser();
  const discourseUser = useDiscourseUser();
  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const handleBtnClick = () => setIsOpen(!isOpen);

  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  if (!currentTeamProfile && !user) return null;

  return (
    <AnimatedDropDown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={isOpen ? user?.name : ''}
      extendedWidth={EXTENDED_WIDTH}
      minimisedWidth={isMobile ? 40 : 82}
      maxWidth={EXTENDED_WIDTH}
      titleMaxWidth={EXTENDED_WIDTH - 80}
      justifyContent="flex-start"
      minH="0px"
      secondary
      pl={1}
      pr={1}
      leftIcon={
        <UserAvatar
          name={user?.name}
          userId={user?.id}
          avatarPicture={
            discourseUser?.avatarTemplate
              ? `${discourseUrl}${discourseUser?.avatarTemplate.replace(
                  '{size}',
                  '240'
                )}`
              : undefined
          }
          size="2xs"
          mr={{ base: -2, md: 0 }}
        />
      }
      {...(!isOpen && !isMobile
        ? {
            rightIcon: (
              <MdIcon
                name="ArrowDropDown"
                color="inherit"
                transform={{ base: isOpen ? 'rotate(180deg)' : '', md: '' }}
              />
            ),
          }
        : {})}
      onClick={handleBtnClick}
      onBg={props.onBg}
      name="Options"
    >
      <Flex
        backgroundColor="background.default"
        direction="column"
        width={{ base: '100%', md: EXTENDED_WIDTH }}
      >
        {Boolean(currentTeam && !isPro && PLATFORM === 'steppit') && (
          <Box mx={2} my={2}>
            <ProCta
              hideProIcon
              label=""
              ctaProps={{ variant: 'outline', width: '100%', size: 'sm' }}
              ctaText={
                currentTeamProfile?.proTrialRedeemed === false
                  ? 'Try Steppit Pro for Free'
                  : 'Upgrade Channel to Pro'
              }
            />
          </Box>
        )}
        {Boolean(!currentTeam || PLATFORM === 'workshop') && (
          <>
            <Divider borderColor="border.default" margin={0} />
            <Link to={navRoutes.common.myProfile.path()}>
              <SettingsItem label="My Profile" icon="Person" />
            </Link>
          </>
        )}
        <Divider borderColor="border.default" margin={0} />
        <Link to={navRoutes.common.settings.path()}>
          <SettingsItem label="Settings" icon="Settings" />
        </Link>
        <Divider borderColor="border.default" margin={0} />
        <SettingsItem
          label="Sign Out"
          icon="PowerSettingsNew"
          onClick={() => {
            dispatch(authActions.tokenDeleteRequest());
            analytics.reset();
          }}
        />
      </Flex>
    </AnimatedDropDown>
  );
};

export default UserSettingsButton;
