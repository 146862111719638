import React from 'react';
import { Stack, Text, Flex, Card, MdIcon } from '@workshop/ui';

interface SelectThemeProps {}

const SelectTheme: React.FC<SelectThemeProps> = () => {
  const activeColorMode =
    localStorage.getItem('chakra-ui-color-mode') || 'system';

  const colorModes = [
    {
      name: 'light',
      label: 'Light',
      icon: 'LightMode',
      bg: '#fff',
      color: 'neutral.800',
    },
    {
      name: 'dark',
      label: 'Dark',
      icon: 'DarkMode',
      bg: 'neutral.800',
      color: '#fff',
    },
    {
      name: 'system',
      label: 'Auto',
      icon: 'BrightnessMedium',
      bg: '',
      color: '',
    },
  ];

  return (
    <Card padding="defaultPadding">
      <Stack
        flex={1}
        direction={{ base: 'column', sm: 'row' }}
        spacing="defaultPadding"
      >
        {colorModes.map((cm) => (
          <Flex
            flex={1}
            borderRadius="sm"
            borderWidth={2}
            borderColor={
              activeColorMode === cm.name
                ? 'common.primaryDark'
                : 'border.muted'
            }
            p={4}
            pr={5}
            pt={12}
            flexDirection="column"
            alignItems="flex-end"
            background={cm.bg}
            cursor="pointer"
            _hover={{ borderColor: 'common.primary' }}
            onClick={() => {
              if (cm.name === 'system') {
                localStorage.removeItem('chakra-ui-color-mode');
              } else {
                localStorage.setItem('chakra-ui-color-mode', cm.name);
              }
              window.location.reload();
            }}
          >
            <Flex alignItems="center">
              <MdIcon name={cm.icon} mr={2} color={cm.color} />
              <Text fontWeight="bold" color={cm.color}>
                {cm.label}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Stack>
    </Card>
  );
};

export default SelectTheme;
