import { useSelector } from 'react-redux';
import { GlobalState } from 'types';
import { STATUS } from 'constants/background';

export const useUploadList = () =>
  useSelector((state: GlobalState) => state.background.uploads);

export const useIsCancelled = (id: string) =>
  useSelector(
    (state: GlobalState) =>
      state.background.uploads[id].status === STATUS.cancelled
  );
