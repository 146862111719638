import { useState } from 'react';
import moment from 'moment-timezone';
import { Range } from 'react-date-range';

import { timePeriodOptions, TimePeriod } from 'constants/analytics';
import { calcTimePeriodUnitOptions } from 'utils';

// Time constants
const now = moment();
const monthStart = now.clone().startOf('month');

export default function useDateTimeSelect() {
  // Time Period Unit Type - based on the time period select set an
  // appropriate default time period unit, i.e. day/week/month/year
  const [selectedTimePeriodUnit, setSelectedTimePeriodUnit] =
    useState<TimePeriod>(timePeriodOptions.day);
  // Time Period
  const [selectedTimePeriod, setSelectedTimePeriod] = useState({
    start: monthStart.unix(),
    prevStart: monthStart.clone().subtract(1, 'month').unix(),
    end: now.unix(),
    prevEnd: now.clone().subtract(1, 'month').unix(),
  });

  /** UI CHANGE HANDLERS */
  const handleTimePeriodChange = (range: Range) => {
    /** Handler for time period selection changes */
    const { startDate, endDate } = range;

    const momentStart = moment(startDate);
    // We add 1 day to the end date to make sure that the backend
    // returns data for the last day selected in the frontend
    const momentEnd = moment(endDate).add(1, 'd');

    const start = parseInt(momentStart.format('X'));
    const end = parseInt(momentEnd.format('X'));

    const prevStart = parseInt(
      moment(momentStart).subtract(1, 'month').format('X')
    );
    const prevEnd = parseInt(
      moment(momentEnd).subtract(1, 'month').format('X')
    );

    // Based on the new time period, calculate the unit options
    // so that we can ensure the selected unit is correctly set
    // based on the new time period range.
    const { defaultOption } = calcTimePeriodUnitOptions(
      { start, end },
      selectedTimePeriodUnit
    );

    // Set the time period and unit
    setSelectedTimePeriod({ start, end, prevStart, prevEnd });
    setSelectedTimePeriodUnit(defaultOption);
  };

  const handleTimePeriodUnitChange = async (option: TimePeriod) => {
    /** Handler for time period unit selection changes */

    // If the unit hasn't changed, don't do anything
    if (selectedTimePeriodUnit.value === option.value) return;

    setSelectedTimePeriodUnit(option);
  };

  return {
    selectedTimePeriodUnit,
    selectedTimePeriod,
    handleTimePeriodChange,
    handleTimePeriodUnitChange,
  };
}
