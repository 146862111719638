import { ToastMetaParams, ToastMeta } from 'types/common';

/**
 * Receives a ToastMetaParams object + request method
 * Returns an object containg a success or error object
 *
 * The default behaviour for toasts is:
 * - Always show an errorMessage regardless of the method
 * - Always show a success message for POST/PATCH requests
 * - Never show a message for GET requests, unless explicitly specified
 *
 * This default behaviour can be overriden by adding a `toast`
 * property in the action meta (see ToastMetaParams type)
 */
export const getToastMessage = (
  toastMeta: ToastMetaParams | undefined,
  method: string,
  isSuccess: boolean
): ToastMeta | {} => {
  // If message is defined, always pass that through
  // If source is defined, always pass that through

  if (toastMeta === false) return {};

  if (isSuccess) {
    if (!toastMeta && method === 'GET') {
      // By default, if no `toastMeta` has been provided, or it's
      // explicitly set to `false`, don't return a success message
      // for GET requests.
      return {};
    }

    if (
      typeof toastMeta === 'object' &&
      !('success' in toastMeta) &&
      method === 'GET'
    ) {
      // If a `toastMeta` is defined but there is no 'success' key
      // present, then don't return a success message if it's a
      // GET request
      return {};
    }

    if (typeof toastMeta === 'object' && toastMeta.success === false) {
      // If we've specified that the success property is `false`, then
      // don't show anything
      return {};
    }

    if (
      typeof toastMeta === 'object' &&
      typeof toastMeta.success === 'string'
    ) {
      // If the sucess property has been provided as a string, then pass
      // that string through to be used as the success message
      return { success: { message: toastMeta.success, source: 'custom' } };
    }

    if (
      typeof toastMeta === 'object' &&
      typeof toastMeta.success === 'object'
    ) {
      // If the success property has been provided as an object, then both
      // the toast message and source become configurable and have to be
      // accounted for.

      // If a message has been defined, use it and set the source to 'custom'
      const successValue = toastMeta.success.message
        ? {
            success: {
              message: toastMeta.success.message,
              // If a source has been defined, use it
              source: toastMeta.success.source || 'custom',
            },
          }
        : // If no message has been defined, set a default message depending on the
        // method and set the source to 'default'
        method === 'GET'
        ? {
            success: {
              message: '',
              // If a source has been defined, use it
              source: toastMeta.success.source || 'default',
            },
          }
        : {
            success: {
              message: 'Saved',
              // If a source has been defined, use it
              source: toastMeta.success.source || 'default',
            },
          };
      return successValue;
    }

    // Fallback to a sensible default
    return {
      success: {
        message: method === 'GET' ? '' : 'Saved',
        source: 'default',
      },
    };
  }

  // If Error

  if (typeof toastMeta === 'object' && toastMeta.error === false) {
    // Always show an error if possible unless we specified not to in the toastMeta
    return {};
  }

  if (typeof toastMeta === 'object' && typeof toastMeta.error === 'string') {
    // If the error property has been provided as a string, then pass
    // that string through to be used as the error message
    return { error: { message: toastMeta.error, source: 'custom' } };
  }

  if (typeof toastMeta === 'object' && typeof toastMeta.error === 'object') {
    // If the error property has been provided as an object, then both
    // the toast message and source become configurable and have to be
    // accounted for.

    // If a message has been defined, use it and set the source to 'custom'
    const errorValue = toastMeta.error.message
      ? {
          error: {
            message: toastMeta.error.message,
            // If a source has been defined, use it
            source: toastMeta.error.source || 'custom',
          },
        }
      : // If no message has been defined, set a default message depending on the
      // method and set the source to 'default'
      method === 'GET'
      ? {
          error: {
            message: 'Failed to fetch data',
            // If a source has been defined, use it
            source: toastMeta.error.source || 'default',
          },
        }
      : {
          error: {
            message: 'Failed to save data',
            // If a source has been defined, use it
            source: toastMeta.error.source || 'default',
          },
        };
    return errorValue;
  }

  // Fallback to a sensible default
  return {
    error: {
      message:
        method === 'GET' ? 'Failed to fetch data' : 'Failed to save data',
      source: 'default',
    },
  };
};
