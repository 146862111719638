const GetAnalyticsDataAT = {
  GET_ANALYTICS_DATA_REQUEST: 'GET_ANALYTICS_DATA_REQUEST',
  GET_ANALYTICS_DATA_SUCCESS: 'GET_ANALYTICS_DATA_SUCCESS',
  GET_ANALYTICS_DATA_FAILURE: 'GET_ANALYTICS_DATA_FAILURE',

  GET_COURSE_GROSS_REVENUE_DATA_REQUEST:
    'GET_COURSE_GROSS_REVENUE_DATA_REQUEST',
  GET_COURSE_GROSS_REVENUE_DATA_SUCCESS:
    'GET_COURSE_GROSS_REVENUE_DATA_SUCCESS',
  GET_COURSE_GROSS_REVENUE_DATA_FAILURE:
    'GET_COURSE_GROSS_REVENUE_DATA_FAILURE',

  GET_COURSE_NET_REVENUE_DATA_REQUEST: 'GET_COURSE_NET_REVENUE_DATA_REQUEST',
  GET_COURSE_NET_REVENUE_DATA_SUCCESS: 'GET_COURSE_NET_REVENUE_DATA_SUCCESS',
  GET_COURSE_NET_REVENUE_DATA_FAILURE: 'GET_COURSE_NET_REVENUE_DATA_FAILURE',

  GET_COURSE_SALES_DATA_REQUEST: 'GET_COURSE_SALES_DATA_REQUEST',
  GET_COURSE_SALES_DATA_SUCCESS: 'GET_COURSE_SALES_DATA_SUCCESS',
  GET_COURSE_SALES_DATA_FAILURE: 'GET_COURSE_SALES_DATA_FAILURE',

  GET_COURSE_REFUNDS_DATA_REQUEST: 'GET_COURSE_REFUNDS_DATA_REQUEST',
  GET_COURSE_REFUNDS_DATA_SUCCESS: 'GET_COURSE_REFUNDS_DATA_SUCCESS',
  GET_COURSE_REFUNDS_DATA_FAILURE: 'GET_COURSE_REFUNDS_DATA_FAILURE',

  GET_STUDENT_SATISFACTION_DATA_REQUEST:
    'GET_STUDENT_SATISFACTION_DATA_REQUEST',
  GET_STUDENT_SATISFACTION_DATA_SUCCESS:
    'GET_STUDENT_SATISFACTION_DATA_SUCCESS',
  GET_STUDENT_SATISFACTION_DATA_FAILURE:
    'GET_STUDENT_SATISFACTION_DATA_FAILURE',

  GET_ACTIVE_LEARNERS_DATA_REQUEST: 'GET_ACTIVE_LEARNERS_DATA_REQUEST',
  GET_ACTIVE_LEARNERS_DATA_SUCCESS: 'GET_ACTIVE_LEARNERS_DATA_SUCCESS',
  GET_ACTIVE_LEARNERS_DATA_FAILURE: 'GET_ACTIVE_LEARNERS_DATA_FAILURE',

  GET_COMPLETION_RATE_DATA_REQUEST: 'GET_COMPLETION_RATE_DATA_REQUEST',
  GET_COMPLETION_RATE_DATA_SUCCESS: 'GET_COMPLETION_RATE_DATA_SUCCESS',
  GET_COMPLETION_RATE_DATA_FAILURE: 'GET_COMPLETION_RATE_DATA_FAILURE',

  GET_POST_COUNT_DATA_REQUEST: 'GET_POST_COUNT_DATA_REQUEST',
  GET_POST_COUNT_DATA_SUCCESS: 'GET_POST_COUNT_DATA_SUCCESS',
  GET_POST_COUNT_DATA_FAILURE: 'GET_POST_COUNT_DATA_FAILURE',

  GET_POSTS_PER_STUDENT_DATA_REQUEST: 'GET_POSTS_PER_STUDENT_DATA_REQUEST',
  GET_POSTS_PER_STUDENT_DATA_SUCCESS: 'GET_POSTS_PER_STUDENT_DATA_SUCCESS',
  GET_POSTS_PER_STUDENT_DATA_FAILURE: 'GET_POSTS_PER_STUDENT_DATA_FAILURE',

  GET_SESSIONS_PER_STUDENT_DATA_REQUEST:
    'GET_SESSIONS_PER_STUDENT_DATA_REQUEST',
  GET_SESSIONS_PER_STUDENT_DATA_SUCCESS:
    'GET_SESSIONS_PER_STUDENT_DATA_SUCCESS',
  GET_SESSIONS_PER_STUDENT_DATA_FAILURE:
    'GET_SESSIONS_PER_STUDENT_DATA_FAILURE',

  CLEAR_COURSE_SALES_DATA: 'CLEAR_COURSE_SALES_DATA',
} as const;

export default GetAnalyticsDataAT;
