export const colors = [
  { name: 'purple', rgba: `144, 112, 140`, hex: '#90708c' },
  { name: 'violet', rgba: `164, 190, 243`, hex: '#a4bef3' },
  { name: 'blue', rgba: `89, 91, 134`, hex: '#595b86' },
  { name: 'red', rgba: `231, 107, 116`, hex: '#e76b73' },
  { name: 'gold', rgba: `247, 197, 159`, hex: '#f7c59f' },
  { name: 'brown', rgba: `160, 113, 120`, hex: '#a07178' },
  { name: 'green', rgba: `133, 186, 161`, hex: '#85baa1' },
  { name: 'pink', rgba: `237, 164, 189`, hex: '#eda4be' },
];

export const darkColors = [
  { name: 'purple', rgba: `117, 91, 114`, hex: '#755b72' },
  { name: 'violet', rgba: `146, 169, 217`, hex: '#92a8d9' },
  { name: 'blue', rgba: `73, 75, 110`, hex: '#494b6e' },
  { name: 'red', rgba: `207, 96, 103`, hex: '#cf6067' },
  { name: 'gold', rgba: `230, 189, 157`, hex: '#e6bd9d' },
  { name: 'brown', rgba: `135, 95, 101`, hex: '#875f65' },
  { name: 'green', rgba: `115, 161, 139`, hex: '#73a18b' },
  { name: 'pink', rgba: `212, 146, 169`, hex: '#d492a9' },
];

export const getRandomColor = (
  input: number = 0,
  alpha: number = 1,
  colorsList = colors,
  type: 'hex' | 'rgba' = 'rgba'
): string => {
  const correctedInput = input < 0 ? 0 : input;
  if (type === 'hex') {
    return colorsList[correctedInput % colors.length].hex;
  }
  const color = colorsList[correctedInput % colors.length].rgba;
  return `${color}, ${alpha}`;
};

export const getRandomColors = (
  input: number = 0,
  alpha: number = 1
): [string, string] => {
  const color = getRandomColor(input, alpha);
  const darkColor = getRandomColor(input, alpha, darkColors);

  return [color, darkColor];
};
