import { callAPI } from 'utils';
import API from 'constants/api';

import { CMSAssessmentsSchema } from 'redux/schemas/cms';
import { AssessmentAT } from 'redux/actionTypes/cms';

import { FetchCohortAssessmentsAction, Grade } from 'types/cms';

export const fetchForCohort = (id: number) =>
  callAPI<FetchCohortAssessmentsAction>({
    types: [
      AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_REQUEST,
      AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_SUCCESS,
      AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_FAILURE,
    ],
    endpoint: API.cms.cohortAssessments(id),
    method: 'GET',
    schema: CMSAssessmentsSchema,
  });

export const updateGrade = (id: number, grade: Grade) =>
  callAPI<FetchCohortAssessmentsAction>({
    types: [
      AssessmentAT.CMS_ASSESSMENT_UPDATE_GRADE_REQUEST,
      AssessmentAT.CMS_ASSESSMENT_UPDATE_GRADE_SUCCESS,
      AssessmentAT.CMS_ASSESSMENT_UPDATE_GRADE_FAILURE,
    ],
    endpoint: API.cms.assessment(id),
    method: 'PATCH',
    body: { grade },
  });
