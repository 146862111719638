import React from 'react';
import {
  Card,
  Flex,
  MdIcon,
  Skeleton,
  Heading,
  Text,
  Box,
  LinkBox,
  LinkOverlay,
  LinkButton,
  useColorModeValue,
} from '@workshop/ui';

import { hooks } from 'utils';

interface FeatureCardProps {
  title: string;
  subtitle: string;
  imageUrl: string;
  linkTo: string;
  btnText: string;
  prefix?: string;
  isLoading?: boolean;
  imageOnly?: boolean;
}

interface TextProps {
  title: string;
  subtitle: string;
  textColor?: string;
  prefix?: string;
  isLoading?: boolean;
}

const FeatureText: React.FC<TextProps> = ({
  isLoading,
  title,
  subtitle,
  prefix,
  textColor = 'text.default',
}) => {
  return (
    <Box flex={1}>
      {prefix && !isLoading && (
        <Text fontSize="sm" color={textColor}>
          {prefix}
        </Text>
      )}
      <Skeleton
        isLoaded={!isLoading}
        loadingStyle={{ height: 4, mb: 2, width: 0.6 }}
      >
        <Text fontWeight="semibold" color={textColor}>
          {title}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!isLoading} loadingStyle={{ height: 3, width: 0.4 }}>
        <Text fontSize="sm" color={textColor}>
          {subtitle}
        </Text>
      </Skeleton>
    </Box>
  );
};

export const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  subtitle,
  imageUrl,
  btnText,
  linkTo,
  prefix,
  isLoading = false,
  imageOnly = false,
}) => {
  const colors = hooks.useColors(imageUrl);

  const bg = useColorModeValue(
    colors?.light.primaryContainer,
    colors?.dark.primaryContainer
  );

  const textColor = useColorModeValue(
    colors?.light.onPrimaryContainer,
    colors?.dark.onPrimaryContainer
  );

  return (
    <LinkBox flex={1}>
      <Card flex={1} padding={0}>
        <LinkOverlay href={linkTo} flex={1}>
          <Flex
            flex={1}
            alignItems={imageOnly ? 'normal' : 'center'}
            flexDirection={imageOnly ? 'column' : 'row'}
          >
            <Flex flex={1}>
              <Box w="100%" h={0} paddingBottom="75%" position="relative">
                <Flex
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  backgroundColor="background.tint3"
                  backgroundImage={
                    isLoading || !imageUrl ? '' : `url(${imageUrl})`
                  }
                  backgroundSize="cover"
                  backgroundPosition="center"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  margin={imageOnly ? 0 : 3}
                  borderRadius={imageOnly ? 0 : 'md'}
                >
                  {imageOnly && (
                    <Flex flex={1} padding={3} position="relative">
                      <Box
                        position="absolute"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        backgroundColor={bg || 'background.default'}
                        opacity={0.9}
                      />
                      <Flex flex={1} alignItems="center" position="relative">
                        <Flex flex={1}>
                          <FeatureText
                            isLoading={isLoading}
                            title={title}
                            subtitle={subtitle}
                            prefix={prefix}
                            textColor={textColor || 'text.default'}
                          />
                        </Flex>
                        <MdIcon
                          ml={3}
                          name="KeyboardArrowRight"
                          color={textColor || 'icon.muted'}
                          boxSize="icon"
                        />
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Box>
            </Flex>
            {!imageOnly && (
              <Flex flex={1} flexDirection="column" padding={3}>
                <FeatureText
                  isLoading={isLoading}
                  title={title}
                  subtitle={subtitle}
                  prefix={prefix}
                />
                {!isLoading && (
                  <Box pt={3}>
                    <LinkButton secondary to={linkTo} fontSize="sm">
                      {btnText}
                    </LinkButton>
                  </Box>
                )}
              </Flex>
            )}
          </Flex>
        </LinkOverlay>
      </Card>
    </LinkBox>
  );
};
