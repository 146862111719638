import { createSelector } from 'reselect';
import moize from 'moize';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import moment from 'moment';

import { getUserDetails } from 'redux/selectors/user';
import { selectCurrentTeamProfile } from 'redux/selectors/organisation';

import { GlobalState } from 'types';
import { APIErrorResponse, DiscourseCategoryState } from 'types/common';
import {
  UnitListState,
  SessionListState,
  StepListState,
  VideoClipListState,
  LicenseListState,
} from 'types/cms';

import {
  CourseDetails,
  CourseDetailState,
  Module,
  ModuleProgress,
  ModuleProgressState,
  ModuleSchedule,
  ModuleScheduleState,
  ModuleState,
  Unit,
  UnitProgress,
  UnitProgressState,
  UnitSchedule,
  UnitScheduleState,
  UnitState,
} from 'types/learner';

/** Used to reduce an array of slugs into the relevant state type
  - e.g: to turn a list of unit slugs into a UnitState
      - unitsSlugs.reduce(stateReducer<UnitState, Unit>(state), {})
        - --> returns UnitState
*/
export function stateReducer<StateType, StateValueType>(state: {
  [key: string]: StateValueType;
}) {
  return (acc: StateType, slug: string) =>
    state[slug] ? { ...acc, [slug]: state[slug] } : { ...acc };
}

/** Used to return the list of slugs for a given state
  - e.g: to return all the module slugs for the given UnitState
    - type U = Unit;
    - type M = 'modules';
    - getSlugs<Pick<U, M>, M>(units, 'modules')
      - --> returns the list of module slugs for all units
*/
export function getSlugs<
  T extends { [key: string]: string[] },
  K extends keyof T
>(state: { [key: string]: T } | null, modulesKey: K) {
  return state
    ? Object.keys(state).reduce(
        (acc, u) => [...acc, ...state[u][modulesKey]],
        [] as string[]
      )
    : [];
}

// ------------------------------------- CMS -------------------------------------

export const getUnitsForCourse = moize(
  (units: UnitListState, courseId: number) =>
    pickBy(units, (unit) => unit.course === courseId) as UnitListState
);

export const getSessionsForUnit = moize(
  (sessions: SessionListState, unitId: number) =>
    pickBy(sessions, (session) => session.unit === unitId) as SessionListState
);

export const getStepsForSession = moize(
  (steps: StepListState, sessionId: number) =>
    pickBy(steps, (step) => step.module === sessionId) as StepListState
);

export const getVideoClipsForSession = moize(
  (clips: VideoClipListState, sessionId: number) =>
    pickBy(clips, (clip) => clip.module === sessionId && !clip.step)
);

export const getVideoClipsForStep = moize(
  (clips: VideoClipListState, stepId: number) =>
    pickBy(clips, (clip) => clip.step === stepId) as VideoClipListState
);

export const getVideoClipsForSteps = moize(
  (clips: VideoClipListState, stepIds: number[]) =>
    pickBy(
      clips,
      (clip) => clip.step && stepIds.includes(clip.step)
    ) as VideoClipListState
);

// ------------------------------------ COURSES ------------------------------------

export const getLearnerCourses = (state: GlobalState) =>
  state.learner.courses.courses;

export const getLicensedCourses = (
  courseState: CourseDetailState,
  licenseState: LicenseListState,
  currentTeamOrg: number
) => {
  let licensedCourseSlugs: string[] = [];
  // TODO: Allow management of ongoing classes after expiry date?
  Object.values(licenseState).forEach((license) => {
    const isExpired =
      license.expiryDate && moment(license.expiryDate).isBefore(moment());
    if (!isExpired && license.licensee.id === currentTeamOrg) {
      const slugs = license.courses.map((course) => course.slug);
      licensedCourseSlugs = [...licensedCourseSlugs, ...slugs];
    }
  });

  const courses = licensedCourseSlugs.reduce(
    stateReducer<CourseDetailState, CourseDetails>(courseState),
    {}
  );

  return isEmpty(courses) ? null : courses;
};

// ------------------------------------ UNIT ------------------------------------

export const getUnits = (state: UnitState, unitsSlugs: string[] = []) => {
  const units = unitsSlugs.reduce(stateReducer<UnitState, Unit>(state), {});

  return isEmpty(units) ? null : units;
};

export const getUnitSchedules = (
  state: UnitScheduleState,
  unitSlugs: string[] = []
) => {
  const unitSchedules = unitSlugs.reduce(
    stateReducer<UnitScheduleState, UnitSchedule>(state),
    {}
  );

  return isEmpty(unitSchedules) ? null : unitSchedules;
};

export const getUnitProgress = (
  state: UnitProgressState,
  unitSlugs: string[] = []
) => {
  const unitProgres = unitSlugs.reduce(
    stateReducer<UnitProgressState, UnitProgress>(state),
    {}
  );

  return isEmpty(unitProgres) ? null : unitProgres;
};

// ------------------------------------ MODULES ------------------------------------

export const getModules = (state: ModuleState, moduleSlugs: string[] = []) => {
  const modules = moduleSlugs.reduce(
    stateReducer<ModuleState, Module>(state),
    {}
  );

  return isEmpty(modules) ? null : modules;
};

export const getModuleSchedules = (
  state: ModuleScheduleState,
  moduleSlugs: string[] = []
) => {
  const moduleSchedules = moduleSlugs.reduce(
    stateReducer<ModuleScheduleState, ModuleSchedule>(state),
    {}
  );

  return isEmpty(moduleSchedules) ? null : moduleSchedules;
};

export const getModulesProgress = (
  state: ModuleProgressState,
  moduleSlugs: string[] = []
) => {
  const moduleProgress = moduleSlugs.reduce(
    stateReducer<ModuleProgressState, ModuleProgress>(state),
    {}
  );

  return isEmpty(moduleProgress) ? null : moduleProgress;
};

// ------------------------------------ COHORT ------------------------------------

export const getDiscourseCategory = (
  categoryId: number,
  categories: DiscourseCategoryState
) => categories[categoryId];

export const getCohorts = (state: GlobalState) => state.cms.cohort;

export const getManagedCohorts = createSelector(
  [getCohorts, getUserDetails, selectCurrentTeamProfile],
  (cohorts, user, currentTeamProfile) =>
    Object.values(cohorts).filter(
      (cohort) =>
        cohort.mentors.some((m) => m.id === user.id) &&
        cohort.organisation === currentTeamProfile?.id &&
        cohort.status === 'live'
    )
);

// ------------------------------------ OTHER ------------------------------------

// DEPRECATED in favour of universal toast
export type ToastError = {
  loading: boolean;
  error: boolean;
  errorPayload: APIErrorResponse | null;
  errorMessage: string | null;
  successMessage?: string | null;
  success?: boolean;
};
