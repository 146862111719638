import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { hooks } from 'utils';
import { useCurrentTeam, useCurrentTeamProfile } from 'redux/selectors';
import { assistantActions } from 'redux/actions/common';

import { GlobalState } from 'types';

import { PRO_ORGS } from 'constants/organisation';

import navRoutes from 'navigation/Routes';

import { Box, Flex, Text, MdIcon, LinkButton } from '@workshop/ui';
import { StepsModal } from 'components/Common';
import { Loading } from 'components/Loading';

interface DigestsPopupProps {
  isOpen: boolean;
  onClose: (confirm?: boolean) => void;
}

const DigestsPopup: React.FC<DigestsPopupProps> = ({ isOpen, onClose }) => {
  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const { digests: digestsLoading } = hooks.useLoadingDataState(
    {
      digests: {
        actions: isOpen
          ? [() => assistantActions.listDigests({ fetchNextPage: true })]
          : [],
      },
    },
    [currentTeam, isOpen]
  );

  const digests = useSelector((state: GlobalState) => state.assistant.digests);

  const digestsList = Object.values(digests).sort(
    (a, b) => b.issueNumber - a.issueNumber
  );

  const steps = [
    {
      label: 'Digests',
      icon: <MdIcon name="Done" />,
      hideNextButton: true,
      content: (
        <Box mb={-4}>
          {digestsLoading ? (
            <Box mt={6} mb={10}>
              <Loading />
            </Box>
          ) : (
            digestsList.map((d) => {
              const date = moment(d.created);
              return (
                <Flex
                  key={`digest-list-${d.slug}`}
                  flex={1}
                  flexDirection="column"
                  alignItems="flex-start"
                  borderTopWidth={1}
                  borderColor="border.muted"
                  pt={3}
                  pb={4}
                >
                  <Text fontWeight="semibold" fontSize="sm" color="text.muted">
                    {`${date.format('MMM Do [‘]YY')} • Issue #${d.issueNumber}`}
                  </Text>
                  <Text fontWeight="bold" fontSize="lg" mb={2}>
                    {d.json.summary.headline}
                  </Text>

                  <Text noOfLines={2} color="text.muted">
                    {d.json.summary.body}
                  </Text>
                  {isPro && d.slug && (
                    <LinkButton
                      mt={4}
                      icon="ArrowForward"
                      iconPosition="right"
                      size="sm"
                      variant="outline"
                      alignSelf="flex-end"
                      to={navRoutes.cms.digest.path(d.slug)}
                    >
                      {`Read Issue #${d.issueNumber}`}
                    </LinkButton>
                  )}
                </Flex>
              );
            })
          )}
        </Box>
      ),
    },
  ];

  return (
    <StepsModal
      heading="My Digests"
      isOpen={isOpen}
      onClose={onClose}
      // onCompleteStep={async (stepIndex: number) => {
      //   if (stepIndex === 0) {
      //     onClose();
      //   }
      // }}
      steps={steps}
      forceHorizontalSteps
      hideStepLabels
      hideAllStepLabels
      hideStepList
      hideIndicators
      bigNext
      disablePrev
      verticalCenter={false}
    />
  );
};

export default DigestsPopup;
