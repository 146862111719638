import React, { useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  chakra,
} from '@workshop/ui';
import { Transition, animated } from 'react-spring';

import { NewPostCard } from 'components/NewPostCard';

interface NewPostPopupProps {
  isOpen: boolean;
  onClose: () => void;
  discourseCategoryId?: number;
  courseSlug: string;
  moduleProgressId: number;
  tags: string[] | null;
  moduleTitle: string;
}

const NewPostPopup: React.FC<NewPostPopupProps> = ({
  isOpen,
  onClose,
  discourseCategoryId,
  courseSlug,
  moduleProgressId,
  tags,
  moduleTitle,
}) => {
  const [postSuccess, setPostSuccess] = useState(false);
  const AnimatedModalContent = animated(ModalContent);
  const AnimatedModalOverlay = animated(ModalOverlay);
  return (
    <Modal onClose={onClose} isOpen={isOpen} motionPreset="none" size="lg">
      <Transition
        items={isOpen}
        from={{ opacity: 0, backdropFilter: 'blur(0px)' }}
        enter={{ opacity: 1, backdropFilter: 'blur(10px)' }}
        leave={{ opacity: 0, backdropFilter: 'blur(0px)' }}
      >
        {(styles, item) => (
          <AnimatedModalOverlay backdropFilter="blur(10px)" style={styles} />
        )}
      </Transition>
      <Transition
        items={isOpen}
        from={{ scale: 0.98 }}
        enter={{ scale: 1 }}
        leave={{ scale: 0.98 }}
        config={{ tension: 200, friction: 8 }}
      >
        {(styles, item) => (
          <AnimatedModalContent marginX={2} marginY="20vh" style={styles}>
            <ModalHeader px={{ base: 4, md: 6 }}>
              <Flex alignItems="center">
                <Text fontWeight="bold" pr={6}>
                  New Post
                  <chakra.span
                    fontWeight="semibold"
                    color="text.muted"
                  >{` for ${moduleTitle}`}</chakra.span>
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody paddingTop={0} paddingBottom={4} px={{ base: 4, md: 6 }}>
              <Box>
                {postSuccess ? (
                  <>
                    <Box
                      borderRadius="md"
                      background="background.tint3"
                      padding={2}
                      paddingTop={3}
                      flexDirection="column"
                    >
                      <Text
                        textAlign="center"
                        fontWeight="semibold"
                        mt={3}
                        mb={1}
                      >
                        Post Submitted 🙌
                      </Text>
                      <Text textAlign="center" mb={3}>
                        You can head to your profile to review your posts
                      </Text>
                    </Box>
                    <Flex mt={4} justifyContent="flex-end">
                      <Button size="sm" onClick={onClose}>
                        Done
                      </Button>
                    </Flex>
                  </>
                ) : (
                  <>
                    <NewPostCard
                      onSubmitSuccess={() => setPostSuccess(true)}
                      discourseCategoryId={discourseCategoryId}
                      fullWidth
                      buttonsPosition="bottom"
                      moduleProgressId={moduleProgressId}
                      tags={tags}
                      isTitleRequired={false}
                    />
                  </>
                )}
              </Box>
            </ModalBody>
          </AnimatedModalContent>
        )}
      </Transition>
    </Modal>
  );
};

export default NewPostPopup;
