import React, { useState } from 'react';

import { Flex, MdIcon, Text } from '@workshop/ui';

interface CheckListItem {
  id: number;
  isChecked?: boolean;
  content: string;
  slug: string;
  onClick?: () => void;
  totalNum?: number;
  currentNum?: number;
}

export interface CheckListProps {
  items: CheckListItem[];
  title?: string;
  onItemChecked?: (slug: string) => void;
  onItemUnchecked?: (slug: string) => void;
  onLastItemChecked?: () => void;
  checkable?: boolean;
}

const CheckList: React.FC<CheckListProps> = ({
  items,
  onItemChecked,
  onItemUnchecked,
  onLastItemChecked,
  checkable = true,
}) => {
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>(
    items.filter((i) => i.isChecked).map((i) => i.id)
  );

  const allChecked = items.length === selectedItemIds.length;

  const toggleItemSelected = (itemId: number, isSelected: boolean) => () => {
    if (allChecked) return;

    const item = items.find((i) => i.id === itemId);
    if (!item) return;

    if (isSelected) {
      // Un-Check Item
      setSelectedItemIds(selectedItemIds.filter((id) => id !== itemId));
      onItemUnchecked && onItemUnchecked(item.slug);
      return;
    }

    const newItemIds = [...selectedItemIds, itemId];
    if (newItemIds.length === items.length) {
      onLastItemChecked && onLastItemChecked();
    }
    setSelectedItemIds(newItemIds);
    onItemChecked && onItemChecked(item.slug);
  };

  const selectAll = () => {
    setSelectedItemIds(items.map((i) => i.id));
    onLastItemChecked && onLastItemChecked();
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      mb={checkable ? 4 : -2}
    >
      {items.map(({ id, content, onClick, totalNum, currentNum }) => {
        const isSelected = Boolean(
          selectedItemIds.includes(id) ||
            (currentNum && totalNum && currentNum >= totalNum)
        );
        const color =
          allChecked || isSelected ? 'text.success' : 'text.default';

        const containerStyle = allChecked
          ? {
              backgroundColor: 'background.success',
              borderColor: 'transparent',
            }
          : {
              cursor: checkable || onClick ? 'pointer' : 'auto',
              backgroundColor: isSelected
                ? 'background.success'
                : 'background.tint3',
              borderColor: 'transparent',
              _hover:
                checkable || onClick
                  ? {
                      backgroundColor: isSelected
                        ? 'background.success'
                        : 'background.tint2',
                    }
                  : {},
            };

        const iconColor = isSelected ? 'icon.success' : 'icon.muted';

        return (
          <Flex
            {...containerStyle}
            alignItems="center"
            borderWidth="2px"
            borderRadius={4}
            mb={2}
            onClick={
              checkable
                ? toggleItemSelected(id, isSelected)
                : onClick
                ? onClick
                : () => null
            }
            padding={2}
            width="100%"
            key={`checklistItem-${id}`}
          >
            <Text color={color} flex={1}>
              {content}
            </Text>
            {Boolean(totalNum) && (
              <Text
                color={iconColor}
                fontSize="sm"
                ml={2}
              >{`${currentNum}/${totalNum}`}</Text>
            )}
            {isSelected && <MdIcon color={iconColor} name="TaskAlt" ml={2} />}
          </Flex>
        );
      })}
      {!allChecked && checkable && (
        <Text
          color="common.primary"
          cursor="pointer"
          fontSize="xs"
          fontWeight="semibold"
          mt={2}
          mb={2}
          onClick={selectAll}
        >
          Select All
        </Text>
      )}
    </Flex>
  );
};

export default CheckList;
