import moment from 'moment';

import { PROGRESS_STATUS } from 'constants/courses';

import { UserProfile } from 'types/common';
import {
  CourseProgress,
  CourseProgressState,
  CourseScheduleState,
  UnitSchedule,
} from 'types/learner';

import { SectionType } from './Sections';

// Nb of hours in a week
const week = 24 * 7;

// Define the possible "stages" of a student journey within our platform
type Stages = 'novice' | 'beginner' | 'intermediate' | 'expert';

export type Acts = {
  [key in Stages]: {
    sections: SectionType[];
  };
};

/*
 * We divided the student journey in different acts like in a Play.
 * The student dashboard should reflect the student journey with workshop.
 * Each act contains sections, learn more about sections in Section.ts (order of sessions determined with priority)
 * The elements and sections displayed on the dashboard should be different for a new student and for a seasoned student.
 * This reflects different goals or incentives we want the student to have.
 * i.e. A new student might need more incentive to interact with his cohort while for a recurring one we'll highlight her upcoming sessions
 */
export const ACTS: Acts = {
  // 'New user - No start courses',
  novice: {
    sections: [
      'upcomingSession',
      'currentCourse',
      'currentSession',
      'outstandingSession',
      'scheduledSession',
      'privateMessage',
      'classActivity',
    ],
  },
  // 'First 2 unit of a course',
  beginner: {
    sections: [
      'upcomingSession',
      'currentCourse',
      'currentSession',
      'outstandingSession',
      'scheduledSession',
      'privateMessage',
      'classActivity',
    ],
  },
  // 'Middle of a course',
  intermediate: {
    sections: [
      'upcomingSession',
      'currentCourse',
      'currentSession',
      'outstandingSession',
      'scheduledSession',
      'privateMessage',
      'classActivity',
    ],
  },
  // 'Last 2 unit of a course',
  expert: {
    sections: [
      'upcomingSession',
      'currentCourse',
      'currentSession',
      'outstandingSession',
      'scheduledSession',
      'privateMessage',
      'classActivity',
    ],
  },
};

// Handles the logic discussed above
export const getAct = (
  courseProgress: CourseProgressState['courses'],
  courseSchedule: CourseScheduleState,
  userProfile: UserProfile
) => {
  // Loop through the user's courses, separating them out into past,
  // current and future courses.
  const { pastCourses, currentCourses, futureCourses } = Object.values(
    courseProgress
  ).reduce(
    (acc, progress) => {
      if (progress.status === PROGRESS_STATUS.complete) {
        // User's completed courses
        acc.pastCourses = [...acc.pastCourses, progress];
      } else if (progress.status === PROGRESS_STATUS.inProgress) {
        // User's current courses
        acc.currentCourses = [...acc.currentCourses, progress];
      } else if (progress.status === PROGRESS_STATUS.upcoming) {
        // User's upcoming courses
        acc.futureCourses = [...acc.futureCourses, progress];
      }
      return acc;
    },
    { pastCourses: [], currentCourses: [], futureCourses: [] } as {
      pastCourses: CourseProgress[];
      currentCourses: CourseProgress[];
      futureCourses: CourseProgress[];
    }
  );

  // Time since user last visited our App
  const timeSinceLastVisit = moment().diff(
    moment(userProfile.lastOpenedApp),
    'hours'
  );

  // Loop through all of the user's unit schedules, grouping them
  // into future and past units
  const { futureUnits, pastUnits } = Object.values(courseSchedule.units).reduce(
    (acc, schedule) => {
      if (moment().isAfter(moment(schedule.weekCommencing))) {
        // Next week's units
        acc.futureUnits = [...acc.futureUnits, schedule];
      } else if (moment().isBefore(moment(schedule.weekCommencing))) {
        // Last week's units
        acc.pastUnits = [...acc.pastUnits, schedule];
      }
      return acc;
    },
    { futureUnits: [], pastUnits: [] } as {
      futureUnits: UnitSchedule[];
      pastUnits: UnitSchedule[];
    }
  );

  // NOVICE
  // No previous unit & No past courses => Novice
  const isNovice = !pastUnits.length && pastCourses.length === 0;
  if (isNovice) return ACTS.novice;

  // BEGINNER
  // If only 2 previous unit & 1 current course => Beginner
  const isBeginner = pastUnits.length <= 2 && currentCourses.length === 1;
  if (isBeginner) return ACTS.beginner;

  // EXPERT
  // If only 2 last unit or multiple courses finished a while ago => Expert
  const isExpert = futureUnits.length <= 2 || pastCourses.length > 0;
  if (isExpert) return ACTS.expert;

  // INTERMEDIATE - Defaults
  return ACTS.intermediate;
};
