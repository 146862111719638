import { NAV_HEIGHT } from 'containers/AppHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { FaGem } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';

import { PLATFORM } from 'constants/env';

import { Box, Flex, Text, MdIcon } from '@workshop/ui';

import { NotificationBubble } from 'components/Common';

export type InPageNavTab = {
  slug: string;
  label: string;
  icon: string;
  linkTo?: string;
  notification?: string | number;
  proNotification?: boolean;
};

interface Props {
  tabs: InPageNavTab[];
  initialTab: string;
  onSwitchTab?: (activeTab: string) => void;
  disabled?: boolean;
  navByParams?: boolean;
  rightElement?: React.ReactElement;
  currentTab?: string;
}

const InPageNav: React.FC<Props> = ({
  tabs,
  initialTab,
  onSwitchTab = () => null,
  disabled,
  navByParams,
  rightElement = null,
  currentTab: tabOverride,
}) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentTab, setCurrentTab] = useState(initialTab);

  const navigateTab = (tabSlug: string) => {
    const tab = tabs.find((t) => t.slug === tabSlug);
    if (navByParams) {
      let currentSearchParams = new URLSearchParams(location.search);
      currentSearchParams.set('tab', tabSlug);
      history.push({
        search: currentSearchParams.toString(),
      });
    } else if (tab && tab.linkTo) {
      history.push({
        pathname: tab.linkTo,
      });
    }
    onSwitchTab(tabSlug);
  };

  useEffect(() => {
    if (navByParams && searchParams.has('tab') && !disabled) {
      const urlTab = searchParams.get('tab') as string;
      if (urlTab !== initialTab) {
        setCurrentTab(urlTab);
        navigateTab(urlTab);
      }
    }
  }, [searchParams.has('tab'), disabled, navByParams]);

  useEffect(() => {
    if (tabOverride && tabOverride !== currentTab) {
      setCurrentTab(tabOverride);
      navigateTab(tabOverride);
    }
  }, [tabOverride]);

  return (
    <Flex
      mb={8}
      backgroundColor="background.tint3"
      borderBottomWidth="1px"
      borderColor="border.default"
      position="sticky"
      top={NAV_HEIGHT}
      zIndex={3}
      paddingTop={{ base: 2, md: 0 }}
      width={{ base: '100vw', md: 'auto' }}
      // flexDirection={{ base: 'column-reverse', md: 'row' }}
    >
      <Box
        position="absolute"
        top={-NAV_HEIGHT}
        left={{ base: 0, md: '-30px' }}
        right={{ base: 0, md: '-30px' }}
        height={NAV_HEIGHT + 35}
        backgroundColor="background.tint3"
      />
      <Box
        display={{ base: 'block', md: 'none' }}
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        width="30px"
        bgGradient="linear(to-r, transparent, background.tint3)"
        zIndex={1}
      />
      <Flex
        position="relative"
        marginBottom="-1px"
        opacity={disabled ? 0.5 : 1}
        pointerEvents={disabled ? 'none' : 'auto'}
        paddingX={{ base: 'defaultMargin', md: 0 }}
        paddingRight="30px"
        maxWidth="100vw"
        overflowX="scroll"
        sx={{
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '::-webkit-scrollbar': { display: 'none' },
        }}
        flex={1}
      >
        {tabs.map((tab) => {
          const isCurrentTab = tab.slug === currentTab;
          return (
            <Flex
              key={`tab-${tab.slug}`}
              cursor="pointer"
              mr={{ base: 4, md: 5 }}
              pb={2}
              onClick={() => {
                setCurrentTab(tab.slug);
                navigateTab(tab.slug);
              }}
              borderBottomWidth="4px"
              borderBottomColor={
                isCurrentTab ? 'common.primary' : 'transparent'
              }
              alignItems="center"
              _hover={{
                opacity: 0.8,
              }}
            >
              <MdIcon
                color={isCurrentTab ? 'common.primary' : 'text.default'}
                name={tab.icon}
                mr={2}
              />
              <Text
                color={isCurrentTab ? 'common.primary' : 'text.default'}
                fontWeight="bold"
                flex={1}
                whiteSpace="nowrap"
                fontSize={{ base: 'sm', md: 'md' }}
              >
                {tab.label}
              </Text>
              {Boolean(tab.notification) && (
                <Flex ml={1}>
                  <NotificationBubble number={tab.notification} />
                </Flex>
              )}
              {Boolean(tab.proNotification) && PLATFORM === 'steppit' && (
                <Flex
                  ml={1}
                  mb={2}
                  color="orange.500"
                  _dark={{ color: 'orange.300' }}
                  fontSize="xs"
                >
                  <FaGem />
                </Flex>
              )}
            </Flex>
          );
        })}
      </Flex>
      <Flex pb={1} justifyContent="flex-end" zIndex={1} mr={{ base: 1, md: 0 }}>
        {rightElement}
      </Flex>
    </Flex>
  );
};

export default InPageNav;
