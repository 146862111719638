export const getVideoDimensions = async (url: string) => {
  return new Promise<{ height: number; width: number }>((resolve) => {
    // create the video element
    const video = document.createElement('video');

    // place a listener on it
    if (video.addEventListener) {
      video.addEventListener(
        'loadedmetadata',
        () => {
          // retrieve dimensions
          const height = video.videoHeight;
          const width = video.videoWidth;

          // send back result
          resolve({ height, width });
        },
        false
      );
    }

    // start download meta-datas
    video.src = url;
  });
};

export const getHeightAndWidthFromImageUrl = (
  dataURL: string
): Promise<{
  height: number;
  width: number;
}> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

export default {
  getVideoDimensions,
  getHeightAndWidthFromImageUrl,
};
