import * as H from 'history';

function getParamFromUrl<S = H.LocationState>(
  location: H.Location<S>,
  search: string
): string | null {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get(search) ? urlParams.get(search) : null;
}

export default getParamFromUrl;
