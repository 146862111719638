import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { Button, Flex, MdIcon, Text } from '@workshop/ui';

import { useUploadList } from 'redux/selectors/background';
import { backgroundActions } from 'redux/actions/common';

import { FileUpload } from 'components/FileUpload';
import { UploadStatus } from 'components/AppHeader';

/** Format the given bites number into a file size in MB */
const getFormattedFileSize = (size: number) =>
  `${(size / 1000000).toFixed(1)}MB`;

interface VideoClipsUploadProps {
  onUploadClips: (files: File[]) => Promise<boolean>;
  onClearError: () => void;
  error?: string | null;
}

const VideoClipFile: React.FC<{
  name: string;
  size: number;
  isLoading: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  type: string;
}> = ({ isDisabled = false, name, size, isLoading, onClick, type }) => {
  const fileType = type?.replace(/\/.*/, '');
  return (
    <Flex
      bg="white"
      p={2}
      borderRadius="md"
      mt={1}
      alignItems="center"
      boxShadow="md"
    >
      <MdIcon
        name={
          fileType === 'video'
            ? 'Movie'
            : fileType === 'audio'
            ? 'Mic'
            : fileType === 'text'
            ? 'Title'
            : 'PhotoCamera'
        }
        color="icon.muted"
        boxSize={4}
        mr={2}
      />
      <Text flex={1} fontSize="sm" overflowWrap="anywhere">
        {name}
      </Text>
      <Text fontSize="sm" color="text.muted">
        {getFormattedFileSize(size)}
      </Text>
      <MdIcon
        color={isLoading ? 'icon.disabled' : 'common.notification'}
        name="RemoveCircle"
        ml={2}
        boxSize="18px"
        cursor={isLoading ? 'default' : 'pointer'}
        onClick={isDisabled ? () => null : onClick}
      />
    </Flex>
  );
};

const VideoClipsUpload: React.FC<VideoClipsUploadProps> = ({
  onUploadClips,
  onClearError,
  error: errorProps = null,
}) => {
  const [error, setError] = useState<string | null>(errorProps);
  const [files, setFiles] = useState<File[] | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const uploadList = useUploadList();

  const onDrop = (newFiles: File[], files: File[]) => {
    setError(null);

    if (
      newFiles.find(
        (f) =>
          !f.type.includes('video') &&
          !f.type.includes('audio') &&
          !f.type.includes('image')
      )
    ) {
      setError('Please select image, video and/or audio files only');
      return;
    }
    setFiles(uniqBy([...files, ...newFiles], 'name'));
  };

  useEffect(() => {
    // If errorProp changes, update the error state to display
    // this error in place of any locally set error
    if (error !== errorProps) setError(errorProps);
  }, [errorProps]);

  const clearError = () => {
    onClearError();
    setError(null);
  };

  // Function for handling resetting of the form
  const reset = () => {
    setFiles(null);
    clearError();
    dispatch(backgroundActions.clearChunkUploads());
  };

  return (
    <Flex flexDir="column">
      {files?.length ? (
        <>
          <FileUpload
            name="video-clips-upload"
            label="Drag Media Here"
            accept="video/mp4,video/x-m4v,video/*,audio/*,image/*"
            onDrop={(name, newFiles) => onDrop(newFiles, files)}
            icon={
              <Flex color="icon.muted" mb={2}>
                <MdIcon name="PhotoCamera" boxSize={8} />
                <MdIcon name="Movie" boxSize={8} mx={2} />
                <MdIcon name="Mic" boxSize={8} />
              </Flex>
            }
          />
          <Flex
            bg="background.tint3"
            flexDir="column"
            borderRadius="md"
            p={3}
            mt={2}
          >
            <Text color="text.muted" fontWeight="semibold" mb={2}>
              Media to Upload
            </Text>
            {files
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(({ name, size, type }, id) => {
                const upload = Object.values(uploadList).find(
                  ({ filename }) => filename === name
                );
                return upload && !error ? (
                  <UploadStatus
                    key={`${id}-${name}`}
                    id={`${id}-${name}`}
                    upload={upload}
                  />
                ) : (
                  <VideoClipFile
                    key={`${id}-${name}`}
                    name={name}
                    size={size}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    onClick={() =>
                      setFiles(files.filter((f) => f.name !== name))
                    }
                    type={type}
                  />
                );
              })}
          </Flex>
        </>
      ) : (
        <FileUpload
          name="video-clips-upload"
          label="Drag Media Here"
          accept="video/mp4,video/x-m4v,video/*,audio/*,image/*"
          onDrop={(name, newFiles) => onDrop(newFiles, [])}
          icon={
            <Flex color="icon.muted" mb={2}>
              <MdIcon name="PhotoCamera" boxSize={8} />
              <MdIcon name="Movie" boxSize={8} mx={2} />
              <MdIcon name="Mic" boxSize={8} />
            </Flex>
          }
        />
      )}

      {/* Buttons */}
      {files?.length ? (
        <Flex mt={3} justifyContent="flex-end">
          <Button isDisabled={isSubmitting} mr={2} onClick={reset} secondary>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={async () => {
              setSubmitting(true);
              clearError();
              dispatch(backgroundActions.clearChunkUploads());

              const success = await onUploadClips(files);
              setSubmitting(false);

              // If the upload completed successfully, reset the form, otherwise
              // leave the files loaded
              if (success) {
                reset();
              }
            }}
          >
            Upload Files
          </Button>
        </Flex>
      ) : null}

      {/* Errors */}
      {error && (
        <Text
          color="text.error"
          w="100%"
          textAlign="center"
          mt={1}
          fontSize="xs"
        >
          {error}
        </Text>
      )}
    </Flex>
  );
};

export default VideoClipsUpload;
