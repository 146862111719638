import React, { useState, useEffect } from 'react';
import { useSpring } from 'react-spring';
import isEmpty from 'lodash/isEmpty';

import { Button, Flex, Spinner, Text, Textarea } from '@workshop/ui';

import { AnimatedFlex } from 'components/Common';
import { NewPostCard } from 'components/NewPostCard';

export interface PromptProps {
  id: number | string;
  help?: React.ReactNode;
  promptProps?: {
    discourseCategoryId?: number;
    moduleProgressId: number;
    tags: string[];
    isAssessment: boolean;
    onSubmitSuccess: () => void;
  };
  question: string;
  title?: React.ReactNode;
  responseType: 'none' | 'text' | 'image';
}

const Prompt: React.FC<PromptProps> = ({
  id,
  help,
  question,
  promptProps,
  responseType,
  title,
}) => {
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [helpActive, setHelpActive] = useState(false);

  useEffect(() => {
    setAnswerSubmitted(false);
    setHelpActive(false);
  }, [id]);

  const helpSectionStyle = useSpring({
    opacity: helpActive ? 1 : 0,
  });

  // TODO : Use react-hook-form to handle the text submission

  const header = (
    <>
      {title && <Text textAlign="center">{title}</Text>}
      <Text textAlign="center" fontWeight="semibold" mb={4}>
        {question}
      </Text>
    </>
  );

  if (responseType === 'none') {
    return (
      <Flex flexDirection="column" alignItems="center" padding={2} width="100%">
        {header}
      </Flex>
    );
  }
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      padding={2}
      mt={2}
      width="100%"
    >
      {header}
      {answerSubmitted ? (
        <Flex
          alignItems="center"
          backgroundColor="background.tint3"
          borderRadius="md"
          flexDirection="column"
          maxW="550px"
          mt={4}
          padding={4}
          textAlign="center"
        >
          <Text color="text.muted">Thank you for your submission! 😊</Text>
        </Flex>
      ) : (
        <Flex width="100%" mt={2}>
          {promptProps && (
            <NewPostCard
              fullWidth
              buttonsPosition="bottom"
              buttonsAlign="center"
              isTitleRequired={false}
              submitButtonLabel={
                promptProps?.isAssessment
                  ? 'Submit for Assessment'
                  : 'Submit Post'
              }
              isTextOnly={responseType === 'text'}
              {...(promptProps
                ? {
                    ...promptProps,
                    onSubmitSuccess: () => {
                      setAnswerSubmitted(true);
                      setTimeout(() => {
                        promptProps.onSubmitSuccess();
                      }, 2000);
                    },
                  }
                : {})}
            />
          )}
        </Flex>
      )}
      {!answerSubmitted && !isEmpty(help) && helpActive && (
        // @ts-ignore
        <AnimatedFlex
          alignItems="center"
          backgroundColor="background.tint1"
          borderRadius="md"
          flexDirection="column"
          maxW="550px"
          mt={4}
          padding={4}
          style={helpSectionStyle}
          textAlign="center"
        >
          {help}
          <Text
            cursor="pointer"
            fontSize="xs"
            fontWeight="semibold"
            color="common.primary"
            onClick={() => setHelpActive(false)}
          >
            Dismiss
          </Text>
        </AnimatedFlex>
      )}
      {!answerSubmitted && !isEmpty(help) && !helpActive && (
        <Button
          mt={2}
          secondary
          onClick={() => setHelpActive(true)}
          minWidth="200px"
          icon="Lightbulb"
        >
          See Tip
        </Button>
      )}
    </Flex>
  );
};

export default Prompt;
