import { schema } from 'normalizr';

export const OrganisationsSchema = new schema.Entity(
  'organisations',
  {},
  {
    idAttribute: 'team',
  }
);

export const OrganisationSummarySchema = new schema.Entity(
  'organisations',
  {},
  {
    idAttribute: 'handle',
  }
);
