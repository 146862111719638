import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { Flex, Text, Divider } from '@workshop/ui';

import { EditCard, LabelInput, LabelWrapper } from 'components/Common';
import { Coupon, CouponTypesProps, RedeemCouponAction } from 'types/common';

import { COUPON_TYPES } from 'constants/settings';

interface RedeemCouponFormProps {
  code: string;
}

interface RedeemCouponProps {
  couponCode?: string;
  isLoading?: boolean;
  onSubmit: (coupon: RedeemCouponFormProps) => Promise<RedeemCouponAction>;
  userCoupons: Coupon[];
}

const redeemedText = (couponType: CouponTypesProps): string => {
  switch (couponType) {
    case COUPON_TYPES.monetary:
      return '';
    case COUPON_TYPES.percentage:
      return 'Percentage discount';
    case COUPON_TYPES.token_uni_direction:
      return '1 free Workshop Token';
    case COUPON_TYPES.token_bi_direction:
      return '1 free Workshop Token';
    case COUPON_TYPES.user_referral:
      return '1 free Workshop Token from using a referral code';
    case COUPON_TYPES.workshop:
      return 'Receive selected workshop(s) for free';
    default:
      return '';
  }
};

const RedeemedCouponItem = (props: Coupon) => {
  const { coupon, isRedeemed, redeemedAt } = props;
  const { code, couponType } = coupon;
  return (
    <Flex flexDir="column" flex={1}>
      <Flex flex={1}>
        <Flex flex={1}>
          <Text fontWeight="semibold">
            {redeemedText(COUPON_TYPES[couponType])}
          </Text>
        </Flex>
        <Flex>
          <Text fontWeight="semibold">
            {isRedeemed ? 'Redeemed' : 'Available'}
          </Text>
        </Flex>
      </Flex>
      <Flex flex={1}>
        <Flex flex={1}>
          <Text>Code: {code}</Text>
        </Flex>
        <Flex>
          <Text>{moment(redeemedAt).format('DD MMM YYYY')}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const RedeemCoupon: React.FC<RedeemCouponProps> = (props) => {
  const { isLoading = false, onSubmit, userCoupons, couponCode = '' } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
  } = useForm<RedeemCouponFormProps>({
    defaultValues: {
      code: couponCode,
    },
  });

  const onSave = handleSubmit(async (data) => {
    setIsSubmitting(true);
    const response = await onSubmit(data);

    // If the coupon was successfully redeemed, clear the
    // form
    if (!response.error) {
      reset({ code: '' });
    }

    setIsSubmitting(false);
  });

  return (
    <EditCard
      onSave={onSave}
      onCancel={() => {
        clearError();
        reset({
          code: couponCode,
        });
      }}
      isUpdating={isSubmitting}
      saveDisabled={Boolean(!dirty || !isEmpty(errors))}
      saveLabel="Redeem"
    >
      <Flex flexDir="column">
        <LabelInput
          id="code"
          name="code"
          label="Coupon Code"
          error={Boolean(errors.code)}
          errorMessage="This information is required."
          isLoading={isLoading}
          loadingStyle={{ width: '75%' }}
          registerInputRef={register}
          labelPosition="top"
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              // e.preventDefault();
              onSave();
            }
          }}
          mb={6}
        />
        <LabelWrapper
          label="Redeemed Coupons"
          labelPosition="top"
          isLoading={isLoading}
          loadingStyle={{ height: 12 }}
        >
          {userCoupons.map((coupon, index) => (
            <React.Fragment key={index}>
              <RedeemedCouponItem {...coupon} />
              {index !== userCoupons.length - 1 && (
                <Divider marginY="defaultMargin" />
              )}
            </React.Fragment>
          ))}
          {!userCoupons?.length && (
            <Text color="text.muted" fontSize="sm">
              You haven't redeemed any Coupons yet
            </Text>
          )}
        </LabelWrapper>
      </Flex>
    </EditCard>
  );
};

export default RedeemCoupon;
