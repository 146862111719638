import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/dateTimePicker.css';

import { Box, Button } from '@workshop/ui';

interface DateTimePickerProps {
  selected?: Date | null | undefined;
  onSet: (datetime: string) => void;
  minDate?: Date;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  selected,
  onSet,
  minDate,
}) => {
  const [date, setDate] = useState(selected);
  return (
    <DatePicker
      selected={date}
      onChange={(date: Date) => {
        setDate(date);
      }}
      onCalendarClose={() => {
        if (date && date.toISOString() !== selected?.toISOString()) {
          onSet(date.toISOString());
        }
      }}
      customInput={
        <Box>
          <Button
            icon="Today"
            padding={0}
            borderRadius="full"
            height={7}
            minWidth={7}
            secondary={!!selected}
          />
        </Box>
      }
      showTimeSelect
      {...(minDate
        ? {
            minDate,
          }
        : {})}
    />
  );
};

export default DateTimePicker;
