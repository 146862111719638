import { ChangeEvent, FocusEvent, useState, useEffect } from 'react';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import {
  FormControl,
  FormLabel,
  Text,
  Box,
  ChakraProps,
  shouldForwardProp,
  chakra,
} from '@workshop/ui';
import {
  ariaDescribedByIds,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
  UiSchema,
} from '@rjsf/utils';

import { LabelTextArea } from 'components/Common';

export interface ChakraUiSchema extends Omit<UiSchema, 'ui:options'> {
  'ui:options'?: ChakraUiOptions;
}

type ChakraUiOptions = UiSchema['ui:options'] & { chakra?: ChakraProps };

interface GetChakraProps {
  uiSchema?: ChakraUiSchema;
}

export function getChakra({ uiSchema = {} }: GetChakraProps): ChakraProps {
  const chakraProps =
    (uiSchema['ui:options'] && uiSchema['ui:options'].chakra) || {};

  Object.keys(chakraProps).forEach((key) => {
    /**
     * Leveraging `shouldForwardProp` to remove props
     *
     * This is a utility function that's used in `@chakra-ui/react`'s factory function.
     * Normally, it prevents ChakraProps from being passed to the DOM.
     * In this case we just want to delete the unknown props. So we flip the boolean.
     */
    if (shouldForwardProp(key)) {
      delete (chakraProps as any)[key];
    }
  });

  return chakraProps;
}

export default function TextareaWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  id,
  placeholder,
  value,
  label,
  disabled,
  autofocus,
  readonly,
  onBlur,
  onFocus,
  onChange,
  options,
  schema,
  uiSchema,
  required,
  rawErrors,
  registry,
}: WidgetProps<T, S, F>) {
  const [isTyping, setIsTyping] = useState(uiSchema?.showTypist ? true : false);

  // @ts-ignore
  const chakraProps = getChakra({ uiSchema });
  const { schemaUtils } = registry;
  const displayLabel =
    schemaUtils.getDisplayLabel(schema, uiSchema) &&
    (!!label || !!schema.title);

  const _onChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
    onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLTextAreaElement>) =>
    onBlur(id, value);
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLTextAreaElement>) =>
    onFocus(id, value);

  useEffect(() => {
    if (uiSchema?.showTypist) {
      setIsTyping(true);
    }
  }, [uiSchema?.showTypist]);

  return (
    // @ts-ignore
    <FormControl
      mb={1}
      {...chakraProps}
      isDisabled={disabled || readonly}
      isRequired={required}
      isReadOnly={readonly}
      isInvalid={rawErrors && rawErrors.length > 0}
    >
      {displayLabel ? (
        <FormLabel htmlFor={id}>{label || schema.title}</FormLabel>
      ) : null}

      {uiSchema?.showTypist && isTyping && (
        // @ts-ignore
        <Box disabled py={2} px={4} borderRadius="md" mb={-0.5}>
          <Text position="relative">
            <>
              <chakra.span opacity={0}>{value}</chakra.span>
              <chakra.span
                position="absolute"
                left={0}
                right={0}
                top={0}
                bottom={0}
              >
                <Typist
                  avgTypingDelay={5}
                  cursor={{
                    show: true,
                    blink: true,
                    element: '|',
                    hideWhenDone: true,
                    hideWhenDoneDelay: 300,
                  }}
                  onTypingDone={() => setIsTyping(false)}
                >
                  {value}
                </Typist>
              </chakra.span>
            </>
          </Text>
        </Box>
      )}

      <LabelTextArea
        id={id}
        name={id}
        value={value ?? ''}
        placeholder={placeholder}
        autoFocus={autofocus}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        aria-describedby={ariaDescribedByIds<T>(id)}
        autoResize
        label=""
        isDisabled={disabled || readonly}
        display={uiSchema?.showTypist && isTyping ? 'none' : 'block'}
      />
    </FormControl>
  );
}
