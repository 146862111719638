import { DashboardAT } from 'redux/actionTypes/learner';

import { Action } from 'types';
import { CohortState } from 'types/common';

const initialState = {};

export const userCohortReducer = (
  state: CohortState = initialState,
  action: Action
): CohortState => {
  switch (action.type) {
    case DashboardAT.FETCH_DASHBOARD_SUCCESS:
      return { ...state, ...action.payload.entities.cohorts };
    default:
      return state;
  }
};
