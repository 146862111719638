import { Action } from 'types';
import { AuthState } from 'types/common';
import { Auth } from 'redux/actionTypes/common';

const initialState: AuthState = {
  isAuthenticated: false,
  loading: false,
  error: false,
  errorMessage: null,
  socialProviders: [],
  socialConnections: [],
};

export const authReducer = (
  state = initialState,
  action: Action
): AuthState => {
  switch (action.type) {
    case Auth.FETCH_TOKEN_REQUEST:
    case Auth.REFRESH_TOKEN_REQUEST:
    case Auth.DELETE_TOKEN_REQUEST:
      return {
        ...state,
        error: !!action.error,
        errorMessage: null,
        loading: !action.error,
      };
    case Auth.FETCH_TOKEN_SUCCESS:
    case Auth.REFRESH_TOKEN_SUCCESS:
    case Auth.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case Auth.FETCH_TOKEN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: true,
        errorMessage: 'Login failed - invalid username/password combination.',
      };
    case Auth.SIGNUP_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: true,
        errorMessage: action.payload.normalizedErrors?.message[0] || null,
      };
    case Auth.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: true,
      };
    case Auth.DELETE_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        error: false,
        loading: false,
        errorMessage: null,
      };
    case Auth.DELETE_TOKEN_FAILURE:
      // If there's an error logging out over the API, ignore it as the user's
      // cookie will be deleted locally and they will appear as logged out.
      return {
        ...state,
        isAuthenticated: false,
        error: false,
        loading: false,
        errorMessage: null,
      };
    case Auth.CLEAR_AUTH_ERROR:
      return { ...state, error: false, errorMessage: null };
    case Auth.FETCH_SOCIAL_PROVIDERS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        socialProviders: payload,
      };
    }
    case Auth.FETCH_SOCIAL_CONNECTIONS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        socialConnections: payload.results,
      };
    }
    case Auth.SOCIAL_CONNECT_SUCCESS:
    case Auth.REMOVE_SOCIAL_CONNECTION_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        socialConnections: payload,
      };
    }
    default:
      return state;
  }
};
