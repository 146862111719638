const DiscourseAT = {
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',

  FETCH_USER_SUMMARY_REQUEST: 'FETCH_USER_SUMMARY_REQUEST',
  FETCH_USER_SUMMARY_SUCCESS: 'FETCH_USER_SUMMARY_SUCCESS',
  FETCH_USER_SUMMARY_FAILURE: 'FETCH_USER_SUMMARY_FAILURE',

  FETCH_SUBCATEGORY_REQUEST: 'FETCH_SUBCATEGORY_REQUEST',
  FETCH_SUBCATEGORY_SUCCESS: 'FETCH_SUBCATEGORY_SUCCESS',
  FETCH_SUBCATEGORY_FAILURE: 'FETCH_SUBCATEGORY_FAILURE',

  FETCH_GROUP_MEMBERS_REQUEST: 'FETCH_GROUP_MEMBERS_REQUEST',
  FETCH_GROUP_MEMBERS_SUCCESS: 'FETCH_GROUP_MEMBERS_SUCCESS',
  FETCH_GROUP_MEMBERS_FAILURE: 'FETCH_GROUP_MEMBERS_FAILURE',

  FETCH_DISCOURSE_NOTIFICATIONS_REQUEST:
    'FETCH_DISCOURSE_NOTIFICATIONS_REQUEST',
  FETCH_DISCOURSE_NOTIFICATIONS_SUCCESS:
    'FETCH_DISCOURSE_NOTIFICATIONS_SUCCESS',
  FETCH_DISCOURSE_NOTIFICATIONS_FAILURE:
    'FETCH_DISCOURSE_NOTIFICATIONS_FAILURE',

  FETCH_DISCOURSE_NOTIFICATIONS_COUNT_SUCCESS:
    'FETCH_DISCOURSE_NOTIFICATIONS_COUNT_SUCCESS',

  MARK_READ_NOTIFICATION_REQUEST: 'MARK_READ_NOTIFICATION_REQUEST',
  MARK_READ_NOTIFICATION_SUCCESS: 'MARK_READ_NOTIFICATION_SUCCESS',
  MARK_READ_NOTIFICATION_FAILURE: 'MARK_READ_NOTIFICATION_FAILURE',

  FETCH_DISCOURSE_MESSAGES_REQUEST: 'FETCH_DISCOURSE_MESSAGES_REQUEST',
  FETCH_DISCOURSE_MESSAGES_SUCCESS: 'FETCH_DISCOURSE_MESSAGES_SUCCESS',
  FETCH_DISCOURSE_MESSAGES_FAILURE: 'FETCH_DISCOURSE_MESSAGES_FAILURE',

  CREATE_TOPIC_REQUEST: 'CREATE_TOPIC_REQUEST',
  CREATE_TOPIC_SUCCESS: 'CREATE_TOPIC_SUCCESS',
  CREATE_TOPIC_FAILURE: 'CREATE_TOPIC_FAILURE',

  FETCH_TOPIC_REQUEST: 'FETCH_TOPIC_REQUEST',
  FETCH_TOPIC_SUCCESS: 'FETCH_TOPIC_SUCCESS',
  FETCH_TOPIC_FAILURE: 'FETCH_TOPIC_FAILURE',

  FETCH_TOPIC_POSTS_REQUEST: 'FETCH_TOPIC_POSTS_REQUEST',
  FETCH_TOPIC_POSTS_SUCCESS: 'FETCH_TOPIC_POSTS_SUCCESS',
  FETCH_TOPIC_POSTS_FAILURE: 'FETCH_TOPIC_POSTS_FAILURE',

  FETCH_TOPICS_BY_USER_REQUEST: 'FETCH_TOPICS_BY_USER_REQUEST',
  FETCH_TOPICS_BY_USER_SUCCESS: 'FETCH_TOPICS_BY_USER_SUCCESS',
  FETCH_TOPICS_BY_USER_FAILURE: 'FETCH_TOPICS_BY_USER_FAILURE',

  CREATE_POST_REQUEST: 'CREATE_POST_REQUEST',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_POST_FAILURE: 'CREATE_POST_FAILURE',

  CREATE_POST_REPLY_REQUEST: 'CREATE_POST_REPLY_REQUEST',
  CREATE_POST_REPLY_SUCCESS: 'CREATE_POST_REPLY_SUCCESS',
  CREATE_POST_REPLY_FAILURE: 'CREATE_POST_REPLY_FAILURE',

  LIKE_POST_REQUEST: 'LIKE_POST_REQUEST',
  LIKE_POST_SUCCESS: 'LIKE_POST_SUCCESS',
  LIKE_POST_FAILURE: 'LIKE_POST_FAILURE',

  UNLIKE_POST_REQUEST: 'UNLIKE_POST_REQUEST',
  UNLIKE_POST_SUCCESS: 'UNLIKE_POST_SUCCESS',
  UNLIKE_POST_FAILURE: 'UNLIKE_POST_FAILURE',

  SEND_PRIVATE_MESSAGE_REQUEST: 'SEND_PRIVATE_MESSAGE_REQUEST',
  SEND_PRIVATE_MESSAGE_SUCCESS: 'SEND_PRIVATE_MESSAGE_SUCCESS',
  SEND_PRIVATE_MESSAGE_FAILURE: 'SEND_PRIVATE_MESSAGE_FAILURE',

  DISCOURSE_CREATE_UPLOAD_REQUEST: 'DISCOURSE_CREATE_UPLOAD_REQUEST',
  DISCOURSE_CREATE_UPLOAD_SUCCESS: 'DISCOURSE_CREATE_UPLOAD_SUCCESS',
  DISCOURSE_CREATE_UPLOAD_FAILURE: 'DISCOURSE_CREATE_UPLOAD_FAILURE',

  DISCOURSE_PICK_AVATAR_REQUEST: 'DISCOURSE_PICK_AVATAR_REQUEST',
  DISCOURSE_PICK_AVATAR_SUCCESS: 'DISCOURSE_PICK_AVATAR_SUCCESS',
  DISCOURSE_PICK_AVATAR_FAILURE: 'DISCOURSE_PICK_AVATAR_FAILURE',
} as const;

export default { ...DiscourseAT };
