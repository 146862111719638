export const STATUS = {
  started: 'started',
  created: 'created',
  inProgress: 'in_progress',
  completed: 'completed',
  cancelled: 'cancelled',
  error: 'error',
} as const;

export type IStatus = ValueOf<typeof STATUS>;
