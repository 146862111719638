const WorkshopAT = {
  FETCH_WORKSHOP_LIST_REQUEST: 'FETCH_WORKSHOP_LIST_REQUEST',
  FETCH_WORKSHOP_LIST_SUCCESS: 'FETCH_WORKSHOP_LIST_SUCCESS',
  FETCH_WORKSHOP_LIST_FAILURE: 'FETCH_WORKSHOP_LIST_FAILURE',
} as const;

const UserWorkshopRelationsipAT = {
  FETCH_USER_WORKSHOP_RELATIONSHIP_REQUEST:
    'FETCH_USER_WORKSHOP_RELATIONSHIP_REQUEST',
  FETCH_USER_WORKSHOP_RELATIONSHIP_SUCCESS:
    'FETCH_USER_WORKSHOP_RELATIONSHIP_SUCCESS',
  FETCH_USER_WORKSHOP_RELATIONSHIP_FAILURE:
    'FETCH_USER_WORKSHOP_RELATIONSHIP_FAILURE',
} as const;

export default {
  ...WorkshopAT,
  ...UserWorkshopRelationsipAT,
};
