import React, { useState, useEffect } from 'react';
import { Message } from 'react-hook-form';

import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  BoxProps,
  FormLabelProps,
} from '@workshop/ui';
import { IconTooltip, Tooltip } from 'components/IconTooltip';
import LabelWrapper, { AiButtonProps } from './LabelWrapper';

export interface LabelInputProps extends InputProps {
  error?: boolean;
  errorMessage?: Message;
  helpText?: string | React.ReactNode;
  helpTextPosition?: 'underline' | 'inline' | 'top';
  inputType?: string;
  isLoading?: boolean;
  label?: string;
  labelPosition?: 'top' | 'inline';
  labelButtonText?: string;
  labelButtonOnClick?: () => void;
  registerInputRef?: RegisterFunc<HTMLInputElement>;
  unit?: string;
  childrenAfterInput?: boolean;
  tooltip?: Tooltip;
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  loadingStyle?: BoxProps;
  labelStyleProps?: FormLabelProps;
  placeholders?: string[];
  noMargin?: boolean;
  aiButton?: AiButtonProps;
}

const noop = () => null;

const LabelInput: React.FC<LabelInputProps> = ({
  backgroundColor = 'background.default',
  children,
  childrenAfterInput = true,
  defaultValue = '',
  error = false,
  errorMessage,
  helpText,
  helpTextPosition,
  id,
  inputType = 'text',
  isDisabled = false,
  isLoading = false,
  label,
  labelPosition = 'inline',
  labelStyleProps = {},
  labelButtonText,
  labelButtonOnClick,
  name,
  value,
  onChange = noop,
  onPaste = noop,
  placeholder,
  registerInputRef,
  unit,
  tooltip,
  rightIcon,
  leftIcon,
  loadingStyle,
  flex,
  noMargin = false,
  aiButton,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);
  const [placeholderOpacity, setPlaceholderOpacity] = useState(1);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (placeholder !== currentPlaceholder) {
      setPlaceholderOpacity(0);
      const timeout = setTimeout(() => {
        setCurrentPlaceholder(placeholder);
        setPlaceholderOpacity(1);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [placeholder]);

  if (!!value && !!defaultValue) {
    console.error(
      'Should not provide `value` and `defaultValue` at the same time to `LabelInput`'
    );
  }

  // Don't allow both `value` and `defaultValue` value to be set on the Input component
  // as this is invalid behaviour. Allow `value` to take precedence.
  const valueProps = value ? { value } : { defaultValue };

  return (
    <LabelWrapper
      errorMessage={errorMessage}
      helpText={helpText}
      helpTextPosition={helpTextPosition}
      inputId={id}
      isInvalid={error}
      isLoading={isLoading}
      label={label}
      labelPosition={labelPosition}
      labelButtonText={labelButtonText}
      labelButtonOnClick={labelButtonOnClick}
      unit={unit}
      loadingStyle={loadingStyle}
      labelStyleProps={labelStyleProps}
      noMargin={noMargin}
      aiButton={aiButton}
      setIsFocused={setIsFocused}
      isFocused={isFocused}
    >
      <Flex>
        {!childrenAfterInput && children}
        {!isLoading && (
          <InputGroup flex={1}>
            {leftIcon && <InputLeftElement children={leftIcon} />}
            <Input
              ref={registerInputRef}
              id={id}
              backgroundColor={backgroundColor}
              isDisabled={isDisabled}
              fontSize="md"
              name={name}
              onChange={onChange}
              onPaste={onPaste}
              placeholder={currentPlaceholder}
              _placeholder={{
                opacity: placeholderOpacity,
                transition: 'opacity 0.5s',
              }}
              type={inputType}
              onFocus={(e) => {
                onFocus && onFocus(e);
                setIsFocused(true);
              }}
              onBlur={(e) => {
                onBlur && onBlur(e);
                setIsFocused(false);
              }}
              {...valueProps}
              {...rest}
            />
            {tooltip && !rightIcon && (
              <InputRightElement>
                <IconTooltip tooltip={tooltip} />
              </InputRightElement>
            )}
            {rightIcon && <InputRightElement children={rightIcon} />}
          </InputGroup>
        )}

        {childrenAfterInput && children}
      </Flex>
    </LabelWrapper>
  );
};

export default LabelInput;
