import { callAPI } from 'utils';
import API from 'constants/api';
import { DashboardAT } from 'redux/actionTypes/learner';
import { DashboardSchema } from 'redux/schemas';

import { FetchDashboardAction } from 'types/learner';

export const dashboardActions = {
  retrieve: () => {
    return callAPI<FetchDashboardAction>({
      types: [
        DashboardAT.FETCH_DASHBOARD_REQUEST,
        DashboardAT.FETCH_DASHBOARD_SUCCESS,
        DashboardAT.FETCH_DASHBOARD_FAILURE,
      ],
      endpoint: API.learner.dashboard,
      method: 'GET',
      schema: DashboardSchema,
    });
  },
};
