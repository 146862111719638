import React from 'react';
import styled, { MdIcon, Text, Box } from '@workshop/ui';

import { getDisplayNotification } from 'utils/discourse/notifications';

import { DiscourseNotification, Cohort } from 'types/common';

import { UserAvatar } from 'components/UserAvatar';

interface NotificationElementProps {
  notification: DiscourseNotification;
  handleNotificationClick: () => void;
  cohorts: Cohort[];
}

const StyledText = styled(Text)`
  text-overflow: ellipsis;
`;

const NotificationElement: React.FC<NotificationElementProps> = ({
  notification,
  handleNotificationClick,
  cohorts,
}) => {
  const { showIcon, notifierName, content } = getDisplayNotification(
    notification,
    cohorts
  );
  // TODO: Make this a link for accessibility purposes
  return (
    <Box
      as="div"
      display="flex"
      alignItems="center"
      padding="defaultPadding"
      cursor="pointer"
      _hover={{
        bg: notification.read ? 'background.tint2' : 'background.tint4',
      }}
      backgroundColor={
        notification.read ? 'background.default' : 'background.tint3'
      }
      onClick={handleNotificationClick}
    >
      <UserAvatar
        userId={notifierName.length}
        name={showIcon ? '' : notifierName}
        size="2xs"
        marginRight={4}
        fallbackIcon={
          showIcon ? (
            <MdIcon name="Mail" color="inherit" boxSize="14px" />
          ) : null
        }
      />
      <Text fontWeight="semibold" whiteSpace="nowrap" fontSize="14px">
        {notifierName}
      </Text>
      <StyledText
        whiteSpace="nowrap"
        overflow="hidden"
        paddingLeft={1}
        fontSize="14px"
      >
        {content}
      </StyledText>
    </Box>
  );
};

export default NotificationElement;
