import React, { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import isEqual from 'fast-deep-equal';

import { Box, Flex, BoxProps } from '@workshop/ui';

interface IBaseData {
  id: number | string;
  dragDisabled?: boolean;
}

interface IDraggableBaseData {
  hasChanged?: boolean;
}

export type IDraggableData<T extends {}> = Array<T & IDraggableBaseData>;

type Children<ItemT> = ({
  index: number,
  ...props
}: ItemT & {
  index: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}) => JSX.Element;

type RequiredProps<ItemT> = {
  data: Readonly<Array<ItemT>>;
};

type OptionalProps<ItemT> = {
  dragEnabled?: boolean;
  onDragEnd?: (
    listData: IDraggableData<ItemT>,
    lastDropped: DropResult,
    currentDroppableId: string
  ) => Promise<void> | void;
  children?: Children<ItemT>;
  hasDragHandle?: boolean;
  hasParentContext?: boolean;
  droppableType?: string;
  lastDropped?: DropResult;
};

type Props<ItemT> = RequiredProps<ItemT> & OptionalProps<ItemT> & BoxProps;

/**
 * react-beautiful-dnd allows us to drop and drag the items but they'll
 * revert back to their original list positions, therefore these set of functions
 * are needed to persist the new list order
 */
function reorder<T>(
  list: Readonly<Array<T>>,
  startIndex: number,
  endIndex: number
) {
  const result = Array.from<T>(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function formatOnDragEnd<T>(
  result: DropResult,
  listData: Readonly<Array<T>>
): IDraggableData<T> | undefined {
  const { destination, source } = result;

  // A check for if our item has been dropped outside of our list
  if (!destination) {
    return;
  }

  // A check for if our item has been dropped in the same list position
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  // Reorder our data to show the new list ordering after dragging has
  // finished
  const newListData = reorder<T>(listData, source.index, destination.index);

  return newListData
    .filter((item) => Boolean(item))
    .map((item, idx: number) => {
      return { ...item, hasChanged: !isEqual(item, listData[idx]) };
    });
}

function DraggableContainer<ItemT extends IBaseData>({
  data,
  onDragEnd,
  children,
  dragEnabled = false,
  hasDragHandle = false,
  hasParentContext = false,
  droppableType,
  lastDropped,
  ...rest
}: Props<ItemT>) {
  const [droppableId] = React.useState(nanoid);
  const [listData, setListData] = useState(data);

  const dataString = JSON.stringify(
    data.map((i) => ({ ...i, children: undefined }))
  );
  useEffect(() => {
    // We ensure if we add/remove anything to our data our list gets correctly updated
    setListData(data);
    // eslint-disable-next-line
  }, [dataString]);

  const handleOnDragEnd = async (result: DropResult) => {
    const formattedData = formatOnDragEnd<ItemT>(result, listData);

    if (formattedData) {
      setListData(formattedData);

      if (onDragEnd) {
        await onDragEnd(formattedData, result, droppableId);
      }
    }
  };

  useEffect(() => {
    if (lastDropped) {
      const { source, destination } = lastDropped;
      const isSource = source.droppableId === droppableId;
      const isDestination =
        destination && destination.droppableId === droppableId;
      if (isSource || isDestination) {
        handleOnDragEnd(lastDropped);
      }
    }
  }, [lastDropped]);

  const droppable = (
    <Droppable
      key={droppableId}
      droppableId={droppableId}
      type={droppableType}
      isDropDisabled={!dragEnabled}
    >
      {(dropProvided, dropSnapshot) => (
        <Box
          ref={dropProvided.innerRef}
          background={
            dropSnapshot.isDraggingOver
              ? 'background.primary'
              : Boolean(dropSnapshot.draggingFromThisWith)
              ? 'background.warning'
              : 'transparent'
          }
          {...dropProvided.droppableProps}
          {...rest}
        >
          {listData.map((item, key) => {
            return (
              <Draggable
                key={`${droppableId}-${item.id.toString()}`}
                draggableId={`${droppableId}-${item.id.toString()}`}
                index={key}
                isDragDisabled={!dragEnabled || item.dragDisabled}
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    marginBottom="-1px"
                    {...provided.draggableProps}
                    {...(hasDragHandle ? {} : provided.dragHandleProps)}
                  >
                    {children &&
                      children({
                        ...item,
                        index: key,
                        dragHandleProps: provided.dragHandleProps,
                      })}
                  </Box>
                )}
              </Draggable>
            );
          })}
          {dropProvided.placeholder}
        </Box>
      )}
    </Droppable>
  );

  if (hasParentContext) {
    return droppable;
  }

  return (
    <Flex flexDirection="column">
      <DragDropContext onDragEnd={handleOnDragEnd}>{droppable}</DragDropContext>
    </Flex>
  );
}

export default DraggableContainer;
