// @ts-ignore
import { emojify } from 'react-emojione';

const isValidUrl = (string: string) => {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
};

const capitalize = (s: string | null | undefined) =>
  s ? s.charAt(0).toUpperCase() + s.slice(1) : null;

const simplifyName = (name: string | null | undefined) => {
  let newName = '';
  if (name) {
    const names = name.split(/\s+/);
    let lastInitial = '';
    if (names[1]) {
      lastInitial = ` ${names[1].charAt(0).toUpperCase()}`;
    }
    newName = capitalize(names[0] + lastInitial) || '';
  }
  return newName;
};

const getFlagEmoji = (countryCode: string) => {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));
};

/* === EMOJIFY-RELATED === */

// Our regex for identifying unicode emojis within normal text
const emojiRegEx = /:[0-9a-z_+-]*?(:t\d):/g;

const correctTone = (text: string) => {
  const textSplit = text.split(':');
  const emojiText = textSplit[1];
  let correctedEmojiText = emojiText;

  // Thumbsup and thumbsdown emojies have iOS shortcuts +1 and -1
  // therefore we need to convert them to shorthand to include tones
  if (emojiText === '+1') {
    correctedEmojiText = 'thumbsup';
  }
  if (emojiText === '-1') {
    correctedEmojiText = 'thumbsdown';
  }

  // We max the tone here because discourse has 6 skin tone levels while emojify only has 5
  const discourseToneText = textSplit[2];
  const correctedToneText = Math.min(
    parseInt(discourseToneText.substr(1), 10),
    5
  );
  return `:${correctedEmojiText}_${`tone${correctedToneText}`}:`;
};

const convert = (text: string) => {
  let formattedText = text.replace(/<img.*?alt=":(.*?):".*?>/g, ':$1:');

  const parsedText = formattedText.match(emojiRegEx);

  if (parsedText) {
    // We replace the original text with the modified emoji string
    parsedText.forEach((t) => {
      formattedText = formattedText.replace(t, correctTone(t));
    });
    return formattedText;
  }
  return formattedText;
};

const emojifyText = (text: string) => {
  // Discourse skin tone emoji shorthand differs from the native mobile
  // so we modify it here
  return emojify(convert(text), { output: 'unicode' });
};

/* ========== */

export { isValidUrl, capitalize, simplifyName, getFlagEmoji, emojifyText };
